import React from 'react';

import styled, { css } from 'styled-components';

import renderCustomKernPairs from '@/components/utils/renderCustomKernPairs';
import useSplitTitle from '@/hooks/useSplitTitle';

import type { HeadingSizes } from '../Elements/Heading';
import Heading from '../Elements/Heading';

type TitleAlignments = 'alternating' | 'alternating-reverse' | 'left' | 'center' | 'right' | 'space-between';

const Title = styled(Heading)<{ $align: TitleAlignments }>`
  ${({ $align }) => `
  
  > span {
    display: block;
    text-align: ${$align === 'alternating' ? 'left' : $align === 'alternating-reverse' ? 'right' : $align};
    

    ${
      $align === 'alternating' || $align === 'alternating-reverse' || $align === 'space-between'
        ? `
    &:nth-child(even) {
      text-align: ${$align === 'alternating-reverse' ? 'left' : 'right'};
      margin-left: auto;
    }`
        : ''
    }
  }
    ${
      $align === 'space-between' &&
      css`
        @media (min-width: 769px) {
          display: flex;
          justify-content: space-between;
        }
      `
    }
`}
`;

const LineBreakTitle = React.forwardRef(
  (
    {
      children,
      className,
      size = 1,
      useSpan = false,
      align = 'alternating',
      autoSplit = false,
    }: {
      children: React.ReactNode;
      size: HeadingSizes;
      useSpan?: boolean;
      className?: string;
      align?: TitleAlignments;
      autoSplit?: boolean;
    },
    ref: React.Ref<HTMLHeadingElement>,
  ) => {
    const text = children?.toString?.();
    const autoSplitLines = useSplitTitle({ text });
    const split = (autoSplit && text?.indexOf('\n') === -1 ? autoSplitLines : text?.split('\n')) || [];

    return (
      <Title ref={ref} size={size} className={className} $align={align} useSpan={useSpan}>
        {split.map((line, i) => (
          <span key={line}>{renderCustomKernPairs(`${line.trim()}${i < split.length - 1 ? ' ' : ''}`)}</span>
        ))}
      </Title>
    );
  },
);

LineBreakTitle.displayName = 'LineBreakTitle';

export default LineBreakTitle;
