import React, { createContext, useContext } from 'react';

import { HeaderStateProvider } from '@/components/Header/headerState';
import type {
  StrapiIntegrationSettingsAttributes,
  StrapiSiteSettingsAttributes,
} from '@/utilities/strapi/types/queryResponseTypes';

export enum SectionTheme {
  dark = 'dark',
  light = 'light',
}

export interface StrapiGlobals {
  siteSettings: StrapiSiteSettingsAttributes;
  integrationSettings: StrapiIntegrationSettingsAttributes | null;
  sectionThemes: SectionTheme[];
  slugs: string[];
  locale: string;
}

const Context = createContext<StrapiGlobals | null>(null);

const useStrapiGlobals = (): StrapiGlobals => {
  const context = useContext(Context);

  if (!context) {
    throw new Error('Please use StrapiGlobalsProvider in parent component');
  }

  return context;
};

export const StrapiGlobalsProvider = ({
  children,
  ...value
}: StrapiGlobals & {
  children?: React.ReactNode;
}) => (
  <Context.Provider value={value}>
    <HeaderStateProvider>{children}</HeaderStateProvider>
  </Context.Provider>
);

export default useStrapiGlobals;
