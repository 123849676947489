import parse, { domToReact, Element } from 'html-react-parser';
import type { DOMNode } from 'html-react-parser';
import React from 'react';

import type { StrapiMinimalPageInfo } from '@/utilities/strapi/types/queryResponseTypes';

import StrapiLink from '../utils/StrapiLink';
import type { StrapiLinkProps } from '../utils/StrapiLink';

export default function RichTextBlock({ text }: { text?: string | null }) {
  if (!text) {
    return null;
  }
  return (
    <>
      {parse(text, {
        replace(domNode: DOMNode) {
          if (domNode instanceof Element && domNode.tagName === 'a' && domNode.attribs) {
            const attrs: StrapiLinkProps = {
              externalUrl: '#',
              useChildren: true,
            };

            if (domNode.attribs['data-gcd']) {
              attrs.genericClickDetails = domNode.attribs['data-gcd'];
            }

            if (domNode.attribs['data-page-data']) {
              attrs.page = JSON.parse(domNode.attribs['data-page-data']) as StrapiMinimalPageInfo;
              delete attrs.externalUrl;
            } else if (domNode.attribs['data-id'] && domNode.attribs['data-slug'] && domNode.attribs['data-locale']) {
              attrs.page = {
                id: +domNode.attribs['data-id'],
                Slug: domNode.attribs['data-slug'],
                locale: domNode.attribs['data-locale'],
              };
              delete attrs.externalUrl;
            } else if (domNode.attribs.href) {
              attrs.externalUrl = domNode.attribs.href;
            }

            return <StrapiLink {...attrs}>{domToReact(domNode.children)}</StrapiLink>;
          }

          return domNode;
        },
      })}
    </>
  );
}
