import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

import Button from '@/components/Elements/Buttons/Button';
import EmailIcon from '@/components/icons/email.svg';
import FacebookIcon from '@/components/icons/facebook.svg';
import LinkedInIcon from '@/components/icons/linkedin.svg';
import TwitterIcon from '@/components/icons/twitter.svg';
import useStrapiGlobals from '@/context/StrapiGlobalsContext';
import type { SocialShareLinksProps as Props } from '@/utilities/strapi/types/componentTypes';
import { StrapiSocialShareServiceType } from '@/utilities/strapi/types/queryResponseTypes';

interface ShareServiceUrlParams {
  currentUrl: string;
  shareMessage: string;
  shareTitle: string;
}

interface ShareService {
  icon: React.ReactNode;
  url: (params: ShareServiceUrlParams) => string;
}

type ImplementedServiceTypes =
  | StrapiSocialShareServiceType.facebook
  | StrapiSocialShareServiceType.twitter
  | StrapiSocialShareServiceType.linkedin
  | StrapiSocialShareServiceType.email;

const SHARE_SERVICES: Record<ImplementedServiceTypes, ShareService> = {
  [StrapiSocialShareServiceType.facebook]: {
    icon: <FacebookIcon />,
    url: (params: ShareServiceUrlParams) => `https://www.facebook.com/sharer/sharer.php?u=${params.currentUrl}`,
  },
  [StrapiSocialShareServiceType.twitter]: {
    icon: <TwitterIcon />,
    url: (params: ShareServiceUrlParams) =>
      `https://twitter.com/intent/tweet?text=${params.shareMessage}&url=${params.currentUrl}`,
  },
  [StrapiSocialShareServiceType.linkedin]: {
    icon: <LinkedInIcon />,
    url: (params: ShareServiceUrlParams) => `https://www.linkedin.com/sharing/share-offsite/?url=${params.currentUrl}`,
  },
  [StrapiSocialShareServiceType.email]: {
    icon: <EmailIcon />,
    url: (params: ShareServiceUrlParams) =>
      `mailto:?subject=${params.shareTitle}&body=${params.shareMessage} ${params.currentUrl}`,
  },
};

export default function SocialShareLinks({ title, shareTitle, shareMessage }: Props) {
  const { siteSettings } = useStrapiGlobals();

  const [currentUrl, setCurrentUrl] = useState<string>('');

  useEffect(() => {
    setCurrentUrl(window.location.href);
  }, []);

  const shareButtonData = (siteSettings?.socialShareServices || [])
    .map((service) => {
      const info = SHARE_SERVICES[service.Service as ImplementedServiceTypes];
      return (
        info && {
          ...service,
          ...info,
        }
      );
    })
    .filter(Boolean);

  return (
    <StyledSocialShareLinks>
      <h3 className="title">{title}</h3>
      <div className="button-wrapper">
        {shareButtonData.map((button) => (
          <Button
            key={button.Service}
            tag="a"
            href={button.url({ currentUrl, shareMessage, shareTitle })}
            icon={button.icon}
            label={button.Title}
            hideLabel
            target="_blank"
          />
        ))}
      </div>
    </StyledSocialShareLinks>
  );
}

const StyledSocialShareLinks = styled.article`
  text-align: center;
  margin: var(--spacing-base) 0;

  .title {
    font: var(--font-h4);
    margin-bottom: var(--spacing-base);
  }

  .button-wrapper {
    display: flex;
    gap: var(--spacing-sm);
    align-items: center;
    justify-content: center;
  }
`;
