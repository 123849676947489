import clsx from 'clsx';
import Link from 'next/link';
import type { ComponentProps } from 'react';
import React from 'react';

import styled from 'styled-components';

import { logMarketingPageGenericClick } from '@/analytics/logger/generatedEvents/marketingPageGenericClick';
import getStrapiLinkHref, { getStrapiLinkLocale } from '@/components/utils/getStrapiLinkHref';
import { getPathWithLocale } from '@/utilities/locale';
import type { StrapiDataObject } from '@/utilities/strapi/types/apiResponseTypes';
import type { StrapiMinimalPageInfo } from '@/utilities/strapi/types/queryResponseTypes';
import type { Link as LinkType } from '@/utilities/strapi/types/utilsTypes';

export interface StrapiLinkProps extends LinkType {
  className?: string;
  children?: unknown;
  useMenuTitle?: boolean;
  callback?: () => void;
  useChildren?: boolean;
  animated?: boolean;
  genericClickDetails?: string;
  target?: string;
}

const StyledLink = styled.a`
  cursor: pointer;
`;

const AnimatedLabel = styled.div`
  &:before,
  &:after {
    content: attr(data-label);
    content: attr(data-label) / '""';
    alt: '""';
    position: absolute;
    top: 0;
    left: 0;
    transition: transform var(--animation-duration) var(--animation-timing);
  }

  &:before {
    transform: translateY(0%);
  }

  &:after {
    color: var(--color-action-inverse);
    transform: translateY(100%);
  }

  .theme-light & {
    &:before {
      color: var(--color-action);
    }
  }

  .theme-dark & {
    &:before {
      color: var(--color-action-inverse);
    }
  }

  .active & {
    &:before {
      transform: translateY(-100%);
    }

    &:after {
      transform: translateY(0%);
    }
  }

  .visually-hidden {
    opacity: 0;
  }
`;

export default function StrapiLink({
  page,
  text,
  externalUrl,
  children,
  useMenuTitle,
  className,
  callback,
  anchor,
  useChildren,
  animated,
  genericClickDetails,
  target,
}: StrapiLinkProps) {
  const href = getStrapiLinkHref(page, externalUrl, anchor);
  const pageData = (page as StrapiDataObject<StrapiMinimalPageInfo>)?.data?.attributes || page;

  const label = pageData?.Slug
    ? useChildren
      ? children
      : (useMenuTitle ? pageData.metadata?.menuTitle : false) || text || pageData.Title || children
    : (useChildren && children) || text || children;

  const onClick = (): void => {
    if (genericClickDetails) {
      // TODO (jackyang) Remove the generic_page_details as a field to log. We should just encapsulate this info inside click details.
      logMarketingPageGenericClick({ generic_page_details: '', generic_click_details: genericClickDetails });
    }

    if (callback) {
      callback();
    }
    return;
  };

  const linkTarget = href.startsWith('#') ? '_self' : target;

  return (
    <>
      {pageData?.Slug ? (
        <Link passHref {...createLinkProps(href, pageData)}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
          <StyledLink className={clsx(className, 'strapi-link')} onClick={onClick}>
            {animated ? (
              <AnimatedLabel data-label={label}>
                <span className="visually-hidden">{label}</span>
              </AnimatedLabel>
            ) : (
              label
            )}
          </StyledLink>
        </Link>
      ) : (
        <StyledLink
          href={href}
          target={linkTarget || '_blank'}
          {...(target !== '_self' ? { rel: 'noopener noreferrer' } : {})}
          className={clsx(className, 'strapi-link')}
          onClick={onClick}
        >
          {animated ? (
            <AnimatedLabel data-label={label}>
              <span className="visually-hidden">{label}</span>
            </AnimatedLabel>
          ) : (
            label
          )}
        </StyledLink>
      )}
    </>
  );
}

function createLinkProps(href: string, pageData: StrapiMinimalPageInfo): ComponentProps<typeof Link> {
  return {
    href: getPathWithLocale(href, getStrapiLinkLocale(pageData)),
  };
}
