import React from 'react';
import styled from 'styled-components';

import useStrapiGlobals from '@/context/StrapiGlobalsContext';

import CTAButton from '../Blocks/CTAButton';

export default function SkipToContentLink() {
  const { siteSettings } = useStrapiGlobals();
  const label = siteSettings.skipToContentLabel;

  return <StyledSkipToContentLink label={label} anchorId="main-content" target="_self" />;
}

const StyledSkipToContentLink = styled(CTAButton)`
  & {
    // Specificity hack for local dev, vs position:relative in CTAButton
    position: absolute !important;
  }

  left: 50%;
  transform: translate(-50%, -200%);
  z-index: 100;
  transition: transform var(--animation-duration) var(--animation-timing);

  &:focus-visible {
    transform: translate(-50%, var(--header-container-padding-top));
  }

  @media (max-width: 768px) {
    display: none;
  }
`;
