import type { HeaderBlockData } from 'editorjs-blocks-react-renderer/dist/renderers/header';
import React from 'react';

import styled from 'styled-components';

import type { HeadingSizes } from '@/components/Elements/Heading';
import Heading from '@/components/Elements/Heading';
import RichTextBlock from '@/components/TextEditor/RichTextBlock';

interface CustomHeaderBlockData extends HeaderBlockData {
  text: string;
  customTune: string;
  alignmentTune?: {
    alignment: string;
  };
}
export interface Props {
  data: CustomHeaderBlockData;
  className?: string;
}

const StyledHeader = styled(Heading)`
  margin-bottom: 0.6em;

  &.inline {
    float: left;
  }

  &.fine-print {
    opacity: 0.5;
  }

  &.text-right {
    text-align: right;
  }

  &.text-center {
    text-align: center;
  }

  &.text-left {
    text-align: left;
  }
`;

export default function HeaderBlock({ data, className }: Props) {
  const props: React.HTMLAttributes<HTMLParagraphElement> = {};

  props.className = data.customTune ? `${data.customTune} ` : '';

  if (data.alignmentTune?.alignment) {
    props.className += `text-${data.alignmentTune.alignment} `;
  }

  if (className) {
    props.className += className;
  }

  return (
    <StyledHeader size={data.level as HeadingSizes} {...props}>
      <RichTextBlock text={data?.text} />
    </StyledHeader>
  );
}
