import clsx from 'clsx';
import React from 'react';

import styled from 'styled-components';

import TextEditor from '@/components/TextEditor';
import MaxWidthContainer from '@/components/utils/MaxWidthContainer';
import type { UtilsThreeColumnTextProps as Props } from '@/utilities/strapi/types/componentTypes';

const StyledThreeColumnText = styled.div`
  display: flex;
  gap: 5.875rem;

  > div {
    flex: 1;
  }

  &.align-top {
    align-items: flex-start;
  }

  &.align-middle {
    align-items: center;
  }

  &.align-bottom {
    align-items: flex-end;
  }

  img {
    margin-bottom: 2rem;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 4rem;
    margin-bottom: 4rem;

    p:last-child {
      margin-bottom: 0.5rem;
    }

    img {
      margin-bottom: 1rem;
    }

    h6 {
      font-size: 2.5rem;
    }
  }
`;

export default function ThreeColumnText({
  column_one: columnOne,
  column_two: columnTwo,
  column_three: columnThree,
  anchorId,
  verticalAlignment,
}: Props) {
  return (
    <MaxWidthContainer>
      <StyledThreeColumnText id={anchorId?.anchorId} className={clsx(`align-${verticalAlignment?.alignment}`)}>
        <div>
          <TextEditor text={columnOne.text} />
        </div>
        <div>
          <TextEditor text={columnTwo.text} />
        </div>
        <div>
          <TextEditor text={columnThree.text} />
        </div>
      </StyledThreeColumnText>
    </MaxWidthContainer>
  );
}
