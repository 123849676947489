import { SectionTheme } from '@/context/StrapiGlobalsContext';
import type { StrapiComponent } from '@/utilities/strapi/types/componentTypes';

export default function getStrapiComponentTheme(component: StrapiComponent) {
  switch (component.__component) {
    case 'creators.creator-hero':
    case 'creators.creator-carousel':
    case 'homepage.home-hero':
    case 'homepage.large-quote-and-title':
    case 'features.features-hero':
    case 'marketing.get-started':
      return SectionTheme.dark;
    default:
      return SectionTheme.light;
  }
}
