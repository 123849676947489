import React from 'react';
import styled from 'styled-components';

const StyledHamburger = styled.button`
  border: none;
  width: 20px;
  height: 20px;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  position: relative;

  :hover {
    opacity: 0.7;
  }

  .burger {
    position: absolute;
    inset: 0;
  }

  .bun {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: currentColor;
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;
    transition-duration: 300ms;
  }

  .bun:nth-of-type(1) {
    transform: translateY(calc(-50% - 3px));

    &[data-open='true'] {
      transform: rotate(45deg);
    }
  }

  .bun:nth-of-type(2) {
    transform: translateY(calc(-50% + 3px));

    &[data-open='true'] {
      transform: rotate(-45deg);
    }
  }
`;

interface HamburgerProps extends React.HTMLAttributes<HTMLButtonElement> {
  open?: boolean;
  onClick?: () => void;
}

export default function Hamburger({ open = false, onClick, ...rest }: HamburgerProps) {
  return (
    <StyledHamburger {...rest} onClick={onClick}>
      <span className="sr-only">Open menu</span>
      <span className="burger">
        {[0, 1].map((idx) => (
          <span key={idx} data-open={open} className="bun" />
        ))}
      </span>
    </StyledHamburger>
  );
}
