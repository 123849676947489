import type { GetStaticProps, GetStaticPropsContext, GetStaticPropsResult } from 'next';
import React, { useEffect, useState } from 'react';

import { BasePage } from '@/components/BasePage';
import { isPreview } from '@/shared/environment';
import { getStaticPaths } from '@/utilities/data-fetching/getStaticPaths';
import { getStaticProps as getProps } from '@/utilities/data-fetching/getStaticProps';
import type { PageProps } from '@/utilities/data-fetching/types';

function PreviewPage(context: GetStaticPropsContext) {
  const [props, setProps] = useState<PageProps>();
  useEffect(() => {
    void (async function () {
      try {
        const result: GetStaticPropsResult<PageProps> = await getProps(context);
        if ('props' in result) {
          setProps(result.props);
        }
      } catch (error) {
        setProps(undefined);
      }
    })();
  }, [context]);

  if (!props) {
    return <></>;
  }

  return <BasePage {...props} />;
}

const getStaticPropsPreview: GetStaticProps<GetStaticPropsContext> = (context: GetStaticPropsContext) =>
  Promise.resolve({
    props: {
      params: context.params,
      preview: true,
    },
  });

const getStaticProps = isPreview ? getStaticPropsPreview : getProps;

export default isPreview ? PreviewPage : BasePage;

export { getStaticPaths, getStaticProps };
