import clsx from 'clsx';
import React from 'react';
import styled from 'styled-components';

import type { ButtonProps as Props } from './types';

const StyledButton = styled.div`
  appearance: none;
  background-color: var(--color-action);
  border: 0;
  border-radius: 5rem;
  color: var(--color-action-contrast);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--font-family);
  font-size: var(--button-font-size);
  -webkit-font-smoothing: antialiased;
  font-weight: var(--button-font-weight);
  padding: 1.4rem 2.8rem;
  text-align: center;
  text-decoration: none;
  transition: background-color var(--animation-duration) var(--animation-timing);
  -webkit-user-select: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  line-height: 110%;
  letter-spacing: -0.036rem;

  .icon-wrapper {
    width: 30px;
    height: 30px;
    margin-right: 0.33em;

    img,
    svg {
      width: 100%;
      height: 100%;
      object-fit: contain;
      display: block;
      object-position: center center;
    }
  }

  .icon-right {
    margin-right: 0;
    margin-left: 0.33em;
  }

  .icon {
    > * {
      width: 100%;
      height: 100%;
    }
  }

  &.icon-only {
    padding: 1.9rem;
    border-radius: 50%;

    .icon-wrapper {
      display: flex;
      align-items: center;
      margin-right: 0;
    }
  }

  &:hover {
    background-color: var(--color-action-light);
  }

  &:focus-visible {
    outline: var(--focus-outline);
    outline-offset: var(--focus-outline-offset);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &.sm,
  &.small {
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: -0.048rem;
    padding: 1rem 1.4rem;
  }

  &.large,
  &.lg {
    padding: var(--spacing-base);
  }

  &.full-width {
    width: 100%;
  }

  &.inverse {
    background-color: var(--color-action-contrast);
    color: var(--color-action);
    border: var(--border);
    border-color: var(--color-action);

    &:hover {
      color: var(--color-action-light);
      border-color: var(--color-action-light);
    }
  }

  .label {
    margin-left: 0.3rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.secondary {
    background-color: var(--color-action-inverse);
    color: var(--color-action);

    &:hover {
      color: var(--color-action-muted);
    }
  }

  &.tertiary {
    background-color: rgba(255, 255, 255, 0.16);
    color: var(--color-white);
  }

  &.bordered {
    border: 1px solid currentColor;
  }
`;

export default function Button({
  tag = 'button',
  children,
  onClick,
  variant = 'primary',
  size = 'md',
  fullWidth,
  icon,
  iconPath,
  label,
  hideLabel,
  childrenLabelOrder = 'childrenFirst',
  iconPlacement = 'first',
  ...rest
}: Props) {
  return (
    <StyledButton
      {...rest}
      as={tag}
      className={clsx(rest.className, { 'icon-only': hideLabel }, { 'full-width': fullWidth }, `${variant} ${size}`)}
      onClick={onClick}
    >
      {iconPlacement === 'first' && (iconPath || icon) && (
        <div className="icon-wrapper">{icon || <img className="icon" src={iconPath} alt="" />}</div>
      )}
      {childrenLabelOrder === 'childrenFirst' && children}
      {label && <span className={hideLabel ? 'sr-only' : 'label'}>{label}</span>}
      {childrenLabelOrder === 'childrenLast' && children}
      {iconPlacement === 'last' && (iconPath || icon) && (
        <div className={clsx('icon-wrapper', 'icon-right')}>
          {icon || <img className="icon" src={iconPath} alt="" />}
        </div>
      )}
    </StyledButton>
  );
}
