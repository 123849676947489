import React from 'react';

import styled from 'styled-components';

import Heading from '@/components/Elements/Heading';
import TextEditor from '@/components/TextEditor';
import LineBreakTitle from '@/components/utils/LineBreakTitle';
import type { TitleAndBlurbProps as Props } from '@/utilities/strapi/types/componentTypes';

export default function TitleAndBlurb({ Title, Layout, Blurb, anchorId }: Props) {
  const layoutClass = Layout.toLowerCase().replace(/\s/g, '-');

  const titleElements = {
    'Left Overflow Title': <LineBreakTitle size={2}>{Title}</LineBreakTitle>,
    'Left Aligned Title': <Heading size={2}>{Title}</Heading>,
  };

  return (
    <StyledTitleAndBlurb id={anchorId?.anchorId} className={layoutClass}>
      {titleElements[Layout]}
      <div className="blurb">
        <TextEditor {...Blurb} />
      </div>
    </StyledTitleAndBlurb>
  );
}

const StyledTitleAndBlurb = styled.section`
  color: var(--text-dark, #000);
  padding-top: 10rem;
  width: 128rem;
  margin: 0 auto calc(var(--font-size-h2) * 0.5);
  // calculation to expand container size when the h2 font-size exceeds the clamped maximum
  --expand: min((var(--font-size-h2) - 17rem) * (128 / 17) * -0.5, 0px);

  @media (max-width: 768px) {
    padding-top: 5rem;
    width: 34rem;
  }

  &.left-overflow-title {
    .blurb {
      width: 51rem;
      margin-top: calc(var(--font-size-h2) * -0.6);
    }
    > h2 {
      margin: 0 var(--expand);
      > span:last-child {
        margin-left: 54rem;
      }
    }
    @media (max-width: 768px) {
      .blurb {
        width: 100%;
        margin-top: 0;
      }
      > h2 {
        margin-bottom: 0.5em;
        > span:last-child {
          margin-left: 0;
        }
      }
    }
  }

  &.left-aligned-title {
    display: flex;
    gap: var(--spacing-base);
    align-items: flex-end;
    justify-content: space-between;
    width: calc(128rem - 2 * var(--expand));

    .blurb {
      width: 60rem;
    }

    @media (max-width: 1024px) {
      width: calc(100% - 20rem);
    }

    @media (max-width: 768px) {
      width: 34rem;
      display: block;
      .blurb {
        width: 100%;
      }
      > h2 {
        margin-bottom: 0.5em;
      }
    }
  }
`;
