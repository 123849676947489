import type Lenis from '@studio-freight/lenis';
import { useLenis } from '@studio-freight/react-lenis';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect } from 'react';

import styled, { css } from 'styled-components';

import { useSnapshot } from 'valtio';

import { useHeaderState } from '@/components/Header/headerState';
import useGetThemeUnderHeader from '@/components/Header/useGetThemeUnderHeader';
import useMegaHoverState from '@/components/Header/useMegaHoverState';
import PatreonLogomark from '@/components/icons/patreon-logomark.svg';
import PatreonWordmark from '@/components/icons/patreon-wordmark.svg';

import useHomeLinkProps from '@/hooks/useHomeLinkProps';
import type { StrapiHeaderAttributes } from '@/utilities/strapi/types/queryResponseTypes';

import Hamburger from './Hamburger';
import MegaDropdown from './MegaDropdown';
import Search from './Search';
import SkipToContentLink from './SkipToContentLink';
import TopLevelNavigation from './TopLevelNavigation';
import CTAButton from '../Blocks/CTAButton';
import MobileMenu from '../MobileNavigation/MobileMenu';
import StrapiLink from '../utils/StrapiLink';

const Backdrop = styled.div`
  position: absolute;
  top: -1.3rem;
  right: 0;
  bottom: -1.3rem;
  left: 0;
  background-color: rgba(255, 255, 255, 0.6);
  transition: opacity var(--animation-duration-slow) var(--animation-timing);
  opacity: 0;
  pointer-events: none;
`;

const PrimaryNavButton = styled(CTAButton).attrs({
  style: 'primary',
})``;

const StyledHeader = styled.header`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 500;
  font-size: var(--button-font-size);
  transform: translateY(1.3rem);
  transition: transform var(--animation-duration-slow) var(--animation-timing);

  &:has(dialog[open]) {
    transform: translateY(0px);
  }

  &:focus-visible {
    outline: none;
  }

  @media (max-width: 768px) {
    transform: translateY(0);
  }

  &.theme-dark {
    --themed-background-color: var(--color-action-inverse);
    --themed-color: var(--color-action-default);

    color: var(--color-action-inverse);

    ${PrimaryNavButton} {
      background-color: var(--color-action-inverse);
      color: var(--color-action-default);
    }
  }

  &.theme-light {
    --themed-background-color: var(--color-action-default);
    --themed-color: var(--color-action-inverse);

    color: var(--color-action-default);
  }

  &.scrolled {
    transform: translateY(0);
    color: var(--color-action-default);

    .backdrop {
      opacity: 1;
    }
  }

  --header-container-padding-top: var(--spacing-sm);
  --header-container-padding-right: max(env(safe-area-inset-right), var(--spacing-lg));
  --header-container-padding-bottom: var(--spacing-sm);
  --header-container-padding-left: max(env(safe-area-inset-left), var(--spacing-lg));
  --header-container-padding: var(--header-container-padding-top) var(--header-container-padding-right)
    var(--header-container-padding-bottom) var(--header-container-padding-left);
  .header-container {
    display: grid;
    gap: var(--spacing-xl);
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    padding: var(--header-container-padding);
    pointer-events: none;
    position: relative;

    @media (max-width: 964px) {
      gap: var(--spacing-sm);
    }

    @media (max-width: 768px) {
      display: flex;
      gap: 0;
      padding: 1rem 2rem;
      justify-content: space-between;
    }
  }

  .left-wrapper {
    pointer-events: auto;
    margin: calc(var(--header-container-padding-top) * -1) calc(var(--header-container-padding-right) * -1)
      calc(var(--header-container-padding-bottom) * -1) calc(var(--header-container-padding-left) * -1);

    > .nav-wrapper {
      padding: var(--header-container-padding);
    }
  }

  .nav-wrapper {
    display: inline-block;
  }

  .center-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: auto;
  }

  .hamburger {
    margin-right: 1.6rem;

    @media (min-width: 769px) {
      display: none;
    }
  }

  .logo-link {
    border-radius: 4rem;
    display: grid;
    grid-template-areas: 'stack';
    justify-items: center;
    align-content: center;
    align-items: center;
    height: 2.7rem;

    > * {
      grid-area: stack;
    }

    @media (min-width: 768px) {
      height: 3.2rem;

      &.scrolled {
        .wordmark {
          opacity: 0;
        }

        .logomark {
          opacity: 1;
        }
      }
    }
  }

  .workmark,
  .logomark {
    transition: opacity var(--animation-duration-slow) var(--animation-timing);
  }

  .wordmark {
    display: block;
    width: auto;
    height: 6.5rem;
    fill: currentColor;

    @media (max-width: 768px) {
      height: 66.666667%;
      display: none;
    }
  }

  .logomark {
    height: 4.2rem;
    align-self: center;
    opacity: 0;

    @media (max-width: 768px) {
      height: 3.4rem;
      opacity: 1;
    }
  }

  .right-wrapper {
    display: flex;
    gap: 1.4rem;
    align-items: center;
    justify-content: flex-end;
    pointer-events: auto;
    min-width: fit-content;
    flex: 1 0 0;

    @media (max-width: 768px) {
      display: none;
    }
  }

  .header-button {
    flex-shrink: 0;
  }

  .mobile-wrapper {
    display: none;
    align-items: center;
    justify-content: flex-end;
    pointer-events: auto;

    @media (max-width: 768px) {
      display: flex;
    }
  }

  .login-link {
    white-space: nowrap;
    background: rgba(0, 0, 0, 0.15);

    @media (max-width: 768px) {
      background: none;
      color: var(--color-action-default);
    }
  }

  .mobile-search {
    display: none;

    @media (max-width: 768px) {
      display: block;
    }
  }
`;

export const headerPaddingTopSnippet = css`
  padding-top: calc(var(--spacing-sm) * 2 + 6rem + 2px + 1.3rem);
  @media (max-width: 768px) {
    padding-top: calc(2rem + 3.92rem + var(--spacing-sm) + var(--font-size-label-1) * 1.2 + 17px);
  }
`;

export default function Header({
  navigationGroups,
  searchPrompt,
  searchResultsLink,
  loginButton,
  getStartedButton,
  getStartedMobileButton,
  updatesButton,
}: StrapiHeaderAttributes) {
  const headerState = useHeaderState();

  const snapshot = useSnapshot(headerState);
  const { isScrolled, isMobileMenuOpen, megaMenuHasTrappedFocus, theme } = snapshot;

  const { asPath } = useRouter();

  const onHamburgerClick = useCallback(() => {
    headerState.isMobileMenuOpen = !isMobileMenuOpen;
  }, [headerState, isMobileMenuOpen]);

  const homeLinkProps = useHomeLinkProps();

  const [headerRef, getThemeUnderHeader] = useGetThemeUnderHeader();

  const lenisCallback = useCallback(
    function ({ scroll }: { scroll: number }) {
      headerState.themeUnderHeader = getThemeUnderHeader(scroll);
      if (scroll > 64) {
        headerState.isScrolled = true;
      } else {
        headerState.isScrolled = false;
      }
    },
    [headerState, getThemeUnderHeader],
  );

  const lenis = useLenis(lenisCallback) as Lenis | undefined;

  // close menu, and update theme, when route changes
  useEffect(() => {
    headerState.isMobileMenuOpen = false;
    headerState.themeUnderHeader = getThemeUnderHeader((lenis?.scroll as number) || 0);
  }, [headerState, asPath, lenis, getThemeUnderHeader]);

  const closeAll = useCallback(() => {
    headerState.isMobileMenuOpen = false;
    headerState.clickedMegaMenuId = null;
    headerState.hoveredMegaMenuId = null;
  }, [headerState]);

  const { handleWrapper2Enter, handleWrapper2Leave } = useMegaHoverState();

  useEffect(() => {
    if (isMobileMenuOpen) {
      lenis?.stop();
    } else {
      lenis?.start();
      headerRef.current?.focus();
    }
  }, [isMobileMenuOpen, lenis, headerRef]);

  useEffect(() => {
    const onResize = () => closeAll();

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [closeAll]);

  return (
    <StyledHeader className={clsx('theme-' + theme, { scrolled: isScrolled })} ref={headerRef} tabIndex={-1}>
      <Backdrop className="backdrop" />

      <div inert={megaMenuHasTrappedFocus ? '' : undefined}>
        <SkipToContentLink />
      </div>

      <div className="header-container">
        <div className="left-wrapper desktop-only">
          <div className="nav-wrapper" onMouseEnter={handleWrapper2Enter} onMouseLeave={handleWrapper2Leave}>
            <TopLevelNavigation navigationGroups={navigationGroups} updatesButton={updatesButton} />
          </div>
        </div>

        <div className="center-wrapper" inert={megaMenuHasTrappedFocus ? '' : undefined}>
          <Hamburger className="hamburger" open={isMobileMenuOpen} onClick={onHamburgerClick} />
          <StrapiLink {...homeLinkProps} className={clsx('logo-link', { scrolled: isScrolled })}>
            <span className="sr-only">Patreon</span>
            <PatreonWordmark className="wordmark" />
            <PatreonLogomark className="logomark" />
          </StrapiLink>
        </div>

        <div className="right-wrapper" inert={megaMenuHasTrappedFocus ? '' : undefined}>
          <Search placeholder={searchPrompt} resultsLink={searchResultsLink} />
          {loginButton && (
            <CTAButton
              label={loginButton.label}
              page={loginButton.page}
              style="secondary"
              external_url={loginButton.external_url}
              genericClickDetails={loginButton.genericClickDetails}
              className="header-button"
              target="_self"
            />
          )}
          {getStartedButton && (
            <PrimaryNavButton
              label={getStartedButton.label}
              page={getStartedButton.page}
              external_url={getStartedButton.external_url}
              genericClickDetails={getStartedButton.genericClickDetails}
              className="header-button"
              target="_self"
            />
          )}
        </div>

        <div className="mobile-wrapper" inert={megaMenuHasTrappedFocus ? '' : undefined}>
          {loginButton && (
            <CTAButton
              label={loginButton.label}
              page={loginButton.page}
              style="text-only"
              external_url={loginButton.external_url}
              genericClickDetails={loginButton.genericClickDetails}
              target="_self"
            />
          )}
          {getStartedMobileButton && (
            <PrimaryNavButton
              label={getStartedMobileButton.label}
              page={getStartedMobileButton.page}
              external_url={getStartedMobileButton.external_url}
              genericClickDetails={getStartedMobileButton.genericClickDetails}
              target="_self"
            />
          )}
        </div>
      </div>

      <div className="mobile-search" inert={megaMenuHasTrappedFocus ? '' : undefined}>
        <Search style="mobile" placeholder={searchPrompt} resultsLink={searchResultsLink} />
      </div>

      <MobileMenu
        navigationGroups={navigationGroups}
        isOpen={isMobileMenuOpen}
        closeCallback={closeAll}
        getStartedButton={getStartedButton}
        updatesButton={updatesButton}
      />

      <MegaDropdown navigationGroups={navigationGroups} closeCallback={closeAll} />
    </StyledHeader>
  );
}
