import clsx from 'clsx';
import { gsap } from 'gsap';
import React, { useRef } from 'react';

import { useRafLoop } from 'react-use';
import styled from 'styled-components';

import getCreatorCarouselResponsiveSizes from '@/components/Creators/CreatorCarousel/getCreatorCarouselResponsiveSizes';
import ResponsiveImage from '@/components/Elements/ResponsiveImage';
import GradientBackground from '@/components/Hydra/GradientBackground';
import ArrowDown from '@/components/icons/arrow-down.svg';
import PatreonLogomark from '@/components/icons/patreon-logo-icon.svg';
import type { CreatorCarouselProps as Props } from '@/utilities/strapi/types/componentTypes';

import CircularIndicator from './CircularIndicator';
import Heading from '../../Elements/Heading';
import TextEditor from '../../TextEditor';
import LineBreakTitle from '../../utils/LineBreakTitle';
import StrapiLink from '../../utils/StrapiLink';

const StyledCreatorCarousel = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;

  .top {
    position: absolute;
    left: 0;
    top: 12rem;
    margin: 0 0 0 max(env(safe-area-inset-left), calc(5rem + var(--rem-scale-viewport-half-excess)));
    z-index: 101;
    color: var(--color-white);
    pointer-events: none;

    @media (max-width: 768px) {
      top: 2.5rem;
      margin: 0 2rem;
    }

    h4 {
      font-size: 9.6rem;
      font-style: normal;
      font-weight: 250;
      line-height: 100%;
      letter-spacing: -0.384rem;
      max-width: 81rem;
      margin-bottom: 6rem;

      @media (max-width: 768px) {
        font-size: 4.8rem;
        font-style: normal;
        font-weight: 350;
        line-height: 105%;
        letter-spacing: -0.192rem;
        max-width: 33.5rem;
        margin-bottom: 2rem;
      }
    }

    .text-base {
      font-size: 2.4rem;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      letter-spacing: -0.048rem;
      max-width: 28.2rem;

      @media (max-width: 768px) {
        font-size: 1.8rem;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: -0.036rem;
        max-width: 19.6rem;
      }
    }
  }

  .carousel {
    position: relative;
    height: 100vh;
  }

  .indicator {
    position: absolute;
    right: max(env(safe-area-inset-right), calc(5.3rem + var(--rem-scale-viewport-half-excess)));
    top: 50%;
    color: var(--color-white);

    @media (max-width: 768px) {
      right: 2rem;
    }

    &.previous {
      top: calc(50% - 0.5rem - 5.4rem);
      left: 4rem;

      svg {
        transform: rotate(180deg);
      }
    }

    &.next {
      top: calc(50% + 0.5rem);
      left: 4rem;
    }

    svg {
      width: 1.4rem;
      height: 1.4rem;
    }
  }
`;

const Background = styled(GradientBackground)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`;

const Slides = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const Slide = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  &:first-child {
    visibility: visible;
  }
`;

const SlideTitles = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
  > div {
    visibility: hidden;
    &:first-child {
      visibility: visible;
    }
  }
`;

const SlideTitle = styled(Heading)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  color: var(--color-white);
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    text-align: left;
    justify-content: flex-start;
    padding-left: 2rem;
    padding-right: 8rem;
  }

  span {
    max-width: 130rem;
    visibility: hidden;

    &:first-child {
      visibility: inherit;
    }
  }
`;

const SlideBackground = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.35) 100%);
  }
  & > video,
  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export default function TimedCreatorCarousel({ id, title, body, slides = [], anchorId, backgroundColor }: Props) {
  const root = useRef<HTMLDivElement | null>(null);
  const carousel = useRef<HTMLDivElement | null>(null);
  const slideRefs = useRef<HTMLDivElement[] | null[]>([]);
  const titleRefs = useRef<HTMLDivElement[] | null[]>([]);
  const indicatorNextRef = useRef<HTMLButtonElement | null>(null);
  const indicatorPreviousRef = useRef<HTMLButtonElement | null>(null);
  const targetSlide = useRef<number | null>(0);
  const slide = useRef<number | null>(0);
  const currentTime = useRef<number | null>(0);
  const lastTime = useRef<number | null>(0);
  const indicatorProgress = useRef<number | null>(0);

  const TIMER_DELAY = 5000;

  const nextSlide = () => {
    if (typeof targetSlide.current !== 'number') {
      return;
    }
    targetSlide.current = targetSlide.current === slides.length - 1 ? 0 : targetSlide.current + 1;
    lastTime.current = currentTime.current;
  };

  const previousSlide = () => {
    if (typeof targetSlide.current !== 'number') {
      return;
    }
    targetSlide.current = targetSlide.current === 0 ? slides.length - 1 : targetSlide.current - 1;
    lastTime.current = currentTime.current;
  };

  useRafLoop((time) => {
    if (time - (lastTime.current || 0) > TIMER_DELAY) {
      nextSlide();
    }

    const progress = (time - (lastTime.current || 0)) / TIMER_DELAY;

    if (targetSlide.current !== slide.current) {
      slide.current = targetSlide.current;
      slides.forEach((_item, i) => {
        const slideEl = slideRefs.current[i];
        const titleEl = titleRefs.current[i];
        if (slideEl) {
          gsap.to(slideEl, { autoAlpha: slide.current === i ? 1 : 0, duration: 0.75 });
        }
        if (titleEl) {
          gsap.to(titleEl, { autoAlpha: slide.current === i ? 1 : 0, duration: 0.75 });
        }
      });
    }

    indicatorProgress.current = progress;

    currentTime.current = time;
  });

  const colors = slides.map((item) => item.background?.image?.data?.attributes?.colors).filter(Boolean);
  const bgColors = backgroundColor || colors[Math.min(3, colors.length - 1)];

  return (
    <StyledCreatorCarousel ref={root}>
      <Background seed={id} colors={bgColors} targetLightness={85} />
      <div className="top">
        {title && (
          <LineBreakTitle size={4} align="left">
            {title}
          </LineBreakTitle>
        )}
        <TextEditor {...body} />
      </div>
      <div id={anchorId?.anchorId} ref={carousel} className="carousel">
        <Slides>
          {slides.map((item, i) => (
            <Slide ref={(r) => (slideRefs.current[i] = r)} key={item.id} className={clsx(`slide-${i}`)}>
              <SlideBackground>
                {item?.quote && (
                  <QuoteContainer>
                    <Heading size={4}>{item?.quote}</Heading>
                    {item?.quoteAuthor && (
                      <QuoteAuthor>
                        <PatreonLogomark className="logomark" />
                        <p>{item.quoteAuthor}</p>
                      </QuoteAuthor>
                    )}
                  </QuoteContainer>
                )}

                {item.background && (
                  <ResponsiveImage image={item.background} sizes={getCreatorCarouselResponsiveSizes(item.background)} />
                )}
              </SlideBackground>
            </Slide>
          ))}
        </Slides>
        <SlideTitles className="titles">
          {slides.map((item, i) => (
            <div key={item.id} ref={(r) => (titleRefs.current[i] = r)}>
              <SlideTitle size={2}>
                <span className={clsx(`title-${i}`)}>
                  <StrapiLink {...item.link} />
                </span>
              </SlideTitle>
            </div>
          ))}
        </SlideTitles>

        <CircularIndicator
          ref={indicatorPreviousRef}
          className="indicator previous"
          onClick={previousSlide}
          ariaLabel="Previous slide"
        >
          <ArrowDown className="icon" />
        </CircularIndicator>
        <CircularIndicator
          ref={indicatorNextRef}
          className="indicator next"
          onClick={nextSlide}
          progress={indicatorProgress}
          ariaLabel="Next slide"
        >
          <ArrowDown className="icon" />
        </CircularIndicator>
      </div>
    </StyledCreatorCarousel>
  );
}

const QuoteContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 16rem;
  top: 14rem;
  right: 7rem;
  max-width: 50%;
  color: var(--color-white);
  text-align: right;
  z-index: 1;

  h4 {
    line-height: 1.2;
    text-indent: 10rem;
  }

    font-size: 3rem;
  }

  .logomark {
    height: 2.2rem;
  }

   @media (max-width: 768px) {
      top: unset;
      right: 2rem;
      left: 2rem;
      bottom: 1.5rem;
      gap: 4rem; 
      max-width: 80%;

      p {
      font-size: var(--font-size-body-lg);
      }
    }
`;

const QuoteAuthor = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  align-items: center;
`;
