import React from 'react';
import styled from 'styled-components';

const RtKernPair = styled.span`
  letter-spacing: -0.02em;
`;

export default function renderCustomKernPairs(string: string): React.ReactNode {
  const segments = string.split('rt');
  if (segments.length > 1) {
    return segments.map((string, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <React.Fragment key={i}>
        {string}
        {i < segments.length - 1 && <RtKernPair>rt</RtKernPair>}
      </React.Fragment>
    ));
  } else {
    return string;
  }
}
