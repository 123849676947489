import * as cheerio from 'cheerio';
import clsx from 'clsx';
import Blocks from 'editorjs-blocks-react-renderer';
import type { Block, DataProp } from 'editorjs-blocks-react-renderer';
import React from 'react';

import styled from 'styled-components';

import HeaderBlock from '@/components/TextEditor/HeaderBlock';
import type { StrapiLinkProps } from '@/components/utils/StrapiLink';
import type { TextEditorProps as Props } from '@/utilities/strapi/types/componentTypes';

import type { StrapiMinimalPageInfo } from '@/utilities/strapi/types/queryResponseTypes';

import CTAButtonBlock from './CTAButton';
import ImageBlock from './ImageBlock';
import ListBlock from './ListBlock';
import Paragraph from './Paragraph';
import styles from './styles.module.css';

const StyledContainer = styled('div')`
  &.block-image {
    display: flex;
    align-items: center;
    margin-bottom: var(--spacing-sm);
    flex-wrap: wrap;
    gap: 2rem;
    &.block-center {
      justify-content: center;
    }
    &.block-right {
      justify-content: flex-end;
    }
  }

  &.block-image-nowrap {
    flex-wrap: nowrap;
  }

  figure {
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    position: relative;
    margin: var(--spacing-base) 0;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    @media (max-width: 480px) {
      margin: var(--spacing-sm) 0;
    }
  }

  hr {
    margin: var(--spacing-base) 0;
  }

  a:not(.button) {
    color: var(--color-highlight);
    text-decoration: underline;

    &:hover {
      color: var(--color-highlight-light);
    }
  }
`;

const StyledBlocks = styled(Blocks)`
  background-color: red;
  font-weight: bold;

  > h2 {
    background-color: red;
  }
`;

type CustomBlock = Block & { tunes: { customTune: string; alignmentTune: { alignment: string } } };

interface Group {
  key: string;
  data: Block[];
  alignment?: string;
  variant?: string;
}

interface CustomDataProp extends DataProp {
  blocks: CustomBlock[];
}

/**
 * Sometimes there is a type:mediaLib block inserted by the strapi editor.js
 * plugin that would be harmless, but when we want to perform logic based on
 * block ordering they get in the way.
 * @param blocks
 */
function sanitizeBlocks(blocks: CustomBlock[]): CustomBlock[] {
  return blocks.filter((block) => block.type !== 'mediaLib');
}

/**
 * Where two or more identical headings (same level, same alignment, same custom
 * tune settings) occur in a row, assume the author wanted to insert linebreaks
 * into a single heading.
 * @param blocks
 */
function convertHeadingLineBreaks(blocks: CustomBlock[]): CustomBlock[] {
  const result: CustomBlock[] = [];
  let prevBlock: CustomBlock | null = null;
  let mergedHeaderBlock: CustomBlock | null = null;
  for (const block of blocks) {
    if (
      prevBlock &&
      block.type === 'header' &&
      prevBlock.type === 'header' &&
      block.data.level === prevBlock.data.level &&
      block.tunes?.alignmentTune?.alignment === prevBlock.tunes?.alignmentTune?.alignment &&
      block.tunes?.customTune === prevBlock.tunes?.customTune
    ) {
      if (!mergedHeaderBlock) {
        mergedHeaderBlock = prevBlock;
      }
      mergedHeaderBlock.data.text = `${mergedHeaderBlock.data.text}<br>${block.data.text}`;
    } else {
      mergedHeaderBlock = null;
      result.push(block);
    }
    prevBlock = block;
  }
  return result;
}

/**
 * Where an image block is immediately followed by a paragraph containing
 * nothing but a hyperlink, assume the author wanted to link from the image.
 * @param blocks
 */
function convertImageHyperlinks(blocks: CustomBlock[]): CustomBlock[] {
  const result: CustomBlock[] = [];
  let imageBlock: CustomBlock | null = null;
  for (const block of blocks) {
    if (block.type === 'image') {
      imageBlock = block;
      result.push(imageBlock);
      continue;
    } else if (imageBlock && block.type === 'paragraph') {
      const trimmed = ((block.data?.text as string) || '').trim();
      if (trimmed.startsWith('<a') && trimmed.endsWith('</a>')) {
        const $ = cheerio.load(trimmed);
        const $links = $('a');
        if ($links.length === 1) {
          const attrs: StrapiLinkProps = {};
          const data = $links.data();
          if (data.gcd) {
            attrs.genericClickDetails = data.gcd as string;
          }
          if (data.pageData) {
            attrs.page = data.pageData as StrapiMinimalPageInfo;
          } else if (data.id && data.slug && data.locale) {
            attrs.page = {
              id: +data.id,
              Slug: data.slug as string,
              locale: data.locale as string,
            };
          } else {
            attrs.externalUrl = $links.attr('href');
          }
          imageBlock.data.link = attrs;
          const text = $links.text();
          if (text && !imageBlock.data.caption) {
            imageBlock.data.caption = text;
          }
          imageBlock = null;
          continue;
        }
      }
    }
    imageBlock = null;
    result.push(block);
  }
  return result;
}

function adjustHeadingLevels(blocks: CustomBlock[], minHeadingLevel?: number) {
  if (minHeadingLevel) {
    let minHeadingLevelUsed = Infinity;
    for (const block of blocks) {
      if (block.type === 'header') {
        minHeadingLevelUsed = Math.min(minHeadingLevelUsed, block.data.level as number);
      }
    }
    const increase = minHeadingLevel - minHeadingLevelUsed;
    if (increase > 0) {
      for (const block of blocks) {
        if (block.type === 'header') {
          block.data.level += increase;
        }
      }
    }
  }
  return blocks;
}

function mergeBlockTunes(blocks: CustomBlock[]) {
  for (const block of blocks) {
    if (block.tunes) {
      block.data = {
        ...block.data,
        ...block.tunes,
      };
    }
  }
  return blocks;
}

function getGroupedBlocks(blocks: CustomBlock[]): Group[] {
  // An array of grouped blocks, where each group consists of blocks with the same 'type' (e.g., 'image' or 'default').
  const groups: Group[] = [];
  let type: string | null = null;
  let data: Block[] = [];
  let variant: string | null = null;
  let alignment: string | null = null;

  blocks.reduce((prev: CustomBlock[], curr: CustomBlock, index: number) => {
    const closeContainer = () => {
      const group: Group = {
        key: `block-${index}`,
        data,
      };
      if (variant) {
        group.variant = variant;
      }
      if (alignment) {
        group.alignment = `block-${alignment}`;
      }
      groups.push(group);
      data = [];
      type = null;
      variant = null;
    };

    // Check if the current block is of type 'image' to make a group
    if (curr.type === 'image' && curr?.tunes?.customTune === 'inline') {
      if (type && type !== 'image') {
        closeContainer();
      }
      data.push(curr);
      type = 'image';
      alignment = curr.tunes?.alignmentTune?.alignment;
      variant = 'block-image';
    } else {
      if (type && type !== 'default') {
        closeContainer();
      }
      data.push(curr);
      type = 'default';
      alignment = null;
    }

    if (blocks.length - 1 === index) {
      closeContainer();
    }

    return [...prev, curr];
  }, []);

  return groups;
}

export default function TextEditor({ text, minHeadingLevel, size = 'default' }: Props) {
  if (!text) {
    return null;
  }
  const data = JSON.parse(text) as CustomDataProp;
  let blocks = sanitizeBlocks(data.blocks);
  blocks = adjustHeadingLevels(blocks, minHeadingLevel);
  blocks = convertHeadingLineBreaks(blocks);
  blocks = convertImageHyperlinks(blocks);
  blocks = mergeBlockTunes(blocks);
  const groups = getGroupedBlocks(blocks);

  return (
    <>
      {groups.map((group, index) => (
        <StyledContainer
          className={clsx(
            'block',
            group.alignment,
            group.variant,
            group.variant === 'block-image' && group.data.length <= 2 && 'block-image-nowrap',
          )}
          key={`${group.key}-${String(index)}`}
        >
          <StyledBlocks
            data={{ blocks: group.data, time: data.time, version: data.version }}
            renderers={{
              paragraph: Paragraph,
              header: HeaderBlock,
              image: ImageBlock,
              list: ListBlock,
              EditorjsButton: CTAButtonBlock,
            }}
            config={{
              code: {
                className: styles.code,
              },
              delimiter: {
                className: '',
              },
              embed: {
                className: styles.embed,
              },
              header: {
                className: 'heading',
              },
              image: {
                className: styles.img,
                actionsClassNames: {
                  stretched: styles.imgStretched,
                  withBorder: styles.imgBordered,
                  withBackground: styles.imgBackgrounded,
                },
              },
              list: {
                className: styles.listInside,
              },
              paragraph: {
                className: clsx('text-base', size === 'large' ? 'text-lg' : undefined),
                actionsClassNames: {
                  alignment: 'text-{alignment}', // This is a substitution placeholder: left or center.
                },
              },
              EditorjsButton: {},
              quote: {
                className: styles.blockquote,
              },
              table: {
                className: styles.table,
              },
            }}
          />
        </StyledContainer>
      ))}
    </>
  );
}
