import clsx from 'clsx';
import React, { useRef } from 'react';

import styled from 'styled-components';

import TextEditor from '@/components/TextEditor';
import type { PricingTierCardProps as Props } from '@/utilities/strapi/types/componentTypes';

import checkedIconDataUri from '@/components/icons/checked.svg?datauri';

const StyledPricingTierCard = styled.div`
  --margin: 4rem;

  flex: 0 0 calc(50% - var(--margin));
  margin: calc(var(--margin) / 2);
  box-sizing: border-box;
  background-color: #ffffff;
  padding: 4rem;
  text-align: left;
  border-radius: 3rem;
  position: relative;
  max-width: 29vw;

  @media (max-width: 768px) {
    max-width: none;
    --margin: 0;
    margin-bottom: 2.6rem;

    &:last-child,
    .text-base:last-child {
      margin-bottom: 0;
    }
  }

  h2 {
    font-size: 4rem;
  }

  &.highlight {
    border: 3px solid black;
  }

  .textHighlight {
    font: var(--font-family);
    background-color: #000;
    color: #fff;
    display: inline-block;
    line-height: 1em;
    margin: 0;
    padding: 1rem 3rem;
    border-radius: 7px 7px 0 0;
    position: absolute;
    top: -2rem;

    @media (max-width: 768px) {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .block {
    .heading {
      margin-right: 8rem;
      margin-bottom: 4rem;
      font-size: 9.6rem;
      font-style: normal;
      font-weight: 250;
      line-height: 0.8em;
      letter-spacing: -0.384rem;

      @media (max-width: 768px) {
        font-size: 4.8rem;
        font-style: normal;
        font-weight: 350;
        line-height: 105%;
        letter-spacing: -0.192rem;
        margin: 0 0 1.9rem 0;
        width: 100%;
      }
    }

    .heading + .text-base {
      margin: 0;
      padding: 0;

      @media (max-width: 768px) {
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: -0.016rem;
      }
    }

    .heading + .text-base + .fine-print + * {
      clear: both;
    }

    .fine-print {
      font-size: 1rem;

      @media (max-width: 768px) {
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: -0.014rem;
        margin-top: 0.5rem;
      }
    }

    .text-base .strapi-link {
      color: var(--text-primary);
      opacity: 0.5;
    }

    .button.strapi-link {
      width: 100%;
    }

    ul {
      list-style-type: none;
    }

    li::before {
      content: '';
      display: inline-block;
      width: 2rem;
      height: 2rem;
      // data-uri requires double quotes
      // prettier-ignore
      background-image: url("${checkedIconDataUri}");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center bottom;
      margin-right: 1rem;

      @media (max-width: 768px) {
        width: 0.8rem;
        height: 0.8rem;
        background-position: center center;
      }
    }
  }
`;

export default function PricingTierCard({ text, title, highlight, className }: Props) {
  const root = useRef<HTMLDivElement>(null);

  return (
    <StyledPricingTierCard className={clsx(highlight && 'highlight', className)} ref={root}>
      {highlight && <div className="textHighlight">{highlight}</div>}
      {title && <h2>{title}</h2>}
      {text && <TextEditor text={text} />}
    </StyledPricingTierCard>
  );
}
