import { useRouter } from 'next/router';
import { useMemo } from 'react';

import { defaultStrapiLocale, mapAppLocaleToStrapiLocale } from '@/components/utils/locale';

export default function useHomeLinkProps() {
  const { locale } = useRouter();
  return useMemo(
    () => ({
      page: {
        Slug: 'home',
        locale: locale ? mapAppLocaleToStrapiLocale(locale) : defaultStrapiLocale,
      },
    }),
    [locale],
  );
}
