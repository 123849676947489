export default function getOffsetTop(element?: HTMLElement | null, relativeTo?: HTMLElement | null) {
  let top = 0;
  while (element) {
    top += element.offsetTop;
    element = element.offsetParent as HTMLElement;
    if (element === relativeTo) {
      break;
    }
  }
  return top;
}
