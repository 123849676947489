export interface Region {
  name: string;
  iso2: string;
  flag: string;
}

// Re-structured and reduced version of the list provided by @jackyang-patreon:
//   https://github.com/Patreon/patreon_marketing/pull/48#issuecomment-1665882753
// With flag icon urls added from https://restcountries.com/
const regions: Region[] = [
  {
    name: 'Afghanistan (‫افغانستان‬‎)',
    iso2: 'AF',
    flag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/5c/Flag_of_the_Taliban.svg/320px-Flag_of_the_Taliban.svg.png',
  },
  {
    name: 'Albania (Shqipëri)',
    iso2: 'AL',
    flag: 'https://flagcdn.com/w320/al.png',
  },
  {
    name: 'Algeria (‫الجزائر‬‎)',
    iso2: 'DZ',
    flag: 'https://flagcdn.com/w320/dz.png',
  },
  {
    name: 'American Samoa',
    iso2: 'AS',
    flag: 'https://flagcdn.com/w320/as.png',
  },
  {
    name: 'Andorra',
    iso2: 'AD',
    flag: 'https://flagcdn.com/w320/ad.png',
  },
  {
    name: 'Angola',
    iso2: 'AO',
    flag: 'https://flagcdn.com/w320/ao.png',
  },
  {
    name: 'Anguilla',
    iso2: 'AI',
    flag: 'https://flagcdn.com/w320/ai.png',
  },
  {
    name: 'Antigua and Barbuda',
    iso2: 'AG',
    flag: 'https://flagcdn.com/w320/ag.png',
  },
  {
    name: 'Argentina',
    iso2: 'AR',
    flag: 'https://flagcdn.com/w320/ar.png',
  },
  {
    name: 'Armenia (Հայաստան)',
    iso2: 'AM',
    flag: 'https://flagcdn.com/w320/am.png',
  },
  {
    name: 'Aruba',
    iso2: 'AW',
    flag: 'https://flagcdn.com/w320/aw.png',
  },
  {
    name: 'Australia',
    iso2: 'AU',
    flag: 'https://flagcdn.com/w320/au.png',
  },
  {
    name: 'Austria (Österreich)',
    iso2: 'AT',
    flag: 'https://flagcdn.com/w320/at.png',
  },
  {
    name: 'Azerbaijan (Azərbaycan)',
    iso2: 'AZ',
    flag: 'https://flagcdn.com/w320/az.png',
  },
  {
    name: 'Bahamas',
    iso2: 'BS',
    flag: 'https://flagcdn.com/w320/bs.png',
  },
  {
    name: 'Bahrain (‫البحرين‬‎)',
    iso2: 'BH',
    flag: 'https://flagcdn.com/w320/bh.png',
  },
  {
    name: 'Bangladesh (বাংলাদেশ)',
    iso2: 'BD',
    flag: 'https://flagcdn.com/w320/bd.png',
  },
  {
    name: 'Barbados',
    iso2: 'BB',
    flag: 'https://flagcdn.com/w320/bb.png',
  },
  {
    name: 'Belarus (Беларусь)',
    iso2: 'BY',
    flag: 'https://flagcdn.com/w320/by.png',
  },
  {
    name: 'Belgium (België)',
    iso2: 'BE',
    flag: 'https://flagcdn.com/w320/be.png',
  },
  {
    name: 'Belize',
    iso2: 'BZ',
    flag: 'https://flagcdn.com/w320/bz.png',
  },
  {
    name: 'Benin (Bénin)',
    iso2: 'BJ',
    flag: 'https://flagcdn.com/w320/bj.png',
  },
  {
    name: 'Bermuda',
    iso2: 'BM',
    flag: 'https://flagcdn.com/w320/bm.png',
  },
  {
    name: 'Bhutan (འབྲུག)',
    iso2: 'BT',
    flag: 'https://flagcdn.com/w320/bt.png',
  },
  {
    name: 'Bolivia',
    iso2: 'BO',
    flag: 'https://flagcdn.com/w320/bo.png',
  },
  {
    name: 'Bosnia and Herzegovina (Босна и Херцеговина)',
    iso2: 'BA',
    flag: 'https://flagcdn.com/w320/ba.png',
  },
  {
    name: 'Botswana',
    iso2: 'BW',
    flag: 'https://flagcdn.com/w320/bw.png',
  },
  {
    name: 'Bouvet Island',
    iso2: 'BV',
    flag: 'https://flagcdn.com/w320/bv.png',
  },
  {
    name: 'Brazil (Brasil)',
    iso2: 'BR',
    flag: 'https://flagcdn.com/w320/br.png',
  },
  {
    name: 'British Indian Ocean Territory',
    iso2: 'IO',
    flag: 'https://flagcdn.com/w320/io.png',
  },
  {
    name: 'British Virgin Islands',
    iso2: 'VG',
    flag: 'https://flagcdn.com/w320/vg.png',
  },
  {
    name: 'Brunei',
    iso2: 'BN',
    flag: 'https://flagcdn.com/w320/bn.png',
  },
  {
    name: 'Bulgaria (България)',
    iso2: 'BG',
    flag: 'https://flagcdn.com/w320/bg.png',
  },
  {
    name: 'Burkina Faso',
    iso2: 'BF',
    flag: 'https://flagcdn.com/w320/bf.png',
  },
  {
    name: 'Burundi (Uburundi)',
    iso2: 'BI',
    flag: 'https://flagcdn.com/w320/bi.png',
  },
  {
    name: 'Cambodia (កម្ពុជា)',
    iso2: 'KH',
    flag: 'https://flagcdn.com/w320/kh.png',
  },
  {
    name: 'Cameroon (Cameroun)',
    iso2: 'CM',
    flag: 'https://flagcdn.com/w320/cm.png',
  },
  {
    name: 'Canada',
    iso2: 'CA',
    flag: 'https://flagcdn.com/w320/ca.png',
  },
  {
    name: 'Cape Verde (Kabu Verdi)',
    iso2: 'CV',
    flag: 'https://flagcdn.com/w320/cv.png',
  },
  {
    name: 'Caribbean Netherlands',
    iso2: 'BQ',
    flag: 'https://flagcdn.com/w320/bq.png',
  },
  {
    name: 'Cayman Islands',
    iso2: 'KY',
    flag: 'https://flagcdn.com/w320/ky.png',
  },
  {
    name: 'Central African Republic (République centrafricaine)',
    iso2: 'CF',
    flag: 'https://flagcdn.com/w320/cf.png',
  },
  {
    name: 'Chad (Tchad)',
    iso2: 'TD',
    flag: 'https://flagcdn.com/w320/td.png',
  },
  {
    name: 'Chile',
    iso2: 'CL',
    flag: 'https://flagcdn.com/w320/cl.png',
  },
  {
    name: 'China (中国)',
    iso2: 'CN',
    flag: 'https://flagcdn.com/w320/cn.png',
  },
  {
    name: 'Colombia',
    iso2: 'CO',
    flag: 'https://flagcdn.com/w320/co.png',
  },
  {
    name: 'Comoros (‫جزر القمر‬‎)',
    iso2: 'KM',
    flag: 'https://flagcdn.com/w320/km.png',
  },
  {
    name: 'Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)',
    iso2: 'CD',
    flag: 'https://flagcdn.com/w320/cd.png',
  },
  {
    name: 'Congo (Republic) (Congo-Brazzaville)',
    iso2: 'CG',
    flag: 'https://flagcdn.com/w320/cg.png',
  },
  {
    name: 'Cook Islands',
    iso2: 'CK',
    flag: 'https://flagcdn.com/w320/ck.png',
  },
  {
    name: 'Costa Rica',
    iso2: 'CR',
    flag: 'https://flagcdn.com/w320/cr.png',
  },
  {
    name: 'Côte d’Ivoire',
    iso2: 'CI',
    flag: 'https://flagcdn.com/w320/ci.png',
  },
  {
    name: 'Croatia (Hrvatska)',
    iso2: 'HR',
    flag: 'https://flagcdn.com/w320/hr.png',
  },
  {
    name: 'Curaçao',
    iso2: 'CW',
    flag: 'https://flagcdn.com/w320/cw.png',
  },
  {
    name: 'Cyprus (Κύπρος)',
    iso2: 'CY',
    flag: 'https://flagcdn.com/w320/cy.png',
  },
  {
    name: 'Czech Republic (Česká republika)',
    iso2: 'CZ',
    flag: 'https://flagcdn.com/w320/cz.png',
  },
  {
    name: 'Denmark (Danmark)',
    iso2: 'DK',
    flag: 'https://flagcdn.com/w320/dk.png',
  },
  {
    name: 'Djibouti',
    iso2: 'DJ',
    flag: 'https://flagcdn.com/w320/dj.png',
  },
  {
    name: 'Dominica',
    iso2: 'DM',
    flag: 'https://flagcdn.com/w320/dm.png',
  },
  {
    name: 'Dominican Republic (República Dominicana)',
    iso2: 'DO',
    flag: 'https://flagcdn.com/w320/do.png',
  },
  {
    name: 'Ecuador',
    iso2: 'EC',
    flag: 'https://flagcdn.com/w320/ec.png',
  },
  {
    name: 'Egypt (‫مصر‬‎)',
    iso2: 'EG',
    flag: 'https://flagcdn.com/w320/eg.png',
  },
  {
    name: 'El Salvador',
    iso2: 'SV',
    flag: 'https://flagcdn.com/w320/sv.png',
  },
  {
    name: 'Equatorial Guinea (Guinea Ecuatorial)',
    iso2: 'GQ',
    flag: 'https://flagcdn.com/w320/gq.png',
  },
  {
    name: 'Eritrea',
    iso2: 'ER',
    flag: 'https://flagcdn.com/w320/er.png',
  },
  {
    name: 'Estonia (Eesti)',
    iso2: 'EE',
    flag: 'https://flagcdn.com/w320/ee.png',
  },
  {
    name: 'Ethiopia',
    iso2: 'ET',
    flag: 'https://flagcdn.com/w320/et.png',
  },
  {
    name: 'Falkland Islands (Islas Malvinas)',
    iso2: 'FK',
    flag: 'https://flagcdn.com/w320/fk.png',
  },
  {
    name: 'Faroe Islands (Føroyar)',
    iso2: 'FO',
    flag: 'https://flagcdn.com/w320/fo.png',
  },
  {
    name: 'Fiji',
    iso2: 'FJ',
    flag: 'https://flagcdn.com/w320/fj.png',
  },
  {
    name: 'Finland (Suomi)',
    iso2: 'FI',
    flag: 'https://flagcdn.com/w320/fi.png',
  },
  {
    name: 'France',
    iso2: 'FR',
    flag: 'https://flagcdn.com/w320/fr.png',
  },
  {
    name: 'French Guiana (Guyane française)',
    iso2: 'GF',
    flag: 'https://flagcdn.com/w320/gf.png',
  },
  {
    name: 'French Polynesia (Polynésie française)',
    iso2: 'PF',
    flag: 'https://flagcdn.com/w320/pf.png',
  },
  {
    name: 'French Southern Territories',
    iso2: 'TF',
    flag: 'https://flagcdn.com/w320/tf.png',
  },
  {
    name: 'Gabon',
    iso2: 'GA',
    flag: 'https://flagcdn.com/w320/ga.png',
  },
  {
    name: 'Gambia',
    iso2: 'GM',
    flag: 'https://flagcdn.com/w320/gm.png',
  },
  {
    name: 'Georgia (საქართველო)',
    iso2: 'GE',
    flag: 'https://flagcdn.com/w320/ge.png',
  },
  {
    name: 'Germany (Deutschland)',
    iso2: 'DE',
    flag: 'https://flagcdn.com/w320/de.png',
  },
  {
    name: 'Ghana (Gaana)',
    iso2: 'GH',
    flag: 'https://flagcdn.com/w320/gh.png',
  },
  {
    name: 'Gibraltar',
    iso2: 'GI',
    flag: 'https://flagcdn.com/w320/gi.png',
  },
  {
    name: 'Greece (Ελλάδα)',
    iso2: 'GR',
    flag: 'https://flagcdn.com/w320/gr.png',
  },
  {
    name: 'Greenland (Kalaallit Nunaat)',
    iso2: 'GL',
    flag: 'https://flagcdn.com/w320/gl.png',
  },
  {
    name: 'Grenada',
    iso2: 'GD',
    flag: 'https://flagcdn.com/w320/gd.png',
  },
  {
    name: 'Guadeloupe',
    iso2: 'GP',
    flag: 'https://flagcdn.com/w320/gp.png',
  },
  {
    name: 'Guam',
    iso2: 'GU',
    flag: 'https://flagcdn.com/w320/gu.png',
  },
  {
    name: 'Guatemala',
    iso2: 'GT',
    flag: 'https://flagcdn.com/w320/gt.png',
  },
  {
    name: 'Guernsey',
    iso2: 'GG',
    flag: 'https://flagcdn.com/w320/gg.png',
  },
  {
    name: 'Guinea (Guinée)',
    iso2: 'GN',
    flag: 'https://flagcdn.com/w320/gn.png',
  },
  {
    name: 'Guinea-Bissau (Guiné Bissau)',
    iso2: 'GW',
    flag: 'https://flagcdn.com/w320/gw.png',
  },
  {
    name: 'Guyana',
    iso2: 'GY',
    flag: 'https://flagcdn.com/w320/gy.png',
  },
  {
    name: 'Haiti',
    iso2: 'HT',
    flag: 'https://flagcdn.com/w320/ht.png',
  },
  {
    name: 'Heard Island and McDonald Islands',
    iso2: 'HM',
    flag: 'https://flagcdn.com/w320/hm.png',
  },
  {
    name: 'Honduras',
    iso2: 'HN',
    flag: 'https://flagcdn.com/w320/hn.png',
  },
  {
    name: 'Hong Kong (香港)',
    iso2: 'HK',
    flag: 'https://flagcdn.com/w320/hk.png',
  },
  {
    name: 'Hungary (Magyarország)',
    iso2: 'HU',
    flag: 'https://flagcdn.com/w320/hu.png',
  },
  {
    name: 'Iceland (Ísland)',
    iso2: 'IS',
    flag: 'https://flagcdn.com/w320/is.png',
  },
  {
    name: 'India (भारत)',
    iso2: 'IN',
    flag: 'https://flagcdn.com/w320/in.png',
  },
  {
    name: 'Indonesia',
    iso2: 'ID',
    flag: 'https://flagcdn.com/w320/id.png',
  },
  {
    name: 'Iraq (‫العراق‬‎)',
    iso2: 'IQ',
    flag: 'https://flagcdn.com/w320/iq.png',
  },
  {
    name: 'Ireland',
    iso2: 'IE',
    flag: 'https://flagcdn.com/w320/ie.png',
  },
  {
    name: 'Isle of Man',
    iso2: 'IM',
    flag: 'https://flagcdn.com/w320/im.png',
  },
  {
    name: 'Israel (‫ישראל‬‎)',
    iso2: 'IL',
    flag: 'https://flagcdn.com/w320/il.png',
  },
  {
    name: 'Italy (Italia)',
    iso2: 'IT',
    flag: 'https://flagcdn.com/w320/it.png',
  },
  {
    name: 'Jamaica',
    iso2: 'JM',
    flag: 'https://flagcdn.com/w320/jm.png',
  },
  {
    name: 'Japan (日本)',
    iso2: 'JP',
    flag: 'https://flagcdn.com/w320/jp.png',
  },
  {
    name: 'Jersey',
    iso2: 'JE',
    flag: 'https://flagcdn.com/w320/je.png',
  },
  {
    name: 'Jordan (‫الأردن‬‎)',
    iso2: 'JO',
    flag: 'https://flagcdn.com/w320/jo.png',
  },
  {
    name: 'Kazakhstan (Казахстан)',
    iso2: 'KZ',
    flag: 'https://flagcdn.com/w320/kz.png',
  },
  {
    name: 'Kenya',
    iso2: 'KE',
    flag: 'https://flagcdn.com/w320/ke.png',
  },
  {
    name: 'Kiribati',
    iso2: 'KI',
    flag: 'https://flagcdn.com/w320/ki.png',
  },
  {
    name: 'Kuwait (‫الكويت‬‎)',
    iso2: 'KW',
    flag: 'https://flagcdn.com/w320/kw.png',
  },
  {
    name: 'Kyrgyzstan (Кыргызстан)',
    iso2: 'KG',
    flag: 'https://flagcdn.com/w320/kg.png',
  },
  {
    name: 'Laos (ລາວ)',
    iso2: 'LA',
    flag: 'https://flagcdn.com/w320/la.png',
  },
  {
    name: 'Latvia (Latvija)',
    iso2: 'LV',
    flag: 'https://flagcdn.com/w320/lv.png',
  },
  {
    name: 'Lebanon (‫لبنان‬‎)',
    iso2: 'LB',
    flag: 'https://flagcdn.com/w320/lb.png',
  },
  {
    name: 'Lesotho',
    iso2: 'LS',
    flag: 'https://flagcdn.com/w320/ls.png',
  },
  {
    name: 'Liberia',
    iso2: 'LR',
    flag: 'https://flagcdn.com/w320/lr.png',
  },
  {
    name: 'Libya (‫ليبيا‬‎)',
    iso2: 'LY',
    flag: 'https://flagcdn.com/w320/ly.png',
  },
  {
    name: 'Liechtenstein',
    iso2: 'LI',
    flag: 'https://flagcdn.com/w320/li.png',
  },
  {
    name: 'Lithuania (Lietuva)',
    iso2: 'LT',
    flag: 'https://flagcdn.com/w320/lt.png',
  },
  {
    name: 'Luxembourg',
    iso2: 'LU',
    flag: 'https://flagcdn.com/w320/lu.png',
  },
  {
    name: 'Macau (澳門)',
    iso2: 'MO',
    flag: 'https://flagcdn.com/w320/mo.png',
  },
  {
    name: 'Macedonia (FYROM) (Македонија)',
    iso2: 'MK',
    flag: 'https://flagcdn.com/w320/mk.png',
  },
  {
    name: 'Madagascar (Madagasikara)',
    iso2: 'MG',
    flag: 'https://flagcdn.com/w320/mg.png',
  },
  {
    name: 'Malawi',
    iso2: 'MW',
    flag: 'https://flagcdn.com/w320/mw.png',
  },
  {
    name: 'Malaysia',
    iso2: 'MY',
    flag: 'https://flagcdn.com/w320/my.png',
  },
  {
    name: 'Maldives',
    iso2: 'MV',
    flag: 'https://flagcdn.com/w320/mv.png',
  },
  {
    name: 'Mali',
    iso2: 'ML',
    flag: 'https://flagcdn.com/w320/ml.png',
  },
  {
    name: 'Malta',
    iso2: 'MT',
    flag: 'https://flagcdn.com/w320/mt.png',
  },
  {
    name: 'Marshall Islands',
    iso2: 'MH',
    flag: 'https://flagcdn.com/w320/mh.png',
  },
  {
    name: 'Martinique',
    iso2: 'MQ',
    flag: 'https://flagcdn.com/w320/mq.png',
  },
  {
    name: 'Mauritania (‫موريتانيا‬‎)',
    iso2: 'MR',
    flag: 'https://flagcdn.com/w320/mr.png',
  },
  {
    name: 'Mauritius (Moris)',
    iso2: 'MU',
    flag: 'https://flagcdn.com/w320/mu.png',
  },
  {
    name: 'Mayotte',
    iso2: 'YT',
    flag: 'https://flagcdn.com/w320/yt.png',
  },
  {
    name: 'Mexico (México)',
    iso2: 'MX',
    flag: 'https://flagcdn.com/w320/mx.png',
  },
  {
    name: 'Micronesia',
    iso2: 'FM',
    flag: 'https://flagcdn.com/w320/fm.png',
  },
  {
    name: 'Moldova (Republica Moldova)',
    iso2: 'MD',
    flag: 'https://flagcdn.com/w320/md.png',
  },
  {
    name: 'Monaco',
    iso2: 'MC',
    flag: 'https://flagcdn.com/w320/mc.png',
  },
  {
    name: 'Mongolia (Монгол)',
    iso2: 'MN',
    flag: 'https://flagcdn.com/w320/mn.png',
  },
  {
    name: 'Montenegro (Crna Gora)',
    iso2: 'ME',
    flag: 'https://flagcdn.com/w320/me.png',
  },
  {
    name: 'Montserrat',
    iso2: 'MS',
    flag: 'https://flagcdn.com/w320/ms.png',
  },
  {
    name: 'Morocco (‫المغرب‬‎)',
    iso2: 'MA',
    flag: 'https://flagcdn.com/w320/ma.png',
  },
  {
    name: 'Mozambique (Moçambique)',
    iso2: 'MZ',
    flag: 'https://flagcdn.com/w320/mz.png',
  },
  {
    name: 'Myanmar (Burma) (မြန်မာ)',
    iso2: 'MM',
    flag: 'https://flagcdn.com/w320/mm.png',
  },
  {
    name: 'Namibia (Namibië)',
    iso2: 'NA',
    flag: 'https://flagcdn.com/w320/na.png',
  },
  {
    name: 'Nauru',
    iso2: 'NR',
    flag: 'https://flagcdn.com/w320/nr.png',
  },
  {
    name: 'Nepal (नेपाल)',
    iso2: 'NP',
    flag: 'https://flagcdn.com/w320/np.png',
  },
  {
    name: 'Netherlands (Nederland)',
    iso2: 'NL',
    flag: 'https://flagcdn.com/w320/nl.png',
  },
  {
    name: 'New Caledonia (Nouvelle-Calédonie)',
    iso2: 'NC',
    flag: 'https://flagcdn.com/w320/nc.png',
  },
  {
    name: 'New Zealand',
    iso2: 'NZ',
    flag: 'https://flagcdn.com/w320/nz.png',
  },
  {
    name: 'Nicaragua',
    iso2: 'NI',
    flag: 'https://flagcdn.com/w320/ni.png',
  },
  {
    name: 'Niger (Nijar)',
    iso2: 'NE',
    flag: 'https://flagcdn.com/w320/ne.png',
  },
  {
    name: 'Nigeria',
    iso2: 'NG',
    flag: 'https://flagcdn.com/w320/ng.png',
  },
  {
    name: 'Niue',
    iso2: 'NU',
    flag: 'https://flagcdn.com/w320/nu.png',
  },
  {
    name: 'Norfolk Island',
    iso2: 'NF',
    flag: 'https://flagcdn.com/w320/nf.png',
  },
  {
    name: 'Northern Mariana Islands',
    iso2: 'MP',
    flag: 'https://flagcdn.com/w320/mp.png',
  },
  {
    name: 'Norway (Norge)',
    iso2: 'NO',
    flag: 'https://flagcdn.com/w320/no.png',
  },
  {
    name: 'Oman (‫عُمان‬‎)',
    iso2: 'OM',
    flag: 'https://flagcdn.com/w320/om.png',
  },
  {
    name: 'Pakistan (‫پاکستان‬‎)',
    iso2: 'PK',
    flag: 'https://flagcdn.com/w320/pk.png',
  },
  {
    name: 'Palau',
    iso2: 'PW',
    flag: 'https://flagcdn.com/w320/pw.png',
  },
  {
    name: 'Palestine (‫فلسطين‬‎)',
    iso2: 'PS',
    flag: 'https://flagcdn.com/w320/ps.png',
  },
  {
    name: 'Panama (Panamá)',
    iso2: 'PA',
    flag: 'https://flagcdn.com/w320/pa.png',
  },
  {
    name: 'Papua New Guinea',
    iso2: 'PG',
    flag: 'https://flagcdn.com/w320/pg.png',
  },
  {
    name: 'Paraguay',
    iso2: 'PY',
    flag: 'https://flagcdn.com/w320/py.png',
  },
  {
    name: 'Peru (Perú)',
    iso2: 'PE',
    flag: 'https://flagcdn.com/w320/pe.png',
  },
  {
    name: 'Philippines',
    iso2: 'PH',
    flag: 'https://flagcdn.com/w320/ph.png',
  },
  {
    name: 'Poland (Polska)',
    iso2: 'PL',
    flag: 'https://flagcdn.com/w320/pl.png',
  },
  {
    name: 'Portugal',
    iso2: 'PT',
    flag: 'https://flagcdn.com/w320/pt.png',
  },
  {
    name: 'Puerto Rico',
    iso2: 'PR',
    flag: 'https://flagcdn.com/w320/pr.png',
  },
  {
    name: 'Qatar (‫قطر‬‎)',
    iso2: 'QA',
    flag: 'https://flagcdn.com/w320/qa.png',
  },
  {
    name: 'Réunion (La Réunion)',
    iso2: 'RE',
    flag: 'https://flagcdn.com/w320/re.png',
  },
  {
    name: 'Romania (România)',
    iso2: 'RO',
    flag: 'https://flagcdn.com/w320/ro.png',
  },
  {
    name: 'Russia (Россия)',
    iso2: 'RU',
    flag: 'https://flagcdn.com/w320/ru.png',
  },
  {
    name: 'Rwanda',
    iso2: 'RW',
    flag: 'https://flagcdn.com/w320/rw.png',
  },
  {
    name: 'Saint Barthélemy (Saint-Barthélemy)',
    iso2: 'BL',
    flag: 'https://flagcdn.com/w320/bl.png',
  },
  {
    name: 'Saint Helena',
    iso2: 'SH',
    flag: 'https://flagcdn.com/w320/sh.png',
  },
  {
    name: 'Saint Kitts and Nevis',
    iso2: 'KN',
    flag: 'https://flagcdn.com/w320/kn.png',
  },
  {
    name: 'Saint Lucia',
    iso2: 'LC',
    flag: 'https://flagcdn.com/w320/lc.png',
  },
  {
    name: 'Saint Martin (Saint-Martin (partie française))',
    iso2: 'MF',
    flag: 'https://flagcdn.com/w320/mf.png',
  },
  {
    name: 'Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)',
    iso2: 'PM',
    flag: 'https://flagcdn.com/w320/pm.png',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    iso2: 'VC',
    flag: 'https://flagcdn.com/w320/vc.png',
  },
  {
    name: 'Samoa',
    iso2: 'WS',
    flag: 'https://flagcdn.com/w320/ws.png',
  },
  {
    name: 'San Marino',
    iso2: 'SM',
    flag: 'https://flagcdn.com/w320/sm.png',
  },
  {
    name: 'São Tomé and Príncipe (São Tomé e Príncipe)',
    iso2: 'ST',
    flag: 'https://flagcdn.com/w320/st.png',
  },
  {
    name: 'Saudi Arabia (‫المملكة العربية السعودية‬‎)',
    iso2: 'SA',
    flag: 'https://flagcdn.com/w320/sa.png',
  },
  {
    name: 'Senegal (Sénégal)',
    iso2: 'SN',
    flag: 'https://flagcdn.com/w320/sn.png',
  },
  {
    name: 'Serbia (Србија)',
    iso2: 'RS',
    flag: 'https://flagcdn.com/w320/rs.png',
  },
  {
    name: 'Seychelles',
    iso2: 'SC',
    flag: 'https://flagcdn.com/w320/sc.png',
  },
  {
    name: 'Sierra Leone',
    iso2: 'SL',
    flag: 'https://flagcdn.com/w320/sl.png',
  },
  {
    name: 'Singapore',
    iso2: 'SG',
    flag: 'https://flagcdn.com/w320/sg.png',
  },
  {
    name: 'Sint Maarten',
    iso2: 'SX',
    flag: 'https://flagcdn.com/w320/sx.png',
  },
  {
    name: 'Slovakia (Slovensko)',
    iso2: 'SK',
    flag: 'https://flagcdn.com/w320/sk.png',
  },
  {
    name: 'Slovenia (Slovenija)',
    iso2: 'SI',
    flag: 'https://flagcdn.com/w320/si.png',
  },
  {
    name: 'Solomon Islands',
    iso2: 'SB',
    flag: 'https://flagcdn.com/w320/sb.png',
  },
  {
    name: 'Somalia (Soomaaliya)',
    iso2: 'SO',
    flag: 'https://flagcdn.com/w320/so.png',
  },
  {
    name: 'South Africa',
    iso2: 'ZA',
    flag: 'https://flagcdn.com/w320/za.png',
  },
  {
    name: 'South Korea (대한민국)',
    iso2: 'KR',
    flag: 'https://flagcdn.com/w320/kr.png',
  },
  {
    name: 'South Sudan (‫جنوب السودان‬‎)',
    iso2: 'SS',
    flag: 'https://flagcdn.com/w320/ss.png',
  },
  {
    name: 'Spain (España)',
    iso2: 'ES',
    flag: 'https://flagcdn.com/w320/es.png',
  },
  {
    name: 'Sri Lanka (ශ්‍රී ලංකාව)',
    iso2: 'LK',
    flag: 'https://flagcdn.com/w320/lk.png',
  },
  {
    name: 'Sudan (‫السودان‬‎)',
    iso2: 'SD',
    flag: 'https://flagcdn.com/w320/sd.png',
  },
  {
    name: 'Suriname',
    iso2: 'SR',
    flag: 'https://flagcdn.com/w320/sr.png',
  },
  {
    name: 'Svalbard and Jan Mayen',
    iso2: 'SJ',
    flag: 'https://flagcdn.com/w320/sj.png',
  },
  {
    name: 'Swaziland',
    iso2: 'SZ',
    flag: 'https://flagcdn.com/w320/sz.png',
  },
  {
    name: 'Sweden (Sverige)',
    iso2: 'SE',
    flag: 'https://flagcdn.com/w320/se.png',
  },
  {
    name: 'Switzerland (Schweiz)',
    iso2: 'CH',
    flag: 'https://flagcdn.com/w320/ch.png',
  },
  {
    name: 'Taiwan (台灣)',
    iso2: 'TW',
    flag: 'https://flagcdn.com/w320/tw.png',
  },
  {
    name: 'Tajikistan',
    iso2: 'TJ',
    flag: 'https://flagcdn.com/w320/tj.png',
  },
  {
    name: 'Tanzania',
    iso2: 'TZ',
    flag: 'https://flagcdn.com/w320/tz.png',
  },
  {
    name: 'Thailand (ไทย)',
    iso2: 'TH',
    flag: 'https://flagcdn.com/w320/th.png',
  },
  {
    name: 'Timor-Leste',
    iso2: 'TL',
    flag: 'https://flagcdn.com/w320/tl.png',
  },
  {
    name: 'Togo',
    iso2: 'TG',
    flag: 'https://flagcdn.com/w320/tg.png',
  },
  {
    name: 'Tokelau',
    iso2: 'TK',
    flag: 'https://flagcdn.com/w320/tk.png',
  },
  {
    name: 'Tonga',
    iso2: 'TO',
    flag: 'https://flagcdn.com/w320/to.png',
  },
  {
    name: 'Trinidad and Tobago',
    iso2: 'TT',
    flag: 'https://flagcdn.com/w320/tt.png',
  },
  {
    name: 'Tunisia (‫تونس‬‎)',
    iso2: 'TN',
    flag: 'https://flagcdn.com/w320/tn.png',
  },
  {
    name: 'Turkey (Türkiye)',
    iso2: 'TR',
    flag: 'https://flagcdn.com/w320/tr.png',
  },
  {
    name: 'Turkmenistan',
    iso2: 'TM',
    flag: 'https://flagcdn.com/w320/tm.png',
  },
  {
    name: 'Turks and Caicos Islands',
    iso2: 'TC',
    flag: 'https://flagcdn.com/w320/tc.png',
  },
  {
    name: 'Tuvalu',
    iso2: 'TV',
    flag: 'https://flagcdn.com/w320/tv.png',
  },
  {
    name: 'U.S. Virgin Islands',
    iso2: 'VI',
    flag: 'https://flagcdn.com/w320/vi.png',
  },
  {
    name: 'Uganda',
    iso2: 'UG',
    flag: 'https://flagcdn.com/w320/ug.png',
  },
  {
    name: 'Ukraine (Україна)',
    iso2: 'UA',
    flag: 'https://flagcdn.com/w320/ua.png',
  },
  {
    name: 'United Arab Emirates (‫الإمارات العربية المتحدة‬‎)',
    iso2: 'AE',
    flag: 'https://flagcdn.com/w320/ae.png',
  },
  {
    name: 'United Kingdom',
    iso2: 'GB',
    flag: 'https://flagcdn.com/w320/gb.png',
  },
  {
    name: 'United States',
    iso2: 'US',
    flag: 'https://flagcdn.com/w320/us.png',
  },
  {
    name: 'United States Minor Outlying Islands',
    iso2: 'UM',
    flag: 'https://flagcdn.com/w320/um.png',
  },
  {
    name: 'Uruguay',
    iso2: 'UY',
    flag: 'https://flagcdn.com/w320/uy.png',
  },
  {
    name: 'Uzbekistan (Oʻzbekiston)',
    iso2: 'UZ',
    flag: 'https://flagcdn.com/w320/uz.png',
  },
  {
    name: 'Vanuatu',
    iso2: 'VU',
    flag: 'https://flagcdn.com/w320/vu.png',
  },
  {
    name: 'Vatican City (Città del Vaticano)',
    iso2: 'VA',
    flag: 'https://flagcdn.com/w320/va.png',
  },
  {
    name: 'Venezuela',
    iso2: 'VE',
    flag: 'https://flagcdn.com/w320/ve.png',
  },
  {
    name: 'Vietnam (Việt Nam)',
    iso2: 'VN',
    flag: 'https://flagcdn.com/w320/vn.png',
  },
  {
    name: 'Wallis and Futuna',
    iso2: 'WF',
    flag: 'https://flagcdn.com/w320/wf.png',
  },
  {
    name: 'Yemen (‫اليمن‬‎)',
    iso2: 'YE',
    flag: 'https://flagcdn.com/w320/ye.png',
  },
  {
    name: 'Zambia',
    iso2: 'ZM',
    flag: 'https://flagcdn.com/w320/zm.png',
  },
  {
    name: 'Zimbabwe',
    iso2: 'ZW',
    flag: 'https://flagcdn.com/w320/zw.png',
  },
];

export default regions;
