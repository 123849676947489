import type { MutableRefObject } from 'react';
import { useCallback, useRef } from 'react';

import { SectionTheme } from '@/context/StrapiGlobalsContext';
import clamp from '@/utilities/clamp';
import getOffsetTop from '@/utilities/getOffsetTop';

function getThemeUnderHeader(header: HTMLElement, scrollY: number): SectionTheme {
  const headerBounds = header.getBoundingClientRect();
  let currentCoverage = 0;
  let currentTheme = SectionTheme.light;
  document.querySelectorAll('[data-theme]').forEach((node) => {
    const element = node as HTMLElement;
    const top = getOffsetTop(element);
    const viewportTop = top - scrollY;
    const viewportBottom = viewportTop + element.offsetHeight;
    const headerTop = viewportTop - headerBounds.top;
    const headerBottom = viewportBottom - headerBounds.top;
    const coverage = clamp(headerBottom, 0, headerBounds.height) - clamp(headerTop, 0, headerBounds.height);
    if (coverage > currentCoverage) {
      currentCoverage = coverage;
      currentTheme = element.dataset.theme as SectionTheme;
    }
  });
  return currentTheme;
}

export default function useGetThemeUnderHeader(): [
  MutableRefObject<HTMLDivElement | null>,
  (scrollY: number) => SectionTheme,
] {
  const headerRef = useRef<HTMLDivElement | null>(null);
  const callback = useCallback(
    (scrollY: number) => {
      if (headerRef.current) {
        return getThemeUnderHeader(headerRef.current, scrollY);
      }
      return SectionTheme.light;
    },
    [headerRef],
  );
  return [headerRef, callback];
}
