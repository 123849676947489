import clsx from 'clsx';
import { gsap } from 'gsap';
import React, { useRef } from 'react';

import styled, { css } from 'styled-components';

import { proxy, subscribe } from 'valtio';
import { derive, subscribeKey } from 'valtio/utils';

import ResponsiveImage from '@/components/Elements/ResponsiveImage';
import Video from '@/components/Elements/Video';
import ScrollHintArrow from '@/components/Homepage/ScrollHintArrow';
import ChevronLeft from '@/components/icons/chevron-left.svg';
import ChevronRight from '@/components/icons/chevron-right.svg';

import type { SplitWordElement, SplitWordParent } from '@/components/utils/SplitLines';
import SplitLines, { linesProp } from '@/components/utils/SplitLines';
import SplitLinesWithRightArrow from '@/components/utils/SplitLinesWithRightArrow';
import type { StrapiLinkProps } from '@/components/utils/StrapiLink';
import StrapiLink from '@/components/utils/StrapiLink';
import useGsapEffect, { makeContextWrapper } from '@/hooks/useGsapEffect';
import { remToCurrentPx } from '@/hooks/useRemScale';
import getHydraNextBus from '@/hydra/next/getHydraNextBus';
import getOffsetTop from '@/utilities/getOffsetTop';
import type { HomeHeroProps as Props, MediaWithMeta } from '@/utilities/strapi/types/componentTypes';

import CTAButton from '../Blocks/CTAButton';
import LineBreakTitle from '../utils/LineBreakTitle';

const staggerProp = Symbol('CreatorInfoStagger');

interface CreatorInfoLineElement extends SplitWordElement {
  [staggerProp]?: number;
}

function getEventCoords(e: MouseEvent | TouchEvent) {
  let x = 0;
  let y = 0;
  if ((e as TouchEvent).touches || (e as TouchEvent).changedTouches) {
    const touchEvent = e as TouchEvent;
    if (touchEvent.touches.length) {
      x = touchEvent.touches[0].clientX;
      y = touchEvent.touches[0].clientY;
    } else {
      x = touchEvent.changedTouches[0].clientX;
      y = touchEvent.changedTouches[0].clientY;
    }
  } else {
    const mouseEvent = e as MouseEvent;
    x = mouseEvent.clientX;
    y = mouseEvent.clientY;
  }
  return [x, y];
}

type ZoneNumber = -1 | 0 | 1;

interface HomeHeroState {
  isHover: boolean;
  activeSlideIndex: number;
  nextSlideIndex: number;
  zone: ZoneNumber;
  x: number;
  y: number;
  layoutInitialized: boolean;
  hydraInitialized: boolean;
  inView: boolean;
  debouncedZone: ZoneNumber;
  debouncedActiveSlideIndex: number;
  videoPlaying: boolean[];
}

interface HomeHeroRootElement extends HTMLElement {
  homeHeroState?: HomeHeroState;
}

interface CreatorPosition {
  cssTop: number;
  layoutTop: number;
}

function getCreatorLines(selector: gsap.utils.SelectorFunc) {
  const groups: CreatorInfoLineElement[][][] = (selector('h3,p') as SplitWordParent[]).map(
    (el) => el[linesProp]?.slice() || [],
  );

  // Want to use a negative stagger but gsap doesn’t respect the line groupings (nested arrays)
  // in that case. So need to precalulate reverse-order stagger values.

  // First pass: re-number lines sequentially across all groups
  let lineCount = 0;
  groups.forEach((group) => {
    group.forEach((els) => {
      lineCount += 1;
      els.forEach((el) => {
        el[staggerProp] = lineCount;
      });
    });
  });

  // Second pass: reverse stagger values
  const lines = groups.flat(2);
  lines.forEach((el) => {
    el[staggerProp] = (lineCount - (el[staggerProp] || 1)) / (lineCount - 1);
  });

  return lines;
}

function makeStagger(duration: number, ease = 'sine.out') {
  return function (_index: number, target: { target: CreatorInfoLineElement }) {
    return gsap.parseEase(ease)(target.target[staggerProp] || 0) * duration;
  };
}

function animateLines(
  t: gsap.core.Timeline,
  targets: Element[],
  duration: number,
  ease: string,
  out: boolean,
  stagger: gsap.NumberValue | gsap.StaggerVars,
  position: gsap.Position,
  onComplete?: () => void,
) {
  const objs = targets.map((target) => {
    let yPercent = 0;
    return {
      target,
      get yPercent() {
        return yPercent;
      },
      set yPercent(value) {
        yPercent = value;
        gsap.set(target, {
          yPercent,
          clipPath: `inset(${-yPercent + 10}% -10% ${yPercent - 10}% -10%)`,
        });
      },
    };
  });
  t.fromTo(
    objs,
    { yPercent: out ? 0 : -100 },
    {
      yPercent: out ? 100 : 0,
      duration,
      ease,
      stagger,
      onStart() {
        gsap.set(targets, { autoAlpha: 1 });
      },
      onComplete() {
        gsap.set(targets, { clearProps: 'clipPath,yPercent' });
        if (onComplete) {
          onComplete();
        }
      },
    },
    position,
  );
}

async function waitForCreatorReady(creator: Element) {
  const selector = gsap.utils.selector(creator);
  if ((selector('h3,p') as SplitWordParent[]).some((el) => el[linesProp])) {
    return;
  }
  return new Promise<void>((resolve) => {
    const handler = () => {
      creator.removeEventListener('splitlines', handler, false);
      resolve();
    };
    creator.addEventListener('splitlines', handler, false);
  });
}

function animateNextCreator(t: gsap.core.Timeline, nextCreator: Element, position: gsap.Position) {
  const n = gsap.utils.selector(nextCreator);
  const nlines = getCreatorLines(n);
  t.set(nextCreator, { autoAlpha: 1 }, position);
  t.fromTo(n('img'), { scale: 1.6 }, { scale: 1, duration: 0.5 }, `${position}+=0.4`);
  t.fromTo(n('img')[0].parentNode, { scale: 0 }, { scale: 1, duration: 0.5 }, '<');
  animateLines(t, nlines, 0.5, 'power1.out', false, makeStagger(0.2, 'sine.in'), `${position}+=0.4`);
}

function animatePrevCreator(t: gsap.core.Timeline, prevCreator: Element, position: gsap.Position) {
  const p = gsap.utils.selector(prevCreator);
  const plines = p && getCreatorLines(p);
  t.set(prevCreator, { autoAlpha: 1 }, position);
  t.to(p('a'), { opacity: 0, duration: 0.5 }, `${position}+=0.4`);
  t.to(p('img'), { scale: 1.6, duration: 0.7 }, `${position}+=0.4`);

  animateLines(t, plines, 0.35, 'power1.out', true, makeStagger(0.2, 'sine.out'), position, () => {
    gsap.set(plines, { autoAlpha: 0 });
  });
}

function animateCreator(t: gsap.core.Timeline, prevCreator: Element, nextCreator: Element, position: gsap.Position) {
  animatePrevCreator(t, prevCreator, position);
  animateNextCreator(t, nextCreator, position);
}

function animateCreatorPosition(
  t: gsap.core.Timeline,
  prevCreator: Element,
  prevCreatorPosition: CreatorPosition,
  nextCreator: Element,
  nextCreatorPosition: CreatorPosition,
  position: gsap.Position,
) {
  const prevY = gsap.getProperty(prevCreator, 'y') as number;
  const prevTop = prevCreatorPosition.cssTop + prevY;
  const duration = 0.8;
  const ease = 'cubic.out';
  t.to(prevCreator, { y: nextCreatorPosition.layoutTop - prevCreatorPosition.cssTop, duration, ease }, position);
  t.fromTo(
    nextCreator,
    { y: prevTop - nextCreatorPosition.cssTop },
    { y: nextCreatorPosition.layoutTop - nextCreatorPosition.cssTop, duration, ease },
    position,
  );
}

function animateArrowPosition(t: gsap.core.Timeline, nextPosition: number, position: gsap.Position) {
  t.to(
    '.left, .right',
    {
      y: nextPosition - (gsap.getProperty('.right', 'top') as number),
      duration: 0.8,
      ease: 'cubic.out',
    },
    position,
  );
}

function useCarousel() {
  const root = useRef<HTMLDivElement | null>(null);
  useGsapEffect(root, (ctx) => {
    const query = window.matchMedia('(hover: hover)');

    const rootEl = root.current as HomeHeroRootElement;
    const slides = Array.from(rootEl.getElementsByClassName('slide'));
    const assets: Array<HTMLImageElement | HTMLVideoElement | null> = slides.map((slide) =>
      slide.querySelector('video,img'),
    );
    const slideContents = Array.from(rootEl.getElementsByClassName('slide-content'));
    const titles: HTMLElement[] = Array.from(rootEl.querySelectorAll('.slide-content h1'));
    const creatorInfos: HTMLElement[] = Array.from(rootEl.querySelectorAll('.slide-content .creator-info'));

    const ctaButtons: Array<HTMLElement | null> = Array.from(rootEl.querySelectorAll('.slide-content')).map((el) =>
      el.querySelector('.custom-cta-button'),
    );

    const state: HomeHeroState = proxy({
      isHover: query.matches,
      activeSlideIndex: 0,
      debouncedActiveSlideIndex: 0,
      nextSlideIndex: 1,
      zone: 0,
      debouncedZone: 0,
      x: 0,
      y: 0,
      layoutInitialized: false,
      hydraInitialized: false,
      inView: false,
      videoPlaying: [],
      ctaButtons: [],
    });
    rootEl.homeHeroState = state;

    const withGsap = makeContextWrapper(ctx);
    let creatorPositions: CreatorPosition[];
    let arrowPositions: number[];

    const resizeObserver = new ResizeObserver(
      withGsap(() => {
        // Figure out the ideal positioning for each CreatorInfo panel.
        const isMobile = window.getComputedStyle(creatorInfos[0]).getPropertyValue('align-self') === 'start';
        if (isMobile) {
          // mobile: where css puts it is correct
          creatorPositions = creatorInfos.map((el) => {
            const cssTop = getOffsetTop(el, rootEl);
            return {
              cssTop,
              layoutTop: cssTop,
            };
          });
        } else {
          // desktop: vertically aligned with the bottom of the first title span
          creatorPositions = creatorInfos.map((el, i) => {
            const cssTop = getOffsetTop(el, rootEl);
            const firstLine = titles[i].querySelector('span');
            let layoutTop = cssTop;
            if (firstLine) {
              const firstSpanBaseline =
                getOffsetTop(firstLine, rootEl) +
                firstLine.offsetHeight -
                parseFloat(getComputedStyle(firstLine).lineHeight) * 0.2;
              layoutTop = firstSpanBaseline - el.offsetHeight;
            }
            return {
              cssTop,
              layoutTop,
            };
          });
        }
        creatorInfos.forEach((el, i) => {
          const { cssTop, layoutTop } = creatorPositions[i];
          gsap.set(el, { y: layoutTop - cssTop, overwrite: 'auto' });
        });

        // Position the left and right arrows midway between the top nav and the top of the Title
        const topNavHeight = remToCurrentPx(12);
        arrowPositions = titles.map((title, i) => {
          let top;
          if (isMobile) {
            top = creatorPositions[i].cssTop;
          } else {
            top = getOffsetTop(title, rootEl);
          }
          return topNavHeight + (top - topNavHeight) / 2;
        });
        gsap.set('.left, .right', {
          y: arrowPositions[state.activeSlideIndex] - (gsap.getProperty('.right', 'top') as number),
        });

        if (!state.layoutInitialized) {
          void waitForCreatorReady(creatorInfos[0]).then(() => {
            state.layoutInitialized = true;
            animateNextCreator(gsap.timeline(), creatorInfos[0], 'n');
          });
        }
      }),
    );
    titles.forEach((title) => {
      resizeObserver.observe(title);
    });

    const intersectionObserver = new IntersectionObserver(
      withGsap((entries) => {
        state.inView = entries[0].isIntersecting;
      }),
    );
    intersectionObserver.observe(rootEl);

    let zoneDebounceTimeout: NodeJS.Timeout | undefined;
    subscribeKey(
      state,
      'zone',
      withGsap((zone) => {
        if (state.isHover) {
          if (zone === -1) {
            // previous
            gsap.to('.left', { opacity: 1, duration: 0.7, overwrite: 'auto' });
            gsap.to('.right', { opacity: 0, duration: 0.3, overwrite: 'auto' });
            gsap.set(rootEl, { cursor: 'pointer' });
          } else if (zone === 1) {
            // next
            gsap.to('.left', { opacity: 0, duration: 0.3, overwrite: 'auto' });
            gsap.to('.right', { opacity: 1, duration: 0.7, overwrite: 'auto' });
            gsap.set(rootEl, { cursor: 'pointer' });
          } else {
            gsap.to('.left', { opacity: 0, duration: 0.3, overwrite: 'auto' });
            gsap.to('.right', { opacity: 0, duration: 0.3, overwrite: 'auto' });
            gsap.set(rootEl, { clearProps: 'cursor' });
          }
        }
        if (zone === 0) {
          // when transitioning to zero, the video needs to stay active while the
          // blob tweens away. But coming up from 0 needs to activate the video
          // instantly.
          zoneDebounceTimeout = setTimeout(() => {
            if (state.zone === 0) {
              state.debouncedZone = 0;
            }
            zoneDebounceTimeout = undefined;
          }, 600);
        } else {
          clearTimeout(zoneDebounceTimeout);
          zoneDebounceTimeout = undefined;
          state.debouncedZone = zone;
        }
      }),
    );

    let activeSlideIndexDebounceTimeout: NodeJS.Timeout | undefined;
    subscribeKey(state, 'activeSlideIndex', (activeSlideIndex) => {
      clearTimeout(activeSlideIndexDebounceTimeout);
      activeSlideIndexDebounceTimeout = setTimeout(() => {
        if (state.activeSlideIndex === activeSlideIndex) {
          state.debouncedActiveSlideIndex = activeSlideIndex;
        }
        activeSlideIndexDebounceTimeout = undefined;
      }, 1500);
    });

    // derive video playing state for each video based on the relevant factors
    derive(
      assets.reduce((derivedFns, asset, index) => {
        if (asset instanceof HTMLVideoElement) {
          derivedFns[index] = (get) => {
            const { inView, activeSlideIndex, debouncedActiveSlideIndex, nextSlideIndex, debouncedZone } = get(state);
            if (!inView) {
              return false;
            }
            if (index === activeSlideIndex || index === debouncedActiveSlideIndex) {
              return true;
            }
            return index === nextSlideIndex && debouncedZone !== 0;
          };
        } else {
          state.videoPlaying[index] = false;
        }
        return derivedFns;
      }, {} as Record<number, (get: <P>(state: P) => P) => boolean>),
      {
        proxy: state.videoPlaying,
      },
    );

    // subscribe to videoPlaying state to play and pause each video
    assets.forEach((asset, index) => {
      if (!(asset instanceof HTMLVideoElement)) {
        return;
      }
      const video = asset;
      // Safari automatically pauses autoPlay videos that go out of view, which
      // includes if its parent gains visibility:hidden (documented here:
      // https://developer.apple.com/documentation/webkit/delivering_video_content_for_safari/#3030251).
      // Removing autoplay now that it is already playing serves to prevent this
      // from happening. Hydra is about to set visibility:hidden on the video’s
      // parent so that the webgl canvas can be seen, and that would cause the
      // video to unexpectedly pause if we hadn’t un-set autoPlay.
      video.autoplay = false;
      subscribeKey(state.videoPlaying, index, (playing) => {
        if (playing) {
          void video.play();
        } else {
          video.pause();
        }
      });
    });

    const getZone = function (e: MouseEvent | TouchEvent) {
      const zoneWidth = rootEl.offsetWidth / 2;
      const target = e.target as HTMLElement;
      let zone: ZoneNumber = 0;
      if (!target.matches('.creator-info *, .home-arrow *')) {
        const [x] = getEventCoords(e);
        if (x < zoneWidth) {
          zone = -1;
        } else {
          zone = 1;
        }
      }
      return zone;
    };

    const getNextSlideIndex = function (zone: ZoneNumber) {
      let nextSlideIndex = state.activeSlideIndex + zone;
      if (nextSlideIndex >= slides.length) {
        nextSlideIndex = 0;
      } else if (nextSlideIndex < 0) {
        nextSlideIndex = slides.length - 1;
      }
      return nextSlideIndex;
    };

    const handleMouseMove = function (e: MouseEvent | TouchEvent) {
      const zone = getZone(e);
      state.zone = zone;
      [state.x, state.y] = getEventCoords(e);
      if (zone !== 0) {
        state.nextSlideIndex = getNextSlideIndex(zone);
      }
    };

    const handleMouseLeave = function () {
      state.zone = 0;
    };

    const handleHoverSupportChange = withGsap(function () {
      state.isHover = query.matches;
      if (state.isHover) {
        gsap.to('.left', { opacity: 0, duration: 0.3, overwrite: 'auto' });
        gsap.to('.right', { opacity: 0, duration: 0.3, overwrite: 'auto' });
        rootEl.removeEventListener('touchmove', handleMouseMove);
        rootEl.removeEventListener('touchend', handleMouseLeave);
        rootEl.addEventListener('mousemove', handleMouseMove, { passive: true });
        rootEl.addEventListener('mouseleave', handleMouseLeave);
      } else {
        gsap.to('.left', { opacity: 1, duration: 0.7, overwrite: 'auto' });
        gsap.to('.right', { opacity: 1, duration: 0.7, overwrite: 'auto' });
        rootEl.removeEventListener('mousemove', handleMouseMove);
        rootEl.removeEventListener('mouseleave', handleMouseLeave);
        rootEl.addEventListener('touchmove', handleMouseMove, { passive: true });
        rootEl.addEventListener('touchend', handleMouseLeave);
      }
    });

    // Hide the DOM chevrons when Hydra will render them instead (but still
    // allow css to show them when using keyboard navigation)
    const hydraState = getHydraNextBus().hydra.state as { disabled: boolean };
    const chevronState = derive({
      ready: (get) => get(hydraState).disabled || get(state).hydraInitialized,
      hidden: (get) => get(state).hydraInitialized && get(state).isHover,
    });
    const updateChevrons = () => {
      Array.from(rootEl.querySelectorAll('.left, .right')).forEach((button) => {
        button.classList.toggle('hide', chevronState.hidden);
        button.classList.toggle('ready', chevronState.ready);
      });
    };
    subscribe(chevronState, updateChevrons);

    let t: gsap.core.Timeline | undefined;

    function changeSlide() {
      if (state.zone === 0 || (t && t.progress() < 1)) {
        return;
      }
      const prevSlide = slides[state.activeSlideIndex];
      const prevContent = slideContents[state.activeSlideIndex];
      const prevTitle = titles[state.activeSlideIndex];
      const prevCreator = creatorInfos[state.activeSlideIndex];
      const prevCreatorPosition = creatorPositions[state.activeSlideIndex];
      const prevCta = ctaButtons[state.activeSlideIndex];
      state.activeSlideIndex = getNextSlideIndex(state.zone);
      const nextSlide = slides[state.activeSlideIndex];
      const nextContent = slideContents[state.activeSlideIndex];
      const nextTitle = titles[state.activeSlideIndex];
      const nextCreator = creatorInfos[state.activeSlideIndex];
      const nextCreatorPosition = creatorPositions[state.activeSlideIndex];
      const nextArrowPosition = arrowPositions[state.activeSlideIndex];
      const otherSlides = slides.filter((slide) => slide !== nextSlide && slide !== prevSlide);
      const otherContents = slideContents.filter((content) => content !== nextContent && content !== prevContent);
      const otherTitles = titles.filter((title) => title !== nextTitle && title !== prevTitle);
      const otherCreators = creatorInfos.filter((creator) => creator !== nextCreator && creator !== prevCreator);

      if (t) {
        t.kill();
      }
      t = gsap.timeline({
        onComplete() {
          state.nextSlideIndex = getNextSlideIndex(state.zone);
        },
      });
      t.set(prevSlide, { autoAlpha: 1, zIndex: -1 });
      t.set(prevCta, { autoAlpha: 1, zIndex: -1 });
      t.set(otherSlides, { autoAlpha: 0, zIndex: -1 });
      t.set(nextSlide, { clearProps: 'zIndex' });
      t.set(prevContent, { autoAlpha: 1, zIndex: -1 });
      t.set(nextContent, { autoAlpha: 1, clearProps: 'zIndex' });
      t.set(otherContents, { autoAlpha: 0, zIndex: -1 });
      t.set(nextCreator, { autoAlpha: 0 });
      t.set(otherCreators, { autoAlpha: 0 });
      t.to(nextSlide, {
        autoAlpha: 1,
        duration: 1.5,
      });
      t.addLabel('content', '-=1.1');
      t.addLabel('title', 'content-=0.6');

      if (prevTitle.textContent === nextTitle.textContent) {
        t.set(prevTitle, { autoAlpha: 0 }, 'title');
        t.set(otherTitles, { autoAlpha: 0 }, '<');
        t.set(nextTitle, { autoAlpha: 1 }, '<');
      } else {
        const p = gsap.utils.selector(prevTitle);
        const n = gsap.utils.selector(nextTitle);
        t.set(nextTitle, { autoAlpha: 1 }, 'title');
        animateLines(t, p(':scope>span'), 1.2, 'expo.in', true, -0.13, 'title', () => {
          gsap.set(prevTitle, { autoAlpha: 0 });
        });
        animateLines(t, n(':scope>span'), 1.4, 'power3.out', false, -0.23, 'title+=1.2');
        animateArrowPosition(t, nextArrowPosition, 'content+=0.15');
      }

      t.addLabel('cr', 'content');
      animateCreator(t, prevCreator, nextCreator, 'cr');
      animateCreatorPosition(t, prevCreator, prevCreatorPosition, nextCreator, nextCreatorPosition, 'content+=0.15');
      t.set(prevSlide, { autoAlpha: 0 });
      t.set(prevContent, { autoAlpha: 0 });
    }

    const cycleCarousel = () => {
      state.zone = 1;

      // We start transition from the location of the users mouse or the middle right side of window if offscreen.
      if (state.x === 0 && state.y === 0) {
        state.x = window.innerWidth;
        state.y = window.innerHeight / 2;
      }

      state.nextSlideIndex = getNextSlideIndex(state.zone);

      changeSlide();
    };

    const carouselInterval = setInterval(cycleCarousel, 6000);

    const handleClick = withGsap(function (e: MouseEvent) {
      handleMouseMove(e);
      clearInterval(carouselInterval);

      changeSlide();

      if (!state.isHover) {
        setTimeout(() => {
          state.zone = 0;
        }, 0);
      }
    });

    rootEl.addEventListener('click', handleClick);
    query.addEventListener('change', handleHoverSupportChange);
    handleHoverSupportChange();
    updateChevrons();

    return () => {
      if (t) {
        t.kill();
      }
      resizeObserver.disconnect();
      intersectionObserver.disconnect();
      query.removeEventListener('change', handleHoverSupportChange);
      rootEl.removeEventListener('click', handleClick);
      rootEl.removeEventListener('mousemove', handleMouseMove);
      rootEl.removeEventListener('touchmove', handleMouseMove);
      rootEl.removeEventListener('touchend', handleMouseLeave);
      rootEl.removeEventListener('mouseleave', handleMouseLeave);
    };
  });
  return root;
}

const StyledHomeHero = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  color: var(--color-white);
  -webkit-tap-highlight-color: transparent;
  user-select: none;

  .home-arrow {
    right: auto;
    left: calc(5rem + var(--rem-scale-viewport-half-excess));

    @media (max-width: 768px) {
      left: 2rem;
      right: auto;
      bottom: calc(3.8rem + var(--browser-chrome-height));
    }
  }
`;

const Slides = styled.div`
  position: relative;
`;

const Content = styled.div<{ $count: number }>`
  position: relative;
  width: ${(props) => props.$count * 100}%;
  min-height: clamp(50vw, 100vh, 70vw);
  @media (max-width: 768px) {
    min-height: max(50vw, 100vh);
  }
  pointer-events: none;
  display: flex;
  flex-direction: row;
  align-items: stretch;
`;

const SlideContent = styled.div<{ $index: number }>`
  position: relative;
  flex: 1 0 0px;
  transform: translateX(${(props) => props.$index * -100}%);
  padding-top: 7rem;
  pointer-events: none;
  display: flex;
  flex-direction: column-reverse;
  opacity: 0;
  visibility: hidden;
  &:first-child {
    opacity: 1;
    visibility: visible;
  }
`;

const Slide = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  pointer-events: none;
  &:first-child {
    opacity: 1;
  }
`;

const SlideBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;

  & > video,
  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const HomeHeroTitle = styled(LineBreakTitle).attrs({
  size: 1,
})`
  position: relative;
  width: 100%;
  height: auto;
  padding: 0 max(env(safe-area-inset-right), calc(0.18em + var(--rem-scale-viewport-half-excess))) 0.17em
    max(env(safe-area-inset-left), calc(0.18em + var(--rem-scale-viewport-half-excess)));
  line-height: 0.98;
  pointer-events: none;
  > span:first-child {
    padding-right: max(env(safe-area-inset-right), calc(23 * var(--font-size-body)));
  }
  > span:last-child {
    padding-left: max(env(safe-area-inset-left), 1em);
  }
  @media (max-width: 768px) {
    padding: 0 max(env(safe-area-inset-right), 0.35em) calc(0.48em + var(--browser-chrome-height))
      max(env(safe-area-inset-left), 0.35em);
    > span:first-child {
      padding-right: 0;
    }
  }
`;

const CreatorInfo = styled(StrapiLink)<{ wideLayout?: boolean }>`
  display: block;
  position: relative;
  width: ${({ wideLayout }) => (wideLayout ? '32em' : '22em')};
  margin: 0 max(env(safe-area-inset-right), calc(4rem + var(--rem-scale-viewport-half-excess))) 3.2rem 2rem;
  pointer-events: auto;
  align-self: end;
  bottom: calc(-0.98 * var(--font-size-h1));
  // Hide CreatorInfo until hydration on desktop, because correct positioning
  // depends on how the text wraps.
  visibility: hidden;

  @media (max-width: 768px) {
    align-self: start;
    width: calc(100% - max(env(safe-area-inset-right), 2rem) - max(env(safe-area-inset-left), 2rem));
    bottom: auto;
    max-width: 25em;
    margin: 0 max(env(safe-area-inset-right), 2rem) 3.2rem max(env(safe-area-inset-left), 2rem);
    visibility: inherit;
  }
`;

const CreatorInfoContent = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 3rem;
  align-items: center;
`;

const CreatorCopy = styled.div`
  > h3 {
    line-height: 1.2;
  }
  > p {
    line-height: 1.2;
  }
  > h3 + p {
    margin-top: 0.4em;
  }

  .hidden {
    display: none;
  }

  .custom-cta {
    font-size: var(--font-size-h6);
  }

  .custom-cta-button {
    margin-top: 1.5rem;
    background-color: var(--color-action-inverse);
    border: var(--color-action-inverse);
    color: var(--color-action-default);
  }
`;

const ProfilePic = styled.div`
  flex: 0 0 5em;
  width: 5em;
  height: 5em;
  border-radius: 50%;
  overflow: hidden;
  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .hidden {
    display: none;
  }
`;

const chevronStyles = css`
  position: absolute;
  top: 50%;
  margin-top: -3.5rem;
  opacity: 0; // will show when hydrated and interactive
  visibility: hidden;
  width: 7rem;
  height: 7rem;
  appearance: none;
  padding: 0;
  border: 0;
  display: block;
  z-index: 1;
  &.hide {
    opacity: 0 !important;
  }
  &.ready {
    visibility: visible;
  }
  &:focus-visible {
    opacity: 1 !important;
  }
  > svg {
    display: block;
    width: 100%;
    height: 100%;
  }
  @media (max-width: 768px) {
    margin-top: -1.2rem;
    width: 2.4rem;
    height: 2.4rem;
  }
`;

const PrevButton = styled.button`
  ${chevronStyles};
  left: max(env(safe-area-inset-left), calc(5rem + var(--rem-scale-viewport-half-excess)));
  @media (max-width: 768px) {
    left: max(env(safe-area-inset-left), 2rem);
  }
`;

const NextButton = styled.button`
  ${chevronStyles};
  right: max(env(safe-area-inset-right), calc(5rem + var(--rem-scale-viewport-half-excess)));
  @media (max-width: 768px) {
    right: max(env(safe-area-inset-right), 2rem);
  }
`;

function itemIsVideo(item: Props['Items'][0]) {
  if (item.Media) {
    const strapiMedia = item.Media.media?.data?.attributes;
    if (strapiMedia && strapiMedia.mime.startsWith('video')) {
      return true;
    }
  }
  return false;
}

function getImage(item: Props['Items'][0]) {
  if (item.Media) {
    const strapiMedia = item.Media.media?.data?.attributes;
    if (strapiMedia && strapiMedia.mime.startsWith('image')) {
      return item.Media;
    }
  }
  return item.MediaFallbackImage;
}

export default function HomeHero({ Items: items = [] }: Props) {
  const data = {
    type: 'HomeHero',
  };
  const ref = useCarousel();
  return (
    <StyledHomeHero data-hydra={JSON.stringify(data)} ref={ref}>
      <PrevButton className="left">
        <ChevronLeft />
      </PrevButton>
      <NextButton className="right">
        <ChevronRight />
      </NextButton>
      <Slides>
        {items.map((item, i) => {
          const isVideo = itemIsVideo(item);
          const image = getImage(item);
          return (
            <Slide key={item.id} className="slide">
              <SlideBackground>
                {isVideo ? (
                  <Video
                    media={item.Media as MediaWithMeta}
                    posterImage={item.MediaFallbackImage}
                    autoPlay={i === 0}
                    preload={i === 0 ? 'auto' : i === 1 ? 'metadata' : 'none'}
                    loop
                    muted
                    crossOrigin="anonymous"
                    disableIntersectionObserver
                  />
                ) : (
                  image && <ResponsiveImage image={image} crossOrigin="anonymous" />
                )}
              </SlideBackground>
            </Slide>
          );
        })}
        <Content $count={items.length}>
          {items.map((item, index) => {
            const description = item.CreatorDescription?.trim() || '';
            const { external_url: externalUrl, ...creatorLink }: { external_url?: string } & Partial<StrapiLinkProps> =
              item.Creator;
            creatorLink.externalUrl = externalUrl;
            const useCustomCta = item?.useCustomCta;
            const customCtaText = item?.CustomCtaText;
            const customCtaButton = item?.CustomCtaButton;

            const hasCustomButton = useCustomCta && customCtaButton && customCtaButton.label;

            return (
              <SlideContent key={item.id} $index={index} className="slide-content">
                <HomeHeroTitle>{item.Title}</HomeHeroTitle>
                <CreatorInfo {...creatorLink} className="creator-info hoverable" useChildren wideLayout={useCustomCta}>
                  <CreatorInfoContent>
                    <ProfilePic>
                      {item.ProfilePic && (
                        <ResponsiveImage
                          image={item.ProfilePic}
                          sizes="95px"
                          crossOrigin="anonymous"
                          className={clsx({ hidden: useCustomCta })}
                        />
                      )}
                    </ProfilePic>

                    <CreatorCopy>
                      <h3 aria-label={item.Creator?.label} className={clsx({ hidden: useCustomCta })}>
                        <SplitLinesWithRightArrow animateOnHover>{item.Creator?.label}</SplitLinesWithRightArrow>
                      </h3>
                      {(description || customCtaText) && (
                        <p aria-label={description} className={clsx({ 'custom-cta': useCustomCta })}>
                          <SplitLines>{customCtaText ? customCtaText : description}</SplitLines>
                        </p>
                      )}
                      {hasCustomButton && (
                        <CTAButton {...customCtaButton} style="primary" className="custom-cta-button" />
                      )}
                    </CreatorCopy>
                  </CreatorInfoContent>
                </CreatorInfo>
              </SlideContent>
            );
          })}
        </Content>
      </Slides>
      <ScrollHintArrow sectionRef={ref} className="home-arrow" />
    </StyledHomeHero>
  );
}
