import { pick } from 'lodash';
import type { VideoHTMLAttributes } from 'react';
import React, { useEffect, useRef, useState } from 'react';

import { useIntersection, useRafLoop } from 'react-use';

import ResponsiveImage from '@/components/Elements/ResponsiveImage';
import getMediaFormats from '@/utilities/strapi/getMediaFormats';
import parseMediaUrl from '@/utilities/strapi/parseMediaUrl';
import type { ImageWithMeta, MediaWithMeta } from '@/utilities/strapi/types/componentTypes';

export interface VideoProps extends VideoHTMLAttributes<HTMLVideoElement> {
  media: ImageWithMeta | MediaWithMeta;
  posterImage?: ImageWithMeta;
  preferredImageSize?: string;
  useRetina?: boolean;
  useRemSize?: boolean;
  scaleFactor?: number;
  transparent?: boolean;
  sizes?: string | undefined;
  disableIntersectionObserver?: boolean;
}

function getPosterUrl(posterImage?: ImageWithMeta) {
  if (!posterImage?.image) {
    return;
  }

  const formats = getMediaFormats(posterImage.image);
  if (!formats) {
    return;
  }
  const srcs = Object.values(formats)
    .filter((format) => format.width)
    .sort((a, b) => (a.width as number) - (b.width as number));

  // Can’t do responsive poster images, and it’s not worth swapping poster
  // images after hydration and potentially interrupting the download of the
  // initial one. Choose a reasonable quality one.
  const src = srcs.find((src, i) => i === srcs.length - 1 || (src.width as number) >= 1000);
  return parseMediaUrl(src);
}

export default function Video({
  media,
  posterImage,
  preferredImageSize,
  useRetina = false,
  useRemSize = false,
  scaleFactor = 1,
  transparent = false,
  sizes,
  disableIntersectionObserver = false,
  ...rest
}: VideoProps) {
  const [style, setStyle] = useState<React.CSSProperties>({});
  const videoRef = useRef<HTMLVideoElement>(null);
  const videoLoaded = useRef<boolean | undefined>(false);
  const strapiMedia = ((media as ImageWithMeta).image || (media as MediaWithMeta).media)?.data?.attributes;
  const intersection = useIntersection(videoRef, {});

  useEffect(() => {
    if (!disableIntersectionObserver) {
      if (intersection?.isIntersecting) {
        void videoRef.current?.play();
      } else {
        videoRef.current?.pause();
      }
    }
  }, [intersection, disableIntersectionObserver]);

  useRafLoop(() => {
    if (!strapiMedia || strapiMedia.mime.startsWith('image')) {
      return;
    }

    if (videoRef.current && videoRef.current.videoWidth > 0 && !videoLoaded.current) {
      videoLoaded.current = true;
      let width = videoRef.current.videoWidth;
      let height = videoRef.current.videoHeight;

      if (scaleFactor !== 1) {
        width *= scaleFactor;
        height *= scaleFactor;
        setStyle({ width, height });
      }

      if (useRetina) {
        width /= 2;
        height /= 2;
        setStyle({ width, height });
      }

      if (useRemSize) {
        setStyle({ width: `${width / 10}rem`, height: `${height / 10}rem` });
      }
    }
  });

  if (!strapiMedia) {
    return null;
  }

  if (strapiMedia.mime.startsWith('image')) {
    const imageProps = pick(rest, 'crossOrigin');
    return (
      <ResponsiveImage
        image={media}
        preferredSize={preferredImageSize}
        useRetina={useRetina}
        useRemSize={useRemSize}
        scaleFactor={scaleFactor}
        transparent={transparent}
        sizes={sizes}
        {...imageProps}
      />
    );
  }

  return (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <video
      ref={videoRef}
      style={style}
      {...rest}
      src={parseMediaUrl(strapiMedia)}
      poster={getPosterUrl(posterImage)}
      playsInline
      muted
    />
  );
}
