import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import styled from 'styled-components';

import Button from '@/components/Elements/Buttons/Button';
import type { CookieEditorProps } from '@/utilities/strapi/types/componentTypes';

import { editCookies } from './utils/editCookies';
import MaxWidthContainer from '../utils/MaxWidthContainer';

const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const CookieEditor = ({
  label,
  query_key: queryKey,
  query_value: queryValue,
  style,
  cta_type: ctaType,
}: CookieEditorProps) => {
  const { query } = useRouter();

  if (!ctaType || ctaType !== 'edit_cookies') {
    throw new Error('Attempting to show a cookie editor button without the right CTA type. Verify Strapi');
  }

  // Trigger the label if the query matches the trigger query.
  const shouldTriggerlabel = label && queryKey === queryValue;
  useEffect(() => {
    if (shouldTriggerlabel) {
      editCookies();
    }
  }, [shouldTriggerlabel]);

  // Open cookie selector by default at /policy/cookies?edit=true
  if (query.edit) {
    editCookies();
  }

  if (!ctaType && !query.edit) {
    // Don't render the cookie button if editing is not allowed.
    return null;
  }

  return (
    <MaxWidthContainer mt={false} mb={false} pt={false} pb={false}>
      <CenteredContainer>
        <Button variant={style} onClick={() => editCookies()} className="bordered">
          {label}
        </Button>
      </CenteredContainer>
    </MaxWidthContainer>
  );
};
