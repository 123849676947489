import clsx from 'clsx';
import React from 'react';
import styled, { css } from 'styled-components';

import ArrowIcon from '@/components/icons/arrow-right.svg';

interface Props {
  children?: string;
  animateOnHover?: boolean;
  className?: string;
}

const NoWrap = styled.span`
  white-space: nowrap;
`;

const Arrow = styled(ArrowIcon)<{ $animateOnHover?: boolean }>`
  display: inline-block;
  width: 0.8em;
  height: 0.8em;
  margin-left: 0.2em;
  margin-top: -0.1em;

  ${(props: { $animateOnHover?: boolean }) =>
    props.$animateOnHover
      ? css`
          .hoverable & {
            transition: transform var(--animation-duration-fast) var(--animation-timing);
          }

          .hoverable:hover &,
          .hoverable.active & {
            transform: translateX(66%);
          }
        `
      : undefined}
`;

export const WordWithRightArrow = React.forwardRef<HTMLSpanElement, Props>(function WordWithRightArrow(
  { children, className, animateOnHover },
  ref,
) {
  return (
    <NoWrap className={className} ref={ref}>
      {children}
      <Arrow className="arrow" $animateOnHover={animateOnHover} />
    </NoWrap>
  );
});

export default function TextWithRightArrow({ children, className, animateOnHover }: Props) {
  const words = (children || '').split(/\s+/);
  const lastWord = words[words.length - 1] || '';
  const otherWords = words
    .slice(0, words.length - 1)
    .concat('')
    .join(' ');
  return (
    <span className={clsx({ hoverable: animateOnHover }, className)}>
      {otherWords}
      <WordWithRightArrow animateOnHover={animateOnHover}>{lastWord}</WordWithRightArrow>
    </span>
  );
}
