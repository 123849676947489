import clsx from 'clsx';
import { clamp } from 'lodash';
import React, { useRef } from 'react';

import { useRafLoop } from 'react-use';
import styled from 'styled-components';

import getCreatorCarouselResponsiveSizes from '@/components/Creators/CreatorCarousel/getCreatorCarouselResponsiveSizes';
import ResponsiveImage from '@/components/Elements/ResponsiveImage';
import GradientBackground from '@/components/Hydra/GradientBackground';
import type { CreatorCarouselProps as Props } from '@/utilities/strapi/types/componentTypes';

import Heading from '../../Elements/Heading';
import TextEditor from '../../TextEditor';
import LineBreakTitle from '../../utils/LineBreakTitle';
import StrapiLink from '../../utils/StrapiLink';

const StyledCreatorCarousel = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-bottom: 25vh;

  .top {
    position: relative;
    margin: 34rem 0 -49vh calc(20rem + var(--rem-scale-viewport-half-excess));
    color: var(--color-white);

    @media (max-width: 768px) {
      margin: 8.7rem 2rem -42vh 2rem;
    }

    h4 {
      font-size: 9.6rem;
      font-style: normal;
      font-weight: 250;
      line-height: 100%;
      letter-spacing: -0.384rem;
      max-width: 81rem;
      margin-bottom: 6rem;

      @media (max-width: 768px) {
        font-size: 4.8rem;
        font-style: normal;
        font-weight: 350;
        line-height: 105%;
        letter-spacing: -0.192rem;
        max-width: 33.5rem;
        margin-bottom: 2rem;
      }
    }

    .text-base {
      font-size: 2.4rem;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      letter-spacing: -0.048rem;
      max-width: 28.2rem;

      @media (max-width: 768px) {
        font-size: 1.8rem;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: -0.036rem;
        max-width: 19.6rem;
      }
    }
  }

  .carousel {
    position: relative;
  }
`;

const Background = styled(GradientBackground)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`;

const Slides = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  pointer-events: none;
`;

const Slide = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  clip-path: inset(0 0);
`;

const SlideTitles = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
`;

const SlideTitle = styled(Heading)`
  width: 100%;
  text-align: right;
  padding-right: calc(5rem + var(--rem-scale-viewport-half-excess));
  margin: 3rem 0;
  color: var(--color-white);
  opacity: 0.5;
  transition: 0.3s ease opacity;

  &.active {
    opacity: 1;
  }

  &:first-child {
    margin-top: 50vh;
  }

  @media (max-width: 768px) {
    padding-right: 2.7rem;
    margin: 2rem 0;
    line-height: 1em;
  }
`;

const SlideBackground = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  opacity: 0;
  transition: 0.5s ease opacity;

  &.active {
    opacity: 1;
  }

  @media (max-width: 768px) {
    height: 100vh;
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.35) 100%);
  }
  & > video,
  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export default function ScrollCreatorCarousel({ id, title, body, slides = [], anchorId, backgroundColor }: Props) {
  const root = useRef<HTMLDivElement | null>(null);
  const carousel = useRef<HTMLDivElement | null>(null);
  const carouselSlides = useRef<HTMLDivElement | null>(null);
  const carouselTitles = useRef<HTMLHeadingElement[] | null[]>([]);
  const carouselBgs = useRef<HTMLDivElement[] | null[]>([]);
  const carouselBgInners = useRef<HTMLDivElement[] | null[]>([]);
  const lastScroll = useRef(0);
  const currentSlide = useRef(0);

  useRafLoop(() => {
    if (!carouselSlides.current) {
      return;
    }

    if (window.scrollY !== lastScroll.current) {
      const direction = window.scrollY > lastScroll.current ? 1 : -1;
      carouselTitles.current.forEach((slideTitle, i) => {
        if (slideTitle) {
          const rect = slideTitle.getBoundingClientRect();
          const slideProgress = clamp(rect.y / window.innerHeight, 0, 1);
          if ((direction === 1 && slideProgress <= 0.5) || (direction === -1 && slideProgress <= 0.5)) {
            currentSlide.current = i;
          }
        }
      });

      carouselBgInners.current.forEach((slideBg, i) => {
        if (slideBg) {
          if (i === currentSlide.current) {
            slideBg.classList.add('active');
          } else {
            slideBg.classList.remove('active');
          }
        }
      });

      carouselTitles.current.forEach((slideTitle, i) => {
        if (slideTitle) {
          if (i === currentSlide.current) {
            slideTitle.classList.add('active');
          } else {
            slideTitle.classList.remove('active');
          }
        }
      });
    }

    lastScroll.current = window.scrollY;
  });

  const colors = slides.map((item) => item.background?.image?.data?.attributes?.colors).filter(Boolean);
  const bgColors = backgroundColor || colors[Math.min(3, colors.length - 1)];

  return (
    <StyledCreatorCarousel ref={root} id={anchorId?.anchorId}>
      <Background seed={id} colors={bgColors} targetLightness={85} />
      <Slides ref={carouselSlides}>
        {slides.map((item, i) => (
          <Slide ref={(r) => (carouselBgs.current[i] = r)} key={item.id} className={clsx(`slide-${i}`)}>
            <SlideBackground ref={(r) => (carouselBgInners.current[i] = r)}>
              {item.background && (
                <ResponsiveImage image={item.background} sizes={getCreatorCarouselResponsiveSizes(item.background)} />
              )}
            </SlideBackground>
          </Slide>
        ))}
      </Slides>

      <div className="top">
        <LineBreakTitle size={4} align="left">
          {title}
        </LineBreakTitle>
        <TextEditor {...body} />
      </div>

      <div ref={carousel} className="carousel">
        <SlideTitles className="titles">
          {slides.map((item, i) => (
            <SlideTitle ref={(r) => (carouselTitles.current[i] = r)} key={item.id} size={2}>
              <span className={clsx(`title-${i}`)}>
                <StrapiLink {...item.link} />
              </span>
            </SlideTitle>
          ))}
        </SlideTitles>
      </div>
    </StyledCreatorCarousel>
  );
}
