import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';

import ChevronIcon from '@/components/icons/chevron-down.svg';

import type { SelectDropdownProps as Props } from './types';

interface StyledSelectDropdownProps {
  icon?: React.ReactNode;
  iconPath?: string;
}

const StyledSelectDropdown = styled.div.attrs((props: StyledSelectDropdownProps) => ({
  $hasIcon: !!(props.icon || props.iconPath),
}))<StyledSelectDropdownProps>`
  background-color: var(--color-action);
  color: var(--color-action-contrast);
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.$hasIcon ? 'space-between' : 'flex-end')};
  position: relative;
  font-size: var(--button-font-size);
  font-weight: var(--button-font-weight);
  height: 50px;
  padding: var(--spacing-sm) var(--spacing-xs);
  cursor: pointer;
  transition: background-color var(--animation-duration) var(--animation-timing);

  > svg,
  &::before {
    display: block;
    width: 2.4rem;
    height: 2.4rem;
    z-index: 1;
    flex-shrink: 0;
  }

  .down-arrow {
    display: block;
    width: 3rem;
    height: 3rem;
  }

  ${(props) =>
    props.iconPath &&
    css`
      &::before {
        content: '';
        background-image: url(${props.iconPath});
        background-repeat: no-repeat;
      }
    `}

  &:has(:focus-visible) {
    outline: var(--focus-outline);
    outline-color: var(--focus-outline-color);
    outline-offset: var(--focus-outline-offset);
  }

  .select-element {
    appearance: none;
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
    width: 100%;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;
    z-index: 1;
    outline: none;
    position: absolute;
    inset: 0;
    text-indent: ${(props) => (props.$hasIcon ? '5rem' : '2.4rem')};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 4rem;

    &::-ms-expand {
      display: none;
    }
  }

  @-moz-document url-prefix() {
    .select-element {
      text-indent: ${(props) => (props.$hasIcon ? '2.5rem' : '1.2rem')};
      padding-right: ${(props) => (props.$hasIcon ? '7rem' : '5rem')};
    }
  }

  &.sm,
  &.small {
    font-size: var(--font-size-body-sm);
  }

  &.large,
  &.lg {
    padding: var(--spacing-base) var(--spacing-sm);
  }

  &.full-width {
    width: 100%;
  }

  &.secondary {
    background-color: var(--color-neutral-80);
    color: var(--color-text-primary);

    &:hover {
      background-color: var(--color-neutral-60);
    }
  }
`;

export type Ref = HTMLSelectElement;

export const SelectDropdown = forwardRef<Ref, Props>(
  (
    { label, options, value, onChange = void 0, icon, iconPath = '', type = 'primary', size = 'md', fullWidth },
    ref,
  ) => (
    <StyledSelectDropdown icon={icon} iconPath={iconPath} className={`${type} ${size} ${fullWidth && 'full-width'}`}>
      {icon}
      <select ref={ref} className="select-element" defaultValue={value} onChange={onChange} aria-label={label}>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <ChevronIcon className="down-arrow" />
    </StyledSelectDropdown>
  ),
);

SelectDropdown.displayName = 'SelectDropdown';
