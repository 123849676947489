import React from 'react';
import styled from 'styled-components';

import EmailIcon from '@/components/icons/email.svg';
import FacebookIcon from '@/components/icons/facebook.svg';
import InstagramIcon from '@/components/icons/instagram.svg';
import LinkedInIcon from '@/components/icons/linkedin.svg';
import TwitterIcon from '@/components/icons/twitter.svg';
import YoutubeIcon from '@/components/icons/youtube.svg';

import type { SocialNaviagationProps as Props } from './types';
import Button from '../Elements/Buttons/Button';

type SVGIcon = React.FC<React.SVGProps<SVGSVGElement>>;

const iconsMap: Record<string, SVGIcon> = {
  email: EmailIcon as SVGIcon,
  facebook: FacebookIcon as SVGIcon,
  linkedin: LinkedInIcon as SVGIcon,
  twitter: TwitterIcon as SVGIcon,
  instagram: InstagramIcon as SVGIcon,
  youtube: YoutubeIcon as SVGIcon,
};

export default function SocialNavigation({ socialLinks = [] }: Props) {
  return (
    <StyledSocialNavigation role="group">
      {socialLinks.map((link) => {
        const Icon = iconsMap[link.label.toLowerCase()];

        return (
          <Button
            key={link.id}
            tag="a"
            href={link.url}
            icon={Icon && <Icon />}
            label={link.label}
            hideLabel
            type="tertiary"
            target="_blank"
            rel="noopener noreferrer"
          />
        );
      })}
    </StyledSocialNavigation>
  );
}

const StyledSocialNavigation = styled.div`
  display: flex;
  gap: var(--spacing-xs);

  @media (max-width: 768px) {
    width: 100%;
    justify-content: center;
  }
`;
