import type { ImageBlockWithMeta, ImageWithMeta, MediaWithMeta } from '@/utilities/strapi/types/componentTypes';

export default function getAlternativeText(image?: ImageWithMeta | MediaWithMeta | ImageBlockWithMeta) {
  if (!image) {
    return;
  }

  const alt = (image as ImageWithMeta).alternativeText || (image as MediaWithMeta | ImageBlockWithMeta).caption;
  if (alt) {
    return alt;
  }

  const media = (image as ImageWithMeta).image || (image as MediaWithMeta).media;
  return media?.data?.attributes.alternativeText || media?.data?.attributes.caption;
}
