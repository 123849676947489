import clsx from 'clsx';
import React from 'react';

import styled from 'styled-components';

import TextEditor from '@/components/TextEditor';
import MaxWidthContainer from '@/components/utils/MaxWidthContainer';
import type { UtilsOneColumnTextProps as Props } from '@/utilities/strapi/types/componentTypes';

export default function OneColumnText({ content, fullWidth, className, anchorId }: Props) {
  if (!content?.text) {
    return null;
  }

  const Container = fullWidth ? 'div' : MaxWidthContainer;

  return (
    <Container>
      <StyledOneColumnText id={anchorId?.anchorId} className={clsx(className, { 'full-width': fullWidth })}>
        <TextEditor text={content.text} />
      </StyledOneColumnText>
    </Container>
  );
}

const StyledOneColumnText = styled.div`
  &.full-width {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  @media (max-width: 768px) {
    &.full-width {
      margin: var(--spacing-sm) 0;
      padding: 0;
    }
  }
`;
