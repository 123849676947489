import React from 'react';
import styled, { css } from 'styled-components';

import renderCustomKernPairs from '@/components/utils/renderCustomKernPairs';

export type HeadingSizes = 1 | 2 | 3 | 4 | 5 | 6;
export interface Props {
  size: HeadingSizes;
  useSpan?: boolean;
  children: React.ReactNode;
  className?: string | undefined;
  id?: string;
}

const StyledHeading = styled.div<{ $size: HeadingSizes; className?: string | undefined; $useSpan?: boolean }>`
  font: var(--font-${(props) => `h${props.$size}`});
  letter-spacing: ${(props) => (props.$size < 4 ? 6 : 3) * -0.01}em;
  ${(props) =>
    props.$useSpan
      ? css`
          display: block;
        `
      : ''}
`;

const Heading = React.forwardRef(
  ({ size = 1, useSpan, children, className, id }: Props, ref: React.Ref<HTMLHeadingElement>) => (
    <StyledHeading
      as={useSpan ? 'span' : `h${size}`}
      ref={ref}
      $size={size}
      $useSpan={useSpan}
      className={className}
      id={id}
    >
      {typeof children === 'string' ? renderCustomKernPairs(children) : children}
    </StyledHeading>
  ),
);

Heading.displayName = 'Heading';

export default Heading;
