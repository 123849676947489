import { gsap } from 'gsap';
import React, { useRef } from 'react';

import styled from 'styled-components';

import Heading from '@/components/Elements/Heading';
import GradientBackground from '@/components/Hydra/GradientBackground';
import TextEditor from '@/components/TextEditor';
import useGsapEffect, { makeContextWrapper } from '@/hooks/useGsapEffect';
import BackgroundColors from '@/shared/constants/BackgroundColors';
import type { PricingPlansProps as Props } from '@/utilities/strapi/types/componentTypes';

import PricingTierCard from './PricingTierCard';

const StyledRoot = styled.div`
  position: relative;
  width: 100%;
  max-width: 150rem;
  margin: 0 auto;
  color: var(--color-black);
  padding: 23rem 0 16rem 0;

  @media (max-width: 768px) {
    max-height: none;
    padding: 12.6rem 0;
  }

  .content {
    position: relative;
    width: 100%;
    height: 100%;

    @media (max-width: 768px) {
      height: auto;
      width: calc(100% - 4rem);
      margin-left: 2rem;
    }
  }
  .header {
    padding: 5rem 0;
    margin-bottom: 10rem;

    @media (max-width: 768px) {
      margin-bottom: 3.6rem;
      padding: 0;
    }

    & .title {
      width: 100%;
      text-align: center;
      margin-bottom: 2rem;

      h1 {
        color: var(--color-black);
        padding: 0 5rem;
        font-size: 17rem;
        font-style: normal;
        font-weight: 250;
        line-height: 87%;
        letter-spacing: -1.02rem;

        @media (max-width: 768px) {
          padding: 0;
          font-size: 5.8rem;
          font-style: normal;
          font-weight: 300;
          line-height: 102%;
          letter-spacing: -0.232rem;
        }
      }
    }

    & .body {
      color: var(--color-black);
      width: 100%;
      position: relative;
      margin: 0 auto;
      max-width: 60rem;

      .text-base {
        text-align: center;
        font-size: 2.4rem;
        line-height: 3rem;
        letter-spacing: -0.02em;
        margin: 0;

        @media (max-width: 768px) {
          font-size: 1.8rem;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          letter-spacing: -0.036rem;
        }
      }
    }
  }
`;

const StyledContainerPrices = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 5rem 0;
  justify-content: center;
  max-width: 126rem;
  margin: 0 auto;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Background = styled(GradientBackground)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`;

type ElementAndHeight = [Element, number];
function useCustomAlignment() {
  const root = useRef<HTMLDivElement | null>(null);
  useGsapEffect(root, (ctx) => {
    if (!root.current) {
      return;
    }
    const withGsap = makeContextWrapper(ctx);
    const selector = gsap.utils.selector(root);
    const resizeObserver = new ResizeObserver(
      withGsap(() => {
        const cards = selector('.card > .block');
        const linesOfCards: Element[][] = [];
        let top: number | undefined;
        cards.forEach((card) => {
          const cardTop = (card.parentElement as Element).getBoundingClientRect().top;
          if (cardTop === top) {
            linesOfCards[linesOfCards.length - 1].push(card);
          } else {
            top = cardTop;
            linesOfCards.push([card]);
          }
        });
        linesOfCards.forEach((cards) => {
          if (cards.length < 2) {
            // Nothing to do, just reset everything
            cards.forEach((card) => {
              for (const child of card.children) {
                gsap.set(child, { clearProps: 'marginTop' });
              }
            });
          }
          const rows: ElementAndHeight[][] = [];
          let rowIndex = 0;
          nextRow: for (; ; rowIndex += 1) {
            const rowItems: ElementAndHeight[] = [];
            for (let cardIndex = 0; cardIndex < cards.length; ++cardIndex) {
              const card = cards[cardIndex];
              if (card.children.length <= rowIndex) {
                // No more rows
                break nextRow;
              }
              const el = card.children[rowIndex];
              const rowItem: ElementAndHeight = [el, gsap.getProperty(el, 'height') as number];
              if (cardIndex === 0) {
                rowItems.push(rowItem);
              } else {
                if (el.tagName !== rowItems[0][0].tagName) {
                  // mismatch, stop here
                  break nextRow;
                }
                rowItems.push(rowItem);
              }
            }
            rows.push(rowItems);
          }

          // Now add top margins where elements are not the same height as each other.
          rows.forEach((row, rowIndex) => {
            if (rowIndex === rows.length - 1) {
              // Don’t need to do anything on the last row.
              return;
            }
            let rowHeight = 0;
            row.forEach(([, height]) => {
              rowHeight = Math.max(rowHeight, height);
            });
            row.forEach(([el, height], cardIndex) => {
              let floated = gsap.getProperty(el, 'float');
              if (floated && floated !== 'none') {
                // Floated elements can’t be handled because who knows what the
                // next element is?
                return;
              }
              const props: gsap.TweenVars = {};
              const marginBottom = gsap.getProperty(el, 'marginBottom') as number;
              if (height < rowHeight) {
                props.marginTop = marginBottom + rowHeight - height;
              } else {
                props.clearProps = 'marginTop';
              }
              const nextRowEl = rows[rowIndex + 1][cardIndex][0];
              floated = gsap.getProperty(nextRowEl, 'float');
              if (floated && floated !== 'none' && rowIndex < rows.length - 2) {
                // need to get the next element too
                const afterFloatedEl = rows[rowIndex + 2][cardIndex][0];
                gsap.set(afterFloatedEl, props);
                if (typeof props.marginTop === 'number') {
                  props.marginTop -= marginBottom;
                }
              }
              gsap.set(nextRowEl, props);
            });
          });
        });
      }),
    );
    resizeObserver.observe(root.current);
    return () => {
      resizeObserver.disconnect();
    };
  });
  return root;
}

export default function PricingPlans({ id, title, body, prices, anchorId, backgroundGradientColor }: Props) {
  const root = useCustomAlignment();

  return (
    <div id={anchorId?.anchorId}>
      <Background seed={id} colors={backgroundGradientColor || BackgroundColors.black} />
      <StyledRoot ref={root}>
        <div className="content">
          <div className="header">
            <div className="title">
              <Heading size={1}>{title}</Heading>
            </div>
            <div className="body">{body && <TextEditor text={body} />}</div>
          </div>
          <StyledContainerPrices>
            {prices.map((price) => (
              <PricingTierCard
                className="card"
                title={price.title}
                text={price.body}
                key={price.id}
                highlight={price.highlight}
              />
            ))}
          </StyledContainerPrices>
        </div>
      </StyledRoot>
    </div>
  );
}
