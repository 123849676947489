import getMediaFormats from '@/utilities/strapi/getMediaFormats';
import getSortedMediaSrcs from '@/utilities/strapi/getSortedMediaSrcs';
import type { ImageWithMeta } from '@/utilities/strapi/types/componentTypes';

export default function getCreatorCarouselResponsiveSizes(image: ImageWithMeta): string {
  // The container is 100vw x 100vh.
  const formats = getMediaFormats(image.image);
  const srcs = getSortedMediaSrcs(formats);
  const src = srcs[srcs.length - 1];
  if (!src.width || !src.height) {
    return '100vw';
  }
  const inverseRatio = Math.max(src.width, src.height) / Math.min(src.width, src.height);
  const scaled = `${+(100 * inverseRatio).toFixed(1)}vh`;
  return `(min-aspect-ratio: ${src.width}/${src.height}) 100vw, ${scaled}`;
}
