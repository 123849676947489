import React, { useRef, useState } from 'react';

import { useClickAway } from 'react-use';
import styled from 'styled-components';

import type { QRCodeProps as Props } from '@/utilities/strapi/types/componentTypes';

import ResponsiveImage from '../Elements/ResponsiveImage';

export default function QRCode({ promptTitle, image }: Props) {
  const [active, setActive] = useState(false);
  const qrCodeRef = useRef<HTMLAnchorElement>(null);

  useClickAway(qrCodeRef, () => setActive(false));

  function enlarge(event: React.MouseEvent) {
    event.preventDefault();
    setActive(true);
  }

  return (
    <StyledQRCode ref={qrCodeRef} onClick={(event) => enlarge(event)} className={active ? 'active' : ''}>
      <article className="qr-code-container">
        <h5 className="prompt-title">{promptTitle}</h5>
        <div className="image-wrapper">
          <ResponsiveImage image={image} className="image" sizes="296px" />
        </div>
      </article>
    </StyledQRCode>
  );
}

const StyledQRCode = styled.a`
  --qr-title-padding: 20px;
  --qr-width: 235px;
  --qr-height: 80px;
  --qr-expanded-width: 296px;
  --qr-expanded-height: 386px;
  --qr-position-right: 5rem;
  --qr-position-bottom: 1rem;

  border-radius: 5px;
  border: 1px solid #fff;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(10px);
  cursor: pointer;
  position: fixed;
  bottom: var(--qr-position-bottom);
  right: var(--qr-position-right);
  z-index: 1000;
  transition: all var(--animation-duration) var(--animation-timing);

  @media (max-width: 768px) {
    display: none;
  }

  &.active {
    transform: translate(
      calc(-50vw + var(--qr-expanded-width) / 2 + var(--qr-position-right)),
      calc(-50vh + var(--qr-expanded-height) / 2 + var(--qr-position-bottom))
    );
    transform: translate(
      calc(-50vw + var(--qr-expanded-width) / 2 + var(--qr-position-right)),
      calc(-50dvh + var(--qr-expanded-height) / 2 + var(--qr-position-bottom))
    );

    .qr-code-container {
      flex-direction: column;
      padding-left: 0;
      width: var(--qr-expanded-width);
      height: var(--qr-expanded-height);
    }

    .image-wrapper {
      width: var(--qr-expanded-width);
    }

    .prompt-title {
      font: var(--font-label-4);
      font-size: 24px;
      letter-spacing: -0.72px;
      text-align: center;
    }
  }

  .qr-code-container {
    display: flex;
    align-items: center;
    width: var(--qr-width);
    height: var(--qr-height);
    transition: all var(--animation-duration) var(--animation-timing);
  }

  .image-wrapper {
    background-color: var(--color-white);
    border-radius: 5px;
    width: 80px;
    flex-shrink: 0;
  }

  .prompt-title {
    font: var(--font-label-0);
    font-size: 14px;
    letter-spacing: -0.28px;
    text-align: right;
    margin-bottom: 0;
    padding: var(--qr-title-padding);
  }

  .image {
    border-radius: 5px;
    width: 100%;
  }
`;
