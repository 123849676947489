import React from 'react';
import styled from 'styled-components';

import Footer from '@/components/Footer';
import Header, { headerPaddingTopSnippet } from '@/components/Header';
import { useHeaderStateSnapshot } from '@/components/Header/headerState';
import Integration from '@/components/PageTypes/Integration';
import Page from '@/components/PageTypes/Page';
import getPageHasFullBleedHero from '@/components/PageTypes/getPageHasFullBleedHero';
import SiteSettings from '@/components/utils/SiteSettings';
import useLocaleRedirect from '@/components/utils/useLocaleRedirect';
import { StrapiGlobalsProvider } from '@/context/StrapiGlobalsContext';
import useLocalizedAlternates from '@/hooks/useLocalizedAlternates';
import useMarketingPageGenericImpression from '@/hooks/useMarketingPageGenericImpression';
import getHydraNextBus from '@/hydra/next/getHydraNextBus';
import type { PageProps } from '@/utilities/data-fetching/types';
import type {
  StrapiIntegrationAttributes,
  StrapiPageAttributes,
  StrapiPageTypesAttributes,
} from '@/utilities/strapi/types/queryResponseTypes';
import type { StrapiPageModels } from '@/utilities/strapi/types/serviceClientTypes';
import { StrapiModel } from '@/utilities/strapi/types/serviceClientTypes';

const StyledMain = styled.main<{ $hasFullBleedHero: boolean }>`
  min-height: 100dvh;
  outline: none;
  ${(props) => {
    if (!props.$hasFullBleedHero) {
      return headerPaddingTopSnippet;
    }
    return false;
  }}
`;

function MainContent({
  page,
  pageType,
  children,
}: {
  page: StrapiPageTypesAttributes;
  pageType: StrapiPageModels;
  children: React.ReactNode;
}) {
  const { megaMenuHasTrappedFocus } = useHeaderStateSnapshot();

  return (
    <StyledMain
      id="main-content"
      $hasFullBleedHero={getPageHasFullBleedHero(page, pageType)}
      inert={megaMenuHasTrappedFocus ? '' : undefined}
      tabIndex={-1}
    >
      {children}
    </StyledMain>
  );
}

export function BasePage({ content, localizations, locales }: PageProps) {
  useMarketingPageGenericImpression(content?.page?.metadata);
  useLocaleRedirect();

  const alternates = useLocalizedAlternates(localizations);
  if (!content?.page) {
    return null;
  }

  const { page, pageType, header, footer, globals } = content;
  const { state } = (
    getHydraNextBus() as {
      next: {
        state: { slug?: string; locale?: string };
      };
    }
  ).next;

  state.slug = page.Slug;
  state.locale = page.locale;

  let renderedPage;
  switch (pageType) {
    case StrapiModel.Integrations:
      renderedPage = <Integration page={page as StrapiIntegrationAttributes} />;
      break;
    case StrapiModel.Pages:
      renderedPage = <Page page={page as StrapiPageAttributes} />;
  }

  return (
    <StrapiGlobalsProvider {...globals}>
      <SiteSettings page={page} alternates={alternates} />
      <Header {...header} />
      <MainContent page={page} pageType={pageType}>
        {renderedPage}
      </MainContent>
      <Footer footer={footer} localizations={localizations} locales={locales} />
    </StrapiGlobalsProvider>
  );
}
