import clsx from 'clsx';
import type { KeyboardEvent, MouseEvent } from 'react';
import React, { useEffect, useRef, useState } from 'react';

import { useIntersection, useRafLoop } from 'react-use';

import styled from 'styled-components';

import LineBreakTitle from '@/components/utils/LineBreakTitle';
import type { ImageWithMeta, PricingCommerceCarouselProps } from '@/utilities/strapi/types/componentTypes';

import { Wrapper } from './PricingStyled';
import ResponsiveImage from '../Elements/ResponsiveImage';
import GradientBackground from '../Hydra/GradientBackground';
import TextEditor from '../TextEditor';

const Background = styled(GradientBackground)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`;

const PricingCommerceCarousel = ({
  id,
  title,
  body,
  slides,
  anchorId,
  backgroundColor,
}: PricingCommerceCarouselProps) => {
  const rootRef = useRef<HTMLDivElement>(null);
  const [index, setIndex] = useState(0);
  const intersection = useIntersection(rootRef, {});

  const currentTime = useRef<number | null>(0);
  const lastTime = useRef<number | null>(0);
  const timeOffset = useRef<number | null>(0);
  const hasInteracted = useRef(false);

  const TIMER_DELAY = 5000;

  function onClick(e: MouseEvent | KeyboardEvent) {
    if (('key' in e && e?.key !== 'Enter') || ('type' in e && e?.type !== 'click')) {
      return;
    }

    setIndex(Number((e.currentTarget as HTMLElement).dataset.index));
    hasInteracted.current = true;
  }

  const nextSlide = () => {
    setSlide(index === slides.length - 1 ? 0 : index + 1);
  };

  const setSlide = (slide: number) => {
    setIndex(slide);
    lastTime.current = currentTime.current;
  };

  useEffect(() => {
    lastTime.current = currentTime.current;
  }, [index]);

  useEffect(() => {
    if (intersection?.isIntersecting && !hasInteracted.current) {
      setSlide(0);
    }
  }, [intersection]);

  useRafLoop((time) => {
    if (hasInteracted.current) {
      const delta = time - (currentTime.current || 0);
      timeOffset.current = (timeOffset.current || 0) + delta;
    }

    const t = time - (timeOffset.current || 0);

    if (t - (lastTime.current || 0) > TIMER_DELAY) {
      nextSlide();
    }

    const progress = (t - (lastTime.current || 0)) / TIMER_DELAY;

    if (rootRef.current) {
      rootRef.current.style.setProperty('--progress', `${progress * 100}%`);
    }

    currentTime.current = time;
  });

  return (
    <Wrapper id={anchorId?.anchorId} ref={rootRef}>
      <Background seed={id} colors={backgroundColor} />
      <div className="head commerce-head">
        <LineBreakTitle size={2}>{title}</LineBreakTitle>
        <div className="head-sub">{body}</div>
      </div>
      <div className="commerce-body">
        <div className="commerce-copy-slider-mobile">
          <div className="commerce-copy-slide last">
            <TextEditor text={slides[index].body} />
          </div>
        </div>
        <div className="commerce-slider">
          {slides.map((slide, i) => (
            <div key={slide.id} className={clsx('commerce-slide', { active: i === index })}>
              <ResponsiveImage
                image={slide as ImageWithMeta}
                className="slide-img"
                transparent
                sizes="(max-width: 768px) 100vw, 720px"
              />
            </div>
          ))}
        </div>
        <div className="commerce-side">
          <div className="commerce-side-title">{body}</div>
          <div className="commerce-nav">
            {slides.map(({ id, title }, i) => (
              <button
                key={id}
                className={clsx('commerce-nav-item', i === index && 'active')}
                onClick={onClick}
                tabIndex={0}
                onKeyDown={onClick}
                data-index={i}
              >
                <div className="commerce-nav-indicator" />
                <div className="commerce-nav-item-number">{String(i + 1).padStart(2, '0')}.</div>
                <div className="commerce-nav-item-title">{title}</div>
              </button>
            ))}
          </div>
          <div className="commerce-nav-item-mobile">{slides[index].title}</div>
          <div className="commerce-divider" />
          <div className="commerce-copy-slider">
            <div className="commerce-copy-slide commerce-fine-print">
              <TextEditor text={slides[index].body} />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default PricingCommerceCarousel;
