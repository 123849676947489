import type { ParagraphBlockData } from 'editorjs-blocks-react-renderer/dist/renderers/paragraph';
import React from 'react';

import styled from 'styled-components';

import RichTextBlock from '@/components/TextEditor/RichTextBlock';

interface CustomParagraphBlockData extends ParagraphBlockData {
  text: string;
  customTune: string;
  alignmentTune?: {
    alignment: string;
  };
}

export interface Props {
  data: CustomParagraphBlockData;
  className?: string;
}

const StyledParagraph = styled.p`
  margin-bottom: 1.3em;

  &.text-base {
    line-height: 120%;
    letter-spacing: -0.02em;
    font-size: var(--font-size-body);
  }

  &.text-lg {
    font-size: var(--font-size-body-lg);
  }

  &.text-right {
    text-align: right;
  }

  &.text-center {
    text-align: center;
  }

  &.text-left {
    text-align: left;
  }

  &.fine-print {
    opacity: 0.5;
  }

  &.inline {
    float: left;
  }

  a {
    color: blue;
    text-decoration: underline;

    &:hover {
      color: rebeccapurple;
    }
  }
`;

export default function Paragraph({ data, className = '' }: Props) {
  const props: React.HTMLAttributes<HTMLParagraphElement> = {};

  props.className = data.customTune ? `${data.customTune} ` : '';

  if (data.alignmentTune?.alignment) {
    props.className += `text-${data.alignmentTune.alignment} `;
  }

  if (className) {
    props.className += className;
  }

  return (
    <StyledParagraph {...props}>
      <RichTextBlock text={data?.text} />
    </StyledParagraph>
  );
}
