import * as Sentry from '@sentry/nextjs';

import getWindow from 'utilities/get-window';

export function getTLD() {
  const domainParts = getWindow().location?.hostname.split('.');
  if (domainParts && domainParts.length >= 2) {
    return domainParts.slice(-2).join('.');
  } else {
    // Handle the case where the domain structure is unexpected
    Sentry.captureMessage('Unexpected domain structure:' + getWindow().location?.hostname || 'missing');

    // Return the entire domain on unexpected case
    return getWindow().location?.hostname;
  }
}
