import clsx from 'clsx';
import React, { useRef } from 'react';

import styled from 'styled-components';

import GradientBackground from '@/components/Hydra/GradientBackground';
import TextEditor from '@/components/TextEditor';
import BackgroundColors from '@/shared/constants/BackgroundColors';
import type { RichTextCardProps as Props } from '@/utilities/strapi/types/componentTypes';

import Heading from '../Elements/Heading';

const StyledRoot = styled.div`
  position: relative;
  width: 100%;
  max-width: 150rem;
  margin: 0 auto;
  color: var(--color-black);
  padding ${(props) => (props?.title ? '6rem 0 16rem 0' : '0 0 var(--spacing-xl) 0')};

  @media (max-width: 768px) {
    max-height: none;
    padding: 4rem 0 12.6rem 0;
  }

  .content {
    position: relative;
    width: 100%;
    height: 100%;

    @media (max-width: 768px) {
      height: auto;
      width: calc(100% - 4rem);
      margin-left: 2rem;
    }
  }

  .header {
    display: flex;
    align-items: flex-end;
    padding-top: 5rem;
    // allow to expand beyond the container when the h2 font-size exceeds the clamped maximum
    margin: 0 min((var(--font-size-h2) - 17rem) * (-83 / 17) * 0.5, 0px) 5rem;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: stretch;
      padding: 0;
      margin-bottom: 5rem;
    }

    & .title {
      flex: 1 0 auto;
      padding-top: 50px;
      max-width: 750px;
      @media (max-width: 768px) {
        padding-right: 5rem;
      }

      h4 {
        line-height: 1.1;
      }
    }

    & .body {
      left: 5rem;
      color: var(--color-black);
      width: 67rem;
      bottom: -5rem;
      position: relative;

      @media (max-width: 768px) {
        left: auto;
        bottom: auto;
        margin-top: 5rem;
        width: 100%;
      }

      .text-base {
        font-size: 2.4rem;
        line-height: 3rem;
        font-weight: var(--font-weight-normal);
        letter-spacing: -0.02em;
        margin: 0;

        @media (max-width: 768px) {
          font-size: 2rem;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          letter-spacing: -0.04rem;
        }
      }
    }
  }

  .bottom {
    margin-top: calc(var(--spacing-lg) * 2);

    img {
      max-width: 25rem;
      max-height: 3.2rem;
      width: auto;
      height: auto;
      margin: 1.2rem 0;
    }

    @media (max-width: 768px) {
      img {
        max-width: 18.8rem;
        max-height: 2.4rem;
        width: auto;
        height: auto;
        margin: 1.4rem 0;
      }

      .text-base {
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: -0.048rem;
      }
    }
  }
`;

const StyledContainerRichCards = styled.div`
  --gap: 4rem;

  display: flex;
  flex-wrap: wrap;
  gap: var(--gap);
  justify-content: space-between;

  &.two-columns {
    > div {
      flex: 1 1 calc(50% - var(--gap));
    }
  }

  &.three-columns {
    > div {
      flex: 1 1 calc(33.333% - var(--gap));
    }
  }

  &.grouped {
    background-color: var(--color-white);
    border-radius: 3rem;

    .card {
      &:after {
        background-color: var(--color-neutral-80);
        content: '';
        display: block;
        width: 1px;
        height: calc(100% - 6rem);
        position: absolute;
        right: calc((var(--gap) / 2) * -1);
        top: 3rem;
      }

      &:last-of-type {
        &:after {
          display: none;
        }
      }
    }

    @media (max-width: 768px) {
      gap: 0;

      .card {
        &:after {
          width: 33.333%;
          height: 1px;
          position: absolute;
          right: auto;
          left: 50%;
          transform: translateX(-50%);
          top: auto;
          bottom: 0;
        }
      }
    }
  }

  @media (max-width: 768px) {
    padding: 0;

    &.two-columns,
    &.three-columns {
      > div {
        flex: 0 0 100%;
      }
    }
  }
`;

const Background = styled(GradientBackground)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`;

export default function RichTextCards({
  id,
  title,
  copy,
  bottomCopy,
  cards,
  anchorId,
  backgroundGradientColor,
  grouped,
}: Props) {
  const root = useRef<HTMLDivElement>(null);

  return (
    <div id={anchorId?.anchorId}>
      <Background seed={id} colors={backgroundGradientColor || BackgroundColors.black} />
      <StyledRoot ref={root} title={title}>
        <div className="content">
          {(title || copy) && (
            <div className="header">
              {title && (
                <div className="title">
                  <Heading size={4}>{title}</Heading>
                </div>
              )}
              {copy && (
                <div className="body">
                  <TextEditor text={copy} />
                </div>
              )}
            </div>
          )}

          <StyledContainerRichCards className={clsx([cards.length > 2 ? 'three-columns' : 'two-columns', { grouped }])}>
            {cards.map((card) => (
              <Card {...card} key={id + card.id} />
            ))}
          </StyledContainerRichCards>
          {bottomCopy && (
            <div className="bottom">
              <TextEditor text={bottomCopy} />
            </div>
          )}
        </div>
      </StyledRoot>
    </div>
  );
}

const Card = ({ body }: { body?: string | null | undefined }) => (
  <CardContainer>
    <TextEditor text={body} size="large" />
  </CardContainer>
);

const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 475px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 18px;
  padding: 2.5rem;

  img {
    margin-bottom: 1.5rem;
  }

  p {
    font-size: var(--font-size-h6) !important;
    margin-bottom: 0;
  }

  @media (max-width: 768px) {
    max-width: 80%;
    margin: 0 auto;
  }
`;
