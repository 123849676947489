// THIS CODE WAS GENERATED. DO NOT ALTER.
// COMMAND USED TO GENERATE: ./scripts/event_logging/create_event.py --event error_page_landed_event
import { patreonTrackerClient } from '..';

interface EventType {
  error_code: string;
  source?: string;
}

export function logErrorPageLandedEvent(data: EventType) {
  patreonTrackerClient.logTypedEvent('Error Page : Landed', data);
}
