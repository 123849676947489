import React from 'react';

import styled from 'styled-components';

import type { StrapiIntegrationAttributes } from '@/utilities/strapi/types/queryResponseTypes';

import OneColumnText from '../Blocks/OneColumnText';
import IntegrationMediaGallery from '../Integrations/IntegrationMediaGallery';
import IntegrationMeta from '../Integrations/IntegrationMeta';
import IntegrationNewsTeasers from '../Integrations/IntegrationNewsTeasers';

interface Props {
  page: StrapiIntegrationAttributes;
}

export default function Integration({ page }: Props) {
  const hasMainContent1 = !!(page.longDescription?.content?.text || page.mediaGallery?.images?.length);
  const hasMainContent2 = !!(page.pricingAndPerks?.content?.text || page.newsTeasers?.newsItems?.length);
  const hasMainContent = hasMainContent1 || hasMainContent2;
  return (
    <StyledIntegration>
      <div className="meta-wrapper">
        <IntegrationMeta {...page.Meta} />
      </div>
      <main className="main-wrapper">
        <header className="integration-header">
          <h1 className="integration-title">{page.Title}</h1>
          <p className="short-description">{page.shortDescription}</p>
        </header>
        {hasMainContent && <hr className="divider" />}
        <div className="integration-main-content">
          <OneColumnText {...page.longDescription} fullWidth className="long-description" />
          <IntegrationMediaGallery {...page.mediaGallery} />
          {hasMainContent1 && hasMainContent2 && <hr className="divider" />}
          <OneColumnText {...page.pricingAndPerks} fullWidth className="pricing-and-perks" />
          <IntegrationNewsTeasers {...page.newsTeasers} />
        </div>
      </main>
    </StyledIntegration>
  );
}

const StyledIntegration = styled.article`
  max-width: 1170px;
  width: 100%;
  max-width: 1170px;
  padding-left: 10rem;
  padding-right: 10rem;
  padding-top: 6.7rem;
  padding-bottom: 10rem;
  margin: 0 auto;
  display: flex;
  gap: calc(var(--spacing-xl) * 2);
  font-size: var(--font-size-body);
  font-weight: 300;

  .meta-wrapper {
    width: 31rem;
    flex-shrink: 0;
  }

  .integration-title {
    font: var(--font-h4);
    letter-spacing: -0.16rem;
    margin-bottom: 1.6rem;
  }

  .short-description {
    font-weight: 200;
  }

  .divider {
    margin: var(--spacing-base) 0;
    border-color: var(--color-neutral-80);
  }

  .long-description,
  .pricing-and-perks {
    margin: 0;
    padding: 0;
    margin-bottom: var(--spacing-xl);
  }

  @media (max-width: 768px) {
    & {
      padding-right: 2rem;
      padding-left: 2rem;
      flex-direction: column;
    }

    .meta-wrapper,
    .main-wrapper {
      width: 100%;
    }
  }
`;
