import getStrapiComponentTheme from '@/components/PageTypes/getStrapiComponentTheme';
import { mapStrapiLocaleToAppLocale } from '@/components/utils/locale';
import type { StrapiGlobals } from '@/context/StrapiGlobalsContext';
import { replacePageLinkReferences } from '@/utilities/editorjs';
import getPageSlugs from '@/utilities/getPageSlugs';
import declutterComponents from '@/utilities/strapi/declutterComponents';
import { queryFromStrapi } from '@/utilities/strapi/index';
import { decorateIntegrationsComponents, hasIntegrations } from '@/utilities/strapi/integrations';
import type { StrapiPage, StrapiPageTypes } from '@/utilities/strapi/types/apiResponseTypes';
import type {
  AppStoreLinks,
  StrapiComponent,
  ImageWithMeta,
  MediaAttributes,
  StrapiMedia,
} from '@/utilities/strapi/types/componentTypes';
import type { StrapiSiteSettingsAttributes } from '@/utilities/strapi/types/queryResponseTypes';
import type { StrapiPageModels } from '@/utilities/strapi/types/serviceClientTypes';
import { StrapiModel } from '@/utilities/strapi/types/serviceClientTypes';
import type { Link } from '@/utilities/strapi/types/utilsTypes';

function getPageSectionThemes(page: StrapiPage) {
  return page.attributes.Components.map(getStrapiComponentTheme);
}

async function populatePageGlobals(
  globals: Partial<StrapiGlobals>,
  page: StrapiPageTypes,
  pageType: StrapiPageModels,
  locale: string,
) {
  const result = await queryFromStrapi(StrapiModel.SiteSettings, {
    populate: 'deep,10',
    locale,
  });
  if (pageType === StrapiModel.Pages) {
    globals.sectionThemes = getPageSectionThemes(page as StrapiPage);
  } else {
    globals.sectionThemes = [];
  }
  globals.siteSettings = result.attributes;
  maybeDefaultSkipToContentLink(globals.siteSettings);
  maybeDefaultAppStoreLinks(globals.siteSettings, locale);
}

type PopulatedStrapiMedia = {
  data: {
    id: number;
    attributes: MediaAttributes;
  };
} & StrapiMedia;

type PopulatedImageWithMeta = {
  id: number;
  alternativeText: string;
  image: PopulatedStrapiMedia;
} & ImageWithMeta;

type PopulatedAppStoreLinks = {
  appleAppStoreLink: Link;
  appleAppStoreBadge: PopulatedImageWithMeta;
  appleAppStoreBadgeLight: PopulatedImageWithMeta;
  googleAppStoreLink: Link;
  googleAppStoreBadge: PopulatedImageWithMeta;
  googleAppStoreBadgeLight: PopulatedImageWithMeta;
} & AppStoreLinks;

function defaultBadge(alternativeText: string, url: string): PopulatedImageWithMeta {
  return {
    id: 0,
    alternativeText,
    image: {
      data: {
        id: 0,
        attributes: {
          alternativeText,
          provider: 'strapi-provider-upload-aws-s3-plus-cdn-append-only',
          size: 0,
          width: 256,
          height: 76,
          mime: 'image/png',
          url,
        },
      },
    },
  };
}

// TODO c5 is likely a much better place, given how tied c14 is to our CMS
const appStoreBadgesImages = {
  apple: {
    black: {
      'da-DK': 'https://c14.patreon.com/apple_app_store_button_da_DK_2e1c65e73f.png',
      'de-DE': 'https://c14.patreon.com/apple_app_store_button_de_DE_60184f511d.png',
      'en-GB': 'https://c14.patreon.com/apple_app_store_button_en_GB_e4c9f143a7.png',
      'en-US': 'https://c14.patreon.com/apple_app_store_button_en_US_38d7cda643.png',
      'es-419': 'https://c14.patreon.com/apple_app_store_button_es_419_e11863ffb9.png',
      'es-ES': 'https://c14.patreon.com/apple_app_store_button_es_ES_71d3265c83.png',
      'fr-FR': 'https://c14.patreon.com/apple_app_store_button_fr_FR_e488e3b8a3.png',
      'it-IT': 'https://c14.patreon.com/apple_app_store_button_it_IT_fbf137fe7d.png',
      'ja-JP': 'https://c14.patreon.com/apple_app_store_button_ja_JP_43bf5edd29.png',
      'ko-KR': 'https://c14.patreon.com/apple_app_store_button_ko_KR_3562f93485.png',
      'nb-NO': 'https://c14.patreon.com/apple_app_store_button_nb_NO_195ab6ed07.png',
      'nl-NL': 'https://c14.patreon.com/apple_app_store_button_nl_NL_eaa07d34c0.png',
      'pl-PL': 'https://c14.patreon.com/apple_app_store_button_pl_PL_8b9b2a0180.png',
      'pt-BR': 'https://c14.patreon.com/apple_app_store_button_pt_BR_30b46e88cd.png',
      'ru-RU': 'https://c14.patreon.com/apple_app_store_button_ru_RU_6cd424c400.png',
      'sv-SE': 'https://c14.patreon.com/apple_app_store_button_sv_SE_85ac88962c.png',
      'uk-UA': 'https://c14.patreon.com/apple_app_store_button_uk_UA_5ca63c36d3.png',
      'zh-CN': 'https://c14.patreon.com/apple_app_store_button_zh_CN_9407411c49.png',
      'zh-Hant-HK': 'https://c14.patreon.com/apple_app_store_button_zh_Hant_HK_60732bf43b.png',
    },
    white: {
      'da-DK': 'https://c14.patreon.com/apple_app_store_button_white_da_DK_19ed39f51d.png',
      'de-DE': 'https://c14.patreon.com/apple_app_store_button_white_de_DE_e9abb2294d.png',
      'en-GB': 'https://c14.patreon.com/apple_app_store_button_white_en_GB_9ff8efc2dc.png',
      'en-US': 'https://c14.patreon.com/apple_app_store_button_white_en_US_cc657b8391.png',
      'es-419': 'https://c14.patreon.com/apple_app_store_button_white_es_419_b0ac90aa8e.png',
      'es-ES': 'https://c14.patreon.com/apple_app_store_button_white_es_ES_0215fe96d5.png',
      'fr-FR': 'https://c14.patreon.com/apple_app_store_button_white_fr_FR_7b2db1a867.png',
      'it-IT': 'https://c14.patreon.com/apple_app_store_button_white_it_IT_37018d86a1.png',
      'ja-JP': 'https://c14.patreon.com/apple_app_store_button_white_ja_JP_96cd59240f.png',
      'ko-KR': 'https://c14.patreon.com/apple_app_store_button_white_ko_KR_9ff6b7e8c7.png',
      'nb-NO': 'https://c14.patreon.com/apple_app_store_button_white_nb_NO_5fa670f82b.png',
      'nl-NL': 'https://c14.patreon.com/apple_app_store_button_white_nl_NL_f9f38aade5.png',
      'pl-PL': 'https://c14.patreon.com/apple_app_store_button_white_pl_PL_85a62fa26b.png',
      'pt-BR': 'https://c14.patreon.com/apple_app_store_button_white_pt_BR_2dcb287024.png',
      'ru-RU': 'https://c14.patreon.com/apple_app_store_button_white_ru_RU_7745d4f5ad.png',
      'sv-SE': 'https://c14.patreon.com/apple_app_store_button_white_sv_SE_639fb97988.png',
      'uk-UA': 'https://c14.patreon.com/apple_app_store_button_white_uk_UA_22ed69f72e.png',
      'zh-CN': 'https://c14.patreon.com/apple_app_store_button_white_zh_CN_59abb29f26.png',
      'zh-Hant-HK': 'https://c14.patreon.com/apple_app_store_button_white_zh_Hant_HK_16ae2bb4d3.png',
    },
  },
  google: {
    black: {
      'da-DK': 'https://c14.patreon.com/google_app_store_button_da_DK_c0ea217ebd.png',
      'de-DE': 'https://c14.patreon.com/google_app_store_button_de_DE_6a12b8f4e7.png',
      'en-GB': 'https://c14.patreon.com/google_app_store_button_en_GB_8739790ca1.png',
      'en-US': 'https://c14.patreon.com/google_app_store_button_en_US_869401a5e7.png',
      'es-419': 'https://c14.patreon.com/google_app_store_button_es_419_4e793a4ad8.png',
      'es-ES': 'https://c14.patreon.com/google_app_store_button_es_ES_ce42c2e557.png',
      'fr-FR': 'https://c14.patreon.com/google_app_store_button_fr_FR_5b6e5c9154.png',
      'it-IT': 'https://c14.patreon.com/google_app_store_button_it_IT_84a31b5942.png',
      'ja-JP': 'https://c14.patreon.com/google_app_store_button_ja_JP_e6e12adee1.png',
      'ko-KR': 'https://c14.patreon.com/google_app_store_button_ko_KR_9a099e3c29.png',
      'nb-NO': 'https://c14.patreon.com/google_app_store_button_nb_NO_491b1f4f3c.png',
      'nl-NL': 'https://c14.patreon.com/google_app_store_button_nl_NL_873531b5b3.png',
      'pl-PL': 'https://c14.patreon.com/google_app_store_button_pl_PL_188799a51f.png',
      'pt-BR': 'https://c14.patreon.com/google_app_store_button_pt_BR_a1cb398ac1.png',
      'ru-RU': 'https://c14.patreon.com/google_app_store_button_ru_RU_a2738e5abb.png',
      'sv-SE': 'https://c14.patreon.com/google_app_store_button_sv_SE_29a29ee08a.png',
      'uk-UA': 'https://c14.patreon.com/google_app_store_button_uk_UA_d10d6125c4.png',
      'zh-CN': 'https://c14.patreon.com/google_app_store_button_zh_CN_25a6b56d73.png',
      'zh-Hant-HK': 'https://c14.patreon.com/google_app_store_button_zh_Hant_HK_1241335919.png',
    },
    white: {
      'da-DK': 'https://c14.patreon.com/google_app_store_button_white_da_DK_b63b37d388.png',
      'de-DE': 'https://c14.patreon.com/google_app_store_button_white_de_DE_d3105c765b.png',
      'en-GB': 'https://c14.patreon.com/google_app_store_button_white_en_GB_422f51bc0b.png',
      'en-US': 'https://c14.patreon.com/google_app_store_button_white_en_US_f4a6014a79.png',
      'es-419': 'https://c14.patreon.com/google_app_store_button_white_es_419_5773b2e68b.png',
      'es-ES': 'https://c14.patreon.com/google_app_store_button_white_es_ES_39463dae26.png',
      'fr-FR': 'https://c14.patreon.com/google_app_store_button_white_fr_FR_60706b8624.png',
      'it-IT': 'https://c14.patreon.com/google_app_store_button_white_it_IT_27db2a6917.png',
      'ja-JP': 'https://c14.patreon.com/google_app_store_button_white_ja_JP_5fafaceed6.png',
      'ko-KR': 'https://c14.patreon.com/google_app_store_button_white_ko_KR_d0dcc0dfc8.png',
      'nb-NO': 'https://c14.patreon.com/google_app_store_button_white_nb_NO_8a04202f60.png',
      'nl-NL': 'https://c14.patreon.com/google_app_store_button_white_nl_NL_1be7677cb5.png',
      'pl-PL': 'https://c14.patreon.com/google_app_store_button_white_pl_PL_dbd990076f.png',
      'pt-BR': 'https://c14.patreon.com/google_app_store_button_white_pt_BR_5fabaed14e.png',
      'ru-RU': 'https://c14.patreon.com/google_app_store_button_white_ru_RU_ceddb41989.png',
      'sv-SE': 'https://c14.patreon.com/google_app_store_button_white_sv_SE_61a98b6e46.png',
      'uk-UA': 'https://c14.patreon.com/google_app_store_button_white_uk_UA_f5d01b76a0.png',
      'zh-CN': 'https://c14.patreon.com/google_app_store_button_white_zh_CN_ee2a0ab2f1.png',
      'zh-Hant-HK': 'https://c14.patreon.com/google_app_store_button_white_zh_Hant_HK_4286f23558.png',
    },
  },
};

function getDefaultAppStoreLinks(locale: string): PopulatedAppStoreLinks {
  locale = mapStrapiLocaleToAppLocale(locale);
  return {
    id: 0,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    __component: 'utils.app-store-links',
    appleAppStoreLink: {
      id: 0,
      externalUrl: 'https://apps.apple.com/us/app/patreon/id1044456188',
      text: 'Download on the App Store',
    },
    appleAppStoreBadge: defaultBadge(
      'Download on the App Store',
      (appStoreBadgesImages.apple.black as Record<string, string>)[locale],
    ),
    appleAppStoreBadgeLight: defaultBadge(
      'Download on the App Store',
      (appStoreBadgesImages.apple.white as Record<string, string>)[locale],
    ),
    googleAppStoreLink: {
      id: 0,
      externalUrl: 'https://play.google.com/store/apps/details?id=com.patreon.android',
      text: 'Get it on Google Play',
    },
    googleAppStoreBadge: defaultBadge(
      'Get it on Google Play',
      (appStoreBadgesImages.google.black as Record<string, string>)[locale],
    ),
    googleAppStoreBadgeLight: defaultBadge(
      'Get it on Google Play',
      (appStoreBadgesImages.google.white as Record<string, string>)[locale],
    ),
  };
}

function maybeDefaultAppStoreLinks(siteSettings: StrapiSiteSettingsAttributes, locale: string) {
  if (!siteSettings) {
    return;
  }
  const defaultAppStoreLinks = getDefaultAppStoreLinks(locale);
  if (!siteSettings.appStoreLinks) {
    siteSettings.appStoreLinks = defaultAppStoreLinks;
    return;
  }
  const appStoreLinks = siteSettings.appStoreLinks;
  (['appleAppStoreLink', 'googleAppStoreLink'] as ['appleAppStoreLink', 'googleAppStoreLink']).forEach((prop) => {
    const link = (appStoreLinks[prop] = appStoreLinks[prop] || defaultAppStoreLinks[prop]);
    link.externalUrl = link.externalUrl || defaultAppStoreLinks[prop].externalUrl;
    link.text = link.text || defaultAppStoreLinks[prop].text;
  });
  (
    ['appleAppStoreBadge', 'appleAppStoreBadgeLight', 'googleAppStoreBadge', 'googleAppStoreBadgeLight'] as [
      'appleAppStoreBadge',
      'appleAppStoreBadgeLight',
      'googleAppStoreBadge',
      'googleAppStoreBadgeLight',
    ]
  ).forEach((prop) => {
    const img = (appStoreLinks[prop] = appStoreLinks[prop] || defaultAppStoreLinks[prop]);
    img.alternativeText = img.alternativeText || defaultAppStoreLinks[prop].alternativeText;
    img.image = img.image || defaultAppStoreLinks[prop].image;
    img.image.data = img.image.data || defaultAppStoreLinks[prop].image.data;
    img.image.data.attributes = img.image.data.attributes || defaultAppStoreLinks[prop].image.data.attributes;
    img.image.data.attributes.url =
      img.image.data.attributes.url || defaultAppStoreLinks[prop].image.data.attributes.url;
  });
}

function maybeDefaultSkipToContentLink(siteSettings: StrapiSiteSettingsAttributes) {
  if (!siteSettings) {
    return;
  }
  if (!siteSettings.skipToContentLabel) {
    siteSettings.skipToContentLabel = 'Skip to content';
  }
}

async function maybePopulateIntegrationSettings(
  globals: Partial<StrapiGlobals>,
  page: StrapiPageTypes,
  pageType: StrapiPageModels,
  locale: string,
) {
  if (pageType === StrapiModel.Integrations || hasIntegrations(page, pageType)) {
    const integrationSettings = await queryFromStrapi(StrapiModel.IntegrationSettings, {
      populate: 'deep,3',
      locale,
    });
    globals.integrationSettings = integrationSettings.attributes;
  }
}

async function getGlobals(page: StrapiPageTypes, pageType: StrapiPageModels, locale: string): Promise<StrapiGlobals> {
  const globals: Partial<StrapiGlobals> = {
    slugs: getPageSlugs(page.attributes, pageType),
    locale: mapStrapiLocaleToAppLocale(locale),
  };
  await Promise.all([
    populatePageGlobals(globals, page, pageType, locale),
    maybePopulateIntegrationSettings(globals, page, pageType, locale),
  ]);
  return globals as StrapiGlobals;
}

async function decorateStrapiPage(
  page: StrapiPageTypes,
  pageType: StrapiPageModels,
  locale: string,
  extraComponents: StrapiComponent[],
) {
  if (pageType === StrapiModel.Pages) {
    const pageContent = page as StrapiPage;
    const components = [...pageContent.attributes.Components, ...extraComponents];
    await Promise.all([
      // editorjs stores page links as references to other pages by id.
      // replace references to page links by id with actual page data!
      replacePageLinkReferences(components, locale),
      decorateIntegrationsComponents(components, locale),
    ]);
    declutterComponents(components);
  }
  // Nothing required for pageType === StrapiModel.Integrations
}

export default async function getGlobalsAndDecorateStrapiPage(
  page: StrapiPageTypes,
  pageType: StrapiPageModels,
  locale: string,
) {
  const globals = await getGlobals(page, pageType, locale);
  const extraComponents: StrapiComponent[] = [];

  if (globals.siteSettings.getStartedCTAModal) {
    extraComponents.push(globals.siteSettings.getStartedCTAModal as StrapiComponent);
  }

  await decorateStrapiPage(page, pageType, locale, extraComponents);
  return globals;
}
