import type { MutableRefObject } from 'react';
import { useRef, useCallback } from 'react';

export default function useCollectionRef<T>(): [MutableRefObject<T[]>, (index: number) => (selector: T) => void] {
  const innerRef = useRef<T[]>([]);
  const setInnerRef = useCallback(
    (index: number) => (selector: T) => {
      if (selector) {
        innerRef.current[index] = selector;
      }
    },
    [],
  );
  return [innerRef, setInnerRef];
}
