import { queryFromStrapi } from '@/utilities/strapi/index';
import type { StrapiIntegration, StrapiPage, StrapiPageTypes } from '@/utilities/strapi/types/apiResponseTypes';
import type {
  StrapiComponent,
  IntegrationPromoTilesDirectoryProps,
  IntegrationPromoTilesCarouselProps,
} from '@/utilities/strapi/types/componentTypes';
import type { StrapiIntegrationAttributes } from '@/utilities/strapi/types/queryResponseTypes';
import type { StrapiPageModels, StrapiParams } from '@/utilities/strapi/types/serviceClientTypes';
import { StrapiModel } from '@/utilities/strapi/types/serviceClientTypes';

function isIntegration(component: StrapiComponent) {
  return (
    component.__component === 'integrations.promo-tiles-directory' ||
    component.__component === 'integrations.promo-tiles-carousel'
  );
}

export function hasIntegrations(page: StrapiPageTypes, pageType: StrapiPageModels) {
  if (pageType === StrapiModel.Pages) {
    const pageContent = page as StrapiPage;
    const components = pageContent.attributes.Components;
    return components.some(isIntegration);
  }
  return false;
}

export async function decorateIntegrationsComponents(components: StrapiComponent[] = [], locale: string) {
  decorateIntegrationPromoTilesCarouselComponents(components);
  await decorateIntegrationPromoTilesComponents(components, locale);
}

export function decorateIntegrationPromoTilesCarouselComponents(components: StrapiComponent[] = []) {
  const integrationComponents = components.filter(
    (component) => component.__component === 'integrations.promo-tiles-carousel',
  ) as IntegrationPromoTilesCarouselProps[];
  integrationComponents.forEach((component) => {
    const integrations = component.integrations?.data;
    if (integrations) {
      integrations.forEach((integration: StrapiIntegration) => {
        integration.attributes.shouldLinkExternally = !integration.attributes?.longDescription?.content?.text;
      });
    }
  });
}

export async function decorateIntegrationPromoTilesComponents(components: StrapiComponent[] = [], locale: string) {
  const integrationComponents = components.filter(
    (component) => component.__component === 'integrations.promo-tiles-directory',
  ) as IntegrationPromoTilesDirectoryProps[];
  if (integrationComponents.length) {
    const params: StrapiParams = {
      fetchAll: true,
      populate: {
        Slug: true,
        Title: true,
        shortDescription: true,
        longDescription: {
          // Populate longDescription just to check whether it is set or not, so we can tell whether
          // it’s a “sparse” record. For those we link out to siteLink instead of to the internal page.
          populate: '*',
        },
        Meta: {
          populate: {
            featureImage: {
              populate: {
                image: '*',
              },
            },
            siteLink: {
              populate: ['externalUrl', 'genericClickDetails', 'anchor'],
            },
          },
        },
      },
      locale,
    };

    // Compile a de-duped flat list of integrations
    const allIntegrations = await queryFromStrapi(StrapiModel.Integrations, params);
    const uniqIntegrations = [...new Map(allIntegrations.map((v) => [v.id, v])).values()];

    const integrationsById: Record<number, StrapiIntegrationAttributes> = uniqIntegrations.reduce((acc, val) => {
      const { id } = val;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { longDescription, ...attributes } = val.attributes;
      attributes.shouldLinkExternally = !longDescription?.content?.text;
      return {
        ...acc,
        [id]: attributes,
      };
    }, {});

    integrationComponents.forEach((component) => {
      component.integrationsById = integrationsById;
    });
  }
}
