import React from 'react';
import styled from 'styled-components';

interface AnimatedLabelProps {
  tag?: 'a' | 'button' | 'div';
  label: string;
  className?: string;
  onClick?: (event: React.MouseEvent) => void;
}

export default function AnimatedLabel({ tag = 'button', label, className, onClick, ...rest }: AnimatedLabelProps) {
  return (
    <StyledAnimatedLabel
      as={tag}
      className={className}
      data-label={label}
      onClick={(event: React.MouseEvent) => onClick?.(event)}
      {...rest}
    >
      <span className="label">{label}</span>
    </StyledAnimatedLabel>
  );
}

const StyledAnimatedLabel = styled.div`
  display: block;
  position: relative;

  &:before,
  &:after {
    content: attr(data-label);
    content: attr(data-label) / '""';
    alt: '""';
    position: absolute;
    top: 0;
    left: 0;
    transition: transform var(--animation-duration) var(--animation-timing);
  }

  &:before {
    transform: translateY(0%);
  }

  &:after {
    color: var(--color-action-inverse);
    transform: translateY(100%);
  }

  .active & {
    &:before {
      transform: translateY(-100%);
    }

    &:after {
      transform: translateY(0%);
    }
  }

  .label {
    opacity: 0;
  }
`;
