import { defaultStrapiLocale, mapStrapiLocaleToAppLocale } from '@/components/utils/locale';
import getPageHref from '@/utilities/getPageHref';
import type { StrapiDataObject } from '@/utilities/strapi/types/apiResponseTypes';
import type { StrapiMinimalPageInfo } from '@/utilities/strapi/types/queryResponseTypes';

export function getStrapiLinkLocale(pageData: StrapiMinimalPageInfo) {
  return mapStrapiLocaleToAppLocale(pageData.locale || defaultStrapiLocale);
}

export default function getStrapiLinkHref(
  page?: StrapiDataObject<StrapiMinimalPageInfo> | StrapiMinimalPageInfo,
  externalUrl?: string,
  anchor?: string,
) {
  const pageData = (page as StrapiDataObject<StrapiMinimalPageInfo>)?.data?.attributes || page;

  const formattedAnchor = (anchor && !anchor.startsWith('#') && !anchor.startsWith('/') ? `#${anchor}` : anchor) || '';
  if (!pageData?.Slug && !externalUrl && !formattedAnchor) {
    throw new Error(`Link has no page, externalUrl, or anchor`);
  }

  // TODO (jackyang) Probably want to fix these in CMS
  let formattedExternalUrl = externalUrl;
  if (formattedExternalUrl?.startsWith('www')) {
    formattedExternalUrl = 'https://' + formattedExternalUrl;
  }

  return pageData?.Slug ? getPageHref(pageData, anchor) : [formattedExternalUrl, formattedAnchor || ''].join('');
}
