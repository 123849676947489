import clsx from 'clsx';
import { useRouter } from 'next/router';
import React from 'react';

import styled from 'styled-components';

import ResponsiveImage from '@/components/Elements/ResponsiveImage';
import type { StrapiLinkProps } from '@/components/utils/StrapiLink';
import StrapiLink from '@/components/utils/StrapiLink';
import type { ImageBlockAttributes } from '@/utilities/strapi/types/componentTypes';

export interface Props {
  data: {
    caption?: string;
    file: ImageBlockAttributes;
    alignmentTune?: {
      alignment: string;
    };
    withBorder?: boolean;
    link?: StrapiLinkProps;
  };
  className?: string;
}

const StyledResponsiveImage = styled(ResponsiveImage)<{ addBoxShadow?: boolean }>`
  filter: ${({ addBoxShadow }) => (addBoxShadow ? 'drop-shadow(30px 30px 30px rgba(4, 64, 80, 0.3))' : undefined)};
  &.align-center {
    margin-left: auto;
    margin-right: auto;
  }
  &.align-right {
    margin-left: auto;
  }

  &.bordered {
    border: 1px solid var(--color-neutral-80, #ccc);
  }
`;

export default function ImageBlock({ data, className }: Props) {
  // Time crunch hack - TODO: make adding box shadow a field in cms
  const router = useRouter();
  const isUpdatesPage = router?.asPath.includes('/updates');
  const props: React.HTMLAttributes<HTMLParagraphElement> = {};

  if (className) {
    props.className = className;
  }
  if (data.alignmentTune?.alignment) {
    props.className = clsx(props.className, `align-${data.alignmentTune.alignment}`, [data?.withBorder && 'bordered']);
  }

  const image = (
    <StyledResponsiveImage
      {...props}
      addBoxShadow={isUpdatesPage}
      image={{ caption: data.caption || data.file.alt, image: { data: { attributes: data.file } } }}
    />
  );

  if (data.link) {
    return (
      <StrapiLink {...data.link} useChildren>
        {image}
      </StrapiLink>
    );
  }
  return image;
}
