import clamp from '@/utilities/clamp';
import type { MediaColors } from '@/utilities/strapi/types/componentTypes';

export class ColorLAB {
  public l: number;
  public a: number;
  public b: number;

  constructor(l = 100, a = 0, b = 0) {
    this.l = l;
    this.a = a;
    this.b = b;
  }

  /**
   * Calculate the perceptual distance (delta E) to another Color
   * using the CIE 1994 metric.
   */
  public deltaECIE94(colorLAB: ColorLAB) {
    const deltaL = this.l - colorLAB.l;
    const deltaA = this.a - colorLAB.a;
    const deltaB = this.b - colorLAB.b;
    const c1 = Math.sqrt(this.a * this.a + this.b * this.b);
    const c2 = Math.sqrt(colorLAB.a * colorLAB.a + colorLAB.b * colorLAB.b);
    const deltaC = c1 - c2;
    let deltaH = deltaA * deltaA + deltaB * deltaB - deltaC * deltaC;
    deltaH = deltaH < 0 ? 0 : Math.sqrt(deltaH);
    const sc = 1.0 + 0.045 * c1;
    const sh = 1.0 + 0.015 * c1;
    const deltaLKlsl = deltaL / 1.0;
    const deltaCkcsc = deltaC / sc;
    const deltaHkhsh = deltaH / sh;
    const i = deltaLKlsl * deltaLKlsl + deltaCkcsc * deltaCkcsc + deltaHkhsh * deltaHkhsh;
    return i < 0 ? 0 : Math.sqrt(i);
  }

  public getHexString() {
    let y = (this.l + 16) / 116;
    let x = this.a / 500 + y;
    let z = y - this.b / 200;

    x = 0.95047 * (x * x * x > 0.008856 ? x * x * x : (x - 16 / 116) / 7.787);
    y = y * y * y > 0.008856 ? y * y * y : (y - 16 / 116) / 7.787;
    z = 1.08883 * (z * z * z > 0.008856 ? z * z * z : (z - 16 / 116) / 7.787);

    let r = x * 3.2406 + y * -1.5372 + z * -0.4986;
    let g = x * -0.9689 + y * 1.8758 + z * 0.0415;
    let b = x * 0.0557 + y * -0.204 + z * 1.057;

    r = clamp(r > 0.0031308 ? 1.055 * Math.pow(r, 1 / 2.4) - 0.055 : 12.92 * r);
    g = clamp(g > 0.0031308 ? 1.055 * Math.pow(g, 1 / 2.4) - 0.055 : 12.92 * g);
    b = clamp(b > 0.0031308 ? 1.055 * Math.pow(b, 1 / 2.4) - 0.055 : 12.92 * b);

    // eslint-disable-next-line no-bitwise
    const hex = ((r * 255) << 16) ^ ((g * 255) << 8) ^ ((b * 255) << 0);
    return '#' + ('000000' + hex.toString(16)).slice(-6);
  }

  public setHexString(string: string) {
    return this.setRGB(...hexColorToRGB(string));
  }

  public setRGB(r: number, g: number, b: number) {
    let x: number;
    let y: number;
    let z: number;

    r = r > 0.04045 ? Math.pow((r + 0.055) / 1.055, 2.4) : r / 12.92;
    g = g > 0.04045 ? Math.pow((g + 0.055) / 1.055, 2.4) : g / 12.92;
    b = b > 0.04045 ? Math.pow((b + 0.055) / 1.055, 2.4) : b / 12.92;

    x = (r * 0.4124 + g * 0.3576 + b * 0.1805) / 0.95047;
    y = r * 0.2126 + g * 0.7152 + b * 0.0722;
    z = (r * 0.0193 + g * 0.1192 + b * 0.9505) / 1.08883;

    x = x > 0.008856 ? Math.pow(x, 1 / 3) : 7.787 * x + 16 / 116;
    y = y > 0.008856 ? Math.pow(y, 1 / 3) : 7.787 * y + 16 / 116;
    z = z > 0.008856 ? Math.pow(z, 1 / 3) : 7.787 * z + 16 / 116;

    this.l = 116 * y - 16;
    this.a = 500 * (x - y);
    this.b = 200 * (y - z);
    return this;
  }
}

export type RGBArray = [number, number, number];

export function hexColorToRGB(string: string): RGBArray {
  const hex = Number(string.replace('#', '0x'));
  return [
    /* eslint-disable no-bitwise */
    ((hex >> 16) & 255) / 255,
    ((hex >> 8) & 255) / 255,
    (hex & 255) / 255,
    /* eslint-enable no-bitwise */
  ];
}

function vibrancy(color: ColorLAB) {
  const ab = color.a ** 2 + color.b ** 2;
  return Math.sqrt(ab) / Math.sqrt(ab + color.l ** 2);
}

export function preprocessGradientColors(mediaColors?: MediaColors | null, targetLightness = 75, mainColorIndex = 0) {
  if (!mediaColors || !mediaColors.length) {
    mediaColors = ['#9a73ff', '#006eff', '#509afb', '#ffffff'];
  }

  // sort in order of most vibrant to least
  const colors = mediaColors
    .map((color) => new ColorLAB().setHexString(color))
    .sort((a, b) => vibrancy(b) - vibrancy(a));

  const mainColor = colors[Math.min(mainColorIndex, colors.length - 1)];
  return [targetLightness - 15, targetLightness - 5, targetLightness + 5, targetLightness + 15].map((lightness) =>
    new ColorLAB(lightness, mainColor.a, mainColor.b).getHexString(),
  ) as MediaColors;
}
