import React from 'react';
import styled from 'styled-components';

interface MaxWidthContainerProps {
  mb?: boolean;
  mt?: boolean;
  pb?: boolean;
  pt?: boolean;
  children: React.ReactNode;
}

export default function MaxWidthContainer({
  children,
  mt = true,
  mb = true,
  pb = true,
  pt = true,
}: MaxWidthContainerProps) {
  return (
    <StyledMaxWidthContainer mt={mt} mb={mb} pt={pt} pb={pb}>
      {children}
    </StyledMaxWidthContainer>
  );
}

const StyledMaxWidthContainer = styled.section<MaxWidthContainerProps>`
  --font-size-h1: 170px;
  --font-size-h2: 96px;
  --font-size-h3: 72px;
  --font-size-h4: 40px;

  --line-height-h1: 87%;
  --line-height-h2: 105%;
  --line-height-h3: 102%;
  --line-height-h4: 120%;

  --font-h1: var(--font-weight-extra-light) var(--font-size-h1) / var(--line-height-h1) var(--font-family-heading);
  --font-h2: var(--font-weight-light) var(--font-size-h2) / var(--line-height-h2) var(--font-family-heading);
  --font-h3: var(--font-weight-light) var(--font-size-h3) / var(--line-height-h3) var(--font-family-heading);
  --font-h4: var(--font-weight-normal) var(--font-size-h4) / var(--line-height-h4) var(--font-family-heading);

  --font-sub-h1-light: var(--font-weight-normal) 34px/120% var(--font-family-heading);

  width: 100%;
  max-width: calc(128rem + 2 * var(--spacing-base));
  padding: var(--spacing-base);
  padding-top: ${({ pt }) => (!pt ? 0 : '')};
  padding-bottom: ${({ pb }) => (!pb ? 0 : '')};
  margin: var(--spacing-base) auto;
  margin-top: ${({ mt }) => (!mt ? 0 : '')};
  margin-bottom: ${({ mb }) => (!mb ? 0 : '')};

  @media (max-width: 768px) {
    --font-size-h1: 54px;
    --font-size-h2: 48px;
    --font-size-h3: 40px;
    --font-size-h4: 24px;

    --line-height-h1: 104%;
    --line-height-h3: 100%;

    --font-h1: var(--font-weight-extra-light) var(--font-size-h1) / var(--line-height-h1) var(--font-family-heading);
    --font-h2: var(--font-weight-light) var(--font-size-h2) / var(--line-height-h2) var(--font-family-heading);
    --font-h3: var(--font-weight-light) var(--font-size-h3) / var(--line-height-h3) var(--font-family-heading);
    --font-h4: var(--font-weight-normal) var(--font-size-h4) / var(--line-height-h4) var(--font-family-heading);

    --font-sub-h1-light: var(--font-weight-normal) 34px/120% var(--font-family-heading);

    padding: var(--spacing-sm);
    margin: 0;
  }
`;
