import type { StrapiLinkProps } from '@/components/utils/StrapiLink';
import type { StrapiIntegrationAttributes } from '@/utilities/strapi/types/queryResponseTypes';

export default function getIntegrationLinkProps(integration: StrapiIntegrationAttributes): StrapiLinkProps {
  const { Slug, Meta, shouldLinkExternally } = integration;
  const linkProps: Partial<StrapiLinkProps> = {};
  if (shouldLinkExternally) {
    linkProps.externalUrl = Meta?.siteLink?.externalUrl;
  } else {
    linkProps.page = {
      Slug,
      Parent: {
        page: { data: { id: integration.id, attributes: { Slug: 'apps' } } },
      },
    };
  }
  return linkProps;
}
