interface SplitTitleProps {
  text?: string | null;
}

export default function useSplitTitle({ text }: SplitTitleProps) {
  if (!text) {
    return null;
  }
  const words = text.split(/\s+/);
  let breakIndex = 0;
  let len1 = 0;
  let len2 = words.reduce((len, word) => len + word.length, 0) + words.length - 1;
  while (len1 < len2 && breakIndex < words.length) {
    const len = words[breakIndex].length;
    len1 += len;
    len2 -= len;
    if (breakIndex > 0) {
      len1 += 1; // bring a space with it
    }
    if (breakIndex < words.length - 1) {
      len2 -= 1;
    }
    breakIndex += 1;
  }
  breakIndex = Math.max(0, breakIndex - 1);

  return [words.slice(0, breakIndex).join(' '), words.slice(breakIndex).join(' ')];
}
