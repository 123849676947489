import React from 'react';

import styled from 'styled-components';

import Video from '@/components/Elements/Video';
import useStrapiGlobals from '@/context/StrapiGlobalsContext';
import type { GetStartedProps as Props } from '@/utilities/strapi/types/componentTypes';

import AppStoreLinks from '../AppStoreLinks/AppStoreLinks';
import Heading from '../Elements/Heading';
import TextEditor from '../TextEditor';

const StyledGetStarted = styled.div`
  position: relative;
  width: 100%;
  padding: 21.2rem calc(27.8rem + var(--rem-scale-viewport-half-excess)) 21.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15.6rem;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 7rem;
    padding: 11.45rem 2rem;
  }

  .video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: '';
      display: block;
      background: currentColor;
      opacity: 0.15;
    }

    > video,
    > img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;

const Title = styled(Heading).attrs({ size: 4 })`
  display: flex;
  width: 7.1em;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  font-size: 9.6rem;
  font-style: normal;
  font-weight: 300;
  line-height: 100%;
  letter-spacing: -0.384rem;
  color: var(--color-white);

  @media (max-width: 768px) {
    width: auto;
    font-size: 4.8rem;
    font-weight: 350;
    line-height: 105%;
    letter-spacing: -0.192rem;
    text-align: center;
    display: block;
  }
`;

const Login = styled.div`
  background: var(--color-white);
  width: 45.2rem;
  padding: 7rem 4rem 10rem;
  border-radius: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .logomark {
    width: 9rem;
    height: 9rem;
    margin-bottom: 2rem;
  }

  .text-editor {
    text-align: center;

    h4 {
      font-size: 3.4rem;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      letter-spacing: -0.136rem;
      padding: 0 1rem;
      margin-bottom: 5rem;
      text-align: center;
    }

    > div {
      margin-bottom: 3rem;

      > p {
        color: #959595;
        font-size: 1.8rem;
        font-style: normal;
        font-weight: 350;
        line-height: 110%;
        letter-spacing: -0.036rem;
        margin-bottom: 5rem;

        > a {
          color: var(--color-black);
        }
      }
      > div {
        > a {
          width: 100%;
        }
      }
    }
  }

  .app-store {
    display: flex;
    justify-content: center;
    align-items: center;

    .app-store-button {
      width: 12.8rem;
      height: 3.58rem;

      &:first-child {
        margin-right: 1rem;
      }
    }
  }

  @media (max-width: 768px) {
    width: 35rem;
    padding: 6rem 4rem;

    .text-editor {
      h4 {
        padding: 0;
        font-size: 2.4rem;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: -0.048rem;
        margin-bottom: 4rem;
      }

      > p {
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: -0.028rem;
        margin-bottom: 4rem;
      }
    }

    .app-store {
      .app-store-button {
        width: 11.6rem;
        height: 3.26rem;
      }
    }
  }
`;

export default function GetStarted({ title, background, anchorId }: Props) {
  const { siteSettings } = useStrapiGlobals();
  const ctaModal = siteSettings?.getStartedCTAModal;

  return (
    <StyledGetStarted id={anchorId?.anchorId}>
      <div className="video">
        <Video key={background.id} media={background} playsInline muted loop />
      </div>
      {title && <Title>{title}</Title>}
      <Login>
        <img
          className="logomark"
          alt=""
          src="https://c5.patreon.com/external/marketing/new_marketing_pages/logomark-animated.webp"
          width="120"
          height="120"
        />
        <div className="text-editor">{ctaModal && <TextEditor minHeadingLevel={4} {...ctaModal} />}</div>
        <div className="app-store">
          <AppStoreLinks theme="light" className="app-store-button" />
        </div>
      </Login>
    </StyledGetStarted>
  );
}
