import parse from 'html-react-parser';
import React from 'react';

// Fork of https://github.com/moveyourdigital/editorjs-blocks-react-renderer/blob/master/src/index.tsx
// with support for list item syntax `{ text: '' }`, which we use to make Smartling integration easier

interface WrappedListItem {
  text: string;
}
type ListItem = string | WrappedListItem;

interface ListItemWithNestedItems {
  content: string;
  items: NestedListItem[];
}
type NestedListItem = ListItemWithNestedItems | ListItem;

export interface Props {
  data: {
    style: 'ordered' | 'unordered';
    items: NestedListItem[];
  };
  className?: string;
}

function Bullet({ children }: { children: React.ReactNode }) {
  const parsedChildren = children ? parse(children as string) : null;
  return <li>{parsedChildren}</li>;
}

interface GroupProps {
  Tag: keyof JSX.IntrinsicElements;
  items: NestedListItem[];
  className?: string;
}

function Group({ Tag, items, ...props }: GroupProps) {
  return (
    <Tag {...props}>
      {items.map((item, i) => {
        const text = typeof item === 'string' ? item : (item as WrappedListItem).text;
        if (text) {
          // eslint-disable-next-line react/no-array-index-key
          return <Bullet key={i}>{text}</Bullet>;
        }
        item = item as ListItemWithNestedItems;
        return (
          // eslint-disable-next-line react/no-array-index-key
          <Bullet key={i}>
            {parse(item?.content)}
            {item?.items?.length > 0 && <Group Tag={Tag} items={item.items} {...props} />}
          </Bullet>
        );
      })}
    </Tag>
  );
}

export default function List({ data, className = '' }: Props) {
  const props: Record<string, string> = {};

  if (className) {
    props.className = className;
  }

  const Tag = (data?.style === 'ordered' ? `ol` : `ul`) as keyof JSX.IntrinsicElements;
  return data && <Group Tag={Tag} items={data.items} {...props} />;
}
