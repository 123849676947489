import clsx from 'clsx';
import { gsap } from 'gsap';
import React, { useRef } from 'react';

import styled, { css } from 'styled-components';

import ResponsiveImage from '@/components/Elements/ResponsiveImage';
import PatreonLogomark from '@/components/icons/patreon-logo-icon.svg';

import useGsapEffect from '@/hooks/useGsapEffect';
import useIsMobile from '@/hooks/useIsMobile';
import getMediaFormats from '@/utilities/strapi/getMediaFormats';
import getSortedMediaSrcs from '@/utilities/strapi/getSortedMediaSrcs';
import type { FullBleedTestimonialProps as Props, ImageWithMeta } from '@/utilities/strapi/types/componentTypes';

import Heading from '../Elements/Heading';
import LineBreakTitle from '../utils/LineBreakTitle';

const StyledFullBleedTestimonial = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;

  &.light {
    color: var(--color-white);
  }

  &.dark {
    color: var(--color-black);
  }

  .background {
    width: 100%;
    height: calc(100% + 30vh);
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;

    @media (max-width: 768px) {
      height: calc(100% + 15vh);
    }

    > img {
      display: block;
      object-fit: cover;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  .title-spacer {
    margin-top: 16.5rem;

    @media (max-width: 768px) {
      margin-top: 9.6rem;
    }
  }

  .title-wrapper {
    position: relative;
    left: calc(5rem + var(--rem-scale-viewport-half-excess));
    mix-blend-mode: exclusion;
    min-height: 50rem;

    @media (max-width: 768px) {
      left: 2rem;
      width: 20rem;
      min-height: 15rem;
    }
  }
`;

const Title = styled(LineBreakTitle).attrs({ size: 1, align: 'left' })`
  line-height: 90%;
  letter-spacing: -0.06em;
  color: var(--color-white);

  @media (max-width: 768px) {
    font-size: 6.4rem;
    line-height: 100%;
    letter-spacing: -0.256rem;
  }
`;

const QuoteAttribution = styled.p`
  position: relative;
  left: calc(23rem + var(--rem-scale-viewport-half-excess));
  margin-top: 35rem;
  width: 34.5rem;
  font-size: var(--font-size-label-2);
  font-style: normal;
  font-weight: 350;
  line-height: 110%;
  letter-spacing: -0.036rem;

  @media (max-width: 768px) {
    width: 18rem;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: -0.048rem;
    left: 2rem;
    margin-top: 20rem;
  }
`;

const Quote = styled(Heading).attrs({ size: 4 })<{ quotePlacement?: Props['quotePlacement'] }>`
  position: relative;

  ${({ quotePlacement }) =>
    quotePlacement === 'right'
      ? css`
          position: absolute;
          top: 14rem;
          right: 7rem;
          max-width: 50%;
          line-height: 1.2;
          text-indent: 8rem;
          text-align: right;
        `
      : css`
          width: 142rem;
          left: max(env(safe-area-inset-left), calc(5rem + var(--rem-scale-viewport-half-excess)));
          margin-top: 9.5rem;
          text-indent: 18rem;
          line-height: 110%;
          letter-spacing: -0.288rem;
        `}

  @media (max-width: 768px) {
    width: 35rem;
    max-width: 80%;
    left: max(env(safe-area-inset-left), 2rem);
    margin-top: 9.6rem;
    text-indent: 7rem;
    font-size: 2.4rem;
    line-height: 100%;
    letter-spacing: -0.096rem;
  }
`;

const QuoteAuthor = styled(Heading).attrs({ size: 3 })`
  position: relative;
  padding-right: calc(15.8rem + var(--rem-scale-viewport-half-excess));
  line-height: 110%;
  letter-spacing: -0.288rem;
  text-align: right;

  @media (max-width: 768px) {
    left: max(env(safe-area-inset-left), 2rem);
    padding-right: 6.2rem;
    margin-top: 5.5rem;
    font-size: 2.4rem;
    line-height: 100%;
    letter-spacing: -0.096rem;
    padding-bottom: 6.7rem;
  }
`;

function getResponsiveSizes(image: ImageWithMeta): string {
  // The container is 100vw x something depending on content, let's estimate at 100vw x 100vw.
  const formats = getMediaFormats(image.image);
  const srcs = getSortedMediaSrcs(formats);
  const src = srcs[srcs.length - 1];
  if (!src.width || !src.height || src.width < src.height) {
    return '100vw';
  }
  return `${+(100 * (src.width / src.height)).toFixed(1)}vw`;
}

export default function FullBleedTestimonial({
  title,
  quote,
  quoteAttribution,
  quoteAuthor,
  featuredImage,
  theme,
  anchorId,
  quotePlacement,
}: Props) {
  const root = useRef<HTMLDivElement | null>(null);
  const tl = useRef<gsap.core.Timeline | null>(null);
  const bgRef = useRef<HTMLImageElement | null>(null);

  const { getIsMobile } = useIsMobile();

  useGsapEffect(root, () => {
    const updateProgress = (progress: number) => {
      gsap.to(bgRef.current, { y: `-${(getIsMobile() ? 15 : 30) * progress}vh`, duration: 0.5 });
    };

    tl.current = gsap.timeline({
      scrollTrigger: {
        trigger: root.current,
        start: 'top bottom',
        end: 'bottom',
        scrub: false,
        onUpdate: (self) => updateProgress(self.progress),
      },
    });
  });

  return (
    <div>
      <StyledFullBleedTestimonial id={anchorId?.anchorId} ref={root} className={clsx(theme)}>
        <div ref={bgRef} className="background">
          {featuredImage && <ResponsiveImage image={featuredImage} sizes={getResponsiveSizes(featuredImage)} />}
        </div>
        <div className="title-spacer" />
        <div className="title-wrapper">{title && <Title size={1}>{title}</Title>}</div>

        <QuoteAttribution>{quoteAttribution}</QuoteAttribution>

        <Quote className="quotetext" quotePlacement={quotePlacement}>
          {quote}
        </Quote>

        {quotePlacement === 'right' ? (
          <QuoteAuthorWithLogo>
            <PatreonLogomark className="logomark" />
            <p>{quoteAuthor}</p>
          </QuoteAuthorWithLogo>
        ) : (
          <QuoteAuthor>{quoteAuthor}</QuoteAuthor>
        )}
      </StyledFullBleedTestimonial>
    </div>
  );
}

const QuoteAuthorWithLogo = styled.div`
  position: absolute;
  top: 60rem;
  right: 7rem;
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  align-items: center;

  p {
    font-size: 3rem;
  }

  .logomark {
    height: 2.2rem;
  }

  @media (max-width: 768px) {
    top: unset;
    bottom: 4rem;

    p {
      font-size: var(--font-size-body-lg);
    }
  }
`;
