import clsx from 'clsx';
import Link from 'next/link';
import React from 'react';

import styled from 'styled-components';

import { logMarketingPageGenericClick } from '@/analytics/logger/generatedEvents/marketingPageGenericClick';
import getStrapiLinkHref, { getStrapiLinkLocale } from '@/components/utils/getStrapiLinkHref';
import type { StrapiDataObject } from '@/utilities/strapi/types/apiResponseTypes';
import type { StrapiMinimalPageInfo } from '@/utilities/strapi/types/queryResponseTypes';
import type { AnchorLink as LinkType } from '@/utilities/strapi/types/utilsTypes';

export interface StrapiAnchorLinkProps extends LinkType {
  className?: string;
  callback?: () => void;
}

const StyledLink = styled.a`
  cursor: pointer;

  .title {
    font: var(--font-mega-nav-anchor);
    letter-spacing: -0.016rem;
  }

  .subtitle {
    color: var(--color-action-muted);
    font: var(--font-label-0);
    transition: color var(--animation-duration) var(--animation-timing);
  }

  &:hover {
    .subtitle {
      color: var(--color-action);
    }
  }
`;

export default function StrapiAnchorLink({
  page,
  externalUrl,
  title,
  subtitle,
  className,
  callback,
  anchor,
  genericClickDetails,
}: StrapiAnchorLinkProps) {
  const href = getStrapiLinkHref(page, externalUrl, anchor);
  const pageData = (page as StrapiDataObject<StrapiMinimalPageInfo>)?.data?.attributes || page;

  const onClick = (): void => {
    if (genericClickDetails) {
      // TODO (jackyang) Remove the generic_page_details as a field to log. We should just encapsulate this info inside click details.
      logMarketingPageGenericClick({ generic_page_details: '', generic_click_details: genericClickDetails });
    }

    if (callback) {
      callback();
    }
    return;
  };

  return (
    <>
      {pageData?.Slug ? (
        <Link href={href} locale={getStrapiLinkLocale(pageData)} passHref>
          <StyledLink className={clsx(className, 'strapi-anchor-link')} onClick={onClick}>
            {(title || pageData?.Title) && <div className="title">{title || pageData?.Title}</div>}
            {subtitle && <div className="subtitle">{subtitle}</div>}
          </StyledLink>
        </Link>
      ) : (
        <StyledLink href={href} className={clsx(className, 'strapi-anchor-link')} onClick={onClick}>
          {(title || pageData?.Title) && <div className="title">{title || pageData?.Title}</div>}
          {subtitle && <div className="subtitle">{subtitle}</div>}
        </StyledLink>
      )}
    </>
  );
}
