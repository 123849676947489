import getPageSlugs from '@/utilities/getPageSlugs';
import type { StrapiPage, StrapiDataObject } from '@/utilities/strapi/types/apiResponseTypes';
import type { StrapiMinimalPageInfo } from '@/utilities/strapi/types/queryResponseTypes';
import type { Link } from '@/utilities/strapi/types/utilsTypes';

interface GenericObject {
  [key: string]: string | boolean | number | string[] | boolean[] | number[] | GenericObject | GenericObject[];
}

type PagesByPath = Record<string, StrapiMinimalPageInfo>;

const internalHosts = ['patreon.com', 'www.patreon.com', 'staging.patreon.com'];

function getPathFromExternalUrl(externalUrl: string): string | undefined {
  // Attempt to detect missing protocol in fully-qualified url
  if (!/^(?:\w+:)?\/\//.test(externalUrl) && /^[^./]+\.[^./]/.test(externalUrl)) {
    externalUrl = `//${externalUrl}`;
  }
  try {
    const url = new URL(externalUrl, 'https://patreon.com');
    if (internalHosts.includes(url.host)) {
      return url.pathname;
    }
  } catch (e) {
    // ignore url parsing errors
  }
  return undefined;
}

function getPageFromExternalUrl(pagesByPath: PagesByPath, externalUrl: string) {
  const path = getPathFromExternalUrl(externalUrl);
  if (!path) {
    return null;
  }
  return pagesByPath[path] || null;
}

function convertUrls(something: unknown, pagesByPath: PagesByPath) {
  if (Array.isArray(something)) {
    something.forEach((item) => {
      convertUrls(item, pagesByPath);
    });
  } else if (something && typeof something === 'object') {
    const object = something as GenericObject;
    for (const [key, value] of Object.entries(object)) {
      if ((key === 'externalUrl' || key === 'external_url') && typeof value === 'string') {
        const page =
          (object.page as unknown as StrapiDataObject<StrapiMinimalPageInfo>)?.data?.attributes || object.page;
        if (!page?.Slug) {
          // The externalUrl will be used. Now check if it looks like an internal link.
          const linkedPage = getPageFromExternalUrl(pagesByPath, value);
          if (linkedPage) {
            (object as Link).page = linkedPage;
          }
        }
        if (object.links) {
          convertUrls(object.links, pagesByPath);
        }
        break;
      } else if (value && typeof value === 'object') {
        convertUrls(value, pagesByPath);
      }
    }
  }
}

export default function convertExternalUrlsToPages(objectGraph: unknown, locale: string, allPages: StrapiPage[]) {
  const pagesByPath: PagesByPath = {};
  allPages.forEach((pageRecord) => {
    const page = pageRecord.attributes;
    if (page.locale !== locale) {
      return;
    }
    pagesByPath[['', ...getPageSlugs(page)].join('/')] = page;
  });
  // Walk the components tree looking for externalUrl or external_url
  convertUrls(objectGraph, pagesByPath);
}
