import { useEffect, useCallback, useState } from 'react';

import { useWindowSize } from 'react-use';

import clamp from '@/utilities/clamp';

import useIsMobile from './useIsMobile';

function round(vw: number) {
  return +vw.toFixed(3);
}

function getRemNumber(rem: number | string) {
  if (typeof rem === 'string') {
    return parseFloat(rem);
  }
  return rem;
}

function remToVw(rem: number | string, designWidth: number) {
  rem = getRemNumber(rem);
  return `${round(100 * (10 / designWidth) * rem)}vw`;
}

function remToMinPx(rem: number | string, minScale: number) {
  rem = getRemNumber(rem);
  return `${round(rem * Math.max(1, minScale * 10))}px`;
}

function remToMaxPx(rem: number | string, maxScale: number) {
  rem = getRemNumber(rem);
  return `${round(rem * maxScale * 10)}px`;
}

function remToVwCalc(rem: number | string, designWidth: number, minScale: number, maxScale: number) {
  const value = remToVw(rem, designWidth);
  const min = remToMinPx(rem, minScale);
  const max = remToMaxPx(rem, maxScale);
  return `clamp(${min}, ${value}, ${max})`;
}

export function remToVwMobile(rem: number | string) {
  return remToVw(rem, 390);
}

export function remToMinPxMobile(rem: number | string) {
  return remToMinPx(rem, 0.1);
}

export function remToMaxPxMobile(rem: number | string) {
  return remToMaxPx(rem, 1.5);
}

export function remToVwCalcMobile(rem: number | string) {
  return remToVwCalc(rem, 390, 0.1, 1.5);
}

export function remToVwDesktop(rem: number | string) {
  return remToVw(rem, 1920);
}

export function remToMinPxDesktop(rem: number | string) {
  return remToMinPx(rem, 0.1);
}

export function remToMaxPxDesktop(rem: number | string) {
  return remToMaxPx(rem, 1);
}

export function remToVwCalcDesktop(rem: number | string) {
  return remToVwCalc(rem, 1920, 0.1, 1);
}

export function remToCurrentPx(rem: number | string) {
  let vw: string;
  let min: string;
  let max: string;
  const windowWidth = window.innerWidth;
  if (windowWidth <= 768) {
    vw = remToVwMobile(rem);
    min = remToMinPxMobile(rem);
    max = remToMaxPxMobile(rem);
  } else {
    vw = remToVwDesktop(rem);
    min = remToMinPxDesktop(rem);
    max = remToMaxPxDesktop(rem);
  }
  const valuePx = (parseFloat(vw) * windowWidth) / 100;
  const minPx = parseFloat(min);
  const maxPx = parseFloat(max);
  return clamp(valuePx, minPx, maxPx);
}

const useRemScale = () => {
  const [scale, setScale] = useState(1);
  const { isMobile } = useIsMobile();
  const { width } = useWindowSize();

  const getScale = useCallback(
    (width = window.innerWidth) => {
      const w = isMobile ? 375 : 1920;
      return width / w;
    },
    [isMobile],
  );

  useEffect(() => {
    setScale(getScale());
  }, [width, isMobile, getScale]);

  return { scale, getScale, width };
};

export default useRemScale;
