import React from 'react';
import styled from 'styled-components';

import type { IntegrationNewsTeaserItemProps } from './IntegrationNewsTeaserItem';
import IntegrationNewsTeaserItem from './IntegrationNewsTeaserItem';

export interface IntegrationNewsTeasersProps {
  title?: string;
  newsItems?: IntegrationNewsTeaserItemProps[];
}

export default function IntegrationNewsTeasers({ title, newsItems }: IntegrationNewsTeasersProps) {
  if (!newsItems?.length) {
    return null;
  }
  return (
    <StyledIntegrationNewsTeasers>
      <header className="teasers-header">
        <h2 className="teasers-title">{title}</h2>
      </header>
      <ul className="teasers-list">
        {newsItems.map((newsItem) => (
          <li key={newsItem.id} className="teasers-item">
            <IntegrationNewsTeaserItem {...newsItem} />
          </li>
        ))}
      </ul>
    </StyledIntegrationNewsTeasers>
  );
}

const StyledIntegrationNewsTeasers = styled.section`
  .teasers-header {
    font: var(--font-label-2);
    text-transform: uppercase;
    letter-spacing: 0.036rem;
    margin-bottom: var(--spacing-base);
  }

  .teasers-list {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-lg);
  }
`;
