import { useCallback } from 'react';
import { proxy, useSnapshot } from 'valtio';
import { subscribeKey } from 'valtio/utils';

const state = proxy({
  wrapper1Hovered: false,
  wrapper2Hovered: false,
  hovered: false,
});

let timeout: NodeJS.Timeout;

function updateHovered() {
  state.hovered = state.wrapper1Hovered || state.wrapper2Hovered;
}

function handleWrapperHoveredChange(hovered: boolean) {
  clearTimeout(timeout);
  if (hovered) {
    state.hovered = true;
  } else {
    timeout = setTimeout(updateHovered, 200);
  }
}

subscribeKey(state, 'wrapper1Hovered', handleWrapperHoveredChange);
subscribeKey(state, 'wrapper2Hovered', handleWrapperHoveredChange);

export default function useMegaHoverState() {
  const handleWrapper1Enter = useCallback(() => {
    state.wrapper1Hovered = true;
  }, []);

  const handleWrapper1Leave = useCallback(() => {
    state.wrapper1Hovered = false;
  }, []);

  const handleWrapper2Enter = useCallback(() => {
    state.wrapper2Hovered = true;
  }, []);

  const handleWrapper2Leave = useCallback(() => {
    state.wrapper2Hovered = false;
  }, []);

  const snapshot = useSnapshot(state);

  return {
    handleWrapper1Enter,
    handleWrapper1Leave,
    handleWrapper2Enter,
    handleWrapper2Leave,
    snapshot,
    state,
  };
}
