import clsx from 'clsx';
import React from 'react';

import styled from 'styled-components';

import Video from '@/components/Elements/Video';
import TextEditor from '@/components/TextEditor';
import MaxWidthContainer from '@/components/utils/MaxWidthContainer';
import type { PromoTileProps as Props } from '@/utilities/strapi/types/componentTypes';

export default function PromoTile({ text, Media, layout, verticalAlignment }: Props) {
  const layoutClasses: Record<string, string> = {
    'Ratio - 50/50': 'ratio-50-50',
    'Ratio - 66/33': 'ratio-66-33',
    'Ratio - 33/66': 'ratio-33-66',
  };
  const desktopMediaSizes: Record<string, string> = {
    'Ratio - 50/50': '568px',
    'Ratio - 66/33': '360px',
    'Ratio - 33/66': '780px',
  };
  const sizes = `(max-width: 768px) 100vw, ${desktopMediaSizes[layout]}`;

  return (
    <MaxWidthContainer>
      <StyledPromoTile className={clsx(layoutClasses[layout], `align-${verticalAlignment?.alignment}`)}>
        <div className="media-container">
          <Video key={Media.id} media={Media} autoPlay playsInline muted loop sizes={sizes} />
        </div>
        <div className="copy-container">
          <TextEditor {...text} />
        </div>
      </StyledPromoTile>
    </MaxWidthContainer>
  );
}

const StyledPromoTile = styled.section`
  display: flex;
  flex-direction: row-reverse;
  gap: var(--spacing-xl);
  align-items: center;
  justify-content: space-between;

  &.align-top {
    align-items: flex-start;
  }

  &.align-middle {
    align-items: center;
  }

  &.align-bottom {
    align-items: flex-end;
  }

  &.ratio-50-50 {
    .copy-container {
      width: 50%;
    }
  }

  &.ratio-66-33 {
    .copy-container {
      width: 66.66666%;
    }
  }

  &.ratio-33-66 {
    .copy-container {
      width: 33.33333%;
    }
  }

  .media-container {
    flex: 1 0 0;
  }

  img,
  video {
    object-fit: fill;
    width: 100%;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: var(--spacing-sm);

    .copy-container,
    .media-container {
      width: 100% !important;
    }
  }
`;
