import React, { useEffect } from 'react';

import styled from 'styled-components';

import { logErrorPageLandedEvent } from '@/analytics/logger/generatedEvents/errorPageLandedEvent';

import type { ErrorProps as Props } from '@/utilities/strapi/types/componentTypes';

import CTAButton from '../Blocks/CTAButton';

const StyledErrorContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f5f5f5;
  display: grid;
  place-items: center;
  z-index: 1000;
  font-weight: bold;
`;

const StyledErrorCodeHeading = styled.h1`
  color: #fff;
  position: absolute;
  font-size: max(80px, 50vw);
  letter-spacing: min(-12px, -7vw);
  margin-left: min(-12px, -7vw);
  text-align: center;
  z-index: -1;
`;

const StyledErrorDescription = styled.div`
  display: grid;
  place-items: center;
  row-gap: 32px;
`;

const StyledHeading = styled.h4`
  font-size: clamp(40px, 96px, 7vw);
  font-weight: 250;
  text-align: center;
  margin: 0px;
`;

export default function Error({ errorCode, description, ctaHeading }: Props) {
  // Collapse the main contain so that we don't scroll on the error page.
  useEffect(() => {
    const container = document.getElementById('main-content');
    container?.classList.add('collapsed-main-content');

    return () => {
      const container = document.getElementById('main-content');
      container?.classList.remove('collapsed-main-content');
    };
  }, []);

  // Log an event when the page loads
  useEffect(() => {
    logErrorPageLandedEvent({
      error_code: errorCode,
    });
  }, [errorCode]);

  return (
    <StyledErrorContainer>
      <StyledErrorCodeHeading>{errorCode}</StyledErrorCodeHeading>
      <StyledErrorDescription>
        <StyledHeading>{description}</StyledHeading>
        <CTAButton label={ctaHeading} external_url="/" target="_self" />
      </StyledErrorDescription>
    </StyledErrorContainer>
  );
}
