import Cookies from 'js-cookie';
import { acceptLanguage } from 'next/dist/server/accept-header';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { defaultLocale, getLocaleFromPath, getPathWithLocale, locales as availableLocales } from '@/utilities/locale';

// Matches cookie name written in patreon_py/patreon/model/manager/locale_resolution_mgr.py
const LOCALE_COOKIE_NAME = 'patreon_locale_code';

/**
 * Leverage Next.js’ built-in locale selection logic to choose from the
 * available locales based on the user’s locale as reported by the browser.
 * @param locales
 */
function parseBrowserLocales(locales?: string[]): string | undefined {
  let languagesHeader: string;
  const languages = window?.navigator?.languages;
  if (languages) {
    languagesHeader = languages.join(',');
  } else {
    languagesHeader = window?.navigator?.language;
  }
  if (languagesHeader) {
    return selectLocale(languagesHeader, locales);
  }
  return undefined;
}

/**
 * Leverage Next.js’ built-in locale selection logic to choose the best
 * match among our configured locales.
 * @param languagesHeader
 * @param locales
 */
function selectLocale(languagesHeader?: string, locales?: string[]): string | undefined {
  if (languagesHeader && locales) {
    return acceptLanguage(languagesHeader, locales);
  }
  return undefined;
}

function getCookieLocale(locales?: string[]) {
  let locale = Cookies.get(LOCALE_COOKIE_NAME);
  if (!locale) {
    try {
      locale = window.localStorage.getItem(LOCALE_COOKIE_NAME) ?? undefined;
    } catch (e) {
      // ignore
    }
  }
  if (!locale) {
    // If the language picker was used it sets this global flag, so we don’t
    // immediately overrule the user’s choice here. This is helpful in case
    // both the cookie and localStorage are blocked.
    locale = window[LOCALE_COOKIE_NAME];
  }
  if (locale) {
    locale = selectLocale(locale, locales);
  }
  return locale;
}

export default function useLocaleRedirect(): void {
  const router = useRouter();
  const { pathname, asPath, query } = router;

  useEffect(() => {
    const browserLocale = parseBrowserLocales(availableLocales);
    const cookieLocale = getCookieLocale(availableLocales);
    const expectedLocale = cookieLocale || browserLocale || defaultLocale;

    const currentLocale = getLocaleFromPath(asPath);
    if (currentLocale === expectedLocale) {
      // The correct locale is set.
      return;
    }

    // Hopefully this means the user has just landed on the site, and we are
    // helpfully redirecting them to their preferred locale. There is
    // one case where we’d redirect incorrectly:
    //   * user has cookies and localStorage blocked
    //   * they chose a language in the picker that doesn’t match their
    //     browser prefs
    //   * a page reload occurs (or they click a link that is not routed
    //     client-side)
    //   * we can no longer tell what language they chose, so we’ll be
    //     redirecting them here to the best match according to their browser
    //     prefs.
    // Potential improvement could be to do as Next.js does, and only perform
    // automatic locale detection on the `/` route.
    void router.replace(getPathWithLocale(router.asPath, expectedLocale));
  }, [asPath, pathname, query, router]);
}
