import { gsap } from 'gsap';
import type { RefObject } from 'react';
import { useIsomorphicLayoutEffect } from 'react-use';

interface RevertConfig {
  suppressEvents?: boolean;
  isStart?: boolean;
  kill?: boolean;
}
type ContextCleanupFunction = (revert: RevertConfig | undefined, context: gsap.Context) => void;
type ContextFunc = (context: gsap.Context) => ContextCleanupFunction | void;

/**
 * Hook for running gsap animation code within a gsap Context, so
 * that all affected elements can be reverted (returned to their
 * original state) at once, either automatically when the component
 * unmounts, or whenever required by calling `revert()` on the
 * `gsap.Context` object passed to the callback.
 * @see https://greensock.com/docs/v3/GSAP/gsap.context()
 * @param rootRef A React ref used to scope any selector text used in gsap selectors
 * @param callback A callback in which to run gsap code. Optionally return a cleanup function to be called on revert.
 */
export default function useGsapEffect(rootRef: RefObject<Element>, callback: ContextFunc) {
  useIsomorphicLayoutEffect(() => {
    const ctx = gsap.context(callback, rootRef);
    return () => ctx.revert();
  }, []);
}

/**
 * Helper for scoping gsap calls that occur asynchronously. Example usage:
 *   useGsapEffect(root, (ctx) => {
 *     const withGsap = makeContextWrapper(ctx);
 *     const handleClick = withGsap((function (e: MouseEvent) {
 *       gsap.to('.selector', {}); // selector is scoped correctly
 *     });
 *   });
 * @param context
 */
export function makeContextWrapper(context: gsap.Context) {
  return function <T extends (this: unknown, ...args: never[]) => void>(callback: T): T {
    return function (this: unknown, ...args: never[]) {
      context.add(callback.bind(this, ...args));
    } as T;
  };
}
