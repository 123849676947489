import clsx from 'clsx';
import type { KeyboardEvent, MouseEvent } from 'react';
import React, { useEffect, useRef, useState } from 'react';

import { useIntersection, useRafLoop } from 'react-use';

import styled from 'styled-components';

import useCollectionRef from '@/hooks/useCollectionRef';
import type { ImageWithMeta, PricingFeaturesCarouselProps } from '@/utilities/strapi/types/componentTypes';

import { Wrapper } from './PricingStyled';
import ResponsiveImage from '../Elements/ResponsiveImage';
import GradientBackground from '../Hydra/GradientBackground';
import LineBreakTitle from '../utils/LineBreakTitle';

const Background = styled(GradientBackground)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`;

const PricingFeaturesCarousel = ({
  id,
  title,
  body,
  slides = [],
  anchorId,
  backgroundColor,
}: PricingFeaturesCarouselProps) => {
  const rootRef = useRef<HTMLDivElement>(null);
  const indicator = useRef<HTMLDivElement>(null);
  const [refs, addRef] = useCollectionRef<HTMLButtonElement>();
  const intersection = useIntersection(rootRef, {});

  const [index, setIndex] = useState(0);

  const currentTime = useRef<number | null>(0);
  const lastTime = useRef<number | null>(0);
  const timeOffset = useRef<number | null>(0);
  const hasInteracted = useRef(false);

  const TIMER_DELAY = 5000;

  function onClick(e: MouseEvent | KeyboardEvent) {
    if (('key' in e && e?.key !== 'Enter') || ('type' in e && e?.type !== 'click')) {
      return;
    }

    setIndex(Number((e.currentTarget as HTMLElement).dataset.index));
    hasInteracted.current = true;
  }

  const nextSlide = () => {
    setSlide(index === slides.length - 1 ? 0 : index + 1);
  };

  const setSlide = (slide: number) => {
    setIndex(slide);
    lastTime.current = currentTime.current;
  };

  useEffect(() => {
    lastTime.current = currentTime.current;
  }, [index]);

  useEffect(() => {
    if (intersection?.isIntersecting && !hasInteracted.current) {
      setSlide(0);
    }
  }, [intersection]);

  useRafLoop((time) => {
    if (hasInteracted.current) {
      const delta = time - (currentTime.current || 0);
      timeOffset.current = (timeOffset.current || 0) + delta;
    }

    const t = time - (timeOffset.current || 0);

    if (t - (lastTime.current || 0) > TIMER_DELAY) {
      nextSlide();
    }

    const progress = (t - (lastTime.current || 0)) / TIMER_DELAY;

    if (indicator.current) {
      const x = refs.current[index].offsetLeft;
      indicator.current.style.transform = 'translateX(' + x + 'px)';
      indicator.current.style.setProperty('--progress', `${progress * 100}%`);
    }

    currentTime.current = time;
  });

  return (
    <Wrapper id={anchorId?.anchorId} ref={rootRef}>
      <Background seed={id} colors={backgroundColor} />
      <div className="head">
        <LineBreakTitle size={2}>{title}</LineBreakTitle>
        <div className="head-body">{body}</div>
      </div>
      <div className="features-body">
        <div className="features-nav">
          <div className="features-nav-indicator" ref={indicator} />
          {slides.map(({ id, title }, i) => (
            <button
              key={id}
              ref={addRef(i)}
              className={clsx('features-nav-item', i === index && 'active')}
              onClick={onClick}
              tabIndex={0}
              onKeyDown={onClick}
              data-index={i}
            >
              <div className="features-nav-item-number">{String(i + 1).padStart(2, '0')}.</div>
              <div className="features-nav-item-title">{title}</div>
            </button>
          ))}
        </div>
        <div className="features-nav-item-mobile">{slides[index].title}</div>

        <div className="features-slider">
          {slides.map((slide, i) => (
            <div key={slide.id} className={clsx('features-slide', { active: i === index })}>
              <ResponsiveImage
                image={slide as ImageWithMeta}
                className="slide-img"
                transparent
                sizes="(max-width: 768px) 100vw, 1280px"
              />
            </div>
          ))}
        </div>
      </div>
    </Wrapper>
  );
};

export default PricingFeaturesCarousel;
