import type { MutableRefObject } from 'react';
import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useEnsuredForwardedRef, useLockBodyScroll } from 'react-use';
import styled from 'styled-components';

import type { ModalProps as Props } from './types';

const StyledModal = styled.dialog`
  align-items: center;
  justify-content: center;
  background-color: transparent;
  width: 100%;
  height: 100%;

  &[open] {
    display: flex;
    animation: show 250ms cubic-bezier(0.4, 0, 0.2, 1) forwards;

    &.modal--closing {
      animation: hide 150ms cubic-bezier(0.4, 0, 0.2, 1) forwards;
    }
  }

  &::backdrop {
    background-color: rgba(0, 0, 0, 0.85);
  }

  &:modal {
    max-width: 100vw;
    max-height: 100vh;
    max-height: 100dvh;
  }

  .dialog-content {
    background-color: var(--color-viewport-background);
    box-shadow: var(--elevation-1);
    border-radius: 4rem;
    text-align: center;

    .text-button {
      font-size: var(--button-font-size);
    }

    @media (max-width: 768px) {
      max-width: calc(100vw - var(--spacing-sm) * 2);
    }
  }

  .modal-footer {
    margin-bottom: var(--spacing-xl);
  }

  @keyframes show {
    from {
      opacity: 0;
      transform: translateY(min(10rem, 5vh));
    }
    to {
      opacity: 1;
      transform: translateY(0%);
    }
  }

  @keyframes hide {
    from {
      opacity: 1;
      transform: translateY(0%);
    }
    to {
      opacity: 0;
      transform: translateY(min(10rem, 5vh));
    }
  }
`;

export const Modal = forwardRef<HTMLDialogElement, Props>(
  ({ open, onClose, children, triggerClose, hideCancel }, dialog) => {
    const ensuredDialog = useEnsuredForwardedRef(dialog as MutableRefObject<HTMLDialogElement>);
    const [mounted, setMounted] = useState(false);

    const onCancel = useCallback(
      (event: React.FormEvent) => {
        event.preventDefault();
        onClose();
      },
      [onClose],
    );

    const onClick = useCallback(
      ({ target }) => {
        const { current: el } = ensuredDialog;

        if (target === el) {
          onClose();
        }
      },
      [onClose, ensuredDialog],
    );

    const onAnimEnd = useCallback(() => {
      const { current: el } = ensuredDialog;

      if (!open) {
        el.close();
      }
    }, [open, ensuredDialog]);

    useEffect(() => {
      setMounted(true);
    }, []);

    useEffect(() => {
      const { current: el } = ensuredDialog;

      if (!el?.open && open) {
        el.showModal();
      } else if (el?.open && !open) {
        el.close();
      }
    }, [open, ensuredDialog]);

    useEffect(() => {
      const { current: el } = ensuredDialog;

      if (triggerClose) {
        el.close();
      }
    }, [triggerClose, ensuredDialog]);

    useLockBodyScroll(open);

    const modalMarkup = (
      <StyledModal
        ref={ensuredDialog}
        onClose={onClose}
        onCancel={onCancel}
        onClick={onClick}
        onAnimationEnd={onAnimEnd}
      >
        <div className="dialog-content">
          <article className="modal-content">{children}</article>
          {!hideCancel && (
            <footer className="modal-footer">
              <form method="dialog">
                <button className="text-button">Cancel</button>
              </form>
            </footer>
          )}
        </div>
      </StyledModal>
    );

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
    return mounted ? createPortal(modalMarkup, document.body) : null;
  },
);

Modal.displayName = 'Modal';
