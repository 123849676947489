// THIS CODE WAS GENERATED. DO NOT ALTER.
// COMMAND USED TO GENERATE: ./scripts/event_logging/create_event.py --event marketing_page_generic_click
import { patreonTrackerClient } from '..';

interface EventType {
  generic_click_details: string;
  generic_page_details: string;
}

export function logMarketingPageGenericClick(data: EventType) {
  patreonTrackerClient.logTypedEvent('Marketing Page : Generic Click', data);
}
