import clsx from 'clsx';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

import ArrowIcon from '@/components/icons/arrow-left.svg';
import CloseIcon from '@/components/icons/close.svg';
import type { NavigationGroup } from '@/utilities/strapi/types/utilsTypes';

import Button from '../Elements/Buttons/Button';
import StrapiLink from '../utils/StrapiLink';

interface MobileSubLevelMenuProps {
  navigationGroup: NavigationGroup;
  activeMenuId: number | null;
  closeCallback?: () => void;
  backCallback?: () => void;
}

export default function MobileSubLevelMenu({
  navigationGroup,
  activeMenuId,
  closeCallback,
  backCallback,
}: MobileSubLevelMenuProps) {
  const navList = useRef<HTMLUListElement>(null);

  function close() {
    if (backCallback) {
      backCallback();
    }

    if (closeCallback) {
      closeCallback();
    }
  }

  const active = navigationGroup.id === activeMenuId;

  useEffect(() => {
    if (active) {
      navList.current?.focus();
    }
  }, [active, navList]);

  return (
    <StyledMobileSubLevelMenu className={clsx({ active })} inert={active ? undefined : ''}>
      <header className="submenu-header">
        <div className="back">
          <Button icon={<ArrowIcon />} label="Back" hideLabel size="sm" onClick={backCallback} />
        </div>
        <div className="title">
          <h3 className="title-label">{navigationGroup.title}</h3>
        </div>
        <div className="close">
          <Button icon={<CloseIcon />} label="Close" hideLabel size="sm" onClick={close} />
        </div>
      </header>
      <div className="nav-wrapper" data-lenis-prevent>
        <ul ref={navList} className="submenu-list" tabIndex={-1}>
          {navigationGroup.linksWithAnchors.map((link) => (
            <li key={link.id}>
              <StrapiLink {...link} className="nav-link" callback={close} />
              {link.links && link.links.length > 0 && (
                <ul className="sub-nav-list">
                  {link.links.map((sublink) => (
                    <li key={sublink.id}>
                      <StrapiLink {...sublink} className="sub-nav-link" callback={close} />
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>
    </StyledMobileSubLevelMenu>
  );
}

const StyledMobileSubLevelMenu = styled.section`
  --header-height: 62px;

  color: var(--color-action-contrast);
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  height: 100dvh;
  background-color: var(--color-action-default);
  position: fixed;
  transform: translateX(-100%);
  transition: transform var(--animation-duration-fast) var(--animation-timing);
  z-index: 100;
  top: 0px;

  &.active {
    transform: translateX(0px);
  }

  .submenu-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: var(--header-height);
    padding: var(--spacing-xs);
  }

  .title-label {
    font: var(--font-label-3);
    letter-spacing: 0.04rem;
  }

  .nav-wrapper {
    width: 100%;
    height: 100%;
    height: calc(100vh - var(--header-height));
    height: calc(100dvh - var(--header-height));
    overflow-y: auto;
  }

  .submenu-list {
    border: 1px solid var(--color-action-default);
    font: var(--font-sub-h1-light);
    padding: var(--spacing-sm) var(--spacing-sm) var(--spacing-xl);

    &:focus-visible {
      outline: none;
    }
  }

  .nav-link {
    display: block;
    margin-bottom: 1.8rem;
    letter-spacing: -0.06rem;
  }

  .sub-nav-list {
    display: block;
    margin-top: 2.6rem;
  }

  .sub-nav-link {
    color: var(--color-action-muted) !important;
    display: block;
    margin-bottom: 2.4rem;
    letter-spacing: -0.06rem;

    &:before {
      content: '';
      display: inline-block;
      width: 0.5em;
      height: 0.5em;
      border-left: 1px solid currentColor;
      border-bottom: 1px solid currentColor;
      margin-right: 0.3em;
      vertical-align: middle;
      transform: translateY(-0.25em);
    }
  }
`;
