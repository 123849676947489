import Cookies from 'js-cookie';
import type { NextRouter } from 'next/router';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import Icon from '@/components/icons/globe.svg';
import { LOCALE_COOKIE_NAME } from '@/utilities/cookieNames';
import { getTLD } from '@/utilities/getTLD';
import { getLocaleFromPath, getPathWithLocale } from '@/utilities/locale';
import type { LocaleResultItem } from '@/utilities/strapi/types/apiResponseTypes';

import type { LanguageSelectorProps as Props } from './types';
import Button from '../Elements/Buttons/Button';
import { SelectDropdown } from '../Elements/SelectDropdown/SelectDropDown';
import { Modal } from '../Modal/Modal';

const StyledLanguageSelector = styled.div`
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const StyledModalContent = styled.div`
  max-width: 50rem;
  padding: var(--spacing-xl);
  padding-bottom: var(--spacing-sm);

  .content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: var(--spacing-sm);
    margin-bottom: var(--spacing-sm);
  }

  @media (max-width: 768px) {
    padding: var(--spacing-base);
    padding-bottom: 0;
  }
`;

declare global {
  interface Window {
    [LOCALE_COOKIE_NAME]?: string;
  }
}

export default function LanguageSelector({ className, locales = [] }: Props) {
  const router = useRouter();
  const activeLocale = getLocaleFromPath(router.asPath);

  const [isOpen, setIsOpen] = useState(false);
  const [localesData] = useState<LocaleResultItem[]>(locales);
  const [localeLabel, setLocaleLabel] = useState<string>('...');

  const dialog = useRef<HTMLDialogElement | null>(null);
  const dropdownRef = useRef<HTMLSelectElement | null>(null);

  const getLocaleData = useCallback(
    (code: string) => localesData.find((locale) => locale.code === code),
    [localesData],
  );

  const updateLocale = () => {
    const locale = dropdownRef?.current?.value;

    if (!locale) {
      return;
    }

    const domain = getTLD();

    Cookies.set(LOCALE_COOKIE_NAME, locale, { expires: 30, domain });
    if (typeof window !== 'undefined') {
      window[LOCALE_COOKIE_NAME] = locale;
      try {
        window.localStorage.setItem(LOCALE_COOKIE_NAME, locale);
      } catch (e) {
        // ignore
      }
    }

    setIsOpen(false);
    updateLocaleLabel(locale);
    void navigateToLocale(router, locale);
  };

  const updateLocaleLabel = useCallback(
    (locale) => {
      const localeData = getLocaleData(locale as string);
      if (localeData) {
        setLocaleLabel(localeData.name);
      }
    },
    [getLocaleData, setLocaleLabel],
  );

  useEffect(() => {
    updateLocaleLabel(activeLocale);
  }, [localesData, activeLocale, updateLocaleLabel]);

  return (
    <StyledLanguageSelector className={className}>
      <div className="desktop-only">
        <Button variant="tertiary" icon={<Icon />} onClick={() => setIsOpen(true)} label={localeLabel} />
      </div>
      <div className="mobile-only">
        <Button
          variant="tertiary"
          size="sm"
          fullWidth
          icon={<Icon />}
          onClick={() => setIsOpen(true)}
          label={localeLabel}
        />
      </div>

      <Modal ref={dialog} onClose={() => setIsOpen(false)} open={isOpen}>
        <StyledModalContent>
          <header className="header">
            <h2 className="subheading-1">Choose your language</h2>
          </header>
          <div className="content-wrapper">
            <SelectDropdown
              ref={dropdownRef}
              label="Select Language"
              options={localesData.map((locale) => ({
                label: locale.name,
                value: locale.code,
              }))}
              value={activeLocale || ''}
              icon={<Icon />}
              type="secondary"
              size="lg"
              fullWidth
            />
            <Button size="lg" fullWidth onClick={() => void updateLocale()}>
              Save & Update
            </Button>
          </div>
        </StyledModalContent>
      </Modal>
    </StyledLanguageSelector>
  );
}

function navigateToLocale(router: NextRouter, locale: string): Promise<boolean> {
  return router.replace(getPathWithLocale(router.asPath, locale));
}
