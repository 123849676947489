import type { GetStaticPropsContext, GetStaticPropsResult } from 'next/types';

import { defaultStrapiLocale, mapAppLocaleToStrapiLocale } from '@/components/utils/locale';
import { shouldRenderStrapiDraft } from '@/shared/environment';
import getPageSlugs from '@/utilities/getPageSlugs';
import { queryFromStrapi, StrapiApiError } from '@/utilities/strapi';
import convertExternalUrlsToPages from '@/utilities/strapi/convertExternalUrlsToPages';
import getGlobalsAndDecorateStrapiPage from '@/utilities/strapi/getGlobalsAndDecorateStrapiPage';
import getPopulateParentQueryParams from '@/utilities/strapi/getPopulateParentQueryParams';
import type { LocaleResultItem, StrapiPage, StrapiPageTypes } from '@/utilities/strapi/types/apiResponseTypes';
import type { StrapiPageModels, StrapiParams } from '@/utilities/strapi/types/serviceClientTypes';
import { StrapiModel } from '@/utilities/strapi/types/serviceClientTypes';
import type { Localization } from '@/utilities/strapi/types/utilsTypes';

import type { PageProps } from './types';
import { defaultLocale, isValidLocale, locales } from '../locale';

async function getAvailablePages(): Promise<StrapiPage[]> {
  const strapiQueryDraft = shouldRenderStrapiDraft();

  const query: StrapiParams = {
    fields: ['Slug', 'locale'],
    locale: 'all',
    populate: getPopulateParentQueryParams(),
    fetchAll: true,
  };
  if (strapiQueryDraft) {
    query.publicationState = 'preview';
  }
  return queryFromStrapi(StrapiModel.Pages, query);
}

async function getPageLocalizations(
  page: StrapiPageTypes,
  pageType: StrapiPageModels,
  strapiQueryDraft: boolean,
): Promise<Localization[]> {
  let localizations: Localization[];
  if (pageType === StrapiModel.Integrations) {
    localizations = [page, ...page.attributes.localizations.data].map(({ attributes }) => ({
      params: { slug: ['apps', attributes.Slug] },
      locale: attributes.locale,
    }));
  } else {
    // TODO will be able to merge this into the initial page data request if we *don’t* use the deep plugin.
    //  it’s not possible to pass additional populate options (to populate props of localizations) in the same
    //  request as populate=deep
    const query: StrapiParams = {
      locale: 'all',
      populate: {
        localizations: {
          populate: getPopulateParentQueryParams(),
        },
      },
      filters: {
        id: {
          $eq: page.id,
        },
      },
    };
    if (strapiQueryDraft) {
      query.publicationState = 'preview';
    }
    const result = await queryFromStrapi(pageType, query);
    if (!result.length) {
      localizations = [];
    } else {
      const data = result[0];
      localizations = [page, ...data.attributes.localizations.data].map(({ attributes }) => {
        const params = {
          slug: getPageSlugs(attributes),
        };
        return {
          params,
          locale: attributes.locale,
        };
      });
    }
  }

  localizations.sort((a, b) => a.locale.localeCompare(b.locale));
  return localizations;
}

function getSlugFilters(slugs: string[], pageType: StrapiPageModels) {
  if (!slugs || slugs.length < 1) {
    // Note: /[locale]/home is redirected to /[locale] in Next.js config
    slugs = ['home'];
  }
  const result: StrapiParams = {
    Slug: { $eq: slugs[slugs.length - 1] },
  };
  if (pageType !== StrapiModel.Pages) {
    return result;
  }

  // TODO (jackyang) This is an optimization that doesn't work well for pages that don't have parent slug relations properly set
  //  Also note, the commented-out code ensured that incorrect urls (e.g.
  //  /wrong/podcasts) responded with 404 instead of incorrectly matching only
  //  the rightmost slug (e.g. /wrong/podcasts would otherwise serve the content
  //  for /creators/podcasts instead of responding with 404). But the same
  //  effect is being achieved by `fallback:false`, because incorrect urls like
  //  /wrong/podcasts aren’t statically generated so are served as 404s in
  //  production. If we ever start using partial SSR (fallback:true) then we’ll
  //  need to either:
  //    1. re-enable the filter clauses below, or
  //    2. add new code after getting the query response, iterating over the
  //       (possibly multiple) result pages to find the one (or none) whose
  //       full path matches the requested path exactly, taking parents into
  //       account.

  // result.$and = [];
  // if (slugs.length > 1) {
  //   const levels = [];
  //   for (let depth = 1; depth < slugs.length; ++depth) {
  //     const typeCombinations = getSlugTypeCombinations(depth);
  //     const slug = slugs[slugs.length - 1 - depth];
  //     levels.push(
  //       typeCombinations.map((types) => {
  //         const level = {};
  //         set(level, [...types.flatMap((type) => ['Parent', type]), 'Slug', '$eq'], slug);
  //         return level;
  //       }),
  //     );
  //   }
  //   // combine with AND
  //   result.$and.push(
  //     ...levels.map((level) => ({
  //       $or: level,
  //     })),
  //   );
  // }

  // if (slugs.length < 3) {
  //   // Also check that the top-level slug is actually top level.
  //   // Don’t do this for 3 or more levels of nesting to avoid exceeding database join limits.
  //   result.$and.push(
  //     ...getSlugTypeCombinations(slugs.length).map((types) => {
  //       const level = {};
  //       set(level, [...types.flatMap((type) => ['Parent', type]), 'id', '$null'], true);
  //       return level;
  //     }),
  //   );
  // }

  return result;
}

async function getHeader(locale: string) {
  // Note: Now that the Strapi schema is stable, we have switched from using the deep plugin to
  // passing explicit populate params where possible to minimize the size of the next.js page
  // data blob. https://nextjs.org/docs/messages/large-page-data
  const populateLink = {
    populate: {
      page: {
        populate: getPopulateParentQueryParams(),
      },
    },
  };
  const query: StrapiParams = {
    populate: {
      navigationGroups: {
        populate: {
          linksWithAnchors: {
            populate: {
              ...populateLink.populate,
              anchorLinks: '*',
              links: populateLink,
            },
          },
          titleLink: populateLink,
        },
      },
      loginButton: populateLink,
      getStartedButton: populateLink,
      getStartedMobileButton: populateLink,
      searchResultsLink: populateLink,
      updatesButton: populateLink,
    },
    locale,
  };
  const result = await queryFromStrapi(StrapiModel.Header, query);
  return result.attributes;
}

async function getFooter(locale: string) {
  const populateLink = {
    populate: {
      page: {
        populate: getPopulateParentQueryParams(),
      },
    },
  };
  const query: StrapiParams = {
    populate: {
      navigationGroups: {
        populate: {
          linksWithAnchors: {
            populate: {
              ...populateLink.populate,
              anchorLinks: '*',
              links: populateLink,
            },
          },
          titleLink: populateLink,
        },
      },
      socialLinks: {
        populate: '*',
      },
    },
    locale,
  };
  const result = await queryFromStrapi(StrapiModel.Footer, query);
  return result.attributes;
}

async function getLocales(nextLocales: string[] = []) {
  const strapiLocales = await queryFromStrapi(StrapiModel.Locales);
  const locales = nextLocales
    .map((locale) => strapiLocales.find((strapiLocale) => strapiLocale.code === locale))
    .filter(Boolean) as LocaleResultItem[];
  // Filter out 'en', which is included in next.config.js but should never be seen
  // by the user. Deliberately not using `defaultStrapiLocale` because that’s not
  // the intended behavior here - definitely don’t want to filter out `'en-US'`
  // when that becomes the Strapi default.
  return locales.filter((locale) => locale.code !== 'en');
}

export async function getStaticProps(context: GetStaticPropsContext): Promise<GetStaticPropsResult<PageProps>> {
  const { params } = context;
  const locale = isValidLocale(params?.slug?.[0]) ? params?.slug?.[0] : defaultLocale;
  const slugs =
    params?.slug && Array.isArray(params.slug)
      ? isValidLocale(params?.slug?.[0])
        ? params.slug.slice(1)
        : params.slug
      : undefined;

  const strapiQueryDraft = shouldRenderStrapiDraft();

  // TODO (jackyang) change `defaultStrapiLocale` to `defaultLocale` once strapi db migration is complete
  const strapiLocale = (locale && mapAppLocaleToStrapiLocale(locale)) || defaultStrapiLocale;

  let pageType = StrapiModel.Pages;

  const query: StrapiParams = {
    locale: strapiLocale,
    // populate: See https://market.strapi.io/plugins/strapi-plugin-populate-deep
    // Populates up to a maximum depth of 3 by default.
    // We’ve forked the plugin to accept further configuration via a JSON string.
    // `terminals` is an array of Strapi uids that should not be descended past;
    // useful for many-to-many references like integration categories.

    // TODO: since categories are done, not sure what to set terminal to
    populate: [
      'deep',
      JSON.stringify({
        maxDepth: 10,
        terminals: ['api::integration-category.integration-category'],
      }),
    ].join(','),
  };
  if (slugs) {
    // Only support up to 3 levels of nested slugs
    if (slugs.length > 3) {
      return {
        notFound: true,
      };
    }
    const parentSlug = slugs[slugs.length - 2];
    if (parentSlug === 'apps') {
      // TODO could look this up based on IntegrationSettings.appsPageLink
      pageType = StrapiModel.Integrations;
    }
    query.filters = getSlugFilters(slugs, pageType);
  }

  if (strapiQueryDraft) {
    query.publicationState = 'preview';
  }

  try {
    const [pages, header, footer, localeResults, allPages] = await Promise.all([
      queryFromStrapi(pageType, query),
      getHeader(strapiLocale),
      getFooter(strapiLocale),
      getLocales(locales),
      getAvailablePages(),
    ]);

    const page: StrapiPageTypes = pages[0];

    if (!page) {
      return {
        notFound: true,
      };
    }

    const [globals, localizations] = await Promise.all([
      getGlobalsAndDecorateStrapiPage(page, pageType, strapiLocale),
      getPageLocalizations(page, pageType, !!strapiQueryDraft),
    ]);
    convertExternalUrlsToPages([page, header, footer], strapiLocale, allPages);

    return {
      props: {
        content: {
          page: page.attributes,
          pageType,
          globals,
          header,
          footer,
        },
        // Pass all available localizations to the page, so the LocaleSwitcher knows
        // the correct slugs for each locale (slugs are translated).
        localizations,
        locales: localeResults,
      },
    };
  } catch (e) {
    if (e instanceof StrapiApiError && e.status === 404) {
      return {
        notFound: true,
      };
    }
    throw e;
  }
}
