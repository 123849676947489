import { gsap } from 'gsap';
import React, { useRef } from 'react';

import styled from 'styled-components';

import useGsapEffect from '@/hooks/useGsapEffect';
import useIsMobile from '@/hooks/useIsMobile';
import type { LogoShowcaseProps as Props } from '@/utilities/strapi/types/componentTypes';

import ResponsiveImage from '../Elements/ResponsiveImage';
import GradientBackground from '../Hydra/GradientBackground';
import TextEditor from '../TextEditor';
import LineBreakTitle from '../utils/LineBreakTitle';

const StyledLogoShowcase = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 23rem calc(20rem + var(--rem-scale-viewport-half-excess));

  .content {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }

    .logos {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 5.6rem;
      justify-content: center;
      align-items: center;

      @media (max-width: 768px) {
        grid-gap: 4rem;
        margin-top: 4.8rem;

        img {
          max-width: 7.6rem !important;
          height: auto !important;
        }
      }
    }
  }

  @media (max-width: 768px) {
    padding: 12.6rem 2rem !important;
    margin: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }
`;

const Title = styled(LineBreakTitle).attrs({ size: 2 })`
  line-height: 1;
  letter-spacing: -0.06em;
  margin-bottom: ${`${(14 / 17).toFixed(2)}em`};

  span {
    word-wrap: break-word;
  }

  @media (max-width: 768px) {
    margin-bottom: 4.8rem;
    margin-right: 0;
    text-align: left;
  }
`;

const Body = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  column-gap: 10rem;
  justify-content: center;
  max-width: 32rem;

  .text-base {
    &:last-child {
      margin-bottom: 0 !important;
    }
  }

  @media (max-width: 768px) {
    max-width: none;
    grid-auto-columns: 0.9fr !important;
    grid-auto-flow: row;
    justify-content: start;
    margin-bottom: 0;
    margin-top: 0;
    gap: 0;
    float: none;
    position: relative;
    bottom: auto;

    .text-base {
      margin-bottom: 4rem;
    }
  }
`;

export default function LogoShowcase({ title, id, body, logos, backgroundColor }: Props) {
  const sectionId: string = title.replace(/\s+/g, '-').toLowerCase();

  const root = useRef<HTMLDivElement | null>(null);
  const bodyRef = useRef<HTMLDivElement | null>(null);
  const logosRef = useRef<HTMLDivElement | null>(null);
  const tl = useRef<gsap.core.Timeline>();
  const { getIsMobile } = useIsMobile();

  useGsapEffect(root, () => {
    const updateProgress = (progress: number) => {
      const isMobile = getIsMobile();
      const amt = isMobile ? 40 : 40;
      const ta = amt * 0.3;
      gsap.to([bodyRef.current, logosRef.current], { y: `-${ta * progress - ta / 2}vh`, duration: 0.5 });
    };

    tl.current = gsap.timeline({
      scrollTrigger: {
        trigger: root.current,
        start: 'top bottom',
        end: 'bottom',
        scrub: false,
        onUpdate: (self) => updateProgress(self.progress),
      },
    });
  });

  return (
    <div>
      <GradientBackground seed={id} colors={backgroundColor} />
      <StyledLogoShowcase ref={root} id={sectionId}>
        <Title>{title}</Title>
        <div className="content">
          <Body ref={bodyRef}>
            <TextEditor minHeadingLevel={3} {...body} />
          </Body>
          <div ref={logosRef} className="logos">
            {logos.map((logo) => (
              <ResponsiveImage key={logo.id} image={logo} transparent useRetina useRemSize />
            ))}
          </div>
        </div>
      </StyledLogoShowcase>
    </div>
  );
}
