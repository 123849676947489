import type { MediaFormat } from '@/utilities/strapi/types/componentTypes';

const isAbsoluteUrlRegExp = /^(?:[a-z+]+:)?\/\//i;

export default function parseMediaUrl(media?: MediaFormat) {
  if (!media?.url) {
    return media?.url;
  }
  if (isAbsoluteUrlRegExp.test(media.url)) {
    return media.url;
  }

  let path = media.url;
  if (!path.startsWith('/')) {
    path = `/${path}`;
  }

  // TODO update STRAPI_MEDIA_URL_BASE to c14.patreon.com
  return `${process.env.STRAPI_MEDIA_URL_BASE}${path}`;
}
