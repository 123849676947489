const MIN = -2147483648; // Int32 min
const MAX = 2147483647; // Int32 max

function clamp(value: number, min: number, max: number) {
  return Math.min(Math.max(value, Math.min(min, max)), Math.max(min, max));
}

function xorshift(value: number) {
  // Xorshift*32
  // Based on George Marsaglia's work: http://www.jstatsoft.org/v08/i14/paper
  /* eslint-disable no-bitwise */
  value ^= value << 13;
  value ^= value >> 17;
  value ^= value << 5;
  return value;
  /* eslint-enable no-bitwise */
}

export default function getDeterministicRandom(seed = 123, min = 0, max = 1, asInteger = false) {
  let value = xorshift(xorshift(seed * 51241 + 1263534242));

  if (asInteger) {
    max = Math.floor(max) + 1;
  }

  value = ((value - MIN) * (max - min)) / (MAX - MIN) + min;
  value = clamp(value, Math.min(min, max), Math.max(min, max));

  if (asInteger) {
    value = Math.floor(value);
  }

  return value;
}
