import { useEffect, useState } from 'react';

import { useWindowSize } from 'react-use';

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);
  const { width } = useWindowSize();

  const getIsMobile = (width = window.innerWidth) => width <= 768;

  useEffect(() => {
    setIsMobile(getIsMobile(width));
  }, [width]);

  return { isMobile, getIsMobile };
};

export default useIsMobile;
