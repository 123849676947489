import { useEffect } from 'react';

import { logMarketingPageGenericImpression } from '@/analytics/logger/generatedEvents/marketingPageGenericImpression';
import type { StrapiPageAttributes } from '@/utilities/strapi/types/queryResponseTypes';

export default function useMarketingPageGenericImpression(metadata: StrapiPageAttributes['metadata']) {
  useEffect(() => {
    // TODO (jackyang) to make typing work out. Let's make this a required field actually.
    if (metadata?.genericPageDetails) {
      logMarketingPageGenericImpression({ generic_page_details: metadata?.genericPageDetails });
    }
  }, [metadata?.genericPageDetails]);
}
