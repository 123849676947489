import React from 'react';

import { renderStrapiComponent } from '@/components/PageTypes/renderStrapiComponent';
import QRCode from '@/components/QRCode/QRCode';
import ContentContainer from '@/components/utils/ContentContainer';
import useStrapiGlobals from '@/context/StrapiGlobalsContext';
import getHydraNextBus from '@/hydra/next/getHydraNextBus';
import type { StrapiComponent } from '@/utilities/strapi/types/componentTypes';
import type { StrapiPageAttributes } from '@/utilities/strapi/types/queryResponseTypes';

interface Props {
  page: StrapiPageAttributes;
}

function renderComponents(components: StrapiComponent[]) {
  return components
    .map((component) => {
      const rendered = renderStrapiComponent(component);
      if (rendered) {
        const componentKey = `${component.__component}-${component.id}`;
        return (
          <ContentContainer key={componentKey} className={componentKey} component={component}>
            {rendered}
          </ContentContainer>
        );
      }
      return null;
    })
    .filter(Boolean);
}

export default function Page({ page }: Props) {
  const { state } = (
    getHydraNextBus() as {
      next: {
        state: { components?: Array<{ type: string }> };
      };
    }
  ).next;

  state.components = page.Components.map((component) => ({
    type: component.__component,
  }));

  const { siteSettings } = useStrapiGlobals();
  const qrSettings = siteSettings?.QRCode;

  return (
    <>
      {renderComponents(page.Components)}
      {qrSettings && <QRCode {...qrSettings} />}
    </>
  );
}
