import React from 'react';

import styled from 'styled-components';

import type { ComponentBase } from '@/utilities/strapi/types/componentTypes';

const StyledContentContainer = styled.div`
  .heading {
    margin-bottom: 1rem;
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .inline-block {
    display: inline-block;
  }

  .data-output {
    display: block;
    font-size: 0.875rem;
    line-height: 1.25rem;
    white-space: pre-wrap;
  }
`;

export default function StrapiStub(data: ComponentBase) {
  return (
    <StyledContentContainer>
      <h2 className="heading">
        New Strapi component: <strong>{data.__component}</strong>
      </h2>
      <div>
        Create a React component for this then add to <pre className="inline-block">renderStrapiComponent.tsx</pre>.
      </div>
      <p>The following data is available:</p>
      <pre className="data-output">{JSON.stringify(data, null, 2)}</pre>
    </StyledContentContainer>
  );
}
