import * as cheerio from 'cheerio';

import getPopulateParentQueryParams from '@/utilities/strapi/getPopulateParentQueryParams';
import type { StrapiComponent } from '@/utilities/strapi/types/componentTypes';

import type { StrapiMinimalPageInfo } from '@/utilities/strapi/types/queryResponseTypes';

import { queryFromStrapi } from './strapi';
import type { StrapiPage } from './strapi/types/apiResponseTypes';
import { StrapiModel } from './strapi/types/serviceClientTypes';

interface RichText {
  id: number;
  text: string;
}

interface TextObject {
  blocks: TextBlock[];
}

interface TextBlock {
  type: string;
  data: {
    text?: string;
    id?: string | number;
    slug?: string;
    locale?: string;
    title?: string;
    pageData?: StrapiMinimalPageInfo;
  };
}

function isRichText(value: unknown): value is RichText {
  return (
    !!value &&
    typeof value === 'object' &&
    'text' in value &&
    typeof value.text === 'string' &&
    value.text.startsWith('{') &&
    value.text.endsWith('}')
  );
}

function forEachRichText(param: unknown, callback: (arg0: RichText) => unknown) {
  if (isRichText(param)) {
    callback(param);
  } else if (param) {
    if (Array.isArray(param)) {
      const array = param as [];
      array.forEach((value) => {
        forEachRichText(value, callback);
      });
    } else if (typeof param === 'object') {
      const object = param as Record<string, unknown>;
      for (const key in object) {
        if (Object.prototype.hasOwnProperty.call(object, key)) {
          forEachRichText(object[key], callback);
        }
      }
    }
  }
}

export async function replacePageLinkReferences(components: StrapiComponent[] = [], locale: string) {
  const lookFor = ['paragraph', 'EditorjsButton'];

  const getPages = async (ids: number[]) =>
    await queryFromStrapi(StrapiModel.Pages, {
      filters: {
        id: {
          $in: ids,
        },
      },
      fields: ['Slug', 'Title', 'locale'],
      populate: {
        localizations: {
          fields: ['Slug', 'Title', 'locale'],
          ...getPopulateParentQueryParams(),
        },
        ...getPopulateParentQueryParams(),
      },
    });

  const getLinkedPageIds = (components: StrapiComponent[]) => {
    let ids: number[] = [];
    forEachRichText(components, (component) => {
      const blocks: TextObject = JSON.parse(component.text) as TextObject;
      const textBlocks: TextBlock[] = blocks.blocks;

      textBlocks.forEach((block) => {
        if (!lookFor.includes(block.type) || !block.data.text) {
          return;
        }
        if (block.type === 'paragraph') {
          const $ = cheerio.load(block.data.text);
          const links = $('a[data-id]');

          ids = [
            ...ids,
            ...links.map((_i, el) => {
              const dataId = $(el).attr('data-id');
              return typeof dataId === 'string' ? parseInt(dataId, 10) : dataId;
            }),
          ];
        } else if (block.type === 'EditorjsButton') {
          if (block.data.id) {
            ids.push(+block.data.id);
          }
        }
      });
    });
    return [...new Set(ids)];
  };

  const parseLinks = (text: string, pages: StrapiPage[]) => {
    const $ = cheerio.load(text);
    const links = $('a[data-id]');
    links.each((i, el) => {
      const dataId = $(el).attr('data-id');
      if (typeof dataId !== 'string') {
        return;
      }
      const id = parseInt(dataId, 10);
      const pageData = pages.find((page) => page.id === id);

      if (!pageData) {
        return;
      }

      const localized =
        pageData.attributes.localizations?.data?.find?.((l) => l.attributes.locale === locale) || pageData;

      links
        .eq(i)
        .attr('data-slug', localized.attributes.Slug)
        .attr('data-locale', localized.attributes.locale)
        .attr('data-title', localized.attributes.Title)
        .attr('data-page-data', JSON.stringify(localized.attributes));
    });
    return $('body').html() || undefined;
  };

  const parseButton = (data: TextBlock['data'], pages: StrapiPage[]) => {
    if (data.id) {
      data.id = +data.id;
      const pageData = pages.find((page) => page.id === data.id);
      if (pageData) {
        const localized =
          pageData.attributes.localizations?.data?.find?.((l) => l.attributes.locale === locale) || pageData;
        data.slug = localized.attributes.Slug;
        data.locale = localized.attributes.locale;
        data.title = localized.attributes.Title;
        data.pageData = localized.attributes;
      }
    }
    return data;
  };

  const parseBlock = (block: TextBlock, pages: StrapiPage[]) => {
    if (lookFor.includes(block.type) && block.data.text) {
      if (block.type === 'paragraph') {
        block.data.text = parseLinks(block.data.text, pages);
      } else if (block.type === 'EditorjsButton') {
        block.data = parseButton(block.data, pages);
      }
    }
    return block;
  };

  const parseComponents = (components: StrapiComponent[], pages: StrapiPage[]) => {
    forEachRichText(components, (component) => {
      const textObject: TextObject = JSON.parse(component.text) as TextObject;
      if (!textObject.blocks) {
        return;
      }
      const blocks = textObject.blocks.map((block) => parseBlock(block, pages));
      component.text = JSON.stringify({ ...textObject, blocks });
    });
  };

  const ids = getLinkedPageIds(components);
  if (ids.length) {
    const pages = await getPages(ids);
    parseComponents(components, pages);
  }
}
