import type { IntegrationMediaGalleryProps } from '@/components/Integrations/IntegrationMediaGallery';
import type { IntegrationMetaProps } from '@/components/Integrations/IntegrationMeta';
import type { IntegrationNewsTeasersProps } from '@/components/Integrations/IntegrationNewsTeasers';
import type { SocialLink } from '@/components/SocialNavigation/types';

import type { StrapiDataArray, StrapiDataObject } from './apiResponseTypes';
import type {
  AppStoreLinks,
  CallToActionButton,
  ComponentBase,
  QRCode,
  StrapiComponent,
  StrapiMedia,
  TextEditorProps,
  UtilsOneColumnText,
} from './componentTypes';
import type { Link, NavigationGroup } from './utilsTypes';

export interface StrapiPageParent {
  id?: number;
  page?: StrapiDataObject<{
    Slug: string;
    Parent?: StrapiPageParent;
  }>;
  url_segment?: StrapiDataObject<{
    Slug: string;
    Parent?: StrapiPageParent;
  }>;
  urlPrefixFallback?: string;
}

export interface StrapiMinimalPageInfo {
  id?: number;
  Slug: string;
  Title?: string;
  locale?: string;
  metadata?: {
    id: string;
    menuTitle?: string;
    sitemapPriority?: number | null;
    metaTitle?: string;
    metaDescription?: string;
    openGraphType?: string;
    openGraphImage?: StrapiMedia;
    twitterCardType?: string;
    twitterCreator?: string;
    // TODO (jackyang) this may need to be required in the future, for now this is fine.
    genericPageDetails?: string;
  };
  Parent?: StrapiPageParent;
}

export interface StrapiLocalizable extends StrapiMinimalPageInfo {
  locale: string;
  localizations: StrapiDataArray<StrapiPageAttributes>;
}

export interface StrapiPageAttributes extends StrapiLocalizable {
  id: number;
  Components: StrapiComponent[];
}

export interface StrapiIntegrationSettingsAttributes extends StrapiLocalizable {
  categoriesLabel: string;
  appsPageLink: Link;
}

export interface StrapiIntegrationAttributes extends StrapiLocalizable {
  id: number;
  Meta?: IntegrationMetaProps;
  shortDescription?: string;
  longDescription?: UtilsOneColumnText;
  mediaGallery?: IntegrationMediaGalleryProps & ComponentBase;
  pricingAndPerks?: UtilsOneColumnText;
  newsTeasers?: IntegrationNewsTeasersProps & ComponentBase;
  shouldLinkExternally?: boolean;
}

export type StrapiPageTypesAttributes = StrapiPageAttributes | StrapiIntegrationAttributes;

export enum StrapiSocialShareServiceType {
  email = 'email',
  facebook = 'facebook',
  instagram = 'instagram',
  linkedin = 'linkedin',
  twitter = 'twitter',
  youtube = 'youtube',
}

export interface StrapiSocialShareService {
  Title: string;
  Service: StrapiSocialShareServiceType;
}

export interface StrapiSiteSettingsAttributes {
  openGraphImage?: StrapiMedia | undefined;
  siteTitle?: string;
  defaultTwitterCardType?: string;
  defaultTwitterCreator?: string;
  defaultOpenGraphType?: string;
  defaultOpenGraphImage?: StrapiMedia;
  getStartedCTAModal?: TextEditorProps;
  socialShareServices?: StrapiSocialShareService[];
  QRCode?: QRCode;
  appStoreLinks: AppStoreLinks;
  skipToContentLabel: string;
}

export interface StrapiHeaderAttributes {
  navigationGroups: NavigationGroup[];
  searchPrompt?: string;
  loginButton?: CallToActionButton;
  getStartedButton?: CallToActionButton;
  updatesButton?: CallToActionButton;
  getStartedMobileButton?: CallToActionButton;
  searchResultsLink?: Link;
}

export interface StrapiFooterAttributes {
  navigationGroups: NavigationGroup[];
  streetAddress: string;
  country: string;
  city: string;
  state: string;
  zipCode: string;
  phoneNumber: string;
  socialLinks?: SocialLink[];
}
