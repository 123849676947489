import clsx from 'clsx';
import React, { useEffect, useRef } from 'react';
import { useClickAway } from 'react-use';
import styled from 'styled-components';

import { useHeaderState, useHeaderStateSnapshot } from '@/components/Header/headerState';
import useMegaHoverState from '@/components/Header/useMegaHoverState';
import TextWithRightArrow from '@/components/utils/TextWithRightArrow';
import type { NavigationGroup } from '@/utilities/strapi/types/utilsTypes';

import StrapiAnchorLink from '../utils/StrapiAnchorLink';
import StrapiLink from '../utils/StrapiLink';

interface Props {
  navigationGroups: NavigationGroup[];
  closeCallback?: () => void;
}

export type Ref = HTMLDivElement;

export default function MegaDropdown({ navigationGroups, closeCallback }: Props) {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const headerState = useHeaderState();
  const { activeMegaMenuId, clickedMegaMenuId } = useHeaderStateSnapshot();
  const { handleWrapper1Enter, handleWrapper1Leave } = useMegaHoverState();

  useClickAway(wrapperRef, (event: Event) => {
    handleWrapper1Leave();
    // Close menu if it was opened via click or keyboard, but only if the click
    // wasn't on one of the buttons to open this (or another) mega nav
    const navItem = (event.target as Element)?.closest('.nav-item');
    if (!navItem) {
      headerState.clickedMegaMenuId = null;
    }
  });

  useEffect(() => {
    if (clickedMegaMenuId) {
      const keydownHandler = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          headerState.clickedMegaMenuId = null;
        }
      };
      document.addEventListener('keydown', keydownHandler);
      return () => {
        document.removeEventListener('keydown', keydownHandler);
      };
    }
    return;
  }, [clickedMegaMenuId, headerState]);

  return (
    <StyledMegaDropdown className={clsx({ active: activeMegaMenuId })}>
      <Backdrop />
      <div
        ref={wrapperRef}
        className="menu-wrapper"
        onMouseEnter={handleWrapper1Enter}
        onMouseLeave={handleWrapper1Leave}
      >
        <div className="spacer" />
        {navigationGroups.map((group) => (
          <div
            id={`mega-nav-list-${group.id}`}
            key={group.id}
            className={clsx('section-wrapper', { active: group.id === activeMegaMenuId })}
            aria-hidden={group.id !== activeMegaMenuId}
            tabIndex={-1}
            inert={group.id !== activeMegaMenuId ? '' : undefined}
          >
            <ul className="section-nav-list">
              {group.linksWithAnchors.map(({ text, ...link }) => (
                <li key={link.id}>
                  <StrapiLink {...link} className="nav-link" callback={closeCallback} useChildren>
                    <TextWithRightArrow animateOnHover>{text}</TextWithRightArrow>
                  </StrapiLink>
                  <ul>
                    {link.anchorLinks.map((anchorLink) => (
                      <li key={anchorLink.id} className="anchor-nav-item">
                        <StrapiAnchorLink {...link} {...anchorLink} callback={closeCallback} className="anchor-link" />
                      </li>
                    ))}
                    {link.links.map((subLink) => (
                      <li key={subLink.id} className="anchor-nav-item">
                        <StrapiLink {...subLink} className="anchor-link" callback={closeCallback} />
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </StyledMegaDropdown>
  );
}

MegaDropdown.displayName = 'MegaDropdown';

const Backdrop = styled.div`
  position: absolute;
  top: -1.3rem;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--color-white);
  pointer-events: none;
`;

const StyledMegaDropdown = styled.nav`
  width: 100%;
  height: auto;
  --mega-padding-top: 9.5rem;
  --mega-padding-right: max(env(safe-area-inset-right), var(--spacing-lg));
  --mega-padding-bottom: var(--spacing-base);
  --mega-padding-left: max(env(safe-area-inset-left), var(--spacing-lg));
  --mega-padding: var(--mega-padding-top) var(--mega-padding-right) var(--mega-padding-bottom) var(--mega-padding-left);
  padding: var(--mega-padding);
  z-index: -1;

  position: absolute;
  top: 0;
  transform: translateY(-25%);
  transition-property: transform, opacity, visibility;
  transition-duration: var(--animation-duration-fast);
  transition-timing-function: var(--animation-timing);
  opacity: 0;
  visibility: hidden;

  &.active {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }

  .menu-wrapper {
    position: relative;
    margin: 0 calc(var(--mega-padding-right) * -1) calc(var(--mega-padding-bottom) * -1)
      calc(var(--mega-padding-left) * -1);
  }

  .spacer {
    width: 100%;
    height: var(--spacing-lg);
  }

  .section-wrapper {
    height: 0;
    overflow: hidden;
    opacity: 0;
    transform: translateY(calc(var(--spacing-base) * -1));
    transition: transform var(--animation-duration-slow) var(--animation-timing),
      opacity var(--animation-duration-fast) var(--animation-timing);

    &:focus-visible {
      outline: none;
    }

    &.active {
      height: auto;
      opacity: 1;
      transform: translateY(0rem);
      margin-top: calc(var(--spacing-lg) * -1);
    }
  }

  .section-nav-list {
    display: grid;
    gap: var(--spacing-lg);
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    padding: var(--spacing-lg) var(--mega-padding-right) var(--mega-padding-bottom) var(--mega-padding-left);

    > li {
      max-width: 40rem;
    }
  }

  .nav-link {
    display: inline-block;
    font: var(--font-label-3);
    margin-bottom: var(--spacing-lg);
    position: relative;

    &:focus-visible {
      .hoverable {
        .arrow {
          transform: translateX(66%);
        }
      }
    }
  }

  .anchor-nav-item {
    color: var(--color-action-muted);
    font: var(--font-label-1);
    margin-bottom: 1.6rem;
  }

  .anchor-link {
    font: var(--font-mega-nav-anchor);
    letter-spacing: -0.016rem;
    transition: color var(--animation-duration-fast) var(--animation-timing);
    display: inline-block;

    &:hover,
    &:focus-visible {
      color: var(--color-action);
    }
  }
`;
