import React from 'react';

import type { WordProps } from '@/components/utils/SplitLines';
import SplitLines from '@/components/utils/SplitLines';

import { WordWithRightArrow } from '@/components/utils/TextWithRightArrow';

interface Props {
  children: string;
  animateOnHover?: boolean;
}

export default function SplitLinesWithRightArrow({ children, animateOnHover }: Props) {
  const renderWord = (props: WordProps, index: number, words: string[]) => {
    if (index < words.length - 1) {
      return <span {...props} />;
    }
    return <WordWithRightArrow animateOnHover={animateOnHover} {...props} />;
  };

  return <SplitLines renderWord={renderWord}>{children}</SplitLines>;
}
