import clsx from 'clsx';
import { gsap } from 'gsap';
import { useRouter } from 'next/router';
import type { RefObject } from 'react';
import React, { useRef } from 'react';

import styled, { css } from 'styled-components';

import Video from '@/components/Elements/Video';
import GradientBackground from '@/components/Hydra/GradientBackground';
import TextEditor from '@/components/TextEditor';
import useGsapEffect from '@/hooks/useGsapEffect';
import useIsMobile from '@/hooks/useIsMobile';
import type {
  FloatingProductUIProps as Props,
  MediaWithMeta,
  TextEditorProps,
} from '@/utilities/strapi/types/componentTypes';
import { FloatingProductUILayout } from '@/utilities/strapi/types/componentTypes';

import LineBreakTitle from '../utils/LineBreakTitle';

const StyledWrapper = styled.div`
  &.mobile-only {
    display: none;
  }

  @media (max-width: 768px) {
    &.desktop-only {
      display: none;
    }

    &.mobile-only {
      display: block;
    }
  }
`;

const StyledFloatingProductUI = styled.div`
  position: relative;
  width: 100%;

  .split-layout-adjust {
    visibility: hidden;
    display: flex;

    @media (max-width: 768px) {
      display: none;
    }

    > div {
      width: 100%;

      > h2 {
        position: relative;
        height: auto;

        span:last-child {
          display: none;
        }
      }

      > div {
        position: relative;
      }
    }
  }
`;

const Content = styled.div<{ $layout: FloatingProductUILayout }>`
  overflow: hidden;

  ${({ $layout }) => {
    switch ($layout) {
      case FloatingProductUILayout.Right:
        return css`
          padding: 16rem calc(17rem + var(--rem-scale-viewport-half-excess));

          &.wide {
            padding: 16rem max(env(safe-area-inset-right), calc(5rem + var(--rem-scale-viewport-half-excess))) 16rem
              max(env(safe-area-inset-left), calc(5rem + var(--rem-scale-viewport-half-excess)));
          }
        `;
      case FloatingProductUILayout.TopRight:
        return css`
          padding: 16rem calc(17rem + var(--rem-scale-viewport-half-excess));

          &.wide {
            padding: 16rem max(env(safe-area-inset-right), calc(5rem + var(--rem-scale-viewport-half-excess))) 16rem
              max(env(safe-area-inset-left), calc(5rem + var(--rem-scale-viewport-half-excess)));
          }

          // Push the floated product UI downwards on desktop layouts, to fit
          // one line of the title above the product UI before wrapping
          &::before {
            content: '';
            display: block;
            width: 0;
            height: calc(var(--font-size-h2) * 1.5);
            float: right;
            @media (max-width: 768px) {
              content: none;
            }
          }
        `;
      case FloatingProductUILayout.LeftBottom:
        return css`
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: flex-end;
          padding: 25rem calc(22rem + var(--rem-scale-viewport-half-excess));
        `;
      case FloatingProductUILayout.RightLeft:
        return css`
          padding: 23rem calc(15rem + var(--rem-scale-viewport-half-excess));
        `;
      case FloatingProductUILayout.SplitCenter:
        return css`
          margin: 23rem calc(20rem + var(--rem-scale-viewport-half-excess));
          position: relative;
          overflow: initial;
        `;
      default:
      case FloatingProductUILayout.TopLeftTight:
        return css`
          padding: 23rem calc(20rem + var(--rem-scale-viewport-half-excess));

          // Push the floated product UI downwards on desktop layouts, to fit
          // one line of the title above the product UI before wrapping
          &::before {
            content: '';
            display: block;
            width: 0;
            height: calc(var(--font-size-h2) * 1.5);
            float: left;
            @media (max-width: 768px) {
              content: none;
            }
          }
        `;
    }
  }};

  @media (max-width: 768px) {
    padding: 12.6rem max(env(safe-area-inset-right), 2rem) 12.6rem max(env(safe-area-inset-left), 2rem) !important;
    margin: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }
`;

const Title = styled(LineBreakTitle).attrs({ size: 2, align: 'left' })<{
  $layout: FloatingProductUILayout;
  ref?: RefObject<HTMLHeadingElement>;
}>`
  line-height: 1;
  letter-spacing: -0.06em;

  span {
    word-wrap: break-word;
  }

  ${({ $layout }) => {
    switch ($layout) {
      case FloatingProductUILayout.Right:
        return css`
          margin-bottom: ${`${(7 / 17).toFixed(2)}em`};
        `;
      case FloatingProductUILayout.LeftBottom:
        return css`
          margin-bottom: ${`${(10 / 17).toFixed(2)}em`};
          margin-right: 10rem;
          flex: 2.4 2.4 0;
          text-align: right;
          @media (max-width: 768px) {
            flex: unset;
            text-align: right;
          }
        `;
      case FloatingProductUILayout.RightLeft:
        return css`
          margin-bottom: ${`${(14 / 17).toFixed(2)}em`};

          // Push the floated product UI downwards on desktop layouts, to fit
          // one line of the title above the product UI before wrapping
          &::after {
            content: '';
            display: block;
            width: 0.8em;
            height: calc(var(--font-size-h2));
            float: left;
            display: flex;
            align-items: flex-end;
            @media (max-width: 768px) {
              content: none;
            }
          }

          span {
            min-width: 50%;
          }

          @media (max-width: 768px) {
            span {
              min-width: 100%;
            }
          }
        `;
      case FloatingProductUILayout.TopLeftTight:
        return css`
          margin-bottom: ${`${(10 / 17).toFixed(2)}em`};
        `;
      case FloatingProductUILayout.TopRight:
        return css`
          margin-bottom: ${`${(14 / 17).toFixed(2)}em`};

          span {
            &:first-child {
              text-indent: 37rem;
            }
          }
        `;
      default:
        return css`
          margin-bottom: ${`${(14 / 17).toFixed(2)}em`};
        `;
    }
  }};

  @media (max-width: 768px) {
    margin-bottom: 4rem;
    margin-right: 0;
    text-align: left;

    span {
      text-indent: 0 !important;
      display: inline;
    }
  }
`;

const SplitUITitle = styled(LineBreakTitle).attrs({ size: 2, align: 'left', autoSplit: true })<{
  $layout: FloatingProductUILayout;
  ref?: RefObject<HTMLHeadingElement>;
}>`
  line-height: 1;
  letter-spacing: -0.06em;
  bottom: auto;
  pointer-events: auto;
  color: inherit;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  padding: 0;
  position: absolute;

  span {
    max-width: 57rem;
    word-wrap: break-word;
    line-height: 75%;

    &:last-child {
      position: absolute;
      right: 0;
      bottom: 0;
      text-align: right;
    }
  }

  ${({ $layout }) => {
    switch ($layout) {
      default:
        return css`
          margin-bottom: 14rem;
        `;
    }
  }};

  @media (max-width: 768px) {
    margin-bottom: 4rem;
    margin-right: 0;
    text-align: left;
    position: relative;
    top: initial;
    left: initial;
    bottom: initial;
    height: auto;

    span {
      display: inline;
      max-width: none;

      &:last-child {
        position: relative;
        text-align: left;
      }
    }
  }
`;

const StyledCopyColumn = styled.div`
  > *:last-child,
  .text-base:last-child {
    margin-bottom: 0;
  }

  img {
    margin-bottom: 2rem;
  }

  .text-base {
    @media (max-width: 768px) {
      letter-spacing: -0.03rem;
    }
  }

  .strapi-link.button {
    @media (max-width: 768px) {
      padding: 1.3rem 1.4rem;
    }
  }

  .heading {
    font-size: 3.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: -0.072rem;
    margin-bottom: 0.8em;

    @media (max-width: 768px) {
      font-size: 3rem;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      letter-spacing: -0.06rem;
      margin-bottom: 0.6em;
    }
  }
`;

function CopyColumn(props: TextEditorProps) {
  return (
    <StyledCopyColumn>
      <TextEditor minHeadingLevel={3} {...props} />
    </StyledCopyColumn>
  );
}

const CopyColumns = styled.div<{ $layout: FloatingProductUILayout; alignColumns?: 'left' | 'center' | 'right' }>`
  display: grid;

  ${({ $layout, alignColumns }) => {
    switch ($layout) {
      case FloatingProductUILayout.LeftBottom:
        return css`
          flex: 1 1 0;
          grid-auto-flow: row;
          margin-bottom: 10rem;
          > ${StyledCopyColumn}:not(:last-child) {
            margin-bottom: 4rem;
          }
        `;
      case FloatingProductUILayout.TopLeftTight:
        return css`
          grid-auto-columns: 1fr;
          grid-auto-flow: row;
          gap: 1.3em;
          justify-content: start;
          margin-bottom: 12rem;
          max-width: 42rem;
        `;
      case FloatingProductUILayout.RightLeft:
        return css`
          grid-auto-columns: 0.65fr;
          grid-auto-flow: row;
          column-gap: 10rem;
          justify-content: ${alignColumns === 'left' ? 'start' : 'center'};

          margin-bottom: 0;

          > ${StyledCopyColumn}:not(:last-child) {
            margin-bottom: 4rem;
          }

          &.single {
            grid-auto-columns: 0.52fr;
          }
        `;
      case FloatingProductUILayout.SplitCenter:
        return css`
          max-width: 45rem;
          grid-auto-columns: 1fr;
          grid-auto-flow: row;
          gap: 1.3em;
          justify-content: start;
          margin-bottom: 0;
          position: absolute;
          bottom: 0;
        `;
      case FloatingProductUILayout.Right:
        return css`
          grid-auto-columns: 0.65fr;
          grid-auto-flow: column;
          column-gap: 10rem;
          justify-content: ${alignColumns === 'left' ? 'start' : 'center'};
          margin-bottom: 12rem;

          &.single {
            grid-auto-columns: 0.52fr;
          }

          &.wide {
            grid-auto-columns: 1fr;

            &:not(.cols) {
              max-width: 50rem;
            }
          }
        `;
      case FloatingProductUILayout.TopRight:
        return css`
          grid-auto-columns: 0.65fr;
          grid-auto-flow: row;
          column-gap: 10rem;
          justify-content: flex-start;
          margin-bottom: 12rem;

          > ${StyledCopyColumn} {
            max-width: 40rem;
            position: relative;
            left: 0.5em;

            &:last-child {
              margin-top: 11rem;
            }
          }

          &.single {
            grid-auto-columns: 1fr;

            > ${StyledCopyColumn} {
              left: 15rem;

              &:last-child {
                left: 45rem;
              }
            }
          }
        `;
      default:
        return css`
          grid-auto-columns: 0.65fr;
          grid-auto-flow: column;
          column-gap: 10rem;
          justify-content: center;

          margin-bottom: 12rem;

          &.single {
            grid-auto-columns: 0.52fr;
          }
        `;
    }
  }};

  @media (max-width: 768px) {
    grid-auto-columns: 0.9fr !important;
    grid-auto-flow: row;
    justify-content: start;
    margin-bottom: 0;
    margin-top: 0;
    gap: 0;
    float: none;
    position: relative;
    bottom: auto;
    > ${StyledCopyColumn} {
      margin-bottom: 4rem;
      left: 0 !important;
      margin-top: 0 !important;
    }
  }
`;

const ProductUI = styled.div<{ addBoxShadow?: boolean }>`
  overflow: hidden;
  border-radius: 4rem;

  ${({ addBoxShadow }) =>
    addBoxShadow &&
    css`
      border: 7px solid rgba(255, 255, 255, 0.25);
      box-shadow: 30px 30px 30px 0px rgba(4, 64, 80, 0.3);
    `}

  video {
    width: 45rem !important;
  }

  &.transparent {
    border-radius: 0;
  }

  & > * {
    width: 100%;
    height: auto !important;
  }

  &:last-child {
    margin-top: 20rem;
    margin-left: -3.7rem;
  }

  &.single {
    &.tall {
      width: 43rem;
    }

    &:last-child {
      margin-top: 0;
      margin-left: 0;
    }
  }

  @media (max-width: 768px) {
    border-radius: 3.2rem;

    &.single {
      width: 35rem !important;
    }

    &:last-child {
      margin-top: 0;
      margin-left: 0;
    }

    &:nth-child(2) {
      margin-top: 2.4rem;
    }
  }
`;

const ProductUIs = styled.div<{ $layout: FloatingProductUILayout }>`
  display: flex;
  flex-wrap: nowrap;
  max-width: 70rem;

  ${({ $layout }) => {
    switch ($layout) {
      case FloatingProductUILayout.Right:
        return css`
          float: right;
          margin-left: 8rem;

          &.wide {
            max-width: 95rem;
            margin-bottom: 10rem;
          }
        `;
      case FloatingProductUILayout.LeftBottom:
        return css`
          float: none;
          width: 100%;
          max-width: none;
          order: 2;

          > ${ProductUI} {
            &,
            &.wide img {
              width: 100% !important;
            }

            &:nth-child(2) {
              margin-left: 6.8rem;
              margin-top: 0;
            }

            &.tall {
              &:not(.single) {
                width: 30.1% !important;

                @media (max-width: 768px) {
                  width: 100% !important;
                }
              }
            }

            @media (max-width: 768px) {
              &,
              &.wide,
              &.tall {
                width: 100% !important;
                margin-left: 0;
                margin-bottom: 4rem;

                &:last-child {
                  margin-bottom: 0;
                }
              }
            }
          }
        `;
      case FloatingProductUILayout.RightLeft:
        return css`
          float: left;
          clear: left;
          margin-left: 8rem;
          margin-right: 12rem;
          min-height: 85rem;
        `;
      case FloatingProductUILayout.TopLeftTight:
        return css`
          float: left;
          clear: left;
          width: calc(50% - 6rem);
          margin-right: 12rem;
          > ${ProductUI} {
            float: right;

            &.single {
              margin-left: auto !important;
            }
          }
        `;
      case FloatingProductUILayout.TopRight:
        return css`
          float: right;
          clear: right;
          width: auto;
          max-width: none;

          &.single {
            width: calc(50% - 6rem);
            margin-right: 12rem;
            max-width: 70rem;
          }

          > ${ProductUI} {
            float: left;

            &.single {
              margin-left: auto !important;
            }
          }
        `;
      case FloatingProductUILayout.SplitCenter:
        return css`
          float: none;
          flex-direction: column;
          max-width: none;
          position: absolute;
          width: 100%;

          &.single.wide {
            min-height: 60rem;
          }

          > ${ProductUI} {
            margin: 0 auto 2.6rem auto;
            max-width: 35rem;

            &.single {
              margin: 0 auto;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        `;
      default:
        return css`
          float: left;
          clear: left;
          margin-left: 10rem;
          margin-right: 14rem;
          min-height: 60rem;
        `;
    }
  }};

  @media (max-width: 768px) {
    float: none;
    margin-left: 0 !important;
    margin-right: 0;
    margin-bottom: 0 !important;
    min-height: 0;
    max-width: none !important;
    position: relative;
    order: 2;
    width: 100%;
    display: block;
  }
`;

const Background = styled(GradientBackground)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`;

function getImageAspectRatio(media: MediaWithMeta) {
  const strapiMedia = media.media?.data?.attributes;
  if (!strapiMedia) {
    return null;
  }
  return strapiMedia.width && strapiMedia.height ? strapiMedia.width / strapiMedia.height : null;
}

export default function FloatingProductUI({
  id,
  Title: title,
  ProductUI: items,
  Layout,
  Column1,
  Column2,
  anchorId,
  backgroundColor,
  formFactor,
  alignColumns,
}: Props) {
  const productUiRefs = useRef<HTMLDivElement[] | null[]>([]);
  const titleRef = useRef<HTMLHeadingElement>(null);
  const bodyRef = useRef<HTMLDivElement>(null);
  const colors = items.map((ui) => ui.media?.data?.attributes.colors).filter(Boolean);
  const bgColors = backgroundColor || colors[Math.min(3, colors.length - 1)];
  const data = {
    type: 'FloatingProductUI',
    aspects: items.map(getImageAspectRatio),
  };
  const $layout = Layout || FloatingProductUILayout.TopLeft;
  const single = items.length === 1;
  const wide =
    items[0].media?.data?.attributes?.mime?.startsWith('image') &&
    (items[0].media?.data?.attributes?.width || 0) >= (items[0].media?.data?.attributes?.height || 0);

  const root = useRef<HTMLDivElement>(null);
  const tl = useRef<gsap.core.Timeline>();
  const { getIsMobile } = useIsMobile();

  const router = useRouter();

  // Time crunch hack!
  // TODO: clean this up and add a box-shadow property to the Product UI field in strapi
  const isUpdatesPage = router?.asPath.includes('/updates');

  useGsapEffect(root, () => {
    const updateProgress = (progress: number) => {
      const isMobile = getIsMobile();
      const amt = isMobile ? 40 : 40;
      items.forEach((_ui, i) => {
        if (isMobile) {
          gsap.set(productUiRefs.current[i], { y: 0 });
          return;
        }
        const stagger = Layout === FloatingProductUILayout.LeftBottom ? 1 : 1 + 0.15 * i;
        gsap.to(productUiRefs.current[i], {
          y: `${-amt * stagger * progress + amt / 2}%`,
          duration: 0.5 + (i + 1) * 0.15,
        });
      });

      if (isMobile) {
        gsap.set(bodyRef.current, { y: 0 });
        return;
      }

      const ta = amt * 0.3;
      gsap.to(bodyRef.current, { y: `${ta * progress - ta / 2}vh`, duration: 0.5 });
    };

    tl.current = gsap.timeline({
      scrollTrigger: {
        trigger: root.current,
        start: 'top bottom',
        end: 'bottom',
        scrub: false,
        onUpdate: (self) => updateProgress(self.progress),
      },
    });
  });

  return (
    <StyledWrapper
      id={anchorId?.anchorId}
      className={clsx([formFactor && formFactor?.screenSize?.toLowerCase().replace(/\s/g, '-')])}
    >
      <Background seed={id} colors={bgColors} />
      <StyledFloatingProductUI ref={root} data-hydra={JSON.stringify(data)}>
        <Content $layout={$layout} className={clsx({ wide })}>
          <ProductUIs
            $layout={$layout}
            className={clsx({
              single,
              wide,
            })}
          >
            {items.map((media, i) => (
              <ProductUI
                addBoxShadow={isUpdatesPage}
                ref={(r) => (productUiRefs.current[i] = r)}
                key={media.id}
                className={clsx(
                  media.media?.data?.attributes?.mime?.startsWith('image') &&
                    ((media.media?.data?.attributes?.width || 0) <= (media.media?.data?.attributes?.height || 0)
                      ? 'tall'
                      : 'wide'),
                  { single, transparent: media.media?.data?.attributes?.ext?.toLowerCase() === '.png' },
                )}
              >
                <Video media={media} useRetina useRemSize loop muted transparent />
              </ProductUI>
            ))}
          </ProductUIs>
          {$layout === FloatingProductUILayout.SplitCenter ? (
            <SplitUITitle ref={titleRef} $layout={$layout}>
              {title}
            </SplitUITitle>
          ) : (
            <Title ref={titleRef} $layout={$layout}>
              {title}
            </Title>
          )}
          <CopyColumns
            ref={bodyRef}
            $layout={$layout}
            className={clsx({ single, wide, cols: !!Column1 && !!Column2 })}
            alignColumns={alignColumns}
          >
            <CopyColumn {...Column1} />
            {Column2 && <CopyColumn {...Column2} />}
          </CopyColumns>

          {/* For split title layout, duplicate the content without absolute positioning so the layout can properly size behind the real content */}
          {$layout === FloatingProductUILayout.SplitCenter && (
            <div className="split-layout-adjust" aria-hidden>
              <div>
                <SplitUITitle $layout={$layout}>{title}</SplitUITitle>
                <CopyColumns $layout={$layout} className={clsx({ single, wide, cols: !!Column1 && !!Column2 })}>
                  <CopyColumn {...Column1} />
                  {Column2 && <CopyColumn {...Column2} />}
                </CopyColumns>
              </div>
              <div>
                <ProductUIs
                  $layout={$layout}
                  className={clsx({
                    single,
                    wide,
                  })}
                >
                  {items.map((media) => (
                    <ProductUI
                      key={media.id}
                      className={clsx(
                        media.media?.data?.attributes?.mime?.startsWith('image') &&
                          ((media.media?.data?.attributes?.width || 0) <= (media.media?.data?.attributes?.height || 0)
                            ? 'tall'
                            : 'wide'),
                        { single, transparent: media.media?.data?.attributes?.ext?.toLowerCase() === '.png' },
                      )}
                    >
                      <Video media={media} useRetina useRemSize muted transparent disableIntersectionObserver />
                    </ProductUI>
                  ))}
                </ProductUIs>
              </div>
            </div>
          )}
        </Content>
      </StyledFloatingProductUI>
    </StyledWrapper>
  );
}
