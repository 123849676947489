import React from 'react';

import styled from 'styled-components';

import getIntegrationLinkProps from '@/components/Integrations/getIntegrationLinkProps';
import type { ImageWithMeta, IntegrationPromoTilesDirectoryProps } from '@/utilities/strapi/types/componentTypes';

import ResponsiveImage from '../Elements/ResponsiveImage';
import StrapiLink from '../utils/StrapiLink';

const IntegrationPromoTilesDirectory = ({
  title,
  body,
  integrationsById,
  anchorId,
}: IntegrationPromoTilesDirectoryProps) => (
  <StyledWrapper id={anchorId?.anchorId}>
    <div className="promo-tiles-head">
      <h1 className="promo-tiles-title">{title}</h1>
      <p className="promo-tiles-body">{body}</p>
    </div>
    <div className="promo-tiles-grid">
      {Object.entries(integrationsById).map(([id, integration]) => {
        const { Title, shortDescription, Meta } = integration;
        const linkProps = getIntegrationLinkProps(integration);
        return (
          <StrapiLink className="promo-tile" key={id} {...linkProps}>
            <div className="promo-tile-img-wrap">
              {Meta?.featureImage ? (
                <ResponsiveImage
                  image={Meta.featureImage as ImageWithMeta}
                  className="promo-tile-img"
                  sizes="(max-width: 768px) 50vw, 250px"
                />
              ) : (
                <div className="promo-tile-placeholder" />
              )}
            </div>
            <div className="promo-tile-body">
              <div className="promo-tile-title">{Title}</div>
              <div className="promo-tile-desc">{shortDescription}</div>
            </div>
          </StrapiLink>
        );
      })}
    </div>
  </StyledWrapper>
);

const StyledWrapper = styled.div`
  --font-body-light: 300 max(11px, 1.6rem) / 140% var(--font-family-heading);

  /* TODO: use rem */
  padding-top: 72px;

  display: grid;
  width: 100%;
  grid-gap: 5rem 0rem;
  max-width: 1170px;
  padding-left: 10rem;
  padding-right: 10rem;
  width: 100%;
  margin: 0 auto;

  .promo-tiles-head {
    grid-column: 1 / 3;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }

  .promo-tiles-title {
    font: var(--font-h5);
    letter-spacing: -0.05em;
    margin-bottom: 0.8rem;
  }

  .promo-tiles-body {
    font: var(--font-body-light);
  }

  .promo-tiles-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 1.7rem;
  }

  .promo-tile {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .promo-tile-img-wrap {
    position: relative;
    height: 0;
    padding-top: 100%;
    width: 100%;
    border-radius: 3rem;
    overflow: hidden;
  }

  .promo-tile-img {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .promo-tile-placeholder {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #000;
    border-radius: 8%;
    top: 0;
  }

  .promo-tile-body {
    padding: 2rem 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .promo-tile-title {
    font: var(--font-label-1);
    margin-bottom: 0.4rem;
  }

  .promo-tile-desc {
    font: var(--font-body-light);
  }

  @media (max-width: 768px) {
    & {
      grid-template-columns: 1fr;
      grid-gap: 3.6rem;
      padding-right: 2rem;
      padding-left: 2rem;
    }

    .promo-tiles-head {
      grid-column: auto;
    }

    .promo-tiles-side {
      display: none;
    }

    .promo-tiles-grid {
      grid-template-columns: 1fr 1fr;
      grid-gap: 2rem;
    }

    .promo-tiles-title {
      margin-bottom: 1.8rem;
    }
  }
`;

export default IntegrationPromoTilesDirectory;
