import type { HTMLAttributes, ReactNode } from 'react';
import React, { useRef } from 'react';
import { useIsomorphicLayoutEffect } from 'react-use';

export interface WordProps extends HTMLAttributes<HTMLSpanElement> {
  children: string;
  className: string;
  ref?: React.MutableRefObject<HTMLElement | null>;
}

type WordRenderPropType = (props: WordProps, index: number, words: string[]) => ReactNode;

interface Props {
  children: string;
  renderWord?: WordRenderPropType;
}

export const lineProp = Symbol('SplitLinesLine');
export const linesProp = Symbol('SplitLinesLines');

export interface SplitWordElement extends Element {
  [lineProp]?: number;
}

export interface SplitWordParent extends Element {
  [linesProp]?: SplitWordElement[][];
}

function defaultWordRenderProp(props: WordProps) {
  return <span {...props} />;
}

export default function SplitLines({ children, renderWord = defaultWordRenderProp }: Props) {
  const first = useRef<HTMLDivElement | null>(null);
  useIsomorphicLayoutEffect(() => {
    if (!first.current) {
      return;
    }
    const parent = first.current.parentNode as SplitWordParent;
    if (!parent) {
      return;
    }
    for (const el of parent.querySelectorAll('.w')) {
      (el as HTMLElement).style.display = 'inline-block';
    }
    const observer = new ResizeObserver(() => {
      const els = parent.querySelectorAll('.w');
      let y;
      const lines = [];
      for (const el of els as NodeListOf<SplitWordElement>) {
        const bounds = el.getBoundingClientRect();
        if (y === undefined || bounds.y > y) {
          y = bounds.y + bounds.height / 2;
          lines.push([el]);
        } else {
          lines[lines.length - 1].push(el);
        }
        el[lineProp] = lines.length;
      }
      parent[linesProp] = lines;
      parent.dispatchEvent(new CustomEvent('splitlines', { bubbles: true, detail: lines }));
    });
    observer.observe(parent);

    return () => {
      observer.disconnect();
      parent[linesProp]?.forEach((line) => {
        line.forEach((word) => {
          delete word[lineProp];
        });
      });
      delete parent[linesProp];
    };
  });

  const words = children.split(/\s+/);
  return (
    <>
      {words.map((word, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={i}>
          {renderWord(
            {
              ref: i === 0 ? first : undefined,
              className: 'w',
              children: word,
              'aria-hidden': true,
            },
            i,
            words,
          )}
          {i < words.length - 1 && ' '}
        </React.Fragment>
      ))}
    </>
  );
}
