import type { StrapiMedia } from '@/utilities/strapi/types/componentTypes';

export default function getMediaFormats(media?: StrapiMedia) {
  if (!media) {
    return {};
  }
  const attributes = media?.data?.attributes;
  const formats = { ...attributes?.formats };
  const isSVG = attributes?.mime.startsWith('image/svg');

  if (isSVG && attributes) {
    return {
      original: attributes,
    };
  }

  if (media.data?.attributes?.url) {
    formats.original = {
      ext: media.data?.attributes.ext,
      hash: media.data?.attributes.hash,
      height: media.data?.attributes.height,
      mime: media.data?.attributes.mime,
      name: media.data?.attributes.name,
      path: media.data?.attributes.path,
      size: media.data?.attributes.size,
      url: media.data?.attributes.url,
      width: media.data?.attributes.width,
    };
  }

  return formats;
}
