import clsx from 'clsx';
import React, { useRef, useState } from 'react';

import { useRafLoop } from 'react-use';
import styled from 'styled-components';

import { getDisplaySize, getRemResponsiveSizes, getRemSizeStyle } from '@/components/Elements/ResponsiveImage';
import GradientBackground from '@/components/Hydra/GradientBackground';
import useIsMobile from '@/hooks/useIsMobile';
import useRemScale from '@/hooks/useRemScale';
import getMediaFormats from '@/utilities/strapi/getMediaFormats';
import getSortedMediaSrcs from '@/utilities/strapi/getSortedMediaSrcs';
import type { CreatorGridProps as Props, MediaColors, MediaWithMeta } from '@/utilities/strapi/types/componentTypes';

import Video from '../Elements/Video';
import TextEditor from '../TextEditor';
import LineBreakTitle from '../utils/LineBreakTitle';
import StrapiLink from '../utils/StrapiLink';
import TextWithRightArrow from '../utils/TextWithRightArrow';

const altlayoutStyles = [
  [
    {
      width: '38.5rem',
      marginTop: '7rem',
    },

    {
      width: '25rem',
      marginTop: '3.5rem',
    },
  ],

  [
    {
      width: '38.9rem',
      marginTop: '-2rem',
    },

    {
      width: '25.0396rem',
      marginTop: '-1rem',
    },
  ],

  [
    {
      width: '59.6rem',
      marginTop: '8rem',
    },

    {
      width: '38.364rem',
      marginTop: '4rem',
    },
  ],

  [
    {
      width: '35.7rem',
      marginTop: '-10rem',
    },
    {
      width: '23rem',
      marginTop: '-5rem',
    },
  ],
  [
    {
      width: '24.6rem',
      marginTop: '-6rem',
    },

    {
      width: '16rem',
      marginTop: '-3rem',
    },
  ],
];

const StyledCreatorGrid = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 21rem calc(13rem + var(--rem-scale-viewport-half-excess));

  @media (max-width: 768px) {
    padding: 12rem 2rem;
  }

  .images > div img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }

  .creators {
    position: relative;
    height: 108rem;
    margin: -31rem -13rem -39rem;

    @media (max-width: 768px) {
      height: 65rem;
      margin: -8rem -2rem -36rem;
      transform-origin: left top;
      transform: scale(0.6);
    }

    .creators-inner {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      width: 100%;
      height: 100%;
      position: relative;

      &.started {
        display: block;

        > div {
          position: absolute;
          left: 0;
          top: 50%;
          margin-right: 0 !important;
        }
      }
    }

    .creator {
      flex-shrink: 0;
      margin-right: 7rem;
      position: relative;

      &:nth-child(3n + 1) {
        margin-top: -7rem;
      }

      &:nth-child(3n + 2) {
        margin-top: 7rem;
      }

      &:nth-child(3n + 3) {
        margin-top: -5rem;
      }

      img {
        position: relative;
        max-width: none;
      }

      .creator-link {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: block;
      }

      .creator-name {
        position: absolute;
        left: 1rem;
        bottom: 1rem;
        color: var(--color-white);
        font-size: 1.8rem;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        letter-spacing: -0.02em;
      }
    }
  }

  &.altlayout {
    min-height: 125rem;
    padding: 0;
    overflow: visible;

    @media (max-width: 768px) {
      padding: 0;
      min-height: 65rem;
    }

    .creators {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      margin: 0;
      overflow: hidden;

      @media (max-width: 768px) {
        transform: none;
        max-height: 76rem;
      }

      .creator {
        margin-right: 7.5rem;
        flex-shrink: 0;

        @media (max-width: 768px) {
          margin-right: 4.5rem;
        }

        .creator-name {
          @media (max-width: 768px) {
            font-size: calc(1.8rem * 0.6);
          }
        }

        > img {
          width: 100% !important;
          height: auto !important;
        }

        ${altlayoutStyles
          .map(
            ([desktop, mobile], n) => `
              &:nth-child(5n + ${n + 1}) {
                width: ${desktop.width};
                margin-top: ${desktop.marginTop};
                @media (max-width: 768px) {
                  width: ${mobile.width};
                  margin-top: ${mobile.marginTop};
                }
              }
            `,
          )
          .join('')}
      }
    }
  }
`;

const Background = styled(GradientBackground)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`;

const Title = styled(LineBreakTitle).attrs({ size: 3, align: 'left' })`
  position: relative;
  color: var(--color-white);
  font-size: 14rem;
  font-style: normal;
  font-weight: 250;
  line-height: 95%;
  letter-spacing: -0.05em;
  z-index: 300;
  color: var(--color-white);
  will-change: transform;
  mix-blend-mode: exclusion;

  @media (max-width: 768px) {
    font-size: 5.8rem;
    font-style: normal;
    font-weight: 300;
    line-height: 102%;
    letter-spacing: -0.04em;
  }
`;

const HeadingCommon = styled(LineBreakTitle).attrs({ size: 2, align: 'left', useSpan: true })`
  position: absolute;
  width: 50%;
  line-height: 87%;
  letter-spacing: -0.06em;
  color: var(--color-white);
  mix-blend-mode: exclusion;
  z-index: 300;
  pointer-events: none;
`;

const Title1 = styled(HeadingCommon)`
  left: max(env(safe-area-inset-left), calc(5rem + var(--rem-scale-viewport-half-excess)));
  top: 15rem;
  width: 60%;

  @media (max-width: 768px) {
    width: 32.4rem;
    left: 2rem;
    top: 11.3rem;
  }
`;

const Title2 = styled(HeadingCommon)`
  right: max(env(safe-area-inset-right), calc(5rem + var(--rem-scale-viewport-half-excess)));
  bottom: 9rem;

  > span {
    text-align: right !important;
  }

  @media (max-width: 768px) {
    width: 29.1rem;
    right: 2rem;
    bottom: auto;
    top: 54rem;
  }
`;

const Body = styled.div`
  position: absolute;
  left: max(env(safe-area-inset-left), calc(5rem + var(--rem-scale-viewport-half-excess)));
  width: 480px;
  bottom: 8.4rem;
  line-height: 120%;
  letter-spacing: -0.023em;
  color: var(--color-white);
  mix-blend-mode: exclusion;
  z-index: 300;
  pointer-events: none;

  @media (max-width: 768px) {
    width: 30rem;
    margin-left: max(env(safe-area-inset-left), 2rem);
    padding-top: 68rem;
    position: relative;
    left: auto;
    bottom: auto;
    padding-bottom: 5rem;
  }
`;

function getResponsiveSizes(media: MediaWithMeta, index: number, altlayout: boolean): string {
  if (altlayout) {
    const [desktop, mobile] = altlayoutStyles[index % altlayoutStyles.length];
    return getRemResponsiveSizes(desktop.width, mobile.width);
  }
  const formats = getMediaFormats(media?.media);
  const srcs = getSortedMediaSrcs(formats);
  const displaySize = getDisplaySize(srcs, 'small');
  let widthRem = 50;
  if (displaySize) {
    const { width } = getRemSizeStyle(displaySize);
    widthRem = parseFloat(width);
  }
  return getRemResponsiveSizes(widthRem, widthRem * 0.6);
}

export default function CreatorGrid({ id, title, title2, body, creators = [], anchorId, backgroundColor }: Props) {
  const colors = backgroundColor || (['#f8f9fc', '#ebf4ff', '#dfebfc', '#c4dcfc'] as MediaColors);

  const root = useRef<HTMLDivElement | null>(null);
  const creatorsRef = useRef<HTMLDivElement | null>(null);
  const creatorsInnerRef = useRef<HTMLDivElement | null>(null);
  const [started, setStarted] = useState(false);
  const [actives, setActives] = useState<Array<boolean | null>>([]);
  const isHovering = useRef<boolean | null>(false);
  const lastTime = useRef<number | null>(0);
  const timeOffset = useRef<number | null>(0);
  const currentTime = useRef<number | null>(0);
  const { scale: remScale } = useRemScale();
  const { isMobile } = useIsMobile();

  let allCreators = [...creators];
  const allCreatorsRefs = useRef<HTMLDivElement[] | null[]>([]);

  // make sure there are at least 6 items in allCreators
  const creatorsCopy = [...allCreators];
  let creatorsAddition = 1;
  while (allCreators.length < 6) {
    allCreators = [
      ...allCreators,
      ...creatorsCopy.map((i) => ({ ...i, id: parseInt(`${i.id}${creatorsAddition}`, 10) })),
    ];
    creatorsAddition++;
  }

  useRafLoop((time) => {
    if (!started) {
      setStarted(true);
      return;
    }

    if (isHovering.current && !isMobile) {
      const delta = time - (lastTime.current || 0);
      timeOffset.current = (timeOffset.current || 0) + delta;
    }

    const t = time - (timeOffset.current || 0);
    const t2 = currentTime.current || 0;
    const t3 = t2 + (t - t2) * 0.085;
    currentTime.current = Math.abs(t2 - t3) < 500 ? t3 : t;

    let aw = 0;
    let tw = 0;
    let lw = 0;
    let fa = 1;
    const areas: number[] = [];
    const margin = remScale * (isMobile ? 45 : 75);
    allCreatorsRefs.current.forEach((ref, i) => {
      if (ref) {
        const w = ref.offsetWidth;
        const h = ref.offsetHeight;
        if (w === 0) {
          return;
        }
        if (i === 0) {
          fa = w * h;
        }
        if (w + margin > lw) {
          lw = w + margin;
        }
        tw += w + margin;
        areas[i] = w * h;
      }
    });
    aw = tw + lw;
    let zs = areas.map((a, i) => ({ area: a, index: i, zIndex: 0 }));
    zs.sort((a, b) => b.area - a.area);
    zs = zs.map((z, i) => ({ ...z, zIndex: 200 - i }));
    zs.sort((a, b) => a.index - b.index);
    allCreatorsRefs.current.forEach((ref, i) => {
      if (ref) {
        const w = ref.offsetWidth;
        const zr = zs[i];
        const z = Math.sin(w + t3 * 0.000075) * (w * 0.15 * (1 - (zr.area / fa) * 0.15));
        const x = tw - ((aw + z + t3 * (isMobile ? 0.05 : 0.075 * remScale)) % tw) - lw;
        ref.style.transform = `translateX(${x}px) translateY(-50%)`;
        if (ref.style.zIndex !== `${zr.zIndex}`) {
          ref.style.zIndex = `${zr.zIndex}`;
        }
        if (w > 0) {
          aw -= w + margin;
        }
      }
    });

    lastTime.current = time;
  });

  const updateCurrentHover = (i: number | null) => {
    if (i === null) {
      setActives([]);
      isHovering.current = false;
    } else {
      const newActives = [...actives];
      newActives[i] = true;
      setActives(newActives);
      isHovering.current = true;
    }
  };

  const altlayout = !!title2;

  return (
    <div>
      <StyledCreatorGrid ref={root} id={anchorId?.anchorId} className={clsx({ altlayout })}>
        <Background seed={id} colors={colors} />
        {!title2 && <Title>{title}</Title>}
        <div ref={creatorsRef} className="creators">
          <div ref={creatorsInnerRef} className={clsx('creators-inner', { started })}>
            {allCreators.map((creator, i) => (
              <div
                ref={(r) => (allCreatorsRefs.current[i] = r)}
                // Strapi does not provide a unique id for creators...
                // eslint-disable-next-line react/no-array-index-key
                key={`${creator.id}${i}`}
                className="creator"
                onMouseOver={() => updateCurrentHover(i)}
                onFocus={() => updateCurrentHover(i)}
                onMouseOut={() => updateCurrentHover(null)}
                onBlur={() => updateCurrentHover(null)}
                aria-hidden={i >= creators.length}
              >
                {creator?.media && (
                  <Video
                    media={creator.media}
                    preferredImageSize="small"
                    useRemSize
                    sizes={getResponsiveSizes(creator.media, i, altlayout)}
                  />
                )}
                {creator?.creator && (
                  <StrapiLink
                    className="creator-link"
                    page={creator.creator.page}
                    externalUrl={creator.creator.externalUrl}
                    useChildren
                  >
                    <div className="creator-name">
                      <TextWithRightArrow animateOnHover className={clsx({ active: !!actives[i] })}>
                        {creator.creator.text}
                      </TextWithRightArrow>
                    </div>
                  </StrapiLink>
                )}
              </div>
            ))}
          </div>
        </div>
        {title2 && (
          <>
            <h2>
              <Title1>{title}</Title1>
              <Title2>{title2}</Title2>
            </h2>
            <Body>
              <TextEditor {...body} size="large" />
            </Body>
          </>
        )}
      </StyledCreatorGrid>
    </div>
  );
}
