import { resolveHref } from 'next/dist/shared/lib/router/router';
import { addLocale } from 'next/dist/shared/lib/router/utils/add-locale';
import { removeTrailingSlash } from 'next/dist/shared/lib/router/utils/remove-trailing-slash';
import { useRouter } from 'next/router';

import { convertHrefLangs, mapStrapiLocaleToAppLocale } from '@/components/utils/locale';
import type { Localization } from '@/utilities/strapi/types/utilsTypes';

export default function useLocalizedAlternates(localizations: Localization[] = []) {
  const router = useRouter();
  const alternates = convertHrefLangs(localizations);
  return alternates.map(([localization, hrefLang]) => {
    const href = {
      pathname: router.pathname,
      query: { slug: localization.params.slug },
    };
    const [, path] = resolveHref(router, href, true);
    const locale = mapStrapiLocaleToAppLocale(localization.locale);
    const url = `${process.env.CANONICAL_URL_BASE}${removeTrailingSlash(
      addLocale(path, locale, router.defaultLocale),
    )}`;
    return {
      hrefLang,
      href: url,
    };
  });
}
