// THIS CODE WAS GENERATED. DO NOT ALTER.
// COMMAND USED TO GENERATE: ./scripts/event_logging/create_event.py --event marketing_page_generic_impression
import { patreonTrackerClient } from '..';

interface EventType {
  generic_page_details: string;
}

export function logMarketingPageGenericImpression(data: EventType) {
  patreonTrackerClient.logTypedEvent('Marketing Page : Generic Page Impression', data);
}
