import Cookies from 'js-cookie';

import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { REGION_COOKIE_NAME } from '@/utilities/cookieNames';
import { getTLD } from '@/utilities/getTLD';
import type { Region } from '@/utilities/regions';
import regionData from '@/utilities/regions';

import Button from '../Elements/Buttons/Button';

import { SelectDropdown } from '../Elements/SelectDropdown/SelectDropDown';
import { Modal } from '../Modal/Modal';

const StyledRegionSelector = styled.div`
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const StyledModalContent = styled.div`
  max-width: 50rem;
  padding: var(--spacing-xl);
  padding-bottom: var(--spacing-sm);

  .content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: var(--spacing-sm);
    margin-bottom: var(--spacing-sm);
  }

  @media (max-width: 768px) {
    padding: var(--spacing-base);
    padding-bottom: 0;
  }
`;

export default function RegionSelector({ className }: { className?: string }) {
  const DEFAULT_REGION = 'US';

  const dialog = useRef<HTMLDialogElement | null>(null);
  const dropdownRef = useRef<HTMLSelectElement | null>(null);

  const [isOpen, setIsOpen] = useState(false);
  const [currentRegion, setCurrentRegion] = useState<Region>();

  useEffect(() => {
    const cookie = Cookies.get(REGION_COOKIE_NAME as string);

    if (cookie && cookie?.length < 3) {
      setCurrentRegion(regionData.find((region) => region.iso2 === cookie));
    } else {
      setCurrentRegion(regionData.find((region) => region.iso2 === DEFAULT_REGION));
    }
  }, []);

  useEffect(() => {
    if (!currentRegion?.iso2) {
      return;
    }
    const domain = getTLD();

    Cookies.set(REGION_COOKIE_NAME as string, currentRegion?.iso2, { expires: 30, domain });
  }, [currentRegion]);

  const updateRegion = () => {
    const selectedRegion = dropdownRef?.current?.value;
    setCurrentRegion(regionData.find((region) => region.iso2 === selectedRegion));
    dialog?.current?.close();
    setIsOpen(false);
  };

  return (
    <StyledRegionSelector className={className}>
      <div className="desktop-only">
        <Button
          variant="tertiary"
          iconPath={currentRegion?.flag || ''}
          onClick={() => setIsOpen(true)}
          label={currentRegion?.name || ''}
        />
      </div>
      <div className="mobile-only">
        <Button
          variant="tertiary"
          size="sm"
          fullWidth
          iconPath={currentRegion?.flag || ''}
          onClick={() => setIsOpen(true)}
          label={currentRegion?.name || ''}
        />
      </div>

      <Modal ref={dialog} onClose={() => setIsOpen(false)} open={isOpen}>
        <StyledModalContent>
          <header className="header">
            <h2 className="subheading-1">Choose your region</h2>
          </header>

          <div className="content-wrapper">
            <SelectDropdown
              ref={dropdownRef}
              label="Select your region"
              options={regionData.map((region) => ({
                label: region.name,
                value: region.iso2,
              }))}
              value={currentRegion?.iso2 || ''}
              type="secondary"
              size="lg"
              fullWidth
            />
            <Button size="lg" fullWidth onClick={() => void updateRegion()}>
              Save & Update
            </Button>
          </div>
        </StyledModalContent>
      </Modal>
    </StyledRegionSelector>
  );
}
