import React from 'react';

import type { CreatorCarouselProps as Props } from '@/utilities/strapi/types/componentTypes';

import ScrollCreatorCarousel from './ScrollCreatorCarousel';
import TimedCreatorCarousel from './TimedCreatorCarousel';

export default function CreatorCarousel(props: Props) {
  return <>{props.slides.length < 4 ? <TimedCreatorCarousel {...props} /> : <ScrollCreatorCarousel {...props} />}</>;
}
