import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';

import styled from 'styled-components';

import getIntegrationLinkProps from '@/components/Integrations/getIntegrationLinkProps';
import RightIcon from '@/components/icons/arrow-right.svg';
import type { ImageWithMeta, IntegrationPromoTilesCarouselProps } from '@/utilities/strapi/types/componentTypes';

import Button from '../Elements/Buttons/Button';
import ResponsiveImage from '../Elements/ResponsiveImage';
import StrapiLink from '../utils/StrapiLink';

const IntegrationPromoTilesCarousel = ({ title, body, integrations }: IntegrationPromoTilesCarouselProps) => {
  const [index, setIndex] = useState(0);
  const sliderParams = useRef({ visibleSlides: 0, percent: 0 });

  useEffect(() => {
    let prevStatus: boolean | number = -1;

    const fn = () => {
      const width = window.innerWidth;
      const status = width < 768;
      sliderParams.current.visibleSlides = width < 768 ? 1 : 3;
      sliderParams.current.percent = width < 768 ? 100 : 33.333;
      if (prevStatus !== status) {
        if (prevStatus !== -1) {
          const limit = integrations.data.length - sliderParams.current.visibleSlides;
          setIndex((s) => (s < 0 ? 0 : s > limit ? limit : s));
        }
        prevStatus = status;
      }
    };

    fn();

    window.addEventListener('resize', fn);

    return () => window.removeEventListener('resize', fn);
  }, [integrations]);

  function goNext() {
    setIndex((state) => (state < integrations.data.length - sliderParams.current.visibleSlides ? state + 1 : state));
  }

  function goPrev() {
    setIndex((state) => (state > 0 ? state - 1 : state));
  }

  return (
    <StyledWrapper>
      <div className="promo-tiles-head">
        <h1 className="promo-tiles-title">{title}</h1>
        <p className="promo-tiles-body">{body}</p>
      </div>
      <div className="promo-tiles-slider">
        <div
          className={clsx('promo-tiles-track', integrations.data.length < 3 && 'justify-center')}
          style={{ transform: 'translateX(' + -1 * sliderParams.current.percent * index + '%)' }}
        >
          {integrations.data.map((integration) => {
            const {
              id,
              attributes: { Title, shortDescription, Meta },
            } = integration;
            return (
              <StrapiLink key={id} {...getIntegrationLinkProps(integration.attributes)} className="promo-tile">
                <div className="promo-tile-img-wrap">
                  {Meta?.featureImage ? (
                    <ResponsiveImage
                      image={Meta.featureImage as ImageWithMeta}
                      className="promo-tile-img"
                      sizes="(max-width: 768px) 100vw, 320px"
                    />
                  ) : (
                    <div className="promo-tile-placeholder" />
                  )}
                </div>
                <div className="promo-tile-body">
                  <div className="promo-tile-title">{Title}</div>
                  <div className="promo-tile-desc">{shortDescription}</div>
                </div>
              </StrapiLink>
            );
          })}
        </div>
      </div>
      {integrations.data.length > 3 && (
        <div className="promo-tiles-slider-nav">
          <Button icon={<RightIcon />} onClick={goPrev} label="Previous" hideLabel size="lg" className="arrow-left" />
          <Button icon={<RightIcon />} onClick={goNext} label="Next" hideLabel size="lg" className="arrow-right" />
        </div>
      )}
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  --font-body-light: 300 max(11px, 1.6rem) / 140% var(--font-family-heading);

  /* TODO: use rem */
  padding-top: 72px;

  display: grid;
  width: 100%;
  max-width: 1170px;
  padding-left: 10rem;
  padding-right: 10rem;
  width: 100%;
  margin: 0 auto;

  .promo-tiles-head {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }

  .promo-tiles-title {
    font: var(--font-h5);
    letter-spacing: -0.05em;
    margin-bottom: 0.8rem;
  }

  .promo-tiles-body {
    font: var(--font-body-light);
  }

  .promo-tiles-slider {
    margin-top: 5rem;
    overflow: hidden;
  }

  .promo-tiles-track {
    display: flex;
    margin-right: -4.5rem;
    margin-left: -4.5rem;

    .justify-center {
      justify-content: center;
    }
  }

  .promo-tile {
    display: flex;
    flex-direction: column;
    width: 33.3333%;
    padding: 0 4.5rem;
    flex: 0 0 auto;
  }

  .promo-tile-img-wrap {
    position: relative;
    height: 0;
    padding-top: 100%;
    width: 100%;
    border-radius: 3rem;
    overflow: hidden;
  }

  .promo-tile-img {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .promo-tile-placeholder {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #000;
    border-radius: 8%;
    top: 0;
  }

  .promo-tile-body {
    padding: 2rem 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .promo-tile-title {
    font: var(--font-label-1);
    margin-bottom: 0.4rem;
  }

  .promo-tile-desc {
    font: var(--font-body-light);
  }

  .promo-tiles-slider-nav {
    margin-top: 3.6rem;
    display: flex;
    justify-content: center;

    & > * {
      margin: 0 1rem;
    }

    & .arrow-right,
    & .arrow-left {
      padding: 2rem;

      & .icon-wrapper {
        width: 2.4rem;
        height: 2.4rem;
      }
    }

    & .arrow-left .icon-wrapper svg {
      transform: rotate(180deg);
    }
  }

  @media (max-width: 768px) {
    & {
      padding-right: 2rem;
      padding-left: 2rem;
    }

    .promo-tiles-head {
      grid-column: auto;
    }

    .promo-tiles-title {
      margin-bottom: 1.8rem;
    }

    .promo-tiles-slider {
      margin-top: 3.6rem;
    }

    .promo-tiles-slider-nav {
      margin-top: 1.2rem;
    }

    .promo-tile {
      width: 100%;
    }
  }
`;

export default IntegrationPromoTilesCarousel;
