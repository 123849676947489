import React from 'react';
import styled from 'styled-components';

import type { TitleBlockProps } from '@/utilities/strapi/types/componentTypes';

import LineBreakTitle from '../utils/LineBreakTitle';
import MaxWidthContainer from '../utils/MaxWidthContainer';

const CustomLineBreakTitle = styled(LineBreakTitle)`
  font-size: 17rem;
  line-height: var(--line-height-h2);

  @media (max-width: 768px) {
    font: var(--font-h2);
  }
`;
export const TitleBlock = ({ title }: TitleBlockProps) => (
  <MaxWidthContainer>
    <CustomLineBreakTitle size={1}>{title}</CustomLineBreakTitle>
  </MaxWidthContainer>
);
