import { Popover, ThemeProvider } from '@patreon/studio';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

import ArrowLeft from '@/components/icons/arrow-left.svg';
import ChevronDown from '@/components/icons/chevron-down.svg';

import Button from '../Elements/Buttons/Button';
import type { ButtonProps } from '../Elements/Buttons/types';
interface SideBarLink {
  label: string | null;
  href: string;
}

interface DynamicSidebarProps {
  header?: string;
  footer?: { label: string; href: string };
}

export const DynamicSidebar = ({ header, footer }: DynamicSidebarProps) => {
  const [topicList, setTopicList] = useState<SideBarLink[] | null>(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  useEffect(() => {
    const content = document.querySelectorAll('#section-header');
    if (content) {
      const topics = [...content].map((el) => {
        const parentId = el.parentElement?.id;
        return { label: el.textContent, href: `#${parentId ?? ''}` };
      });
      setTopicList(topics);
    }
  }, []);

  const allTopics = (
    <>
      {topicList?.map(({ label, href }) => (
        <ListItem key={label} onClick={() => setIsPopoverOpen(false)} muted>
          <Link href={href}>{label}</Link>
        </ListItem>
      ))}
      {footer && (
        <ListItem>
          <ArrowLeft />

          <Link href={footer.href}>{footer.label}</Link>
        </ListItem>
      )}
    </>
  );

  return (
    <>
      <ThemeProvider>
        <MobileSidebar>
          <Popover content={<TopicList>{allTopics}</TopicList>} isOpen={isPopoverOpen} hideArrow>
            <ButtonWithForwardedRef
              variant="secondary"
              icon={<ChevronDown />}
              onClick={() => setIsPopoverOpen(!isPopoverOpen)}
              iconPlacement="last"
              childrenLabelOrder="childrenFirst"
              fullWidth
              className="bordered"
            >
              {header}
            </ButtonWithForwardedRef>
          </Popover>
        </MobileSidebar>
      </ThemeProvider>

      <Sidebar>
        <TopicList>
          <ListItem>{header}</ListItem>
          {allTopics}
        </TopicList>
      </Sidebar>
    </>
  );
};

// Ref forwarding required for `Popover`
const ButtonWithForwardedRef = React.forwardRef((props: ButtonProps, ref: React.Ref<HTMLButtonElement>) => (
  <Button {...props} innerRef={ref} />
));
ButtonWithForwardedRef.displayName = 'Button';

const Sidebar = styled.div`
  position: sticky;
  left: 0;
  top: 100px;
  padding-right: 24px;

  @media (max-width: 768px) {
    display: none;
    position: relative;
    margin: 0 auto;
    top: unset;
    left: unset;
    padding: 0;
  }
`;

const ListItem = styled.li<{ muted?: boolean }>`
  font-size: 16px;
  display: flex;
  gap: 8px;
  align-items: center;
  font-weight: 400;
  color: ${({ muted }) => muted && '#666666'};

  svg {
    width: 12px;
    height: 12px;
  }
`;

const TopicList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const MobileSidebar = styled.div`
  display: none;
  padding: 24px;

  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    padding: 0;
  }
`;
