import React from 'react';

import styled from 'styled-components';

import Video from '@/components/Elements/Video';
import MaxWidthContainer from '@/components/utils/MaxWidthContainer';
import type { MediaWithMetaProps as Props } from '@/utilities/strapi/types/componentTypes';

export default function MediaWithMeta(props: Props) {
  return (
    <MaxWidthContainer>
      <StyledMediaWithMeta id={props.anchorId?.anchorId}>
        <Video key={props.id} media={props} autoPlay playsInline muted loop sizes="(min-width: 1280px) 1280px, 100vw" />
      </StyledMediaWithMeta>
    </MaxWidthContainer>
  );
}

const StyledMediaWithMeta = styled.div`
  img,
  video {
    object-fit: fill;
    width: 100%;
  }
`;
