import type { MediaFormat } from '@/utilities/strapi/types/componentTypes';

export default function getSortedMediaSrcs(formats: Record<string, MediaFormat>) {
  return Object.keys(formats)
    .map((name) => ({
      ...formats[name],
      name,
    }))
    .filter((format) => format.width)
    .sort((a, b) => (a.width as number) - (b.width as number));
}
