import clsx from 'clsx';
import React from 'react';

import styled from 'styled-components';

import TextEditor from '@/components/TextEditor';
import MaxWidthContainer from '@/components/utils/MaxWidthContainer';
import type { UtilsTwoColumnTextProps as Props } from '@/utilities/strapi/types/componentTypes';

const StyledTwoColumnText = styled.div`
  --gap: var(--spacing-xl);

  display: flex;
  gap: var(--gap);
  justify-content: space-between;

  &.align-top {
    align-items: flex-start;
  }

  &.align-middle {
    align-items: center;
  }

  &.align-bottom {
    align-items: flex-end;
  }

  &.ratio-50-50 {
    .column-one {
      width: calc(50% - (var(--gap) / 2));
    }
  }

  &.ratio-66-33 {
    .column-one {
      width: calc(66.6666% - (var(--gap) / 2));
    }
  }

  &.ratio-33-66 {
    .column-one {
      width: calc(33.3333% - (var(--gap) / 2));
    }
  }

  .column-two {
    flex: 1 0 0;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: var(--spacing-sm);

    .column-one,
    .column-two {
      width: 100% !important;
    }
  }
`;

export default function TwoColumnText({
  column_one: columnOne,
  column_two: columnTwo,
  layout,
  verticalAlignment,
  anchorId,
}: Props) {
  const layoutClasses: Record<string, string> = {
    'Ratio - 50/50': 'ratio-50-50',
    'Ratio - 66/33': 'ratio-66-33',
    'Ratio - 33/66': 'ratio-33-66',
  };

  return (
    <MaxWidthContainer>
      <StyledTwoColumnText
        id={anchorId?.anchorId}
        className={clsx(layoutClasses[layout], `align-${verticalAlignment?.alignment}`)}
      >
        <div className="column-one">
          <TextEditor text={columnOne?.text} />
        </div>
        <div className="column-two">
          <TextEditor text={columnTwo?.text} />
        </div>
      </StyledTwoColumnText>
    </MaxWidthContainer>
  );
}
