import React from 'react';

import styled from 'styled-components';

import Heading from '@/components/Elements/Heading';
import TextEditor from '@/components/TextEditor';

import MaxWidthContainer from '@/components/utils/MaxWidthContainer';

import type { PolicySectionContainerProps } from '@/utilities/strapi/types/componentTypes';

import { DynamicSidebar } from '../DynamicSidebar/DynamicSidebar';

export const PolicySectionContainer = ({
  header,
  footer_link: footerLink,
  policy_section_child: policySectionChild,
}: PolicySectionContainerProps) => (
  <MaxWidthContainer>
    <Container>
      <SidebarContainer>
        <DynamicSidebar
          header={header}
          footer={{ label: footerLink.text, href: footerLink.page?.data?.attributes?.Slug ?? '/' }}
        />
      </SidebarContainer>

      <div>
        {policySectionChild.map((section) => (
          <Columnn key={section.header} {...section} />
        ))}
      </div>
    </Container>
  </MaxWidthContainer>
);

interface ColumnProps {
  content: string;
  header: string;
  anchorId: string;
}

const Columnn = ({ content, header, anchorId }: ColumnProps) => (
  <StyledOneColumnText id={anchorId}>
    <Heading size={4} id="section-header">
      {header}
    </Heading>
    <TextEditor text={content} />
  </StyledOneColumnText>
);

const SidebarContainer = styled.div`
  min-width: 300px;
`;

const Container = styled.div`
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const StyledOneColumnText = styled.div`
  scroll-margin-top: 100px;
  padding: 24px 0;

  ul,
  ol {
    padding-inline-start: 40px;
    list-style-position: outside;
  }

  li {
    margin: 0.5em;
    line-height: 120%;
  }

  :first-child {
    padding-top: 0;
  }

  :not(:last-child) {
    border-bottom: 1px solid #959595;
  }

  p {
    margin-top: 24px;
  }

  &.full-width {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  @media (max-width: 768px) {
    scroll-margin-top: 130px;

    &.full-width {
      margin: var(--spacing-sm) 0;
      padding: 0;
    }
  }
`;
