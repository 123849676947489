import React, { useRef, useState } from 'react';
import styled from 'styled-components';

import type { ImageWithMeta } from '@/utilities/strapi/types/componentTypes';

import IntegrationGalleryLightbox from './IntegrationGalleryLightbox';
import ResponsiveImage from '../Elements/ResponsiveImage';
import { Modal } from '../Modal/Modal';

export interface IntegrationMediaGalleryProps {
  title?: string;
  images?: ImageWithMeta[];
}

export default function IntegrationMediaGallery({ title, images }: IntegrationMediaGalleryProps) {
  const dialog = useRef<HTMLDialogElement | null>(null);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  if (!images?.length) {
    return null;
  }

  return (
    <StyledIntegrationMediaGallery>
      <header className="media-gallery-header">
        <h2 className="media-gallery-title">{title}</h2>
      </header>
      <div className="media-gallery-main">
        <ul className="media-gallery-list">
          {images.map((image) => (
            <li key={image.id} className="media-gallery-item">
              <button
                type="button"
                className="media-gallery-button"
                title={image.alternativeText}
                onClick={(event) => {
                  event.preventDefault();
                  setSelectedIndex(images.indexOf(image));
                  setIsOpen(true);
                }}
              >
                <ResponsiveImage
                  image={image}
                  className="media-gallery-image"
                  sizes="(min-width: 769px) 250px, (min-width: 640px) 50vw, 100vw"
                />
              </button>
            </li>
          ))}
        </ul>
      </div>

      <Modal ref={dialog} onClose={() => setIsOpen(false)} open={isOpen} hideCancel>
        <IntegrationGalleryLightbox images={images} selectedIndex={selectedIndex} />
      </Modal>
    </StyledIntegrationMediaGallery>
  );
}

const StyledIntegrationMediaGallery = styled.aside`
  margin-bottom: var(--spacing-lg);

  .media-gallery-header {
    margin-bottom: var(--spacing-base);
  }

  .media-gallery-title {
    font: var(--font-label-2);
    letter-spacing: 0.036rem;
    text-transform: uppercase;
  }

  .media-gallery-list {
    display: grid;
    gap: var(--spacing-sm);
    grid-template-columns: 1fr 1fr 1fr;

    @media (max-width: 768px) {
      grid-template-columns: 1fr 1fr;
    }
  }

  .media-gallery-button {
    aspect-ratio: 16 / 9;
  }

  .media-gallery-image {
    width: 100%;
    object-fit: cover;
    max-height: 100%;
  }
`;
