import clsx from 'clsx';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { subscribeKey } from 'valtio/utils';

import { useHeaderState, useHeaderStateSnapshot } from '@/components/Header/headerState';
import type { CallToActionButton } from '@/utilities/strapi/types/componentTypes';
import type { NavigationGroup } from '@/utilities/strapi/types/utilsTypes';

import useMegaHoverState from './useMegaHoverState';
import CTAButton from '../Blocks/CTAButton';
import AnimatedLabel from '../utils/AnimatedLabel';
import StrapiLink from '../utils/StrapiLink';

interface TopLevelNavigationProps {
  navigationGroups: NavigationGroup[];
  updatesButton?: CallToActionButton;
}

export default function TopLevelNavigation({ navigationGroups, updatesButton }: TopLevelNavigationProps) {
  const indicator = useRef<HTMLLIElement>(null);
  const navList = useRef<HTMLUListElement>(null);

  const headerState = useHeaderState();
  const { activeMegaMenuId } = useHeaderStateSnapshot();
  const { handleWrapper1Enter, handleWrapper1Leave } = useMegaHoverState();
  const indicatedMegaMenuId = activeMegaMenuId;

  function updateFocusState(id: number | null) {
    headerState.focusedMegaMenuId = id;
  }

  function updateClickedState(id: number | null) {
    if (headerState.clickedMegaMenuId === id) {
      headerState.clickedMegaMenuId = null;
    } else {
      headerState.clickedMegaMenuId = id;
    }
  }

  function updateHoverState(id: number | null) {
    if (id) {
      handleWrapper1Enter();
      headerState.hoveredMegaMenuId = id;
    } else {
      handleWrapper1Leave();
    }
  }

  function handleLinkClick() {
    headerState.isMobileMenuOpen = false;
    headerState.clickedMegaMenuId = null;
    headerState.hoveredMegaMenuId = null;
  }

  useEffect(() => {
    if (indicatedMegaMenuId && indicator.current && navList.current) {
      const element = document.getElementById(`mega-nav-trigger-${indicatedMegaMenuId}`);
      if (!element) {
        return;
      }
      const bounds = element.getBoundingClientRect();
      const parent = navList.current.getBoundingClientRect();

      indicator.current.style.cssText = `
        --x1: ${bounds.left - parent.left}px;
        --x2: ${parent.right - bounds.right}px;
        --y1: ${bounds.top - parent.top}px;
        --y2: ${parent.bottom - bounds.bottom}px;
      `;
    }
  }, [indicatedMegaMenuId]);

  useEffect(() => {
    let prevClickedMegaMenuId = headerState.clickedMegaMenuId;
    function handleClickedMegaMenuIdChange(clickedMegaMenuId: number | null) {
      if (prevClickedMegaMenuId && !clickedMegaMenuId) {
        // when closing the mega menu, return focus to the button that triggered it
        const element = document.getElementById(`mega-nav-trigger-${prevClickedMegaMenuId}`);
        (element?.children[0] as HTMLElement)?.focus();
      } else if (clickedMegaMenuId) {
        // when opening the mega menu, move focus to the mega menu
        setTimeout(() => {
          const element = document.getElementById(`mega-nav-list-${clickedMegaMenuId}`);
          element?.focus();
        }, 100);
      }
      prevClickedMegaMenuId = clickedMegaMenuId;
    }
    return subscribeKey(headerState, 'clickedMegaMenuId', handleClickedMegaMenuIdChange);
  }, [headerState]);

  return (
    <StyledTopLevelNavigation>
      <ul ref={navList} className={clsx('nav-list', { 'has-active-item': indicatedMegaMenuId })}>
        {navigationGroups.map((group) => {
          const hasAnchors = group.linksWithAnchors.some(({ text }) => text);
          if (!hasAnchors && !group?.titleLink) {
            return null;
          }
          return (
            <li
              key={group.id}
              id={`mega-nav-trigger-${group.id}`}
              onMouseEnter={() => updateHoverState(group.id)}
              onFocus={() => updateFocusState(group.id)}
              onBlur={() => updateFocusState(null)}
              className={clsx('nav-item', {
                active: group.id === indicatedMegaMenuId,
              })}
            >
              {!hasAnchors ? (
                <StrapiLink
                  {...group.titleLink}
                  text={group.titleLink?.text ?? group?.title ?? '** Title link needs a Menu Label'}
                  className="nav-link"
                  callback={handleLinkClick}
                  animated
                />
              ) : (
                <button
                  className="nav-link"
                  onClick={() => updateClickedState(group.id)}
                  aria-haspopup="true"
                  aria-controls={`mega-nav-list-${group.id}`}
                >
                  <AnimatedLabel tag="div" label={group?.title ?? '** Menu item needs a title'} />
                </button>
              )}
            </li>
          );
        })}
        <li ref={indicator} className="indicator" />
        {updatesButton && (
          <ButtonWrapper>
            <CTAButton {...updatesButton} />
          </ButtonWrapper>
        )}
      </ul>
    </StyledTopLevelNavigation>
  );
}

const StyledTopLevelNavigation = styled.div`
  --link-padding-y: 1.5rem;
  --link-padding-x: 2.4rem;

  --transition: var(--animation-duration-fast) var(--animation-timing);

  padding: var(--link-padding-y) 0;
  display: inline-block;

  @media (max-width: 768px) {
    display: none;
  }

  .nav-list {
    display: flex;
    position: relative;

    &.has-active-item {
      .indicator {
        --active: 1;
      }
    }
  }

  .indicator {
    background-color: var(--color-action-default);
    border-radius: 50px;
    content: '';
    display: block;
    position: absolute;
    inset: var(--y1) var(--x2) var(--y2) var(--x1);
    transition: opacity var(--transition), scale var(--transition), inset var(--transition);
    opacity: var(--active, 0);
    scale: var(--active, 0);
    pointer-events: none;
  }

  .nav-item {
    position: relative;
    display: flex;

    &.active {
      .nav-link {
        color: var(--color-action-inverse);
      }
    }
  }

  .nav-link {
    display: block;
    position: relative;
    appearance: none;
    border-radius: 4rem;
    padding: var(--link-padding-y) var(--link-padding-x);
    z-index: 1;
    transition: color var(--transition);

    > div {
      position: relative;
      overflow: hidden;
    }

    &.active {
      color: var(--color-action-inverse);
    }
  }
`;

const ButtonWrapper = styled.div`
  margin-left: 1.5rem;
`;
