// TODO: (jackyang), connect this with the main web app

export interface CurrencyData {
  availableForPayIn: boolean;
  availableForPayOut: boolean;
  code: string;
  defaultLocaleCode: string;
  description: string;
  isLaunched: boolean;
  maxPledgeSubunits: number;
  numSubunits: number;
  symbol: string;
}

const currencyList: CurrencyData[] = [
  {
    availableForPayIn: true,
    availableForPayOut: true,
    code: 'AUD',
    defaultLocaleCode: 'en-AU',
    description: 'Australian Dollars',
    isLaunched: true,
    maxPledgeSubunits: 1500000,
    numSubunits: 100,
    symbol: 'AU$',
  },
  {
    availableForPayIn: true,
    availableForPayOut: false,
    code: 'BRL',
    defaultLocaleCode: 'pt-br',
    description: 'Brazilian Real',
    isLaunched: true,
    maxPledgeSubunits: 6500000,
    numSubunits: 100,
    symbol: 'BRL',
  },
  {
    availableForPayIn: true,
    availableForPayOut: true,
    code: 'CAD',
    defaultLocaleCode: 'en-CA',
    description: 'Canadian Dollars',
    isLaunched: true,
    maxPledgeSubunits: 1500000,
    numSubunits: 100,
    symbol: 'CA$',
  },
  {
    availableForPayIn: true,
    availableForPayOut: true,
    code: 'CZK',
    defaultLocaleCode: 'cs-cz',
    description: 'Czech Koruna',
    isLaunched: true,
    maxPledgeSubunits: 35500000,
    numSubunits: 100,
    symbol: 'CZK',
  },
  {
    availableForPayIn: true,
    availableForPayOut: true,
    code: 'DKK',
    defaultLocaleCode: 'da-DK',
    description: 'Danish Kroner',
    isLaunched: true,
    maxPledgeSubunits: 9500000,
    numSubunits: 100,
    symbol: 'DKK',
  },
  {
    availableForPayIn: true,
    availableForPayOut: true,
    code: 'EUR',
    defaultLocaleCode: 'en-IE',
    description: 'Euros',
    isLaunched: true,
    maxPledgeSubunits: 1300000,
    numSubunits: 100,
    symbol: '€',
  },
  {
    availableForPayIn: true,
    availableForPayOut: true,
    code: 'GBP',
    defaultLocaleCode: 'en-GB',
    description: 'British Pounds Sterling',
    isLaunched: true,
    maxPledgeSubunits: 1200000,
    numSubunits: 100,
    symbol: '£',
  },
  {
    availableForPayIn: true,
    availableForPayOut: true,
    code: 'HKD',
    defaultLocaleCode: 'zh-hk',
    description: 'Hong Kong Dollar',
    isLaunched: true,
    maxPledgeSubunits: 11500000,
    numSubunits: 100,
    symbol: 'HKD',
  },
  {
    availableForPayIn: true,
    availableForPayOut: true,
    code: 'HUF',
    defaultLocaleCode: 'hu-hu',
    description: 'Hungarian Forint',
    isLaunched: true,
    maxPledgeSubunits: 516000000,
    numSubunits: 100,
    symbol: 'HUF',
  },
  {
    availableForPayIn: true,
    availableForPayOut: false,
    code: 'MXN',
    defaultLocaleCode: 'es-mx',
    description: 'Mexican Peso',
    isLaunched: true,
    maxPledgeSubunits: 35500000,
    numSubunits: 100,
    symbol: 'MXN',
  },
  {
    availableForPayIn: true,
    availableForPayOut: true,
    code: 'NOK',
    defaultLocaleCode: 'nb-NO',
    description: 'Norwegian Kroner',
    isLaunched: true,
    maxPledgeSubunits: 12500000,
    numSubunits: 100,
    symbol: 'NOK',
  },
  {
    availableForPayIn: true,
    availableForPayOut: true,
    code: 'NZD',
    defaultLocaleCode: 'en-nz',
    description: 'New Zealand Dollar',
    isLaunched: true,
    maxPledgeSubunits: 2200000,
    numSubunits: 100,
    symbol: 'NZD',
  },
  {
    availableForPayIn: true,
    availableForPayOut: true,
    code: 'PLN',
    defaultLocaleCode: 'pl-pl',
    description: 'Polish Zloty',
    isLaunched: true,
    maxPledgeSubunits: 6500000,
    numSubunits: 100,
    symbol: 'PLN',
  },
  {
    availableForPayIn: true,
    availableForPayOut: true,
    code: 'SEK',
    defaultLocaleCode: 'sv-SE',
    description: 'Swedish Kronor',
    isLaunched: true,
    maxPledgeSubunits: 13000000,
    numSubunits: 100,
    symbol: 'SEK',
  },
  {
    availableForPayIn: true,
    availableForPayOut: true,
    code: 'SGD',
    defaultLocaleCode: 'zh-sg',
    description: 'Singapore Dollar',
    isLaunched: true,
    maxPledgeSubunits: 2000000,
    numSubunits: 100,
    symbol: 'SGD',
  },
  {
    availableForPayIn: true,
    availableForPayOut: true,
    code: 'USD',
    defaultLocaleCode: 'en-US',
    description: 'US Dollars',
    isLaunched: true,
    maxPledgeSubunits: 1500000,
    numSubunits: 100,
    symbol: '$',
  },
];

export default currencyList;
