import { clamp } from 'lodash';
import React, { useRef } from 'react';

import { useRafLoop } from 'react-use';
import styled from 'styled-components';

const StyledIndicator = styled.button`
  width: 5.4rem;
  height: 5.4rem;
  cursor: pointer;
  appearance: none;
  background: none;
  border: 0;
  border-radius: 0;

  @media (max-width: 768px) {
    width: 4.5rem;
    height: 4.5rem;
  }

  .rings {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    &::before {
      display: block;
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      border: 2px solid rgba(255, 255, 255, 0.25);
    }

    > div {
      position: absolute;
      left: 0;
      top: 0;
      width: 2.7rem;
      height: 100%;
      overflow: hidden;

      @media (max-width: 768px) {
        width: 2.25rem;
      }

      &:first-child {
        transform: scaleY(-1);

        > div {
          transform: rotate(calc(var(--progress-left) * 1deg));
        }
      }

      &:last-child {
        left: auto;
        right: 0;
        transform: rotate(180deg);

        > div {
          transform: rotate(calc(var(--progress-right) * 1deg));
        }
      }

      > div {
        position: absolute;
        left: 0;
        top: 0;
        width: 2.7rem;
        height: 100%;
        overflow: hidden;
        transform-origin: 2.7rem 2.7rem;

        @media (max-width: 768px) {
          width: 2.25rem;
          transform-origin: 2.25rem 2.25rem;

          &::before {
            width: 4.5rem !important;
            height: 4.5rem !important;
          }
        }

        &::before {
          display: block;
          content: '';
          width: 5.4rem;
          height: 5.4rem;
          border: 2px solid var(--color-white);
          border-radius: 50%;
        }
      }
    }
  }

  .content {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

interface Props {
  progress?: React.MutableRefObject<number | null>;
  className?: string;
  children?: React.ReactNode;
  onClick?: (() => void) | undefined;
  ariaLabel?: string;
}

const CircularIndicator = React.forwardRef((props: Props, ref: React.Ref<HTMLButtonElement>) => {
  const { progress, className, children, onClick, ariaLabel } = props;
  const baseProgress = useRef<number | null>(0);
  const useProgress = progress || baseProgress;

  useRafLoop(() => {
    if (typeof ref === 'function') {
      return;
    }

    if (ref && ref.current && useProgress && typeof useProgress.current === 'number') {
      let pl = 180 - clamp((useProgress.current * 360 + 180) % 360, 0, 180);
      let pr = clamp((useProgress.current * 360 + 180) % 360, 180, 360);
      if (useProgress.current < 0.5) {
        pl = 180;
      }
      if (useProgress.current > 0.5) {
        pr = 360;
      }
      ref.current.style.setProperty('--progress-left', `${pl}`);
      ref.current.style.setProperty('--progress-right', `${pr}`);
    }
  });

  return (
    <StyledIndicator ref={ref} className={className} onClick={onClick} aria-label={ariaLabel}>
      <div className="rings">
        <div>
          <div />
        </div>
        <div>
          <div />
        </div>
      </div>
      <div className="content">{children}</div>
    </StyledIndicator>
  );
});

CircularIndicator.displayName = 'CircularIndicator';

export default CircularIndicator;
