import type { LegacyRef } from 'react';
import React, { createRef, useEffect } from 'react';

import styled, { css } from 'styled-components';

import { useHeaderStateSnapshot } from '@/components/Header/headerState';
import MinusIcon from '@/components/icons/minus.svg';
import PlusIcon from '@/components/icons/plus.svg';
import { SectionTheme } from '@/context/StrapiGlobalsContext';
import useIsMobile from '@/hooks/useIsMobile';
import type { LocaleResultItem } from '@/utilities/strapi/types/apiResponseTypes';
import type { StrapiFooterAttributes } from '@/utilities/strapi/types/queryResponseTypes';

import type { Localization, NavigationGroup } from '@/utilities/strapi/types/utilsTypes';

import AppStoreLinks from '../AppStoreLinks/AppStoreLinks';
import CurrencySelector from '../CurrencySelector';
import LanguageSelector from '../LanguageSelector/LanguageSelector';
import RegionSelector from '../RegionSelector/RegionSelector';
import SocialNavigation from '../SocialNavigation';
import StrapiLink from '../utils/StrapiLink';

interface FooterProps {
  footer?: StrapiFooterAttributes;
  localizations?: Localization[];
  locales?: LocaleResultItem[];
}

export default function Footer({ footer, localizations = [], locales = [] }: FooterProps) {
  const { isMobile } = useIsMobile();
  const navigationGroups = footer?.navigationGroups || [];
  const socialLinks = footer?.socialLinks || [];

  const { streetAddress, country, city, state, zipCode, phoneNumber } = footer || {};

  const detailsRefs = new Map<number, LegacyRef<HTMLDetailsElement | null>>();

  const { megaMenuHasTrappedFocus } = useHeaderStateSnapshot();

  navigationGroups.forEach((group) => {
    detailsRefs.set(group.id, createRef<HTMLDetailsElement | null>());
  });

  function handleDetailsClick(event: React.MouseEvent, group: NavigationGroup) {
    event.preventDefault();

    detailsRefs.forEach(() => {
      closeDetails(group.id);
    });
  }

  function closeDetails(exceptionId?: number | null) {
    if (isMobile) {
      detailsRefs.forEach((value: LegacyRef<HTMLDetailsElement | null>, key) => {
        if (typeof value === 'object' && Object.hasOwnProperty.call(value, 'current')) {
          const refElement: HTMLDetailsElement = value?.current as HTMLDetailsElement;

          if (exceptionId) {
            if (key !== exceptionId) {
              refElement.open = false;
            } else {
              if (refElement.open) {
                refElement.open = false;
              } else {
                refElement.open = true;
              }
            }
          } else {
            refElement.open = false;
          }
        }
      });
    }
  }

  function openAllDetails() {
    detailsRefs.forEach((value: LegacyRef<HTMLDetailsElement | null>) => {
      if (typeof value === 'object' && Object.hasOwnProperty.call(value, 'current')) {
        const detailsElement: HTMLDetailsElement = value?.current as HTMLDetailsElement;
        detailsElement.open = true;
      }
    });
  }

  useEffect(() => {
    if (isMobile) {
      closeDetails(null);
    } else {
      openAllDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile, navigationGroups]);

  const addressLine2 = [city, [state, zipCode].filter(Boolean).join(' '), country].filter(Boolean).join(', ');
  const contactLines = [streetAddress, addressLine2, phoneNumber, '©️Patreon'].filter(Boolean);

  return (
    <StyledSiteFooter data-theme={SectionTheme.dark} inert={megaMenuHasTrappedFocus ? '' : undefined}>
      <div className="nav-wrapper">
        {navigationGroups.map((group) => (
          <div key={group.id} className="site-footer-nav">
            <details ref={detailsRefs.get(group.id) as React.LegacyRef<HTMLDetailsElement>} className="nav-details">
              {group.titleLink ? (
                <summary
                  {...(!isMobile ? { role: 'heading', 'aria-level': 4 } : {})}
                  className="nav-summary"
                  onClick={(event) => handleDetailsClick(event, group)}
                >
                  <StrapiLink {...group.titleLink} className="nav-title-link" useChildren>
                    {group.title || group.titleLink.text}
                    <Plus />
                    <Minus />
                  </StrapiLink>
                </summary>
              ) : (
                <summary
                  {...(!isMobile ? { role: 'heading', 'aria-level': 4 } : {})}
                  className="nav-summary"
                  onClick={(event) => handleDetailsClick(event, group)}
                >
                  <p className="nav-title">
                    {group.title}
                    <Plus />
                    <Minus />
                  </p>
                </summary>
              )}
              <ul className="nav-list">
                {/* TODO (jackyang) once we add this for linksWithAnchors let's add in logging metadata */}
                {group.linksWithAnchors.map((link) => (
                  <li key={link.id}>
                    <StrapiLink {...link} useMenuTitle callback={() => closeDetails()} />
                    {link.links && link.links.length > 0 && (
                      <ul className="sub-nav-list">
                        {link.links.map((sublink) => (
                          <li key={sublink.id}>
                            <StrapiLink {...sublink} className="sub-nav-link" callback={() => closeDetails()} />
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            </details>
          </div>
        ))}
      </div>
      <div className="nav-wrapper">
        <div className="apps-store-wrapper">
          <AppStoreLinks className="app-store-button" />
        </div>
      </div>
      <div className="nav-wrapper site-meta">
        <div className="nav-inner-wrapper selectors">
          <LanguageSelector className="pref" localizations={localizations} locales={locales} />
          <RegionSelector className="pref" />
          <CurrencySelector className="pref" />
        </div>
        <SocialNavigation socialLinks={socialLinks} />
        <address className="contact-meta">
          {contactLines.map((text, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={i}>
              <span className="mobile-line">{text}</span>
              {i < contactLines.length - 1 && (
                <>
                  {' '}
                  <span className="pipe">|</span>{' '}
                </>
              )}
            </React.Fragment>
          ))}
        </address>
      </div>
    </StyledSiteFooter>
  );
}

const iconStyle = css`
  display: none;
  margin-left: 0.5rem;
  vertical-align: middle;
  width: 1rem;
  height: 1rem;
`;

const Plus = styled(PlusIcon)`
  ${iconStyle};
`;

const Minus = styled(MinusIcon)`
  ${iconStyle};
  height: 0.2rem;
`;

const StyledSiteFooter = styled.footer`
  display: flex;
  padding: 6rem max(env(safe-area-inset-right), calc(5rem + var(--rem-scale-viewport-half-excess))) 5rem
    max(env(safe-area-inset-left), calc(5rem + var(--rem-scale-viewport-half-excess)));
  justify-content: center;
  flex-direction: column;
  width: 100%;
  color: #ffffff;
  background-color: #000000;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 350;
  line-height: 110%;
  letter-spacing: -0.036rem;

  @media (max-width: 768px) {
    padding: 4.8rem max(env(safe-area-inset-right), 3.4rem) 4.8rem max(env(safe-area-inset-left), 3.4rem);
    flex-direction: column;
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 300;
    line-height: 120%;
    letter-spacing: -0.072rem;
  }

  .nav-wrapper {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    gap: var(--spacing-lg);
    width: 100%;
    margin-bottom: 4.5rem;

    &:last-child {
      margin-bottom: 0;
    }

    @media (max-width: 768px) {
      display: flex;
      gap: 1.1rem;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      margin-bottom: var(--spacing-base);
    }
  }

  .nav-inner-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;

    @media (max-width: 768px) {
      justify-content: center;
      flex-direction: column;
    }
  }

  .pref .label {
    @media (min-width: 769px) {
      max-width: 14rem;
    }
  }

  .selectors {
    gap: 1rem;

    @media (max-width: 768px) {
      flex-direction: column;
      width: 100%;
    }
  }

  .lang {
    margin: 0 1rem 0 0;

    @media (max-width: 768px) {
      margin: 0 0 1.8rem 0;
      width: 100%;
    }
  }

  .site-footer-nav {
    max-width: 40rem;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .nav-details {
    &[open] {
      margin-bottom: var(--spacing-base);

      @media (max-width: 768px) {
        margin-bottom: var(--spacing-sm);

        .nav-title-link,
        .nav-title {
          ${Plus} {
            display: none;
          }
          ${Minus} {
            display: inline-block;
          }
        }
      }
    }
  }

  .nav-summary {
    display: block;
    margin-bottom: 1.1rem;

    ::-webkit-details-marker {
      display: none;
    }

    &:details-marker {
      display: none;
    }

    @media (max-width: 768px) {
      &:has(> :not([href]).strapi-link) {
        .nav-title-link,
        .nav-title {
          ${Plus} {
            display: none;
          }
        }
      }
    }
  }

  .nav-title-link,
  .nav-title {
    display: inline-block;
    font: var(--font-label-2);
    margin-bottom: 3rem;
    letter-spacing: 0.036rem;

    @media (max-width: 768px) {
      font: var(--font-label-4-light);
      margin-bottom: 0;

      ${Plus} {
        display: inline-block;
      }
    }
  }

  .nav-list {
    font: var(--font-label-2);
    opacity: 50%;

    @media (max-width: 768px) {
      letter-spacing: 0.04rem;
    }

    li {
      margin-bottom: 0.9rem;
    }
  }

  .site-meta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: var(--spacing-base);

    .nav-inner-wrapper {
      width: auto;

      @media (max-width: 960px) {
        flex-wrap: wrap;
        margin-top: 5rem;
        max-width: 120rem;
        width: 65rem;
      }

      @media (max-width: 768px) {
        width: 100%;
      }
    }

    @media (max-width: 1300px) {
      align-items: end;
    }
  }

  .contact-meta {
    font-size: var(--font-size-label-2);
    font-style: normal;
    white-space: nowrap;

    @media (max-width: 1300px) {
      white-space: normal;
      text-align: right;
      line-height: 1.3;

      .pipe {
        display: none;
      }

      .mobile-line {
        display: block;
        white-space: nowrap;
      }
    }
    @media (max-width: 960px) {
      width: 65rem;
    }
    @media (max-width: 768px) {
      text-align: center;
      width: 100%;
    }
  }

  .sub-nav-list {
    margin-top: 1rem;
  }

  .sub-nav-link {
    &:before {
      content: '';
      display: inline-block;
      width: 0.5em;
      height: 0.5em;
      border-left: 1px solid currentColor;
      border-bottom: 1px solid currentColor;
      margin-right: 0.3em;
      vertical-align: middle;
      transform: translateY(-0.25em);
    }
  }

  .apps-store-wrapper {
    display: flex;
    gap: var(--spacing-sm);
    width: 100%;

    @media (min-width: 769px) and (max-width: 1300px) {
      margin-bottom: -4.5rem;
    }
    @media (max-width: 768px) {
      gap: var(--spacing-xs);
    }
  }

  .app-store-button {
    display: inline-block;
    width: 128px;

    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;
