export const locales = [
  'en-US',
  'en-GB',
  'es-419',
  'pt-BR',
  'zh-CN',
  'zh-Hant-HK',
  'da-DK',
  'de-DE',
  'es-ES',
  'fr-FR',
  'it-IT',
  'nl-NL',
  'nb-NO',
  'pl-PL',
  'sv-SE',
  'ru-RU',
  'uk-UA',
  'ja-JP',
  'ko-KR',
  // TODO (jackyang) Remove this after strapi migration
  'en',
];

export const defaultLocale = 'en-US';

const localesSet = new Set(locales);

export function isValidLocale(locale: string | undefined) {
  return locale && localesSet.has(locale);
}

export function getLocaleFromPath(path: string) {
  const localeSegment = path.split('/')[1];
  return isValidLocale(localeSegment) ? localeSegment : defaultLocale;
}

export function getPathWithLocale(path: string, locale: string): string {
  // Get the segments by splitting on /. The first segment is an empty string.
  const segments = path.split('/').filter((segment, index) => index !== 0 || segment !== '');

  // Get the segments that represent the page path. The first one might just be
  // a locale.
  const [maybeLocaleSegment, ...remainingSegments] = segments;
  const pathSegments = isValidLocale(maybeLocaleSegment) ? remainingSegments : segments;

  // Get the segments with locale included. If the locale is the default locale,
  // we don't need to include the locale.
  const pathSegmentsWithLocale = locale === defaultLocale ? pathSegments : [locale, ...pathSegments];

  // Turn this back into a string, adding an empty segment to get the preceding slash.
  return ['', ...pathSegmentsWithLocale].join('/');
}
