import clsx from 'clsx';
import { gsap } from 'gsap';
import React, { useRef } from 'react';

import styled from 'styled-components';

import ArrowDown from '@/components/icons/arrow-down.svg';
import useGsapEffect from '@/hooks/useGsapEffect';
import type { FeaturesHeroProps as Props, MediaWithMeta } from '@/utilities/strapi/types/componentTypes';

import Video from '../Elements/Video';
import GradientBackground from '../Hydra/GradientBackground';
import TextEditor from '../TextEditor';
import LineBreakTitle from '../utils/LineBreakTitle';

const StyledFeaturesHero = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  min-height: clamp(50vw, 100vh, 70vw);

  @media (max-width: 768px) {
    min-height: max(100vh, 150vw);
  }

  &.has-product-ui {
    min-height: clamp(50vw, 130vh, 90vw);

    @media (max-width: 768px) {
      min-height: max(50vw, 150vh);
    }

    .content {
      min-height: clamp(50vw, 100vh, 90vw);

      @media (max-width: 768px) {
        min-height: 100dvh;
      }
    }

    .product-uis {
      display: flex;
    }

    .arrow {
      display: none;
    }
  }

  .content {
    position: relative;
    width: 100%;
    min-height: clamp(50vw, 100vh, 70vw);
    padding: max(100vh - 90vw, 17rem)
      max(env(safe-area-inset-right), calc(5rem + var(--rem-scale-viewport-half-excess))) 5rem
      max(env(safe-area-inset-left), calc(5rem + var(--rem-scale-viewport-half-excess)));
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    @media (max-width: 768px) {
      padding: 14rem max(env(safe-area-inset-right), 2rem) calc(2rem + var(--browser-chrome-height))
        max(env(safe-area-inset-left), 2rem);
      min-height: 100dvh;
    }
  }

  .background {
    width: 100%;
    height: calc(100% + 12rem);
    position: absolute;
    left: 0;
    top: 0;

    > img {
      display: block;
      object-fit: cover;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      background: rgba(0, 0, 0, 0.25);
    }
  }

  .title {
    position: relative;
    width: 100%;

    h1 {
      color: var(--color-white);
      margin-bottom: 20rem;
      margin-top: 0;

      @media (max-width: 768px) {
        margin-bottom: 5rem;
      }
    }
  }

  .product-uis {
    pointer-events: none;
    position: absolute;
    right: 0;
    width: 100%;
    top: 100%;
    display: none;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: flex-end;
    padding-right: calc(5rem + var(--rem-scale-viewport-half-excess));
    transform: translateY(-35%);

    @media (max-width: 768px) {
      padding-right: 2rem;
      top: 100%;
      transform: none;
    }

    .product-ui {
      flex-shrink: 0;
      margin-left: -7rem;
      z-index: 101;
      margin-top: 13rem;

      &:nth-child(2) {
        margin-top: 0;
        z-index: 100;
      }

      &:last-child {
        margin-right: 0;
      }

      @media (max-width: 768px) {
        max-width: 50%;
        margin-left: -1rem;
        margin-top: 5.7rem;

        &:nth-child(2) {
          margin-top: 0;
        }

        img {
          height: auto !important;
        }
      }
    }
  }

  .arrow {
    width: 7rem;
    height: 7rem;
    position: absolute;
    right: 5rem;
    top: calc(100vh - 5rem - 7rem);
    color: var(--color-white);
    appearance: none;
    background: none;
    padding: 0;
    margin: 0;
    border-radius: 0;

    @media (max-width: 768px) {
      width: 3.8rem;
      height: 3.8rem;
      right: 2rem;
      top: calc(100vh - 2.2rem - 3.8rem);
    }
  }
`;

const Background = styled(GradientBackground)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`;

const Body = styled.div`
  max-width: 41.6rem;
  color: var(--color-white);

  @media (max-width: 768px) {
    position: relative;
    bottom: auto;
    left: auto;
    max-width: 26.9rem;
  }

  .text-base {
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: -0.048rem;

    @media (max-width: 768px) {
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      letter-spacing: -0.016rem;
    }
  }
`;

export default function FeaturesHero({
  id,
  title,
  background,
  body,
  productui = [] as MediaWithMeta[],
  backgroundColor,
}: Props) {
  const root = useRef<HTMLDivElement>(null);
  const tl = useRef<gsap.core.Timeline>();
  const atl = useRef<gsap.core.Timeline>();
  const productUisRef = useRef<HTMLDivElement[] | null[]>([]);
  const titleRef = useRef<HTMLDivElement>(null);
  const bodyRef = useRef<HTMLDivElement>(null);
  const bgRef = useRef<HTMLDivElement>(null);
  const arrowRef = useRef<HTMLButtonElement>(null);

  const scrollDown = () => {
    window.scrollTo({
      top: root.current?.offsetHeight || 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  useGsapEffect(root, () => {
    const updateProgress = (progress: number) => {
      const isMobile = window.innerWidth <= 768;
      productui.forEach((_ui, i) => {
        gsap.to(productUisRef.current[productui.length - 1 - i], {
          y: `-${(isMobile ? 40 : 60) * progress}vh`,
          duration: 0.5 + (i + 1) * 0.15,
        });
      });
      gsap.to(titleRef.current, { y: `-${50 * progress}vh`, duration: 0.5 });
      gsap.to(bodyRef.current, { y: `-${(isMobile ? 50 : 25) * progress}vh`, duration: 0.5 });
      gsap.to(bgRef.current, { y: `-${12 * progress}rem`, duration: 0.5 });
    };

    tl.current = gsap.timeline({
      scrollTrigger: {
        trigger: root.current,
        // pin: true,
        start: 'top',
        end: 'bottom',
        scrub: false,
        onUpdate: (self) => updateProgress(self.progress),
      },
    });

    atl.current = gsap.timeline({
      paused: false,
      repeat: -1,
      repeatDelay: 3,
    });
    atl.current.fromTo(arrowRef.current, { y: '0%' }, { y: '25%', duration: 0.5, ease: 'bounce.easeIn' });
    atl.current.to(arrowRef.current, { y: '0%', ease: 'circ.easeOut', duration: 0.2 }, 0.5);
  });

  return (
    <div>
      <Background seed={id} colors={backgroundColor} targetLightness={85} />
      <StyledFeaturesHero ref={root} className={clsx({ 'has-product-ui': productui.length > 0 })}>
        <div ref={bgRef} className="background">
          {background && <Video media={background} />}
        </div>
        <div className="content">
          <div ref={titleRef} className="title">
            <LineBreakTitle size={1} align="alternating-reverse">
              {title}
            </LineBreakTitle>
          </div>
          <div className="product-uis">
            {productui
              .slice(0)
              .reverse()
              .map((ui, i) => (
                <div key={ui.id} ref={(p) => (productUisRef.current[i] = p)} className="product-ui">
                  <Video media={ui} preferredImageSize="medium" useRemSize transparent />
                </div>
              ))}
          </div>
          <Body ref={bodyRef}>
            <TextEditor {...body} />
          </Body>
          <button ref={arrowRef} className="arrow" onClick={scrollDown} aria-label="Scroll down to next section">
            <ArrowDown className="icon" />
          </button>
        </div>
      </StyledFeaturesHero>
    </div>
  );
}
