import clsx from 'clsx';
import React from 'react';

import styled from 'styled-components';

import ResponsiveImage, { getRemResponsiveSizes } from '@/components/Elements/ResponsiveImage';
import GradientBackground from '@/components/Hydra/GradientBackground';
import type { ImageGridWithTitleProps as Props } from '@/utilities/strapi/types/componentTypes';

import TextEditor from '../TextEditor';
import LineBreakTitle from '../utils/LineBreakTitle';

const imageWidths = [
  ['30rem', '16.2rem'],
  ['43.3rem', '18.9rem'],
  ['33.3rem', '9.8rem'],
  ['35rem', '11rem'],
  ['51.1rem', '16.5rem'],
  ['52.7rem', '30.3rem'],
];

const StyledImageGridWithTitle = styled.div`
  position: relative;
  width: 100%;
  height: 201.6rem;
  overflow: hidden;

  @media (max-width: 768px) {
    height: 161rem;
  }

  &.short {
    height: 170rem;

    @media (max-width: 1120px) {
      height: 185rem;
    }

    @media (max-width: 768px) {
      height: 130rem;
    }
  }

  .images > div img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }

  .images {
    position: absolute;
    top: 10rem;
    left: calc(50% - 96rem);
    width: 192rem;
    height: calc(100% - 10rem);

    @media (max-width: 768px) {
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }

    > div {
      position: absolute;

      &:nth-child(1) {
        width: ${imageWidths[0][0]};
        left: 107rem;
        top: 0rem;

        @media (max-width: 768px) {
          width: ${imageWidths[0][1]};
          left: auto;
          right: -1.6rem;
          top: 9.7rem;
        }
      }

      &:nth-child(2) {
        width: ${imageWidths[1][0]};
        left: 0rem;
        top: 22.3rem;

        @media (max-width: 768px) {
          width: ${imageWidths[1][1]};
          left: -6.3rem;
          top: 11.3rem;
        }
      }

      &:nth-child(3) {
        width: ${imageWidths[2][0]};
        right: 0rem;
        top: 48rem;

        @media (max-width: 768px) {
          width: ${imageWidths[2][1]};
          left: 33.6rem;
          top: 26.2rem;
        }
      }

      &:nth-child(4) {
        width: ${imageWidths[3][0]};
        left: -2.4rem;
        top: 78.5rem;

        @media (max-width: 768px) {
          width: ${imageWidths[3][1]};
          left: -2.3rem;
          top: 59.2rem;
        }
      }

      &:nth-child(5) {
        width: ${imageWidths[4][0]};
        right: 21.2rem;
        top: 92rem;

        @media (max-width: 768px) {
          width: ${imageWidths[4][1]};
          right: auto;
          bottom: auto;
          left: 23.3rem;
          top: 65.9rem;
        }
      }

      &:nth-child(6) {
        width: ${imageWidths[5][0]};
        left: 16.2rem;
        top: 155rem;

        @media (max-width: 768px) {
          width: ${imageWidths[5][1]};
          left: -3.1rem;
          top: 148rem;
        }
      }
    }
  }
`;

const Background = styled(GradientBackground)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`;

const Title = styled(LineBreakTitle).attrs({ size: 2, align: 'center' })`
  position: absolute;
  width: 150rem;
  left: calc(50% - 75rem);
  top: 34.8rem;
  line-height: 87%;
  letter-spacing: -0.06em;

  @media (max-width: 768px) {
    width: 29.5rem;
    left: calc(50% - 14.75rem);
    line-height: 102%;
    letter-spacing: -0.07em;
    top: 39.9rem;
  }
`;

const Blurb = styled.div`
  position: absolute;
  top: 119rem;
  left: calc(47.4rem + var(--rem-scale-viewport-half-excess));
  width: 55rem;

  @media (max-width: 768px) {
    width: 24rem;
    left: 2.8rem;
    top: 92rem;
  }
`;

const BlurbBody = styled.div`
  .text-base {
    font-size: max(14px, 2rem);
    font-weight: 350;
    line-height: 120%;
    letter-spacing: -0.02em;

    @media (max-width: 768px) {
      font: var(--font-size-body);
    }
  }

  .text-lg {
    font-size: var(--font-size-body-lg);
  }

  h3 {
    font-size: 3.6rem;
    font-style: normal;
    font-weight: 350;
    line-height: 100%;
    letter-spacing: -0.072rem;
    margin-bottom: 1.5rem;

    @media (max-width: 768px) {
      font-size: 3rem;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      letter-spacing: -0.06rem;
    }
  }
`;

const CtaBlurb = styled.div`
  position: absolute;
  top: 162rem;
  right: calc(47.4rem + var(--rem-scale-viewport-half-excess));
  width: 36rem;

  @media (max-width: 768px) {
    width: 19.9rem;
    right: 2rem;
    top: auto;
    bottom: 25rem;
  }
`;

function getResponsiveSizes(index: number): string {
  const [desktop, mobile] = imageWidths[index % imageWidths.length];
  return getRemResponsiveSizes(desktop, mobile);
}

export default function ImageGridWithTitle({ id, title, body1, body2, images = [], backgroundColor }: Props) {
  const colors = images.map((item) => item.image?.data?.attributes.colors).filter(Boolean);
  const bgColors = backgroundColor || colors[Math.min(3, colors.length - 1)];
  return (
    <StyledImageGridWithTitle className={clsx({ short: !body2 && images.length < 6 })}>
      <Background seed={id} colors={bgColors} targetLightness={85} />
      <div className="images">
        {images.map((image, index) => (
          <div key={image.id}>
            {image && <ResponsiveImage image={image} sizes={getResponsiveSizes(index)} transparent />}
          </div>
        ))}
      </div>
      <Title>{title}</Title>
      <Blurb>
        <BlurbBody>{body1 && <TextEditor {...body1} minHeadingLevel={3} size="large" />}</BlurbBody>
      </Blurb>
      <CtaBlurb>
        <BlurbBody>{body2 && <TextEditor {...body2} minHeadingLevel={3} />}</BlurbBody>
      </CtaBlurb>
    </StyledImageGridWithTitle>
  );
}
