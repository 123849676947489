import React from 'react';

import styled from 'styled-components';

import type { CallToActionButtonProps } from '@/utilities/strapi/types/componentTypes';

import type { StrapiMinimalPageInfo } from '@/utilities/strapi/types/queryResponseTypes';

import CTAButton from '../Blocks/CTAButton';

type Alignment = 'left' | 'center' | 'right';

export interface Props {
  data: {
    id: string;
    link: string;
    text: string;
    style: 'primary' | 'secondary';
    alignmentTune?: {
      alignment: Alignment;
    };
    slug?: string;
    locale?: string;
    pageData?: StrapiMinimalPageInfo;
    gcd?: string;
  };
  className?: string;
}

function flexJustify(alignment?: Alignment) {
  switch (alignment) {
    case 'center':
      return 'center';
    case 'right':
      return 'end';
    default:
      return 'start';
  }
}

const Styled = styled.div<{ $alignment?: Alignment }>`
  display: flex;
  justify-content: ${(props) => flexJustify(props.$alignment)};
  margin-bottom: 1.3em;
`;

export default function CTAButtonBlock({ data, className }: Props) {
  const props: CallToActionButtonProps = {
    external_url: data?.link,
    label: data?.text,
    style: data?.style || 'primary',
    genericClickDetails: data?.gcd,
  };

  if (data.pageData) {
    props.page = data.pageData;
    props.external_url = undefined;
  } else if (data.id && data.slug && data.locale) {
    props.page = {
      id: +data.id,
      Slug: data.slug,
      locale: data.locale,
    };
    props.external_url = undefined;
  }

  props.className = className;

  return (
    <Styled $alignment={data.alignmentTune?.alignment}>
      <CTAButton {...props} />
    </Styled>
  );
}
