import React, { createContext, useContext } from 'react';
import { useSnapshot } from 'valtio';
import { derive, subscribeKey } from 'valtio/utils';

import useMegaHoverState from '@/components/Header/useMegaHoverState';
import useStrapiGlobals, { SectionTheme } from '@/context/StrapiGlobalsContext';
import useConstant from '@/hooks/useConstant';
import getHydraNextBus from '@/hydra/next/getHydraNextBus';

interface HeaderState {
  isScrolled: boolean;
  isMobileMenuOpen: boolean;
  hoveredMegaMenuId: number | null;
  focusedMegaMenuId: number | null;
  clickedMegaMenuId: number | null;
  activeMegaMenuId: number | null; // derived from hovered and clicked - clicked takes precedence
  megaMenuHasTrappedFocus: boolean;
  themeUnderHeader: SectionTheme;
  theme: SectionTheme;
}

const Context = createContext<HeaderState | null>(null);

export function HeaderStateProvider({ children }: { children?: React.ReactNode }) {
  const { sectionThemes } = useStrapiGlobals();
  const { state: megaHoverState } = useMegaHoverState();
  const headerState = useConstant(() => {
    const { state } = (
      getHydraNextBus() as {
        next: {
          state: {
            headerState: Partial<HeaderState>;
          };
        };
      }
    ).next;

    state.headerState = {
      isScrolled: false,
      isMobileMenuOpen: false,
      hoveredMegaMenuId: null,
      clickedMegaMenuId: null,
      themeUnderHeader: sectionThemes[0] || SectionTheme.light,
    };

    const proxy = state.headerState;

    derive(
      {
        activeMegaMenuId: (get) => get(proxy).clickedMegaMenuId || get(proxy).hoveredMegaMenuId,
        megaMenuHasTrappedFocus: (get) => !!get(proxy).clickedMegaMenuId,
        theme: (get) => {
          if (get(proxy).activeMegaMenuId) {
            return SectionTheme.light;
          }
          return get(proxy).themeUnderHeader;
        },
      },
      { proxy: state.headerState },
    );

    subscribeKey(megaHoverState, 'hovered', (hovered) => {
      if (!hovered) {
        proxy.hoveredMegaMenuId = null;
      }
    });

    return state.headerState as HeaderState;
  });

  return <Context.Provider value={headerState}>{children}</Context.Provider>;
}

export function useHeaderState(): HeaderState {
  const headerState = useContext(Context);
  if (!headerState) {
    throw new Error('Missing HeaderStateProvider');
  }

  return headerState;
}

export function useHeaderStateSnapshot(): HeaderState {
  const headerState = useHeaderState();
  return useSnapshot(headerState);
}
