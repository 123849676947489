import type { CSSProperties, ReactNode } from 'react';
import React from 'react';

import styled from 'styled-components';

import HydraSnippet from '@/hydra/next/HydraSnippet';
import type { RGBArray } from '@/utilities/colors';
import { hexColorToRGB, preprocessGradientColors } from '@/utilities/colors';
import getDeterministicRandom from '@/utilities/getDeterministicRandom';
import type { MediaColors } from '@/utilities/strapi/types/componentTypes';
import type { BackgroundColorProps } from '@/utilities/strapi/types/utilsTypes';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  seed?: number;
  colors?: BackgroundColorProps | MediaColors | null;
  targetLightness?: number;
  children?: ReactNode | ReactNode[];
  style?: CSSProperties;
}

const StyledGradientBackground = styled.div`
  height: 100%;
`;

function rgbToCss([r, g, b]: RGBArray, alpha = 1) {
  return `rgba(${Math.round(r * 255)}, ${Math.round(g * 255)}, ${Math.round(b * 255)}, ${alpha})`;
}

function colorStops(rgb: RGBArray, stops: Array<[number, number]>) {
  return stops.map(([alpha, stop]) => `${rgbToCss(rgb, alpha)} ${stop}%`).join(',');
}

function rotate(degrees: number, x: number, y: number, cx = 0, cy = 0) {
  const angle = degrees * (Math.PI / 180);
  const c = Math.cos(angle);
  const s = Math.sin(angle);

  x = x - cx;
  y = y - cy;

  return [Math.round(x * c - y * s + cx), Math.round(x * s + y * c + cy)];
}

function size(degrees: number, w: number, h: number) {
  const [x1, y1] = rotate(degrees, w, 0, 0, 0);
  const [x2, y2] = rotate(degrees, 0, h, 0, 0);
  const x = Math.max(Math.abs(x1), Math.abs(x2));
  const y = Math.max(Math.abs(y1), Math.abs(y2));
  return `${x}% ${y}%`;
}

function pos(degrees: number, x: number, y: number) {
  const [nx, ny] = rotate(degrees, x, y, 50, 50);
  return `${nx}% ${ny}%`;
}

function BackgroundColorToColors(
  colors: BackgroundColorProps | MediaColors | null | undefined,
): [string, string, string, string] | null {
  if (!colors) {
    return null;
  }
  if (Array.isArray(colors)) {
    return colors;
  }
  return [colors.color, colors.color, colors.color, colors.color];
}

export default function GradientBackground({ seed, colors, targetLightness, style, children, ...rest }: Props) {
  colors = preprocessGradientColors(BackgroundColorToColors(colors), targetLightness);
  const angle = getDeterministicRandom(seed, 0, 360, true);
  const rgb = colors.map(hexColorToRGB);
  const backgroundImage = [
    `radial-gradient(${size(angle, 100, 130)} at ${pos(angle, 80, 56)}, ${colorStops(rgb[3], [
      [0.3, -10],
      [0.1, 40],
      [0, 60],
    ])})`,
    `radial-gradient(${size(angle, 120, 40)} at ${pos(angle, 110, 10)}, ${colorStops(rgb[0], [
      [0.8, 0],
      [0, 80],
    ])})`,
    `radial-gradient(${size(angle, 200, 200)} at ${pos(angle, -150, 10)}, ${colorStops(rgb[0], [
      [1, 0],
      [1, 70],
      [0, 95],
    ])})`,
    `linear-gradient(${-75 + angle}deg, ${colorStops(rgb[3], [
      [0, 0],
      [0, 70],
      [0.6, 110],
    ])})`,
    `radial-gradient(${size(angle, 55, 65)} at ${pos(angle, 80, 100)}, ${colorStops(rgb[2], [
      [1, 0],
      [0, 100],
    ])})`,
    `radial-gradient(${size(angle, 30, 45)} at ${pos(angle, 40, 115)}, ${colorStops(rgb[2], [
      [1, 0],
      [0, 90],
    ])})`,
  ].join(',');
  style = {
    ...style,
    backgroundColor: colors[1],
    backgroundImage,
  };
  const data = {
    type: 'GradientBackground',
    colors,
    targetLightness,
  };
  return (
    <StyledGradientBackground style={style} data-hydra={JSON.stringify(data)} {...rest}>
      <HydraSnippet />
      {children}
    </StyledGradientBackground>
  );
}
