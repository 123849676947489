import type { ImgHTMLAttributes } from 'react';
import React from 'react';

import { remToMaxPxDesktop, remToVwMobile } from '@/hooks/useRemScale';
import getAlternativeText from '@/utilities/strapi/getAlternativeText';
import getMediaFormats from '@/utilities/strapi/getMediaFormats';
import getSortedMediaSrcs from '@/utilities/strapi/getSortedMediaSrcs';
import parseMediaUrl from '@/utilities/strapi/parseMediaUrl';
import type {
  ImageWithMeta,
  MediaWithMeta,
  ImageBlockWithMeta,
  MediaFormat,
} from '@/utilities/strapi/types/componentTypes';

export interface ResponsiveImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  image: ImageWithMeta | MediaWithMeta | ImageBlockWithMeta;
  transparent?: boolean;
  useRetina?: boolean;
  preferredSize?: string;
  useRemSize?: boolean;
  scaleFactor?: number;
}

interface Size {
  width: number;
  height: number;
}

export function getDisplaySize(
  srcs: MediaFormat[],
  preferredSize?: string,
  useRetina = false,
  scaleFactor = 1,
): Size | undefined {
  let selected: MediaFormat;
  if (preferredSize) {
    selected = srcs.find((src) => src.name === preferredSize) || srcs[srcs.length - 1];
  } else {
    selected = srcs[srcs.length - 1];
  }
  let width = selected?.width;
  let height = selected?.height;
  if (typeof width !== 'number' || typeof height !== 'number') {
    return;
  }
  width *= scaleFactor;
  height *= scaleFactor;
  if (srcs.length === 1 || useRetina) {
    // only one src (probably using base attributes above),
    // so use half the original size for retina
    width /= 2;
    height /= 2;
  }
  return { width, height };
}

export function getRemSizeStyle({ width, height }: Size) {
  return {
    width: `${width / 10}rem`,
    height: `${height / 10}rem`,
  };
}

export function getRemResponsiveSizes(desktop: number | string, mobile: number | string = desktop) {
  return `(max-width: 768px) ${remToVwMobile(mobile)}, ${remToMaxPxDesktop(desktop)}`;
}

export default function ResponsiveImage({
  image,
  loading = 'lazy',
  decoding = 'async',
  style = {},
  transparent = false,
  useRetina = false,
  preferredSize,
  useRemSize = false,
  scaleFactor = 1,
  sizes,
  ...rest
}: ResponsiveImageProps) {
  const media = (image as ImageWithMeta)?.image || (image as MediaWithMeta)?.media;
  if (!media) {
    return null;
  }

  const attributes = media?.data?.attributes;
  const formats = getMediaFormats(media);

  const isSVG = attributes?.mime.startsWith('image/svg');

  if (isSVG && attributes) {
    return (
      <img
        src={parseMediaUrl(attributes)}
        alt={getAlternativeText(image) || ''}
        width={attributes.width || undefined}
        height={attributes.height || undefined}
        {...rest}
      />
    );
  }

  if (Object.keys(formats).length === 0) {
    return null;
  }

  const srcs = getSortedMediaSrcs(formats);
  const colors = attributes?.colors;
  if (colors && !transparent) {
    style.backgroundColor = colors[0];
  }
  const displaySize = getDisplaySize(srcs, preferredSize, useRetina, scaleFactor);
  if (useRemSize && displaySize) {
    const remSize = getRemSizeStyle(displaySize);
    Object.assign(style, remSize);
    if (!sizes) {
      sizes = getRemResponsiveSizes(remSize.width);
    }
  }
  if (!sizes) {
    sizes = '100vw';
  }

  return (
    <img
      src={parseMediaUrl(srcs[0])}
      alt={getAlternativeText(image) || ''}
      width={displaySize?.width}
      height={displaySize?.height}
      loading={loading}
      decoding={decoding}
      srcSet={srcs.map((src) => `${parseMediaUrl(src)} ${src.width}w`).join(',')}
      style={style}
      sizes={sizes}
      {...rest}
    />
  );
}
