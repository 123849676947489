export default function getPopulateParentQueryParams() {
  return {
    Parent: {
      populate: {
        page: {
          populate: {
            Slug: true,
            Parent: {
              populate: {
                page: {
                  populate: ['Slug'],
                },
                url_segment: {
                  populate: ['Slug'],
                },
                urlPrefixFallback: true,
              },
            },
          },
        },
        url_segment: {
          populate: {
            Slug: true,
            Parent: {
              populate: {
                page: {
                  populate: ['Slug'],
                },
                url_segment: {
                  populate: ['Slug'],
                },
                // TODO(jackyang) Clean this up. This only goes so deep, but yeah this shouldn't be populated in this case
                // urlPrefixFallback: false,
              },
            },
          },
        },
      },
    },
  };
}
