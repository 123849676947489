import React, { useRef } from 'react';

import styled from 'styled-components';

import type { PolicyCardsProps as Props } from '@/utilities/strapi/types/componentTypes';

import { PolicyRichCard } from './components/PolicyRichCard';

const StyledRoot = styled.div`
  position: relative;
  width: 100%;
  max-width: 150rem;
  margin: 0 auto;
  color: var(--color-black);
  padding 0 0 var(--spacing-xl) 0;
`;

const StyledContainerRichCards = styled.div`
  --gap: 4rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
  gap: var(--gap);

  @media (max-width: 768px) {
    --gap: 2rem;
    margin: 0 var(--gap);
    gap: var(--gap);
    grid-template-columns: 1fr;
  }
`;

export default function PolicyCards({ link_card: linkCard }: Props) {
  const root = useRef<HTMLDivElement>(null);

  return (
    <StyledRoot ref={root}>
      <StyledContainerRichCards>
        {linkCard.map((card) => (
          <PolicyRichCard id={card.id} href={card.href} body={card.body} key={card.href} />
        ))}
      </StyledContainerRichCards>
    </StyledRoot>
  );
}
