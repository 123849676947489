import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useKeyPressEvent } from 'react-use';
import styled from 'styled-components';

import LeftIcon from '@/components/icons/arrow-left.svg';
import RightIcon from '@/components/icons/arrow-right.svg';
import type { ImageWithMeta } from '@/utilities/strapi/types/componentTypes';

import Button from '../Elements/Buttons/Button';
import ResponsiveImage from '../Elements/ResponsiveImage';

interface IntegrationGalleryLightboxProps {
  images: ImageWithMeta[];
  selectedIndex: number;
}

export default function IntegrationGalleryLightbox({ images, selectedIndex }: IntegrationGalleryLightboxProps) {
  const [activeIndex, setActiveIndex] = useState(selectedIndex);

  useKeyPressEvent('ArrowLeft', gotoPrevious);
  useKeyPressEvent('ArrowRight', gotoNext);

  useEffect(() => {
    setActiveIndex(selectedIndex);
  }, [selectedIndex]);

  function gotoPrevious() {
    if (activeIndex > 0) {
      setActiveIndex(activeIndex - 1);
    } else {
      setActiveIndex(images.length - 1);
    }
  }

  function gotoNext() {
    if (activeIndex < images.length - 1) {
      setActiveIndex(activeIndex + 1);
    } else {
      setActiveIndex(0);
    }
  }

  return (
    <StyledIntegrationGalleryLightbox>
      <div className="nav-container">
        <Button icon={<LeftIcon />} onClick={() => gotoPrevious()} label="Previous" hideLabel className="inverse" />
      </div>
      <ul className="image-list">
        {images.map((image, index) => (
          <li key={image.id} className={clsx('image-item', { active: index === activeIndex })}>
            <ResponsiveImage image={image} className="media-gallery-image" />
          </li>
        ))}
      </ul>
      <div className="nav-container">
        <Button icon={<RightIcon />} onClick={() => gotoNext()} label="Next" hideLabel className="inverse" />
      </div>
    </StyledIntegrationGalleryLightbox>
  );
}

const StyledIntegrationGalleryLightbox = styled.article`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: var(--spacing-base);
  align-items: center;
  padding: var(--spacing-sm);
  width: 100vw;
  max-width: calc(100vw - (var(--spacing-xl) * 2));
  max-height: calc(100vh - (var(--spacing-xl) * 2));
  max-height: calc(100dvh - (var(--spacing-xl) * 2));

  .image-list {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    place-content: center;
  }

  .image-item {
    transition: opacity var(--animation-duration) var(--animation-timing);
    opacity: 0;
    grid-column: 1;
    grid-row: 1;
    overflow: hidden;
    width: 100%;

    &.active {
      opacity: 1;
    }
  }

  .media-gallery-image {
    display: block;
    object-fit: contain;
    width: 100%;
    height: 100%;
    max-width: calc(100vw - (var(--spacing-xl) * 2.5));
    max-height: calc(100vh - (var(--spacing-xl) * 2.5));
    max-height: calc(100dvh - (var(--spacing-xl) * 2.5));
  }

  @media (max-width: 768px) {
    gap: var(--spacing-xs);
    padding: var(--spacing-xs);

    max-width: calc(100vw - (var(--spacing-sm) * 2));
    max-height: calc(100vh - (var(--spacing-sm) * 2));
    max-height: calc(100dvh - (var(--spacing-sm) * 2));
  }
`;
