import getPageSlugs from '@/utilities/getPageSlugs';
import type { StrapiMinimalPageInfo } from '@/utilities/strapi/types/queryResponseTypes';

export default function getPageHref(page: StrapiMinimalPageInfo, anchor?: string) {
  if (anchor && anchor.startsWith('#')) {
    anchor = anchor.slice(1);
  }
  const slugs = getPageSlugs(page);
  return [slugs.join('/'), anchor].filter(Boolean).join('#');
}
