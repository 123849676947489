import React from 'react';
import styled from 'styled-components';

import TextEditor from '@/components/TextEditor';
import MaxWidthContainer from '@/components/utils/MaxWidthContainer';
import type { FourColumnTextProps } from '@/utilities/strapi/types/componentTypes';

export const FourColumnText = ({ heading_section: headingSection, column, anchorId }: FourColumnTextProps) => (
  <MaxWidthContainer>
    <TextContainer id={anchorId?.anchorId}>
      <TextEditor size="large" text={headingSection?.text} />
    </TextContainer>

    <Columns>
      {column?.map(({ id, text, link }) =>
        link?.externalUrl ? (
          <a href={link.externalUrl} aria-label={link?.text} key={id}>
            <TextEditor text={text} />
          </a>
        ) : (
          <TextEditor key={id} text={text} />
        ),
      )}
    </Columns>
  </MaxWidthContainer>
);

const TextContainer = styled.div`
  max-width: 450px;
  margin: 0 auto;
  margin-bottom: 9rem;
`;

const Columns = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 1.5rem;
  row-gap: 3.25rem;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
