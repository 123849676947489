import Head from 'next/head';
import React from 'react';

import useStrapiGlobals from '@/context/StrapiGlobalsContext';
import parseMediaUrl from '@/utilities/strapi/parseMediaUrl';
import type { SiteSettingsProps as Props } from '@/utilities/strapi/types/componentTypes';

export default function SiteSettings({ page, alternates = [] }: Props) {
  const { siteSettings } = useStrapiGlobals();
  const openGraphType = page.metadata?.openGraphType || siteSettings.defaultOpenGraphType;
  const openGraphImage = page.metadata?.openGraphImage?.data || siteSettings.defaultOpenGraphImage?.data;
  const twitterCardType = page.metadata?.twitterCardType || siteSettings.defaultTwitterCardType;
  const twitterCreator = page.metadata?.twitterCreator || siteSettings.defaultTwitterCreator;

  // TODO(jackyang) We want to switch out how we serve these media
  // Long-term, this shouldn't be necessary since we should migrate all the URLs in the DB to reflect what they actually are
  return (
    <Head>
      <title>{`${page.metadata?.menuTitle || page.Title} — ${siteSettings.siteTitle}`}</title>
      <meta property="og:url" content="https://patreon.com/" />
      <meta property="og:site_name" content="Patreon" />
      {alternates.map((alternate) => (
        <link key={alternate.hrefLang} rel="alternate" {...alternate} />
      ))}
      {page.metadata?.metaDescription && (
        <>
          <meta name="description" content={page.metadata?.metaDescription} />
          <meta property="og:description" content={page.metadata?.metaDescription} />
        </>
      )}
      {openGraphType && <meta property="og:type" content={openGraphType} />}
      {openGraphImage && (
        <>
          <meta property="og:image" content={parseMediaUrl(openGraphImage.attributes)} />
          <meta property="og:image:width" content={`${openGraphImage.attributes.width}`} />
          <meta property="og:image:height" content={`${openGraphImage.attributes.height}`} />
        </>
      )}
      {twitterCardType && <meta name="twitter:card" content={twitterCardType} />}
      {twitterCreator && <meta name="twitter:creator" content={twitterCreator} />}
    </Head>
  );
}
