import { gsap } from 'gsap';
import React, { useRef } from 'react';

import styled from 'styled-components';

import ResponsiveImage from '@/components/Elements/ResponsiveImage';
import useGsapEffect from '@/hooks/useGsapEffect';
import useIsMobile from '@/hooks/useIsMobile';
import type { TitleAndLogoLockupProps as Props } from '@/utilities/strapi/types/componentTypes';

import GradientBackground from '../Hydra/GradientBackground';
import TextEditor from '../TextEditor';
import LineBreakTitle from '../utils/LineBreakTitle';

const StyledLogoLockup = styled.div`
  position: relative;
  width: 100%;
  max-width: 123rem;
  margin: 0 auto;
  color: var(--color-white);
  padding: 23rem 0 16rem 0;

  @media (max-width: 768px) {
    padding: 12.6rem 2rem;
  }

  .body {
    max-width: 43rem;
    float: right;
    clear: both;

    margin-right: -12rem;
    margin-top: -11rem;

    .text-base {
      font-size: 2.2rem;
      font-style: normal;
      font-weight: 350;
      line-height: 120%;
      letter-spacing: -0.044rem;

      @media (max-width: 768px) {
        font-size: 1.8rem;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        letter-spacing: -0.036rem;
        padding-left: 13rem;
      }
    }

    @media (max-width: 768px) {
      float: none;
      margin-right: 0;
      margin-top: 5rem;
    }
  }

  .logos {
    padding-top: 14rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
      padding-top: 5rem;
      flex-direction: column;
    }

    > * {
      margin: 0 2rem;

      @media (max-width: 768px) {
        margin: 0.8rem 0;
      }
    }
  }
`;

const Background = styled(GradientBackground)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`;

const Title = styled(LineBreakTitle).attrs({ size: 2 })``;

export default function TitleAndLogoLockup({ id, title, logos = [], body, anchorId, backgroundColor }: Props) {
  const root = useRef<HTMLDivElement | null>(null);
  const bodyRef = useRef<HTMLDivElement | null>(null);
  const logosRef = useRef<HTMLDivElement | null>(null);
  const tl = useRef<gsap.core.Timeline>();
  const { isMobile, getIsMobile } = useIsMobile();

  useGsapEffect(root, () => {
    const updateProgress = (progress: number) => {
      const isMobile = getIsMobile();
      const amt = isMobile ? 40 : 40;
      const ta = amt * 0.3;
      gsap.to([logosRef.current], { y: `-${ta * progress - ta / 2}vh`, duration: 0.5 });
    };

    tl.current = gsap.timeline({
      scrollTrigger: {
        trigger: root.current,
        start: 'top bottom',
        end: 'bottom',
        scrub: false,
        onUpdate: (self) => updateProgress(self.progress),
      },
    });
  });

  return (
    <div>
      <Background seed={id} colors={backgroundColor} targetLightness={85} />
      <StyledLogoLockup ref={root} id={anchorId?.anchorId}>
        <div className="content">
          <Title>{title}</Title>
          <div ref={bodyRef} className="body">
            <TextEditor {...body} />
          </div>
          <div ref={logosRef} className="logos">
            {logos.map((logo) => (
              <div className="logo" key={logo.id}>
                <ResponsiveImage
                  image={logo}
                  sizes="2000px"
                  transparent
                  useRetina
                  preferredSize="thumbnail"
                  useRemSize
                  scaleFactor={isMobile ? 0.5 : 1.2}
                />
              </div>
            ))}
          </div>
        </div>
      </StyledLogoLockup>
    </div>
  );
}
