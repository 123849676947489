import React from 'react';

import useStrapiGlobals from '@/context/StrapiGlobalsContext';

import getAlternativeText from '@/utilities/strapi/getAlternativeText';
import parseMediaUrl from '@/utilities/strapi/parseMediaUrl';
import type { ImageWithMeta } from '@/utilities/strapi/types/componentTypes';

interface ComponentProps {
  theme?: 'light' | 'dark';
  className?: string;
}

function Badge({ badge, text = '' }: { badge: ImageWithMeta; text?: string }) {
  const media = badge.image?.data?.attributes;
  if (!media) {
    return null;
  }
  return (
    <img
      src={parseMediaUrl(media)}
      width={media.width || 256}
      height={media.height || 76}
      alt={getAlternativeText(badge) || text}
    />
  );
}

export default function AppStoreLinks({ theme = 'dark', className }: ComponentProps) {
  const {
    siteSettings: { appStoreLinks },
  } = useStrapiGlobals();

  const appleLink = appStoreLinks.appleAppStoreLink;
  const appleBadge = (
    theme === 'dark' ? appStoreLinks.appleAppStoreBadge : appStoreLinks.appleAppStoreBadgeLight
  ) as ImageWithMeta;
  const googleLink = appStoreLinks.googleAppStoreLink;
  const googleBadge = (
    theme === 'dark' ? appStoreLinks.googleAppStoreBadge : appStoreLinks.googleAppStoreBadgeLight
  ) as ImageWithMeta;

  return (
    <>
      {googleLink && (
        <a
          href={googleLink?.externalUrl}
          className={className}
          target="_blank"
          rel="noopener noreferrer"
          title={googleLink.text}
        >
          <Badge badge={googleBadge} text={googleLink.text} />
        </a>
      )}
      {appleLink && (
        <a
          href={appleLink?.externalUrl}
          className={className}
          target="_blank"
          rel="noopener noreferrer"
          title={appleLink.text}
        >
          <Badge badge={appleBadge} text={appleLink.text} />
        </a>
      )}
    </>
  );
}
