import React from 'react';
import styled from 'styled-components';

import ResponsiveImage from '@/components/Elements/ResponsiveImage';
import StrapiLink from '@/components/utils/StrapiLink';
import type { ArticleTeaserProps as Props } from '@/utilities/strapi/types/componentTypes';

export default function ArticleTeaser({ headline, category, previewImage, link }: Props) {
  return (
    <StrapiLink {...link} useChildren>
      <StyledArticleTeaser>
        <figure className="feature-image">
          <ResponsiveImage
            image={previewImage}
            className="image"
            sizes="(min-width: 769px) 420px, (min-width: 640px) 50vw, 100vw"
          />
        </figure>
        <div className="article-content">
          <header className="article-header">
            <p className="eyebrow">{category}</p>
            <h5 className="article-title">{headline}</h5>
          </header>
        </div>
      </StyledArticleTeaser>
    </StrapiLink>
  );
}

const StyledArticleTeaser = styled.article`
  transition: transform var(--animation-duration-fast) var(--animation-timing);
  transform: scale(1);

  &:hover {
    transform: scale(1.05);
  }

  .feature-image {
    aspect-ratio: 16 / 9;
  }

  .image {
    object-fit: cover;
    display: block;
    width: 100%;
    height: 100%;
  }

  .article-content {
    padding: var(--spacing-sm);
  }

  .eyebrow {
    font: var(--font-label-0);
    letter-spacing: -0.0056rem;
    text-transform: uppercase;
    margin-bottom: var(--spacing-xs);
  }

  .article-title {
    font: var(--font-label-3);
  }
`;
