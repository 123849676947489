import type { StrapiComponent } from '@/utilities/strapi/types/componentTypes';
import type { StrapiPageAttributes, StrapiPageTypesAttributes } from '@/utilities/strapi/types/queryResponseTypes';
import type { StrapiPageModels } from '@/utilities/strapi/types/serviceClientTypes';
import { StrapiModel } from '@/utilities/strapi/types/serviceClientTypes';

function isFullBleedModule(component: StrapiComponent) {
  if (!component) {
    return false;
  }
  switch (component.__component) {
    case 'creators.creator-hero':
    case 'homepage.home-hero':
    case 'features.features-hero':
    case 'marketing.get-started':
    case 'homepage.image-grid-with-title':
    case 'homepage.large-quote-and-title':
    case 'marketing.floating-product-ui':
    case 'features.creator-grid':
    case 'pricing.title-and-rich-text-cards':
      return true;
    default:
      return false;
  }
}

export default function getPageHasFullBleedHero(page: StrapiPageTypesAttributes, pageType: StrapiPageModels) {
  if (pageType === StrapiModel.Pages) {
    return isFullBleedModule((page as StrapiPageAttributes).Components[0]);
  }
  return false;
}
