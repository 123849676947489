import React from 'react';
import styled from 'styled-components';

import MaxWidthContainer from '@/components/utils/MaxWidthContainer';
import getStrapiLinkHref from '@/components/utils/getStrapiLinkHref';
import renderCustomKernPairs from '@/components/utils/renderCustomKernPairs';
import type { RelatedArticlesProps as Props } from '@/utilities/strapi/types/componentTypes';

import ArticleTeaser from './ArticleTeaser';

export default function RelatedArticles({ header, articles }: Props) {
  const populatedArticles = articles.filter((article) => {
    if (!article.link) {
      return false;
    }
    const { page, externalUrl, anchor } = article.link;
    try {
      getStrapiLinkHref(page, externalUrl, anchor);
    } catch (e) {
      return false;
    }
    return true;
  });
  if (!populatedArticles.length) {
    return null;
  }
  return (
    <MaxWidthContainer>
      <StyledRelatedArticles>
        <header className="related-header">
          <h4 className="related-title">{renderCustomKernPairs(header)}</h4>
        </header>
        <div className="related-content">
          <ul className="related-list">
            {populatedArticles.map((article) => (
              <li key={article.id} className="related-item">
                <ArticleTeaser
                  headline={article.headline}
                  category={article.category}
                  previewImage={article.previewImage}
                  link={article.link}
                />
              </li>
            ))}
          </ul>
        </div>
      </StyledRelatedArticles>
    </MaxWidthContainer>
  );
}

const StyledRelatedArticles = styled.aside`
  .related-header {
    font: var(--font-h4);
    letter-spacing: -0.04em;
    margin-bottom: var(--spacing-base);
  }

  .related-list {
    display: grid;
    grid-gap: var(--spacing-sm);
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: 768px) {
    margin: 0;

    .related-list {
      grid-template-columns: 1fr 1fr;
    }
  }

  @media (max-width: 640px) {
    .related-list {
      grid-template-columns: 1fr;
    }
  }
`;
