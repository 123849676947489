import React from 'react';

import styled from 'styled-components';

import type { ImageWithMeta } from '@/utilities/strapi/types/componentTypes';
import type { Link } from '@/utilities/strapi/types/utilsTypes';

import ResponsiveImage from '../Elements/ResponsiveImage';
import StrapiLink from '../utils/StrapiLink';

export interface IntegrationNewsTeaserItemProps {
  id: number;
  Headline: string;
  teaserCopy: string;
  featureImage: ImageWithMeta;
  link: Link;
}

export default function IntegrationNewsTeaserItem({
  Headline,
  teaserCopy,
  featureImage,
  link,
}: IntegrationNewsTeaserItemProps) {
  return (
    <StyledIntegrationNewsTeaserItem>
      <StrapiLink {...link} useChildren>
        <figure className="featured-figure">
          <ResponsiveImage image={featureImage} className="featured-image" sizes="(min-width: 769px) 250px, 100vw" />
        </figure>
      </StrapiLink>
      <div className="article-content">
        <header className="article-header">
          <h4 className="article-title">{Headline}</h4>
        </header>
        <div className="article-teaser">
          <p className="article-teaser-copy">{teaserCopy}</p>
        </div>
        <footer className="article-footer">
          <StrapiLink {...link} className="article-link" />
        </footer>
      </div>
    </StyledIntegrationNewsTeaserItem>
  );
}

const StyledIntegrationNewsTeaserItem = styled.article`
  display: flex;
  gap: var(--spacing-base);

  .featured-figure {
    width: 20rem;
    flex-shrink: 0;
  }

  .featured-image {
    width: 100%;
  }

  .article-teaser {
    margin-bottom: var(--spacing-xs);
  }

  .article-title {
    font: var(--font-label-3);
    margin-bottom: var(--spacing-xs);
  }

  .article-link {
    text-decoration: underline;
    color: var(--color-highlight);
    font: var(--font-label-1);
    text-transform: uppercase;

    &:hover {
      color: var(--color-highlight-light);
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: var(--spacing-sm);

    .featured-figure {
      width: 100%;
    }
  }
`;
