import clsx from 'clsx';
import React from 'react';

import styled from 'styled-components';

import getStrapiComponentTheme from '@/components/PageTypes/getStrapiComponentTheme';
import type { ContentContainerProps as Props } from '@/utilities/strapi/types/componentTypes';

const StyledContentContainer = styled.div`
  width: 100%;
  position: relative;

  .container-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    font-size: var(--font-size-body);
  }
`;

export default function ContentContainer({ children, className, component }: Props) {
  return (
    <StyledContentContainer className={clsx(className)} data-theme={getStrapiComponentTheme(component)}>
      <div className="container-inner">{children}</div>
    </StyledContentContainer>
  );
}
