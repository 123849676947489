import { get, set, pick } from 'lodash';

import type { StrapiIntegration } from '@/utilities/strapi/types/apiResponseTypes';
import type {
  CallToActionButton,
  RelatedArticlesProps,
  StrapiComponent,
  CreatorCarouselProps,
  CreatorGridProps,
  HomeHeroProps,
  IntegrationPromoTilesCarouselProps,
} from '@/utilities/strapi/types/componentTypes';
import type { StrapiMinimalPageInfo } from '@/utilities/strapi/types/queryResponseTypes';
import type { Link } from '@/utilities/strapi/types/utilsTypes';

interface GenericParent {
  id: number;
  attributes: {
    Slug: string;
    Parent?: GenericParent;
  };
}

type Writeable<T> = { -readonly [P in keyof T]: T[P] };

function declutterLink(link: Link | CallToActionButton) {
  if (link?.page) {
    for (const path of [['page', 'data', 'attributes'], ['page']]) {
      let page = get(link, path) as StrapiMinimalPageInfo;
      if (page) {
        let Parent = page.Parent;
        page = pick(page, 'id', 'Slug', 'Title', 'locale', 'metadata', 'Parent');
        set(link, path, page);

        nextParent: while (Parent) {
          for (const path of [
            ['page', 'data', 'attributes'],
            ['url_segment', 'data', 'attributes'],
          ]) {
            const attributes = get(Parent, path) as GenericParent['attributes'];
            if (attributes) {
              set(Parent, path, {
                Slug: attributes.Slug,
                Parent: attributes.Parent || null,
              });
              Parent = attributes.Parent;
              continue nextParent;
            }
          }
          break;
        }
        break;
      }
    }
  }
}

function declutterIntegration(integration: Writeable<StrapiIntegration>) {
  // Strip off all but Title, shortDescription, shouldLinkExternally, Meta.featureImage, Meta.siteLink
  integration.attributes = pick(
    integration.attributes,
    'id',
    'locale',
    'localizations',
    'Slug',
    'Title',
    'shortDescription',
    'shouldLinkExternally',
    'Meta',
  );
  integration.attributes.Meta = pick(integration.attributes.Meta, 'featureImage', 'siteLink');
}

export default function declutterComponents(components: StrapiComponent[]) {
  for (const component of components) {
    const { __component: id, ...props } = component;
    switch (id) {
      case 'articles.related-articles':
        (props as RelatedArticlesProps).articles?.forEach((article) => {
          declutterLink(article.link);
        });
        break;
      case 'homepage.home-hero':
        (props as HomeHeroProps).Items?.forEach((item) => {
          declutterLink(item.Creator);
        });
        break;
      case 'creators.creator-carousel':
        (props as CreatorCarouselProps).slides?.forEach((slide) => {
          declutterLink(slide.link);
        });
        break;
      case 'features.creator-grid':
        (props as CreatorGridProps).creators?.forEach((creator) => {
          declutterLink(creator.creator);
        });
        break;
      case 'integrations.promo-tiles-carousel':
        (props as IntegrationPromoTilesCarouselProps).integrations?.data?.forEach((integration) => {
          declutterIntegration(integration);
        });
    }
  }
}
