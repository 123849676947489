import Cookies from 'js-cookie';

import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import CurrencyIcon from '@/components/icons/currency-us.svg';
import { CURRENCY_COOKIE_NAME } from '@/utilities/cookieNames';
import type { CurrencyData } from '@/utilities/currencyList';
import currencyList from '@/utilities/currencyList';

import { getTLD } from '@/utilities/getTLD';

import Button from '../Elements/Buttons/Button';

import { SelectDropdown } from '../Elements/SelectDropdown/SelectDropDown';
import { Modal } from '../Modal/Modal';

// interface CurrencySelectorProps {}

// export default function CurrencySelector({}: CurrencySelectorProps) {
export default function CurrencySelector({ className }: { className?: string }) {
  const DEFAULT_CURRENCY = 'USD';

  const dialog = useRef<HTMLDialogElement | null>(null);
  const dropdownRef = useRef<HTMLSelectElement | null>(null);

  const [isOpen, setIsOpen] = useState(false);
  const [currentCurrency, setCurrentCurrency] = useState<CurrencyData | undefined>(undefined);

  useEffect(() => {
    const cookie = Cookies.get(CURRENCY_COOKIE_NAME as string);

    if (cookie && cookie?.length === 3) {
      setCurrentCurrency(currencyList.find((currency) => currency.code === cookie));
    } else {
      setCurrentCurrency(currencyList.find((currency) => currency.code === DEFAULT_CURRENCY));
    }
  }, []);

  useEffect(() => {
    if (!currentCurrency?.code) {
      return;
    }
    const domain = getTLD();

    Cookies.set(CURRENCY_COOKIE_NAME as string, currentCurrency.code, { expires: 30, domain });
  }, [currentCurrency]);

  const updateCurrancy = () => {
    const selectedCurrency = dropdownRef?.current?.value;
    setCurrentCurrency(currencyList.find((currency) => currency.code === selectedCurrency));
    dialog?.current?.close();
    setIsOpen(false);
  };

  return (
    <StyledCurrencySelector className={className}>
      <div className="desktop-only">
        <Button
          variant="tertiary"
          icon={<CurrencyIcon />}
          onClick={() => setIsOpen(true)}
          label={currentCurrency?.code}
        />
      </div>
      <div className="mobile-only">
        <Button
          variant="tertiary"
          size="sm"
          fullWidth
          icon={<CurrencyIcon />}
          onClick={() => setIsOpen(true)}
          label={currentCurrency?.description}
        />
      </div>

      <Modal ref={dialog} onClose={() => setIsOpen(false)} open={isOpen}>
        <StyledModalContent>
          <header className="header">
            <h2 className="subheading-1">Choose your currency</h2>
          </header>

          <div className="content-wrapper">
            <SelectDropdown
              ref={dropdownRef}
              label="Select your region"
              options={currencyList.map((currency) => ({
                label: currency.description,
                value: currency.code,
              }))}
              value={currentCurrency?.code || ''}
              type="secondary"
              size="lg"
              fullWidth
            />
            <Button size="lg" fullWidth onClick={() => void updateCurrancy()}>
              Save & Update
            </Button>
          </div>
        </StyledModalContent>
      </Modal>
    </StyledCurrencySelector>
  );
}

const StyledCurrencySelector = styled.div`
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const StyledModalContent = styled.div`
  max-width: 50rem;
  padding: var(--spacing-xl);
  padding-bottom: var(--spacing-sm);

  .content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: var(--spacing-sm);
    margin-bottom: var(--spacing-sm);
  }

  @media (max-width: 768px) {
    padding: var(--spacing-base);
    padding-bottom: 0;
  }
`;
