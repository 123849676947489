import { useLenis } from '@studio-freight/react-lenis';

import clsx from 'clsx';
import { gsap } from 'gsap';
import { debounce } from 'lodash';
// import { useRouter } from 'next/router';
import type { ChangeEvent } from 'react';
import React, { useCallback, useRef, useState } from 'react';

import styled from 'styled-components';

import CloseIcon from '@/components/icons/close.svg';
import SearchSvg from '@/components/icons/search.svg';
import useGsapEffect from '@/hooks/useGsapEffect';

import type { SearchResults, SearchResultItem } from '@/types/api/search';

import type { Link } from '@/utilities/strapi/types/utilsTypes';

import Button from '../Elements/Buttons/Button';
import StrapiLink from '../utils/StrapiLink';

interface SearchProps {
  style?: 'mobile' | 'desktop';
  placeholder?: string;
  resultsLink?: Link;
}

const StyledSearch = styled.div`
  cursor: pointer;
  position: relative;
  width: fit-content;

  &:hover {
    .input-container {
      border-color: var(--color-black);
      background-color: var(--color-black);
      color: var(--color-white);
    }
  }

  .search-container {
    position: relative;
    line-height: 1;
  }

  .input-container {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6rem;
    border: 1px solid currentColor;
    position: relative;
    transition: background-color var(--animation-duration-fast) var(--animation-timing);
  }

  .search-input {
    cursor: pointer;
    display: block;
    background-color: transparent;
    width: calc(8em + 5.4rem);
    max-width: calc(8em + 5.4rem);
    height: 6rem;
    font: var(--font-label-2);
    letter-spacing: -0.036rem;
    text-indent: 5.4rem;
    border-radius: 6rem;
    outline-color: var(--color-black);
    transition: max-width var(--animation-duration-fast) var(--animation-timing);

    &::placeholder {
      width: 100%;
    }

    &:placeholder-shown {
      text-overflow: ellipsis;
    }

    &::-webkit-search-cancel-button {
      filter: grayscale(100%) invert(100%);
      margin-right: 2rem;
      cursor: pointer;
      display: none;
    }
  }

  .cancel-button {
    background-color: transparent;
    display: none;

    .icon-wrapper {
      width: 1.8rem;
      height: 1.8rem;
    }
  }

  .search-icon {
    width: 1.8rem;
    height: 1.8rem;
    margin-right: 1rem;
    position: absolute;
    left: 2.8rem;
    top: calc(50% - 0.9rem);
    pointer-events: none;
  }

  .results {
    list-style: none;
    display: block;
    position: absolute;
    right: 0;
    top: calc(6rem + var(--spacing-sm));
    color: var(--color-black);
    font-size: var(--button-font-size);
    border-radius: min(3.3rem, 33px);
    overflow: hidden;
    width: 100%;
    min-width: 320px;

    @media (max-width: 768px) {
      min-width: 290px;
      top: calc(6.4rem + var(--spacing-sm));
    }
  }

  .result-item {
    width: 100%;
    background: var(--color-white);
    transition-property: color, background-color;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;

    &:hover {
      background-color: var(--color-black);
      color: var(--color-white);
    }
  }

  .result-link {
    display: flex;
    padding: var(--spacing-sm);
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 16px;
  }

  .result-img-wrapper {
    flex-shrink: 0;
    width: 5rem;
    height: 5rem;
  }

  .result-img {
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  .result-content-wrapper {
    margin-left: 1rem;
    width: 100%;
  }

  .creator-name {
    font: var(--font-label-1);
    font-size: 16px;
    letter-spacing: -0.048rem;
  }

  .creation-name {
    margin-top: 0.3rem;
    opacity: 0.5;
    font: var(--font-label-0);
    font-size: 14px;
    letter-spacing: -0.014rem;
  }

  .see-all {
    padding: 0.5rem 0;
    padding: calc(var(--spacing-xs) / 2);
    border-bottom-left-radius: 3rem;
    border-bottom-right-radius: 3rem;
  }

  &.has-focus,
  &.has-results {
    width: 100%;

    .input-container {
      border-color: var(--color-black);
      background-color: var(--color-black);
      color: var(--color-white);
    }

    .search-input {
      width: 100%;
      max-width: 100%;
      border: none;
      outline: none;
    }

    .cancel-button {
      display: block;
    }
  }

  &.mobile {
    --transition: var(--animation-duration-fast) var(--animation-timing);

    padding: 0 var(--spacing-sm) var(--spacing-sm);
    width: 100%;
    transition: max-width var(--transition);

    .input-container {
      background-color: transparent;
      border-width: 1px;
      border-color: currentColor;
      justify-content: flex-start;
      padding: 0.7rem;
      transition: padding var(--transition);
    }

    .search-input {
      font-size: var(--font-label-1);
      width: 100%;
      height: auto;
      letter-spacing: -0.046rem;
      text-indent: 3.4rem;
      max-width: 100%;
      transition: max-width var(--transition);
    }

    .search-icon {
      left: 1.5rem;
      transition: left var(--transition);
    }

    &.scrolled {
      max-width: 7.3rem;

      .input-container {
        width: 2.4em;
        aspect-ratio: 1 / 1;
      }

      .search-icon {
        left: 1.1rem;
      }

      .search-input {
        max-width: 2.2rem;
      }
    }

    &.has-focus,
    &.has-results {
      .input-container {
        border-color: var(--color-action-inverse);
        background-color: var(--color-action-inverse);
        color: var(--color-action);
        width: 100%;
        aspect-ratio: auto;
      }

      .cancel-button {
        color: var(--color-action);
        /* display: block; */
        opacity: 1;
        pointer-events: auto;
      }

      &.scrolled {
        max-width: 100%;

        .search-icon {
          left: 1.5rem;
        }

        .search-input {
          max-width: 100%;
        }
      }
    }
  }
`;

export default function Search({ style = 'desktop', placeholder, resultsLink }: SearchProps) {
  // const router = useRouter();
  const searchInput = useRef<HTMLInputElement>(null);

  const root = useRef<HTMLDivElement>(null);
  const tl = useRef<gsap.core.Timeline | null>(null);
  const [results, setResults] = useState<SearchResultItem[]>([]);
  const [focused, setFocused] = useState(false);
  const [query, setQuery] = useState<string>('');

  const [isScrolled, setIsScrolled] = useState(false);

  useLenis(({ scroll }: { scroll: number }) => updateScroll(scroll));

  function updateScroll(scroll: number) {
    if (scroll > 64) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  }

  const search = debounce(
    useCallback(
      async (text: string) => {
        text = text.trim();
        if (text.length > 0) {
          try {
            // https://www.patreon.com/api/search?q=hellop&page%5Bsize%5D=5&src=navbar&json-api-version=1.0&include=[]
            const response = await fetch(
              `/api/search?q=${text}&page%5Bsize%5D=5&src=navbar&json-api-version=1.0&include=[]`,
            );
            const sampleData = (await response.json()) as SearchResults;
            if (sampleData.error) {
              // eslint-disable-next-line no-console
              console.error('Error getting search results', sampleData.error);
              setResults([]);
            } else {
              setResults(sampleData.data || []);
            }
          } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
          }
        } else {
          setResults([]);
        }
      },
      [setResults],
    ),
    300,
  );

  const handleInputChange = useCallback(
    (e: ChangeEvent) => {
      if (e.target instanceof HTMLInputElement) {
        void search(e.target.value);
      }
    },
    [search],
  );

  useGsapEffect(root, () => {
    tl.current = gsap.timeline({ paused: true });
  });

  function checkFocus() {
    setTimeout(() => {
      if (!root?.current?.contains(document.activeElement)) {
        setFocused(false);
        clearInput();
      }
    }, 300);
  }

  function clearInput() {
    setTimeout(() => {
      setResults([]);
      setQuery('');
      if (searchInput?.current) {
        searchInput.current.value = '';
      }
    }, 300);
  }

  const handleSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      window.location.href = `https://www.patreon.com/search?q=${query}`; // TODO hotfix for now
      // router
      //   .push(`https://www.patreon.com/search?q=${query}`) // TODO hotfix for now
      //   .then(() => clearInput())
      //   .catch((error) => {
      //     // eslint-disable-next-line no-console
      //     console.error(error);
      //   });
    },
    [query],
  );

  return (
    <StyledSearch
      ref={root}
      onFocus={() => {
        setFocused(true);
      }}
      onBlur={() => {
        checkFocus();
      }}
      className={clsx({ scrolled: isScrolled }, { 'has-results': results.length > 0 }, { 'has-focus': focused }, style)}
    >
      <div className="search-container">
        <form onSubmit={handleSubmit} className="input-container">
          <label id="search-label" className="sr-only">
            {placeholder}
          </label>
          <input
            ref={searchInput}
            className="search-input"
            placeholder={placeholder}
            data-results={results.length > 0}
            value={query}
            type="search"
            inputMode="search"
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            onChange={handleInputChange}
            onInput={(e) => setQuery(e.currentTarget.value)}
            aria-labelledby="search-label"
          />
          <SearchSvg className="search-icon" />
          <Button
            tabIndex={0}
            type="button"
            onClick={clearInput}
            icon={<CloseIcon />}
            label="Close"
            hideLabel
            className="cancel-button"
          />
        </form>

        {results.length > 0 && (
          <ul className="results" role="alert">
            {results.map((result) => (
              <li key={result.id} className="result-item">
                <StrapiLink externalUrl={result.attributes.url} target="_self" className="result-link">
                  <div className="result-img-wrapper">
                    <img
                      src={result.attributes.thumb}
                      alt={result.attributes.name}
                      className="result-img"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="result-content-wrapper">
                    <p className="creator-name">{result.attributes.name}</p>
                    <p className="creation-name">{result.attributes.creation_name}</p>
                  </div>
                </StrapiLink>
              </li>
            ))}
            <li className="result-item see-all">
              <StrapiLink
                externalUrl={`${resultsLink?.externalUrl}?q=${query}`}
                target="_self"
                className="result-link"
                callback={() => {
                  setResults([]);
                  setQuery('');
                }}
              >
                {resultsLink?.text}
              </StrapiLink>
            </li>
          </ul>
        )}
      </div>
    </StyledSearch>
  );
}
