import { gsap } from 'gsap';

import React, { useRef } from 'react';
import styled from 'styled-components';

import ArrowDown from '@/components/icons/arrow-down.svg';
import useGsapEffect from '@/hooks/useGsapEffect';

interface ScrollHintArrowProps {
  className?: string;
  sectionRef: React.MutableRefObject<HTMLDivElement | null>;
}

const StyledButton = styled.button`
  width: 7rem;
  height: 7rem;
  position: absolute;
  right: calc(5rem + var(--rem-scale-viewport-half-excess));
  bottom: 5rem;
  color: var(--color-white);
  appearance: none;
  background: none;
  padding: 0;
  margin: 0;
  border-radius: 0;

  @media (max-width: 768px) {
    width: 3.8rem;
    height: 3.8rem;
    right: 2rem;
    bottom: 2.2rem;
  }
`;

export default function ScrollHintArrow({ className, sectionRef }: ScrollHintArrowProps) {
  const ref = useRef<HTMLButtonElement | null>(null);

  const scrollDown = () => {
    window.scrollTo({
      top: sectionRef.current?.offsetHeight || 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  useGsapEffect(ref, () => {
    const t = gsap.timeline({
      paused: false,
      repeat: -1,
      repeatDelay: 3,
      onUpdate() {
        const y = gsap.getProperty(ref.current, 'yPercent');
        gsap.set(ref.current, {
          clipPath: `inset(${-y}% 0% ${y}% 0%)`,
        });
      },
    });

    t.fromTo(ref.current, { yPercent: 0 }, { yPercent: 150, duration: 0.7, ease: 'power3.in' });
    t.fromTo(ref.current, { yPercent: -100 }, { yPercent: 0, duration: 0.5, ease: 'circ.out' });

    return () => {
      t.kill();
    };
  });

  return (
    <StyledButton ref={ref} className={className} onClick={scrollDown} aria-label="Scroll down to next section">
      <ArrowDown className="icon" />
    </StyledButton>
  );
}
