import clsx from 'clsx';
import React from 'react';

import styled from 'styled-components';

import Download from '@/components/icons/download.svg';
import ExternalLink from '@/components/icons/external-link.svg';
import useStrapiGlobals from '@/context/StrapiGlobalsContext';
import type { ImageWithMeta } from '@/utilities/strapi/types/componentTypes';
import type { Link } from '@/utilities/strapi/types/utilsTypes';

import Button from '../Elements/Buttons/Button';
import type { ButtonProps } from '../Elements/Buttons/types';
import ResponsiveImage from '../Elements/ResponsiveImage';
import StrapiLink from '../utils/StrapiLink';

export interface IntegrationMetaProps {
  featureImage?: {
    id: number;
    alternativeText: string;
    image: ImageWithMeta;
  };
  installLink?: Link;
  siteLink?: Link;
  supportLink?: Link;
  privacyLink?: Link;
  appsPageLink?: Link;
}

interface RenderableLink {
  key: string;
  link: Link;
}

interface ButtonLink extends RenderableLink {
  key: string;
  link: Link;
  Icon: React.FC;
  buttonProps?: Partial<ButtonProps>;
}

export default function IntegrationMeta({
  featureImage,
  installLink,
  siteLink,
  supportLink,
  privacyLink,
}: IntegrationMetaProps) {
  const { integrationSettings } = useStrapiGlobals();
  const buttonLinks: ButtonLink[] = [
    {
      key: 'install',
      link: installLink,
      Icon: Download,
    },
    {
      key: 'site',
      link: siteLink,
      Icon: ExternalLink,
      buttonProps: {
        type: 'secondary' as ButtonProps['type'],
        className: 'bordered',
      },
    },
  ].filter(({ link }) => link?.externalUrl && link?.text) as ButtonLink[];
  const otherLinks: RenderableLink[] = [
    {
      key: 'support',
      link: supportLink,
    },
    {
      key: 'privacy',
      link: privacyLink,
    },
  ].filter(({ link }) => link?.externalUrl && link?.text) as RenderableLink[];
  return (
    <StyledIntegrationMeta>
      <figure className={clsx('featured-image', !featureImage && 'no-image')}>
        <ResponsiveImage image={featureImage as ImageWithMeta} sizes="(max-width: 768px) 100vw, 310px" />
      </figure>
      {buttonLinks.length > 0 && (
        <ul className="button-list">
          {buttonLinks.map(({ key, link, Icon, buttonProps }) => (
            <li key={key} className="button-item">
              <Button
                tag="a"
                icon={<Icon />}
                href={link.externalUrl}
                target="_blank"
                rel="noopener noreferrer"
                {...buttonProps}
              >
                {link.text}
              </Button>
            </li>
          ))}
        </ul>
      )}
      {otherLinks.length > 0 && (
        <ul className="link-list">
          {otherLinks.map(({ key, link }) => (
            <li key={key} className="link-item">
              <a href={link.externalUrl} target="_blank" rel="noopener noreferrer" className="link">
                {link.text}
              </a>
            </li>
          ))}
        </ul>
      )}
      <hr className="divider" />
      <StrapiLink {...integrationSettings?.appsPageLink} className="apps-link" />
    </StyledIntegrationMeta>
  );
}

const StyledIntegrationMeta = styled.aside`
  .featured-image {
    width: 100%;
    margin-bottom: var(--spacing-base);
    border-radius: 3rem;
    overflow: hidden;

    img {
      width: 100%;
    }

    &.no-image {
      &::after {
        content: '';
        display: block;
        padding-top: 100%;
        background-color: var(--color-black);
      }
    }
  }

  .button-list {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-sm);
    margin-bottom: var(--spacing-lg);
  }

  .link-list {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xs);
    margin-bottom: var(--spacing-xl);
  }

  .divider {
    margin: var(--spacing-base) 0;
  }

  .categories-label {
    font: var(--font-label-0);
    letter-spacing: 0.056rem;
    text-transform: uppercase;
  }

  .link {
    text-decoration: underline;
  }

  .apps-link {
    text-decoration: underline;
    color: var(--color-neutral-50);
  }
`;
